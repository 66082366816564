/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    Modal,
    ModalBody,
    Progress,
    Row,
    Table, UncontrolledAlert,
    UncontrolledDropdown,
    UncontrolledTooltip,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
// import HorizontalGallery from "react-dynamic-carousel";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import Stars from "../../components/Mobi/Stars";
import {HttpConfig} from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import Utils from "../../Utils";
import TagsInput from "react-tagsinput";
import {Scrollbars} from "react-custom-scrollbars";
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from "@toast-ui/react-image-editor";
const queryString = require('query-string');
let rankOrder = false;
let countryOrder = false;

const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');

const blackTheme = {
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconb,
};
export default class DetailsIOS extends React.Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(props.props.location.search);
        this.state = {
            app: null,
            competitorsApps:[],
            developerApps:[],
            screenshotToEdit:null,
            showMoreClicked:false,
            rankingHistory:[],
            hasTopRanked: false,
            developer: null,
            devapps: [],
            similarApps: [],
            id:params['id'],
            loadingDetails:true,
            loadingDev:true,
            loadingTopRanks:true,
            libs:[],
            devices:[],
            categories:[],
            age:null,
            short:null,
            showMoreLibs:null,
            showMorePermissions:null,
            collectionModal:false,
            rankingHistoryTotal:0,
            sdkTags:[
                'Development Tool',
                'Ad Network',
                'Analytics',
                'Attribution',
                'Backend',
                'CRM',
                'Commerce',
                'Communication',
                'Data Intelligence',
                'Game Engine',
                'Marketing Automation',
                'Social',
                'Survey'
            ],
            priorityCountries:[
                'United States',
                'United Kingdom',
                'Australia',
                'South Korea',
                'New Zealand',
                'Belgium',
                'Germany',
            ]
        }
    }
    componentDidUpdate (prevProps) {
        if (prevProps.props.location.key !== this.props.props.location.key) {
            let params = queryString.parse(this.props.props.location.search);
            // document.documentElement.scrollTop = 0;
            window.scrollTo(0,0);
            this.setState({loadingDetails:true, id:params['id']},() => {
                this.getApplicationDetail();
                // this.getRankingHistory();
            })
        }
    }

    componentDidMount() {
        document.documentElement.scrollTop = 0;
        this.getApplicationDetail();
        this.getDeveloper();
        this.getSimilar();
        // this.getLibs();
        // this.getRankingHistory();
        // this.getCategories();
    }

    getApplicationDetail(){
        HttpConfig().get('app-ios/'+this.state.id)
            .then(res => {
                if(res.data.status){
                    let app = res.data.app;
                    document.getElementsByTagName("title")[0].text = app.trackCensoredName;
                    app.screenshotUrls = JSON.parse(app.screenshotUrls);
                    app.permissions = JSON.parse(app.permissions);
                    app.supportedDevices = JSON.parse(app.supportedDevices);
                    app.categories = JSON.parse(app.genres);


                    let permissions = [];
                    let devices = [];
                    if(app.permissions)
                        app.permissions.map((el,i) => {
                            permissions.push(el.id);
                        })
                    if(app.supportedDevices)
                        app.supportedDevices.map((el,i) => {
                            devices.push(el);
                        })
                    this.setState({app, loadingDetails:false, permissions,devices}, () => {
                        this.getLibs();
                        this.getRankingHistory();
                    });
                }
                else{
                    //error
                    this.setState({ loadingDetails:false})
                }
            }).catch(err => {
            this.setState({ loadingDetails:false})
        })
    }

    getLibs(){
        HttpConfig().get('app-ios/sdk/'+this.state.id)
            .then(res => {
                if(res.data.status){
                    let libs = [];
                    let a = this.state.app;
                    a.libs = JSON.parse(res.data.sdk);
                    a.libs.map((el,i)=>{
                        libs.push(el)
                    })
                    this.setState({libs,app:a})
                }
                else{
                    //error
                }
            }).catch(err => {

        })
    }

    // getDeveloper(){
    //     HttpConfig().get('/app-/dev/apps/'+this.state.id)
    //         .then(res => {
    //             if(res.data.status){
    //                 this.setState({devapps: res.data.fromdev, loadingDev:false})
    //             }
    //             else{
    //                 //error
    //                 this.setState({ loadingDev:false})
    //
    //             }
    //         }).catch(err => {
    //         //err
    //         this.setState({ loadingDev:false})
    //
    //     })
    // }
    // getSimilar(){
    //     HttpConfig().get('/similar/'+this.state.id)
    //         .then(res => {
    //             if(res.data.status){
    //                 this.setState({competitorsApps: res.data.similar})
    //             }
    //             else{
    //                 //error
    //
    //             }
    //         }).catch(err => {
    //         //err
    //
    //     })
    // }
    getRankingHistory(){
        HttpConfig().get('/app-ios/history/'+this.state.id)
            .then(res => {
                if(res.data.status){
                    let arr =  JSON.parse(res.data.history);
                    let rankingHistory = [];
                    // console.log(arr);
                    arr.map((el,i)=> {
                        let top = 700;
                        // if(i<12){
                        el.positions.map(p => {
                            if(p.position && p.position < top)
                                top = p.position;
                        })
                        if(top < 10000)
                            rankingHistory.push({
                                position:top,
                                country_name: el.country_name,
                                category_name: el.primary_genre_name,
                                list_title: el.list_title ? el.list_title : el.list_name
                            })
                        // }
                    })
                    let ark = rankingHistory;
                    if(rankingHistory.length>0){
                        ark = [];
                        this.state.priorityCountries.map(el => {
                            rankingHistory.map(r => {
                                if(el === r.country_name)
                                    ark.push(r)
                            })
                        })
                        this.state.priorityCountries.map(el => {
                            rankingHistory.map(r => {
                                if(el !== r.country_name)
                                    ark.push(r)
                            })
                        })
                    }


                    this.setState({rankingHistory:ark,hasTopRanked:rankingHistory.length>0, rankingHistoryTotal:rankingHistory.length})
                }
                else{
                    //error

                }
            }).catch(err => {
            //err

        })
    }


    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row >
                        <Col className="card-wrapper" lg="12">
                            {this.state.app &&
                            [this.renderIfDeleted(),
                                this.renderAppScreens(),
                                this.renderRankings(),
                                this.renderDescription(),
                                <Row>
                                    <Col lg={6}>
                                        {this.renderPermissions()}
                                    </Col>
                                    <Col lg={6}>
                                        {this.renderLibraries()}
                                    </Col>
                                </Row>,
                                this.renderCompetitorApps(),
                                this.renderDevApps()]
                            }
                        </Col>
                    </Row>
                    {this.renderScreensModal()}
                    {/*{this.addToCollectionModal()}*/}
                </Container>
            </>
        )
    }
    // addToCollectionModal(){
    //     return (
    //         <Modal
    //             size={"md"}
    //             className="modal-md modal-dialog-centered"
    //             isOpen={this.state.collectionModal}
    //             toggle={() => this.setState({collectionModal: false})}
    //         >
    //             <>
    //                 <div className="modal-header">
    //                     <h6 className="modal-title" id="modal-title-default">
    //                         Selecting Collection
    //                     </h6>
    //                     <button
    //                         aria-label="Close"
    //                         className="close"
    //                         data-dismiss="modal"
    //                         type="button"
    //                         onClick={() => this.setState({collectionModal:false})}
    //                     >
    //                         <span aria-hidden={true}>×</span>
    //                     </button>
    //                 </div>
    //                 <div className="modal-body" >
    //                     <Form>
    //                         <Select2
    //                             className="form-control"
    //                             options={{
    //                                 placeholder: "Select"
    //                             }}
    //                             value={this.state.selectedCategory}
    //                             onSelect={(e) => {
    //                                 this.setState({selectedCategory: e.target.value});
    //                             }}
    //                             data={this.state.categories}
    //                         />
    //                     </Form>
    //                 </div>
    //                 <div className="modal-footer">
    //                     <Button color="primary" type="button">
    //                         + New Collection
    //                     </Button>
    //                     <Button
    //                         className="ml-auto"
    //                         color="success"
    //                         data-dismiss="modal"
    //                         type="button"
    //                         disabled={!this.state.selectedCategory}
    //                         onClick={() => {this.setState({collectionModal:false}); this.addToCollectionPost(); }}
    //                     >
    //                         Add
    //                     </Button>
    //                 </div>
    //             </>
    //         </Modal>
    //     )
    // }
    renderAppScreens(){
        return(
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col style={{display:'flex',alignItems:'center' }}>
                            <img
                                alt="..."
                                className="img-fluid mr-1"
                                style={{width:20, height:20}}
                                src={"/img/app-store.png"}
                            />&nbsp;
                            <h3 className="mb-0">App Store Listing</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className="align-items-center">
                        <Col className="col-auto">
                            <a href="#pablo" onClick={e => {
                                e.preventDefault();
                                this.setState({
                                    showEditor:true,
                                    screenshotToEdit:this.state.app.artworkUrl512
                                })
                            }}>
                                <img
                                    alt="..."
                                    className="avatar-details  rounded shadow"
                                    src={this.state.app.artworkUrl512}
                                />
                            </a>
                        </Col>
                        <div className="col">
                            <h2 className="mb-1">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    {this.state.app.trackCensoredName}
                                </a>
                            </h2>
                            <p className="text-md-leftf mb-0">
                                <small>by </small> <Link to={'/ios-dev?id='+this.state.app.artistName}>{this.state.app.artistName}</Link>
                            </p>
                            <p className="text-md-left mb-0">
                                <small>Whats's new:</small> {this.state.app.releaseNotes ? this.state.app.releaseNotes.substring(0,100) : null}
                            </p>
                            <p className="text-sm text-muted text-md-left">
                                {(this.state.app.categories && this.state.app.categories.length > 0) && <span>
                                    {this.state.app.categories.length > 1 ? this.state.app.categories[1].replace('_',' '):
                                        this.state.app.categories[0].replace('_',' ')}&nbsp;</span>}
                            </p>
                        </div>
                    </Row>
                    {/* <Row>
                        <Col xl={12} className={"mt-4"}>
                            <HorizontalGallery
                                tiles={this.state.app.screenshotUrls.map((el,i) => (
                                    <img key={i} src={el} className="img-fluid cursor-pointer rounded" style={{marginRight:5, height:400}}
                                         alt="" onClick={() => this.setState({showEditor:true,
                                        screenshotToEdit:el})} />
                                ))}
                                elementWidth={250}
                                fadeDistance={1}
                                minPadding={1}
                            />
                        </Col>
                    </Row> */}
                </CardBody>
            </Card>
        )
    }
    renderRankings(){
        return(
            <Row >
                <Col xl={6} md={12}>
                    <Row>
                        <Col xl={4}>
                            {this.getCard("Genre", this.state.app.genres ? JSON.parse(this.state.app.genres)[0] : "","")}
                            {this.getCard("App version",  this.state.app.version, " ")}
                            {this.getCard("App size", this.formatBytes(this.state.app.fileSizeBytesNumeric), "")}
                        </Col>
                        <Col xl={4}>
                            {this.getCard("Rating", !this.state.app.averageUserRatingForCurrentVersion ? 'Not Rated' : this.state.app.averageUserRatingForCurrentVersion.toString().match(/\d+\.\d{2}/), this.formatNumber(this.state.app.userRatingCountForCurrentVersion) +' Rating')}
                            {this.getCard("Min Os Version", this.state.app.minimumOsVersion, "")}
                            {this.getCard("Content rating", this.state.app.contentAdvisoryRating, null)}
                        </Col>
                        <Col xl={4}>
                            {this.getCardEpic("Ranking", this.state.hasTopRanked ? 'Top Ranked ' : 'Not Ranked', (this.state.hasTopRanked && this.state.rankingHistoryTotal > 0 ) ? 'in '+ this.state.rankingHistoryTotal+' country' : '')}
                            {this.getCard("App age",this.state.app.releaseDate, "")}
                            {this.getCard("Pricing", this.state.app.price == "0" ? "FREE" : "PAID", this.state.app.price === 0 ? "" : this.state.app.price)}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {this.renderDevices()}
                        </Col>
                    </Row>
                </Col>
                <Col xl="6" md={12}>
                    <Card style={{height:'96.3%',minHeight:300, overflow:'hidden'}}>
                        <CardHeader className="border-0">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h3 className="mb-0">App Store Rankings (last 10 days)</h3>
                                </div>
                                <div className="col text-right">

                                </div>
                            </Row>
                        </CardHeader>
                        <div style={{height:'100%', width:'100%', overflow:'hidden'}}>
                            <Scrollbars style={{height:'90%', width:'100%', position:'absolute'}}>
                                {this.state.hasTopRanked ? this.getRankingTable() :
                                    <div style={{width:'100%', alignItems:'center', justifyContent:'center', textAlign:'center'}}>
                                        <h4>This App Is not Ranked</h4>
                                </div>}
                            </Scrollbars>
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    }

    renderDevices(){

        return (
            <Card style={{minHeight:300, overflow:'hidden'}}>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">Supported Devices</h3>
                        </Col>

                    </Row>
                </CardHeader>
                <div style={{height:'100%', width:'100%', overflow:'hidden'}} className={""}>
                    <Scrollbars hideTracksWhenNotNeeded style={{ width:'99%', height:'80%', overflow:'hidden', position:'absolute', }} >
                        <Row className={"p-0 ml-2 mt-2"} style={{width:'98%'}}>
                            <Form>
                                <TagsInput
                                    disabled
                                    onlyUnique
                                    className="bootstrap-tagsinput"
                                    onChange={this.handleTagsinput}
                                    value={this.state.devices}
                                    tagProps={{ className: "tag badge mr-1" }}
                                    inputProps={{
                                        className: "",
                                        placeholder: "",
                                        opacity:0
                                    }}
                                />
                            </Form>
                        </Row>
                    </Scrollbars>
                </div>
            </Card>
        );

    }

    formatNumber(num) {
        num = parseInt(num);
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    renderLibraries(){
        return(
            <Card style={{minHeight:400, maxHeight:400, }}>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">Libraries and Dependencies</h3>
                        </Col>
                        {!this.state.showMoreLibs &&

                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="btn"
                                color="primary"
                                role="button"
                                size="sm"
                            >

                                Show Filters
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                {this.state.sdkTags.map(sdk => {
                                    return (
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={e => {e.preventDefault();
                                                let libs = [];
                                                if(this.state.app.libs)
                                                    this.state.app.libs.map((el,i) => {
                                                        el.tags.map(t => {
                                                            if(t === sdk)
                                                                libs.push(el);
                                                        })
                                                    })
                                                this.setState({libs})
                                            }}
                                        >
                                            {sdk}
                                        </DropdownItem>
                                    )
                                })}

                            </DropdownMenu>
                        </UncontrolledDropdown>
                        }
                    </Row>
                </CardHeader>
                <div style={{height:'100%', width:'100%', overflow:'hidden'}} className={""}>
                    <Scrollbars hideTracksWhenNotNeeded style={{ width:'99%', height:'80%', overflow:'hidden', position:'absolute', }} >
                        <Row className={"p-0 ml-2 mt-2"} style={{width:'98%'}}>
                            {this.state.libs.map((el,i) => {
                                return (
                                    <Col xl={4} className={"mb-3 pt-2 sdk-unit"} onClick={() => this.gotoLibLink(el)}>
                                        <div style={{display:'flex', flexDirection:'row',minHeight:40, maxHeight:40, alignItems:'center'}}>
                                            {el.icon && <img src={el.icon}  className={"shadow rounded"} alt="" style={{maxHeight:40, maxWidth:40}} />}
                                            <h3 className={"ml-2"}>{el.title}</h3>
                                        </div>
                                        <div style={{paddingTop:3}}>
                                            <h4 className={"text-sm"}><strong>ID:</strong> {el.id}</h4>
                                        </div>
                                        <h5 className={"text-muted"} >{el.tags[0]}&nbsp;{el.tags.length > 1 ?
                                            <>
                                                &nbsp;<u id={"print-tooltip-"+i}><span className={"h6 text-muter"}>+{el.tags.length -1} more</span></u>

                                                <UncontrolledTooltip
                                                    placement="top"
                                                    target={"print-tooltip-"+i}
                                                >
                                                    {el.tags.map((t,i) => {
                                                        return t+(i === el.tags.length -1 ? '' : ', ')
                                                    })}
                                                </UncontrolledTooltip>
                                            </> : null}</h5>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Scrollbars>
                </div>
            </Card>
        )
    }
    renderPermissions(){
        return(
            <Card style={{minHeight:400, maxHeight:400,overflow:'hidden'}}>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">App Permissions</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <div style={{height:'100%', width:'100%', overflow:'hidden'}} className={""}>
                    <Scrollbars hideTracksWhenNotNeeded style={{ width:'99%', height:'80%', overflow:'hidden', position:'absolute', }} >
                        <Row className={"p-0 ml-2 mt-2"} style={{width:'98%'}}>
                            <Form>
                                <TagsInput
                                    disabled
                                    onlyUnique
                                    className="bootstrap-tagsinput"
                                    onChange={this.handleTagsinput}
                                    value={this.state.permissions}
                                    tagProps={{ className: "tag badge mr-1" }}
                                    inputProps={{
                                        className: "",
                                        placeholder: "",
                                        opacity:0
                                    }}
                                />
                            </Form>
                        </Row>
                    </Scrollbars >
                </div>
            </Card>
        )
    }
    renderDescription(){
        if(!this.state.app)
            return null;

        let desc = this.state.app.description;
        if(!this.state.showMoreClicked){
            if(desc.length > 1180){
                desc = desc.slice(0,1180);
            }
        }

        return(
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">App Description</h3>
                        </Col>
                        <div className="col text-right">
                            {!this.state.showMoreClicked &&
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => this.setState({showMoreClicked:true})}
                            >
                                Show Full Description
                            </Button>
                            }
                        </div>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div style={{whiteSpace:"pre-line"}}>{desc}</div>
                </CardBody>
            </Card>
        )
    }
    // renderCompetitorApps(){
    //     return(
    //         <Card>
    //             <CardHeader>
    //                 <Row className="align-items-center">
    //                     <Col>
    //                         <h3 className="mb-0">Competitors Apps</h3>
    //                     </Col>
    //                 </Row>
    //             </CardHeader>
    //             <CardBody>
    //                 <Row className={"justify-content-center"}>
    //                     {this.state.competitorsApps.map((el, i) => {
    //                         if(i < 6)
    //                             return(
    //                                 <Col lg={2} sm={6} className={"justify-content-center d-flex"}>
    //                                     <Link to={"details?id="+el.app_id} className="pointer-cursor" key={i}
    //                                           style={{textDecoration:'none',display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center'}}>
    //                                         <img
    //                                             alt="..."
    //                                             className="avatar-xl  rounded mb-3 shadow"
    //                                             src={el.icon}
    //                                         />
    //                                         <h4 className={"text-center"}>{el.title? el.title.substring(0,29) : ''}</h4>
    //                                         <small className="h4 font-weight-light mt-1 text-muted">
    //                                             {el.installs}
    //                                         </small>
    //                                         <div className="mt--1 pl-2 pr-2">
    //                                             <Stars score={el.score ? el.score : 0} />
    //                                         </div>
    //                                     </Link>
    //                                 </Col>
    //                             )
    //                     })}
    //
    //                 </Row>
    //             </CardBody>
    //         </Card>
    //     )
    // }
    // renderDevApps(){
    //
    //     return(
    //         <Card>
    //             <CardHeader>
    //                 <Row className="align-items-center">
    //                     <Col>
    //                         <h3 className="mb-0">More from {this.state.app? this.state.app.developer : ''}</h3>
    //                     </Col>
    //                 </Row>
    //             </CardHeader>
    //             <CardBody>
    //                 <Row className={"justify-content-center"}>
    //                     {this.state.devapps.map((el, i) => {
    //                         if(i < 6)
    //                             return(
    //                                 <Col lg={2} sm={6} className={"justify-content-center d-flex"}>
    //                                     <Link to={"details?id="+el.app_id} className="pointer-cursor" key={i}
    //                                           style={{textDecoration:'none',display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center'}}>
    //                                         <img
    //                                             alt="..."
    //                                             className="avatar-xl  rounded mb-3 shadow"
    //                                             src={el.icon}
    //                                         />
    //                                         <h4 className={"text-center"}>{el.title? el.title.substring(0,29) : ''}</h4>
    //                                         <small className="h4 font-weight-light mt-1 text-muted">
    //                                             {el.installs}
    //                                         </small>
    //                                         <div className="mt--1 pl-2 pr-2">
    //                                             <Stars score={el.score ? el.score : 0} />
    //                                         </div>
    //                                     </Link>
    //                                 </Col>
    //                             )
    //                     })}
    //
    //                 </Row>
    //             </CardBody>
    //         </Card>
    //     )
    // }
    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Applications
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-rocket" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            App Store App Details
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <a className=" btn btn-sm btn-neutral" color="default" size="sm" href={this.state.app? this.state.app.trackViewUrl : '#'} target="_blank">
                                    View on App Store
                                </a>
                                {/*<Button className="btn-neutral" color="default" size="sm" onClick={()=>this.setState({collectionModal:true})}>*/}
                                {/*    Add To Collection*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }



    renderScreensModal() {
        return (
            <Modal
                size={"lg"}
                className="modal-lg modal-dialog-centered"
                isOpen={this.state.showEditor}
                toggle={() => this.setState({showEditor:!this.state.showEditor})}
            >
                {this.getEditorInstance()}
            </Modal>

        )
    }
    getCardEpic(v1,v2,v3) {
        return (
            <Card className="bg-gradient-white">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                                {v1}&nbsp;
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                 <i className="fas fa-trophy"></i>&nbsp;{v2}
                             </span>
                        </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                      {v3}&nbsp;
                    </span>
                    </p>
                </CardBody>
            </Card>)
    }
    getCard(v1,v2,v3) {
        return (
            <Card className="bg-gradient-white">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0">
                                {v1}&nbsp;
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                 {v2}&nbsp;
                             </span>
                        </div>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                        <span className="text-nowrap text-light">
                      {v3}&nbsp;
                    </span>
                    </p>
                </CardBody>
            </Card>)
    }

    getRankingTable(){
        return(
            <Table className="align-items-center table-flush mt-2"  responsive>
                <thead className="thead-light">
                <tr>
                    <th className="sort" data-sort="" scope="col"
                        onClick={() => {this.sortRankingHistory('position',false, rankOrder);rankOrder = !rankOrder}} >Rank</th>
                    <th className="sort" data-sort="" scope="col"
                        onClick={() => {this.sortRankingHistory('country_name', true, countryOrder);countryOrder = !countryOrder}}>Country</th>
                    <th >Category</th>
                    <th >List</th>
                </tr>
                </thead>
                <tbody className="list">
                {this.state.rankingHistory.map((el, i) => {
                    return (
                        <tr>
                            <td>
                                <span className="text-muted">
                                        {el.position}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted" id={"print-tooltip"+i}>
                                    {el.country_name}
                                </span>
                            </td>
                            <td>
                                <span className="text-muted">
                                       {el.category_name}
                                </span>
                            </td>
                            <td>
                                {el.list_title}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        );
    }


    addToCollectionPost(id){
        // toast('please wait while we add your app to your favourite collection');
        HttpConfig().post('favourite',{
            app_id:this.state.app.id,
            category_id:this.state.selectedCategory
        })
            .then(res => {
                if(res.data.status){
                    // toast.success('App successfully added to your collection');
                    this.getCategories();
                    this.props.notify("success", "Updated",'Application successfully added to your collection');

                }
                else{
                    //error
                    // toast.error('there was an error while adding your app');
                    this.props.notify("danger", "Error",'there was an error while adding your app');
                }
            }).catch(err => {
            this.props.notify("danger", "Error",'there was an error while adding your app');
            // toast.error('there was an error while adding your app');
        })
    }

    getCategories(){
        HttpConfig().get('categories')
            .then(res => {
                if(res.data.status){
                    let arr = [];
                    res.data.categories.map(el =>{
                        arr.push({id:el.id, text:el.title+ "("+el.total+" apps)"})
                    })
                    this.setState({categories:arr});
                }
                else{
                    //error
                }
            }).catch(err => {

        })

    }

    formatBytes(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

    gotoLibLink(el){
        window.open(el.website, "_blank") //to open new page
    }
    sortRankingHistory(attr,isString, desc){
        let rankingHistory = this.state.rankingHistory;
        Utils.sortByAttr(rankingHistory,attr, isString,desc)
        this.setState({rankingHistory});
    }
    getDeveloper(){
        HttpConfig().get('/app-ios/developer/'+this.state.id)
            .then(res => {
                if(res.data.status){
                    this.setState({devapps: res.data.fromdev, loadingDev:false})

                    // this.setState({devapps: res.data.fromdev, loadingDev:false})
                }
                else{
                    //error
                    this.setState({ loadingDev:false})

                }
            }).catch(err => {
            //err
            this.setState({ loadingDev:false})

        })
    }
    getSimilar(){
        HttpConfig().get('/app-ios/similar/'+this.state.id)
            .then(res => {
                if(res.data.status){
                    this.setState({similarApps: res.data.fromdev, loadingDev:false})

                    // this.setState({devapps: res.data.fromdev, loadingDev:false})
                }
                else{
                    //error
                    this.setState({ loadingDev:false})

                }
            }).catch(err => {
            //err
            this.setState({ loadingDev:false})

        })
    }

    getEditorInstance(path){
        return (
            <div className="modal-content">
                <ImageEditor
                    // ref={this.editorRef}
                    includeUI={{
                        loadImage: {
                            path: this.state.screenshotToEdit,
                            name: 'SampleImage'
                        },
                        theme: blackTheme,
                        initMenu: 'filter',
                        uiSize: {
                            width: '1000px',
                            height: '700px'
                        },
                        menuBarPosition: 'bottom'
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={1000}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70
                    }}
                    usageStatistics={true}
                />
            </div>
        );
    }

    renderCompetitorApps(){
        return(
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">Competitors Apps</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className={"justify-content-center"}>
                        {this.state.similarApps.map((el, i) => {
                            if(i < 6)
                                return(
                                    <Col lg={2} sm={6} className={"justify-content-center d-flex"}>
                                        <Link to={"details-ios?id="+el.app_id} className="pointer-cursor" key={i}
                                              style={{textDecoration:'none',display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center'}}>
                                            <img
                                                alt="..."
                                                className="avatar-xl  rounded mb-3 shadow"
                                                src={el.icon}
                                            />
                                            <h4 className={"text-center"}>{el.title? el.title.substring(0,29) : ''}</h4>
                                            <div className="mt--1 pl-2 pr-2">
                                                <Stars score={el.score ? el.score : 0} />
                                            </div>
                                            <small className="h4 font-weight-light mt-1 text-muted">
                                                {el.reviews} Reviews
                                            </small>
                                        </Link>
                                    </Col>
                                )
                        })}

                    </Row>
                </CardBody>
            </Card>
        )
    }

    renderIfDeleted(){
        if(this.state.app.market_status === "UNPUBLISHED")
            return (
                <UncontrolledAlert color="danger">
                <span className="alert-icon">
                  <i className="fa fa-trash" />
                </span>
                    <span className="alert-text ml-1">
                  <strong>Alert: </strong> This app is <u>Unpublished</u> from the App Store
                </span>
                </UncontrolledAlert>);
    }

    renderDevApps(){
        return(
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">More from {this.state.app.artistName? this.state.app.artistName : ''}</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row className={"justify-content-center"}>
                        {this.state.devapps.map((el, i) => {
                            if(i < 6)
                                return(
                                    <Col lg={2} sm={6} className={"justify-content-center d-flex"}>
                                        <Link to={"details-ios?id="+el.app_id} className="pointer-cursor" key={i}
                                              style={{textDecoration:'none',display:'flex', flexDirection:'column', width:'100%', alignItems:'center', justifyContent:'center'}}>
                                            <img
                                                alt="..."
                                                className="avatar-xl  rounded mb-3 shadow"
                                                src={el.icon}
                                            />
                                            <h4 className={"text-center"}>{el.title? el.title.substring(0,29) : ''}</h4>
                                            <div className="mt--1 pl-2 pr-2">
                                                <Stars score={el.score ? el.score : 0} />
                                            </div>
                                            <small className="h4 font-weight-light mt-1 text-muted">
                                                {el.reviews} Reviews
                                            </small>
                                        </Link>
                                    </Col>
                                )
                        })}

                    </Row>
                </CardBody>
            </Card>
        )
    }
}
