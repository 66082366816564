/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, Col,
    Container, Row, CardText

} from "reactstrap";
import Highlight from "react-highlight";

export default class AdsTutorial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tutorialFor: 0,
            show: "NONE"
        };
    }

    componentDidMount() {
        this.addLink("css/androidstudio.css");
    }

    addLink(href, rel = "stylesheet") {
        let link = document.createElement("link");
        link.href = href;
        link.rel = rel;
        document.getElementsByTagName("head")[0].appendChild(link);
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            {this.getStep0()}
                            {this.getCards()}
                            {this.state.tutorialFor === 1 && [this.getStep1_(), this.getStep2_()]}
                            {this.state.tutorialFor === 2 && [this.getStep1(), this.getStep2()]}
                            {this.state.tutorialFor !== 0 && this.getCards_()}
                            {this.state.show === "INTERSTITIAL" && this.getStep3()}
                            {this.state.show === "BANNER" && this.getStep4()}
                            {this.state.show === "REWARDED" && this.getStep5()}
                            {this.state.show === "NATIVE" && this.getStep6()}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    getHeader() {
        return (
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            MobiOption Ads Tutorial
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    getStep0() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Mobitoptions Smart Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <CardText>
                        <p className={"pt-0"}>
                            <strong>Mobioptions Smart Ads</strong> is a tool that makes integrating and controlling ads
                            easier and faster! it
                            allows you to add integrate three ad networks (Admob, Facebook Audience Network and Unity
                            Ads) <br/>
                            The library gives full control over how ads are shown in your Android Application,
                            allowing you to change ads settings in your apps on the fly <strong>-no need to update the
                            apk on the store-</strong> <br/> <br/>
                            In addition to instant ads tweaking, <strong>Mobioptions Smart Ads</strong> gives multiple
                            real time insights about your app like: <br/>
                            - <strong>Real Time Installs</strong> that display statistics about installs from play store
                            and other platforms..<br/>
                            - <strong>Real Time Activity</strong> that displays statistics about the users activities
                            like the number of first time users, returning users..<br/>
                            - <strong>Real Time Ads insights</strong> that displays statistics about the ads like number
                            of shown ads of each network..<br/>
                        </p>
                    </CardText>
                </CardBody>
            </Card>
        );
    }

    getStep1() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Installing The Library</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Add jitpack repository to your project gradle file <code>project/build.gradle</code>
                    </p>
                    <Highlight className='JavaScript'>
                        <span className={"commented-code"}>{`// Top-level build file where you can add configuration options common to all sub-projects/modules.

buildscript {
    
    repositories {
        google()
        jcenter()
        
    }
    dependencies {
        classpath 'com.android.tools.build:gradle:3.6.1'
        

        // NOTE: Do not place your application dependencies here; they belong
        // in the individual module build.gradle files
    }
}
`}</span><span className={""}>{`
allprojects {
    repositories {
        google()
        jcenter()
        maven { url 'https://jitpack.io' }

    }
}
`}</span><span className={"commented-code"}>{`
task clean(type: Delete) {
    delete rootProject.buildDir
}
`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Download this two file files
                        {/*(<code>nativetemplates.aar</code> and <code>unity-ads.aar</code>)*/}
                        <br/>
                        <ul>
                            <li>
                                Native Template:&nbsp;
                                <a
                                    href="https://github.com/MobiOptions/MobiOptionsAds/raw/master/MobiOptionsAds/libs/nativetemplates.aar">
                                    LINK 1
                                </a>
                                ,
                                <a href="">
                                    Link 2
                                </a>
                            </li>
                            <li>
                                Unity Ads:&nbsp;
                                <a
                                    href="https://github.com/osamaedev/MobiOptionsAds/raw/master/MobiOptionsAds/libs/unity-ads.aar">
                                    Link 1
                                </a>
                                ,
                                <a href="#">
                                    Link 2
                                </a>
                            </li>
                        </ul>

                    </p>
                    <p className={"form-control-label"}>
                        2 - Add the two files <code>nativetemplates.aar</code> and <code>unity-ads.aar</code> to the
                        folder <code>project/app/libs</code> like this: <strong>(create it if it does not
                        exist)</strong>
                        <br/>
                        <ul>
                            <li>
                                .../app/libs/nativetemplates.aar
                            </li>
                            <li>
                                .../app/libs/unity-ads.aar
                            </li>
                        </ul>
                    </p>
                    <p className={"form-control-label"}>
                        2 - Add these 3 lines to your app <code>build.gradle</code> file (app module
                        scope) <code>project/app/build.gradle</code>
                    </p>
                    <Highlight className='JavaScript'>
                        <span className={"commented-code"}>{`plugins {
    id 'com.android.application'
}

android {
    compileSdkVersion 30
    buildToolsVersion "30.0.2"

    defaultConfig {
        applicationId "com.application.libtesting"
        minSdkVersion 20
        targetSdkVersion 30
        versionCode 1
        versionName "1.0"
        `}</span><span className={""}>{`multiDexEnabled true`}</span><span className={"commented-code"}>{`
        testInstrumentationRunner "androidx.test.runner.AndroidJUnitRunner"
    }

    buildTypes {
        release {
            minifyEnabled false
            proguardFiles getDefaultProguardFile('proguard-android-optimize.txt'), 'proguard-rules.pro'
        }
    }
    `}</span><span className={""}>{`compileOptions {
        sourceCompatibility JavaVersion.VERSION_1_8
        targetCompatibility JavaVersion.VERSION_1_8
    }`}</span><span className={"commented-code"}>{`
}

dependencies {

    implementation 'androidx.appcompat:appcompat:1.2.0'
    implementation 'com.google.android.material:material:1.2.1'
    implementation 'androidx.constraintlayout:constraintlayout:2.0.2'
    testImplementation 'junit:junit:4.13.1'
    androidTestImplementation 'androidx.test.ext:junit:1.1.2'
    androidTestImplementation 'androidx.test.espresso:espresso-core:3.3.0'

    `}</span><span className={""}>{`implementation 'com.github.MobiOptions:MobiOptionsAds:1.1.0'
    implementation files('libs/unity-ads.aar')
    implementation files('libs/nativetemplates.aar')`}</span><span className={"commented-code"}>{`

}`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - Sync your project by clicking on <code>sync now</code> button
                    </p>
                </CardBody>
            </Card>
        );
    }

    getStep2() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Initialising The Library</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create a new class that extends <code>Application</code>, it's the first class that get
                        called when the app lunches and will run throughout the entire lifecycle of the application. and
                        add the following code:</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.example.myapplication;`}</span><span
                        className={""}>{`

import android.app.Application;
import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.MobiOptionsAdsInit;
import org.jetbrains.annotations.NotNull;

public class RootApplication extends Application {

    private static MobiOptionsAdsInit mobiOptionsAdsInit;
    public static RootApplication rootApplication;

    public static synchronized void setupMobiOptionsAds(@NotNull MobiInitializationListener listener) {
        if (mobiOptionsAdsInit == null) {
            MobiOptionsAdsInit.setAdmobTestDevices(Collections.singletonList("YOUR-TEST-DEVICE-ID-PLACED-HERE"));
            MobiOptionsAdsInit.setDisableStoreCheck(true);// true to simulate the play store behaviour                                  
            mobiOptionsAdsInit = MobiOptionsAdsInit.build(rootApplication,
                    `}</span><span className={""}>{`"YOUR_TOKEN_HERE",`}</span><span className={""}>{`
                    true,// If you set this to false, the list of the test devices will be ignored.                                                       
                    listener);
        } else if (mobiOptionsAdsInit.isInitialized()) {
            listener.onInitializationSuccess();
        }
    }

    @Override
    public void onCreate() {
        super.onCreate();
    }

    public RootApplication() {
        super();
        rootApplication = this;
    }
}
`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Replace the String <code>YOUR_TOKEN_HERE</code> by the token generated for your application.
                    </p>
                    <p className={"form-control-label"}>
                        <code>MobiOptionsAdsInit(context, token, listener)</code> takes three parameters
                        the <code>context</code>, the <code>token</code> and a <code>listener</code>. <br/>The listener
                        triggers two functions
                        <code> onInitializationSuccess()</code> when the library is successfully initialised,
                        and <code>onInitializationFailed(error)</code> when the library fails to initialise, either due
                        to internet
                        problems or server problems.</p>
                    <p className={"form-control-label"}>
                        3 - In your <code>AndroidManifest.xml</code> add the <code>name</code> attribute
                        as <code>RootApplication</code> and change your <code>admob</code> information</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    package=" com.application.libtesting">
    <uses-permission android:name="android.permission.INTERNET" />
    <application
        `}</span><span className={""}>{`android:name=".RootApplication"`}</span><span className={"commented-code"}>{`
        android:allowBackup="true"
        android:icon="@mipmap/ic_launcher"
        android:label="@string/app_name"
        android:roundIcon="@mipmap/ic_launcher_round"
        android:supportsRtl="true"
        android:theme="@style/AppTheme">
        <activity android:name=".Active"></activity>
        <activity android:name=".MainActivity">
            <intent-filter>
                <action android:name="android.intent.action.MAIN" />

                <category android:name="android.intent.category.LAUNCHER" />
            </intent-filter>
        </activity>

        `}</span><span className={""}>{`<meta-data
            android:name="com.google.android.gms.ads.APPLICATION_ID"
            android:value="ADMOB-ID"/>`}</span><span className={"commented-code"}>{`

    </application>

</manifest>`}</span>
                    </Highlight>
                </CardBody>
            </Card>
        );
    }

    getStep1_() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Download the code base</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        Download the prebuild project and open it in your Android Studio&nbsp;
                        <a
                            href="https://github.com/osamaedev/MobiOptionsExample/archive/master.zip">
                            LINK 1
                        </a>

                    </p>
                </CardBody>
            </Card>
        );
    }

    getStep2_() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Initialising The Library</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create a new class that extends <code>Application</code>, it's the first class that get
                        called when the app lunches and will run throughout the entire lifecycle of the application. and
                        add the following code:</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.example.myapplication;`}</span><span
                        className={"commented-code"}>{`

import android.app.Application;
import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.MobiOptionsAdsInit;
import org.jetbrains.annotations.NotNull;

public class RootApplication extends Application {

    private static MobiOptionsAdsInit mobiOptionsAdsInit;
    public static RootApplication rootApplication;

    public static synchronized void setupMobiOptionsAds(@NotNull MobiInitializationListener listener) {
        if (mobiOptionsAdsInit == null) {
            MobiOptionsAdsInit.setAdmobTestDevices(Collections.singletonList("YOUR-TEST-DEVICE-ID-PLACED-HERE"));
            MobiOptionsAdsInit.setDisableStoreCheck(true);// true to simulate the play store behaviour                                  
            mobiOptionsAdsInit = MobiOptionsAdsInit.build(rootApplication,
                    `}</span><span className={""}>{`"YOUR_TOKEN_HERE",`}</span><span className={"commented-code"}>{`
                    true,// If you set this to false, the list of the test devices will be ignored.                                                       
                    listener);
        } else if (mobiOptionsAdsInit.isInitialized()) {
            listener.onInitializationSuccess();
        }
    }

    @Override
    public void onCreate() {
        super.onCreate();
    }

    public RootApplication() {
        super();
        rootApplication = this;
    }
}
`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Replace the String <code>YOUR_TOKEN_HERE</code> by the token generated for your application.
                    </p>
                    <p className={"form-control-label"}>
                        <code>MobiOptionsAdsInit(context, token, listener)</code> takes three parameters
                        the <code>context</code>, the <code>token</code> and a <code>listener</code>. <br/>The listener
                        triggers two functions
                        <code> onInitializationSuccess()</code> when the library is successfully initialised,
                        and <code>onInitializationFailed(error)</code> when the library fails to initialise, either due
                        to internet
                        problems or server problems.</p>
                    <p className={"form-control-label"}>
                        3 - In your <code>AndroidManifest.xml</code> add the <code>name</code> attribute
                        as <code>RootApplication</code> and change your <code>admob</code> information</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`<?xml version="1.0" encoding="utf-8"?>
<manifest xmlns:android="http://schemas.android.com/apk/res/android"
    package=" com.application.libtesting">
    <uses-permission android:name="android.permission.INTERNET" />
    <application
        `}</span><span className={""}>{`android:name=".RootApplication"`}</span><span className={"commented-code"}>{`
        android:allowBackup="true"
        android:icon="@mipmap/ic_launcher"
        android:label="@string/app_name"
        android:roundIcon="@mipmap/ic_launcher_round"
        android:supportsRtl="true"
        android:theme="@style/AppTheme">
        <activity android:name=".Active"></activity>
        <activity android:name=".MainActivity">
            <intent-filter>
                <action android:name="android.intent.action.MAIN" />

                <category android:name="android.intent.category.LAUNCHER" />
            </intent-filter>
        </activity>

        `}</span><span className={""}>{`<meta-data
            android:name="com.google.android.gms.ads.APPLICATION_ID"
            android:value="ADMOB-ID"/>`}</span><span className={"commented-code"}>{`

    </application>

</manifest>`}</span>
                    </Highlight>
                </CardBody>
            </Card>
        );
    }

    getStep3() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Interstitial Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create an Instance of MobiOptionsInterstitial in the activity where you want to serve
                        Interstitial
                        Ads</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;
import android.os.Bundle;
import com.lib.mobioptionsads.MobiInitializationListener;`}</span><span>{`
import com.lib.mobioptionsads.interstitial.MobiOptionsInterstitial;`}</span><span className={"commented-code"}>{`

public class MainActivity extends AppCompatActivity {

    `}</span><span>{`private MobiOptionsInterstitial interstitial;`}</span><span
                        className={"commented-code"}>{`
    private Handler handler = new Handler(Looper.getMainLooper());


    @Override
    protected void onCreate(Bundle savedInstanceState) {}
}`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - In the <code>onCreate()</code> method call the initialising listener (if not already
                        done),<br/>
                        Replace <code>interstitial_0</code> with the name of the ad you wanna serve if you have more
                        than 1 Interstitial</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;

import android.os.Bundle;

`}</span><span className={""}>{`import com.lib.mobioptionsads.MobiInitializationListener;`}</span><span
                        className={"commented-code"}>{`
import com.lib.mobioptionsads.interstitial.MobiOptionsInterstitial;

public class MainActivity extends AppCompatActivity {

    private MobiOptionsInterstitial interstitial;
    private Handler handler = new Handler(Looper.getMainLooper());
    
    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        `}</span><span className={""}>{`RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                interstitial = new MobiOptionsInterstitial(MainActivity.this, "interstitial_0");
                handler.postDelayed(() -> {
                    interstitial.loadAd();
                }, 7000);
            }

            @Override
            public void onInitializationFailed(String error) {
            }
        });
        `}</span><span className={"commented-code"}>{`

    }


}`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - Show the ad whenever you want to</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;

import android.os.Bundle;

import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.interstitial.MobiOptionsInterstitial;

public class MainActivity extends AppCompatActivity {

    private MobiOptionsInterstitial interstitial;
    private Handler handler = new Handler(Looper.getMainLooper());

    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        AppCompatButton interstitialButton = findViewById(R.id.show_interstitial);

        RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                interstitial = new MobiOptionsInterstitial(MainActivity.this, "interstitial_0");
                handler.postDelayed(() -> {
                    interstitial.loadAd();
                }, 7000);
            }

            @Override
            public void onInitializationFailed(String error) {
            }
        });

        interstitialButton.setOnClickListener((v) -> {
            `}</span><span className={""}>{`if (interstitial != null && interstitial.isLoaded()) {
                interstitial.show();                                    
                interstitial.loadAd();                                  
            } else {
                Toast.makeText(MainActivity.this, "Interstitial Not loaded", Toast.LENGTH_SHORT).show();
            }`}</span><span className={"commented-code"}>{`
        });
    }


}`}</span>
                    </Highlight>
                </CardBody>
            </Card>
        );
    }

    getStep4() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Banner Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Add a <code>Linear Layout</code> to your activity <code>activity.xml</code> where you want
                        to show the banner, in this example the <code>Linear Layout</code> floats to the bottom of the
                        page</p>
                    <Highlight className='xml'>
                        {"<?xml version=\"1.0\" encoding=\"utf-8\"?>\n" +
                        "<RelativeLayout xmlns:android=\"http://schemas.android.com/apk/res/android\"\n" +
                        "    xmlns:app=\"http://schemas.android.com/apk/res-auto\"\n" +
                        "    xmlns:tools=\"http://schemas.android.com/tools\"\n" +
                        "    android:layout_width=\"match_parent\"\n" +
                        "    android:layout_height=\"match_parent\"\n" +
                        "    tools:context=\".MainActivity\">\n" +
                        "\n" +
                        "    <LinearLayout\n" +
                        "        android:id=\"@+id/banner_container\"\n" +
                        "        android:layout_width=\"match_parent\"\n" +
                        "        android:layout_height=\"wrap_content\"\n" +
                        "        android:layout_alignParentBottom=\"true\"\n" +
                        "        android:orientation=\"horizontal\" />\n" +
                        "\n" +
                        "</RelativeLayout>"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Create an Instance of MobiOptionsBanner and a banner size object in the activity where you
                        want to serve the banner
                        Ads</p>
                    <p className={"form-control-label"}>
                        Then in the <code>onCreate()</code> method initialize the <code>Linear Layout</code> that will
                        hold the banner, the <code>bannerSize</code> and the <code>banner</code> and serve the add by
                        calling the code bellow, where <code>banner_0</code> is the name the
                        banner you want to serve <br/></p>

                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import android.os.Bundle;
import androidx.appcompat.app.AppCompatActivity;`}</span><span className={""}>{`
import android.widget.LinearLayout;

import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.banner.MobiOptionsBanner;
import com.lib.mobioptionsads.banner.MobiOptionsBannerSize;
import com.lib.mobioptionsads.banner.size.AdmobBannerSize;
import com.lib.mobioptionsads.banner.size.FacebookBannerSize;
import com.lib.mobioptionsads.banner.size.UnityBannerSize;

`}</span><span className={"commented-code"}>{`public class MainActivity extends AppCompatActivity {`}</span><span
                        className={""}>{`

    private LinearLayout adsContainer;
    private MobiOptionsBanner mobiOptionsBanner;

    `}</span><span className={""}>{`@Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        `}</span><span className={""}>{`adsContainer = findViewById(R.id.banner_container);

        RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                mobiOptionsBanner = new MobiOptionsBanner(adsContainer, new MobiOptionsBannerSize(
                        new AdmobBannerSize(AdmobBannerSize.ADMOB_SMART_BANNER),
                        new UnityBannerSize(320, 59),
                        new FacebookBannerSize(FacebookBannerSize.FACEBOOK_BANNER_HEIGHT_90)
                ), "banner_0");
                mobiOptionsBanner.load();
            }

            @Override
            public void onInitializationFailed(String error) {}
        });
    }
    `}</span><span className={"commented-code"}>{`
}`}</span>
                    </Highlight>
                </CardBody>
            </Card>
        );
    }

    getStep5() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Rewarded Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create an Instance of MobiOptionRewardedAd in the activity where you want to serve rewarded
                        Ads</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;
import android.os.Bundle;
import com.lib.mobioptionsads.MobiInitializationListener;`}</span><span>{`
import com.lib.mobioptionsads.rewarded.MobiOptionRewardedAd;`}</span><span className={"commented-code"}>{`

public class MainActivity extends AppCompatActivity {

    `}</span><span>{`private MobiOptionRewardedAd rewardedAd;`}</span><span
                        className={"commented-code"}>{`

    @Override
    protected void onCreate(Bundle savedInstanceState) {}
}`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - In the <code>onCreate()</code> method call the initialising listener (if not already
                        done),<br/>
                        Replace <code>rewardedvideo_0</code> with the name of the ad you wanna serve if you have more
                        than 1 rewarded video</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import android.os.Bundle;

import androidx.appcompat.app.AppCompatActivity;
import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.rewarded.MobiOptionRewardedAd;
import com.lib.mobioptionsads.rewarded.MobiRewardAdError;
import com.lib.mobioptionsads.rewarded.MobiRewardAdLoadListener;

public class MainActivity extends AppCompatActivity {

    private MobiOptionRewardedAd rewardedAd;

    `}</span><span className={""}>{`@Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                rewardedAd = new MobiOptionRewardedAd(MainActivity.this, "rewardedvideo_0");
                rewardedAd.load(new MobiRewardAdLoadListener() {
                    @Override
                    public void onRewardedAdLoaded(String adsProvider) {
                    }

                    @Override
                    public void onRewardedAdFailedToLoad(String adsProvider, MobiRewardAdError error) {
                    }
                });
            }

            @Override
            public void onInitializationFailed(String error) {
            }
        });
`}</span><span className={"commented-code"}>{`
    }
}
`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - Show the ad whenever you want to</p>
                    <Highlight className='Java'>
                        <span className={"commented-code"}>{`package com.application.libtesting;

import android.os.Bundle;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;

import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.rewarded.MobiOptionRewardedAd;
import com.lib.mobioptionsads.rewarded.MobiRewardAdError;
import com.lib.mobioptionsads.rewarded.MobiRewardAdListener;
import com.lib.mobioptionsads.rewarded.MobiRewardAdLoadListener;

public class MainActivity extends AppCompatActivity {

    private MobiOptionRewardedAd rewardedAd;

    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        AppCompatButton rewardedButton = findViewById(R.id.show_rewarded);

        RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                rewardedAd = new MobiOptionRewardedAd(MainActivity.this, "rewardedvideo_0");
                rewardedAd.load(new MobiRewardAdLoadListener() {
                    @Override
                    public void onRewardedAdLoaded(String adsProvider) {
                    }

                    @Override
                    public void onRewardedAdFailedToLoad(String adsProvider, MobiRewardAdError error) {
                    }
                });
            }

            @Override
            public void onInitializationFailed(String error) {
            }
        });
        rewardedButton.setOnClickListener((v) -> {`}</span><span className={""}>{`
            rewardedAd.show(new MobiRewardAdListener() {
                @Override
                public void onRewardedAdOpened(String adsProvider) {}
                @Override
                public void onRewardedAdClosed(String adProvider) {}
                @Override
                public void onUserEarnedReward(String adProvider) {}
                @Override
                public void onRewardedAdError(String adProvider, MobiRewardAdError error) {} 
            });
        `}</span><span className={"commented-code"}>{`
        });
    }
}
`}</span>
                    </Highlight>
                </CardBody>
            </Card>
        );
    }


    getStep6() {
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Native Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create an Instance of MobiOptionsNativeAd in the activity where you want to serve the native
                        Ads</p>
                    <Highlight className='Java'>
                                                <span className={"commented-code"}>{`package com.application.libtesting;

import androidx.appcompat.app.AppCompatActivity;
import androidx.appcompat.widget.AppCompatButton;
import android.os.Bundle;
import com.lib.mobioptionsads.MobiInitializationListener;`}</span><span>{`
import com.lib.mobioptionsads.nativeAd.MobiOptionsNativeAd;`}</span><span className={"commented-code"}>{`

public class MainActivity extends AppCompatActivity {

    `}</span><span>{`private MobiOptionsNativeAd nativeAd;`}</span><span
                        className={"commented-code"}>{`

    @Override
    protected void onCreate(Bundle savedInstanceState) {}
}`}</span>
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Add a <code>Linear Layout</code> to your activity <code>activity.xml</code> where you want
                        to show the native, in this example the <code>Linear Layout</code> floats to the bottom of the
                        page</p>
                    <Highlight className='xml'>
                        {"<?xml version=\"1.0\" encoding=\"utf-8\"?>\n" +
                        "<RelativeLayout xmlns:android=\"http://schemas.android.com/apk/res/android\"\n" +
                        "    xmlns:app=\"http://schemas.android.com/apk/res-auto\"\n" +
                        "    xmlns:tools=\"http://schemas.android.com/tools\"\n" +
                        "    android:layout_width=\"match_parent\"\n" +
                        "    android:layout_height=\"match_parent\"\n" +
                        "    tools:context=\".MainActivity\">\n" +
                        "\n" +
                        "    <LinearLayout\n" +
                        "        android:id=\"@+id/native_ad_container\"\n" +
                        "        android:layout_width=\"match_parent\"\n" +
                        "        android:layout_height=\"wrap_content\"\n" +
                        "        android:layout_alignParentBottom=\"true\"\n" +
                        "        android:orientation=\"horizontal\" />\n" +
                        "\n" +
                        "</RelativeLayout>"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - In the <code>onCreate()</code> method initialize the <code>Linear Layout</code> that will
                        hold the native ad, the <code>MobiNativeAdSize</code> and the <code>nativeAd</code> and serve
                        the add by calling the code bellow, where <code>native_0</code> is the name the
                        ad you want to serve <br/></p>
                    <Highlight className='Java'>
                        {`package com.application.libtesting;

import android.os.Bundle;
import android.widget.LinearLayout;

import androidx.appcompat.app.AppCompatActivity;

import com.lib.mobioptionsads.MobiInitializationListener;
import com.lib.mobioptionsads.nativeAd.MobiNativeAdError;
import com.lib.mobioptionsads.nativeAd.MobiNativeAdListener;
import com.lib.mobioptionsads.nativeAd.MobiNativeAdSize;
import com.lib.mobioptionsads.nativeAd.MobiOptionsNativeAd;
import com.lib.mobioptionsads.nativeAd.size.NativeAdFacebookSize;
import com.lib.mobioptionsads.nativeAd.size.NativeAdmobSize;

public class MainActivity extends AppCompatActivity {


    private MobiOptionsNativeAd nativeAd;

    @Override
    protected void onCreate(Bundle savedInstanceState) {
        super.onCreate(savedInstanceState);
        setContentView(R.layout.activity_main);

        LinearLayout nativeAdContainer = findViewById(R.id.native_ad_container);

        RootApplication.setupMobiOptionsAds(new MobiInitializationListener() {
            @Override
            public void onInitializationSuccess() {
                MobiNativeAdSize size = new MobiNativeAdSize(NativeAdmobSize.GNT_SMALL_TEMPLATE, NativeAdFacebookSize.WIDTH_280_HEIGHT_250);
                nativeAd = new MobiOptionsNativeAd(MainActivity.this, "native_0", size, nativeAdContainer);
                nativeAd.load(new MobiNativeAdListener() {
                    @Override
                    public void onAdLoaded(String adsProvider) {
                        nativeAd.show();
                    }

                    @Override
                    public void onAdError(String adsProvider, MobiNativeAdError error) {}

                    @Override
                    public void onAdClicked(String adsProvider) {}
                });
            }

            @Override
            public void onInitializationFailed(String error) {}
        });
    }

}`}
                    </Highlight>
                </CardBody>
            </Card>
        );
    }

    getCards() {
        return (
            <Row id={"type-cards"}>
                <Col xl="6" md={"12"}>
                    <Card className={"bg-gradient-success cursor-pointer"}
                          onClick={() => this.setState({tutorialFor: 1},() => {
                              setTimeout(() => document.getElementById('type-cards').scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                              }), 450)
                          })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 style={{color: 'white'}} className="mb-0">Tutorial for a new project</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <div style={{
                            width: '100%', height: 300, textAlign: "center", display: "flex",
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            <h2 style={{color: 'white'}}>Start serving ads immediately with a prebuild project, ready to
                                serve your Ads.</h2>
                        </div>
                    </Card>
                </Col>
                <Col xl="6" md={"12"}>
                    <Card className={"bg-gradient-info cursor-pointer"} onClick={() => this.setState({tutorialFor: 2},() => {
                        setTimeout(() => document.getElementById('type-cards').scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                        }), 450)
                    })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 style={{color: 'white'}} className="mb-0">Tutorial for an exiting project</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <div style={{
                            width: '100%', height: 300, textAlign: "center", display: "flex",
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            <h2 style={{color: 'white'}}>
                                Add the library to an existing project, update your code and serve Ads.
                            </h2>
                        </div>
                    </Card>
                </Col>
            </Row>
        )
    }

    getCards_() {
        let show = this.state.show;
        return (
            <Row id={"ads-cards-row"}>
                <Col xl="3" md={"6"}>
                    <Card className={`bg-gradient-${show === "INTERSTITIAL" ? 'warning' : 'white'} cursor-pointer`}
                          onClick={() => this.setState({show: "INTERSTITIAL"}, () => {
                              setTimeout(() => document.getElementById('ads-cards-row').scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                              }), 350)
                          })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className={`mb-0 text-${show === "INTERSTITIAL" ? 'white' : 'warning'}`}>Serve
                                        Interstitial Ads</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>

                <Col xl="3" md={"6"}>
                    <Card className={`bg-gradient-${show === "BANNER" ? 'warning' : 'white'} cursor-pointer`}
                          onClick={() => this.setState({show: "BANNER"}, () => {
                              setTimeout(() => document.getElementById('ads-cards-row').scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                              }), 350)
                          })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className={`mb-0 text-${show === "BANNER" ? 'white' : 'warning'}`}>Serve Banner
                                        Ads</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>

                <Col xl="3" md={"6"}>
                    <Card className={`bg-gradient-${show === "REWARDED" ? 'warning' : 'white'} cursor-pointer`}
                          onClick={() => this.setState({show: "REWARDED"}, () => {
                              setTimeout(() => document.getElementById('ads-cards-row').scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                              }), 350)
                          })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className={`mb-0 text-${show === "REWARDED" ? 'white' : 'warning'}`}>Serve
                                        Rewarded Ads</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>

                <Col xl="3" md={"6"}>
                    <Card className={`bg-gradient-${show === "NATIVE" ? 'warning' : 'white'} cursor-pointer`}
                          onClick={() => this.setState({show: "NATIVE"}, () => {
                              setTimeout(() => document.getElementById('ads-cards-row').scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                              }), 350)
                          })}>
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className={`mb-0 text-${show === "NATIVE" ? 'white' : 'warning'}`}>Serve Native
                                        Ads</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
        )
    }
}
