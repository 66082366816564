/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import NotificationAlert from "react-notification-alert";

import routes from "routes.js";
import Error404 from "../views/pages/Error404";
import { HttpConfig } from "../tools/Http";
import Cookies from "js-cookie";
import AdSense from "react-adsense";
import { Provider } from "react-redux";
import { store } from "../tools/store"

class  Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };
  componentDidMount() {
    
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.path}
            component={() => (
              <prop.component
                notify={this.notify}
                props={this.props}
                {...prop}
              />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  notify = (type, title, body) => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {title}
          </span>
          <span data-notify="message">{body}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };

  render() {
    return (
      <>
        <Provider store={store}>
          <Sidebar
            {...this.props}
            routes={routes}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            logo={{
              innerLink: "/dash",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "...",
            }}
          />
          <div
            className="main-content"
            ref="mainContent"
            onClick={this.closeSidenav}
          >
            <AdminNavbar
              {...this.props}
              theme={this.getNavbarTheme()}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <div style={{ minHeight: "90vh" }}>
              <div className="rna-wrapper">
                <NotificationAlert ref="notificationAlert" />
              </div>
              {/* <div style={{ width: "100%", backgroundColor: "#1F61FF" }}>
                <AdSense.Google
                  client="ca-pub-5151567038196737"
                  slot="5704610446"
                  style={{ display: "block", textAlign: "center" }}
                  layout="in-article"
                  format="fluid"
                />
              </div> */}
              <Switch>
                {this.getRoutes(routes)}
                <Route component={Error404} />
                {/*<Redirect from="*" to="/admin/dash" />*/}
              </Switch>
            {/*  <AdSense.Google
                client="ca-pub-5151567038196737"
                slot="3162029970"
                style={{ display: "block" }}
                layout="in-article"
                layoutKey="-6t+ed+2i-1n-4w"
                format="fluid"
              /> */}
            </div>
            <AdminFooter />
          </div>
          {this.state.sidenavOpen ? (
            <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
          ) : null}
        </Provider>
      </>
    );
  }
}

export default Admin;
