/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem,
    Card, CardBody, CardHeader, Col,
    Container, Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import Highlight from "react-highlight";

export default class AdsTutorial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.addLink("css/androidstudio.css");
    }

    addLink(href, rel = "stylesheet"){
        let link = document.createElement( "link" );
        link.href = href;
        link.rel = rel;
        document.getElementsByTagName( "head" )[0].appendChild( link );
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            {this.getStep1()}
                            {this.getStep2()}
                            {this.getStep3()}
                            {/*{this.getStep4()}*/}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Limit Library Tutorial
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    getStep1(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Installing The Library</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Add jitpack repository to your project gradle file <code>project/build.gradle</code>
                    </p>
                    <Highlight className='JavaScript'>
                        {"allprojects {\n" +
                        "    repositories {\n" +
                        "        maven { url 'https://jitpack.io' }\n" +
                        "    }\n" +
                        "}"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Add the dependency to your app gradle file <code>project/app/build.gradle</code>
                    </p>
                    <Highlight className='JavaScript'>
                        {"implementation implementation 'com.github.MobiOptions:admob-limit-protection:1.0.0'"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - Sync your project by clicking on <code>sync now</code> button
                    </p>
                </CardBody>
            </Card>
        );
    }
    getStep2(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Initialising The Library</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - On your first activity, usually <code>SplashActivity.java</code> add the following code to your <code>onCreate</code> method to initialise the library</p>
                    <Highlight className='Java'>
                        {"import com.mobioptions.limitprotection.Log;\n" +
                        "import com.mobioptions.limitprotection.LimitAds;\n" +
                        "\n" +
                        "\n" +
                        "@Override\n" +
                        "protected void onCreate(Bundle savedInstanceState) {\n" +
                        "    super.onCreate(savedInstanceState);\n" +
                        "    setContentView(R.layout.activity_splash);\n" +
                        "\n" +
                        "\n" +
                        "    final LimitAds ads = LimitAds.getInstance();\n" +
                        "    ads.init(\"YOUR_TOKEN_HERE\", this, new LimitAds.InitListener() {\n" +
                        "        @Override\n" +
                        "        public void onInit() {\n" +
                        "            startActivity(new Intent(SplashActivity.this, MainActivity.class));\n" +
                        "        }\n" +
                        "\n" +
                        "        @Override\n" +
                        "        public void onError(String error) {\n" +
                        "            Log.D(error);\n" +
                        "        }\n" +
                        "    });\n" +
                        "}"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Replace the String <code>YOUR_TOKEN_HERE</code> by the token generated for your application.</p>
                    <p className={"form-control-label"}>
                        <code>LimitAds.init(token, listener)</code> takes two parameters the <code>token</code> and a <code>listener</code>. <br/>The listener triggers two functions
                        <code> onInit()</code> when the library is successfully initialised, and <code>onError(error)</code> when the library fails to initialise, either due to internet problems or server problems.</p>
                    <p className={"form-control-label"}>
                        3 - add your admob app id in <code>android.manifest</code>
                    </p>
                    <Highlight>
                        {"" +
                        "<meta-data\n" +
                        "    android:name=\"com.google.android.gms.ads.APPLICATION_ID\"\n" +
                        "    android:value=\"ca-app-pub-xxxxxxxxxxxxxxxx~yyyyyyyyyy\"/>"}
                    </Highlight>
                </CardBody>
            </Card>
        );
    }
    getStep3(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Interstitial Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create an Instance of MobiOptionAds in the activity where you wanna serve Interstitial Ads</p>
                    <Highlight className='Java'>
                        {"import com.mobioptions.limitprotection.LimitAds;\n" +
                        "import com.mobioptions.limitprotection.Log;\n\n" +
                        "public class MainActivity extends AppCompatActivity {\n" +
                        "    LimitAds ads = LimitAds.getInstance();"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - In the <code>onCreate()</code> method load your ad,<br/>
                        Replace <code>interstitial_1</code> with the name of the ad you wanna serve if you have more than 1 Interstitial</p>
                    <Highlight className='Java'>
                        {"protected void onCreate(Bundle savedInstanceState) {\n" +
                        "   super.onCreate(savedInstanceState);\n" +
                        "   setContentView(R.layout.activity_main);\n" +
                        "   ads.loadAd(\"interstitial_1\");\n"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - Show the ad whenever you want to</p>
                    <Highlight className='Java'>
                        {'ads.showInterstitial("interstitial_1");'}
                    </Highlight>
                    <p className={"form-control-label"}>
                        Now the library will stop showing ads following the parameters provided in the project manager page</p>
                </CardBody>
            </Card>
        );
    }
    getStep4(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Serving Banner Ads</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <p className={"form-control-label"}>
                        1 - Create an Instance of MobiOptionAds in the activity where you wanna serve Banner Ads</p>
                    <Highlight className='Java'>
                        {"public class MainActivity extends AppCompatActivity {\n" +
                        "    MobiOptionsAds ads = MobiOptionsAds.getInstance();"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        2 - Add a <code>Linear Layout</code> to your activity <code>activity.xml</code> where you want to show the banner, in this example the <code>Linear Layout</code> floats to the bottom of the page</p>
                    <Highlight className='xml'>
                        {"<?xml version=\"1.0\" encoding=\"utf-8\"?>\n" +
                        "<RelativeLayout xmlns:android=\"http://schemas.android.com/apk/res/android\"\n" +
                        "    xmlns:app=\"http://schemas.android.com/apk/res-auto\"\n" +
                        "    xmlns:tools=\"http://schemas.android.com/tools\"\n" +
                        "    android:layout_width=\"match_parent\"\n" +
                        "    android:layout_height=\"match_parent\"\n" +
                        "    tools:context=\".MainActivity\">\n" +
                        "\n" +
                        "    <LinearLayout\n" +
                        "        android:id=\"@+id/ads_holder\"\n" +
                        "        android:layout_width=\"match_parent\"\n" +
                        "        android:layout_height=\"wrap_content\"\n" +
                        "        android:layout_alignParentBottom=\"true\"\n" +
                        "        android:orientation=\"horizontal\" />\n" +
                        "\n" +
                        "</RelativeLayout>"}
                    </Highlight>
                    <p className={"form-control-label"}>
                        3 - In the <code>onCreate()</code> method initialize the <code>Linear Layout</code> that will hold the banner, and serve the add by calling
                        <code>ads.banner(adContainer,"banner_1");</code> where <code>banner_1</code> is the name the banner you want to serve <br/></p>
                    <Highlight className='Java'>
                        {"LinearLayout adContainer = findViewById(R.id.ads_holder);\n" +
                        "ads.banner(adContainer,\"banner_1\");\n"}
                    </Highlight>
                </CardBody>
            </Card>
        );
    }
}
