import React from "react";
// nodejs library that concatenates classes
import axios from "axios";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import ClipLoader from "react-spinners/ClipLoader";
const queryString = require('query-string');

class VerifyEmail extends React.Component {
    state = {
        isVerified: false,
        loading: true
    }

    componentDidMount() {
        let token = queryString.parse(this.props.location.search)['token'] || ""
        if(token === "") {
            this.props.history.push('/auth/login');
            return false;
        }
        this.checkEmail(token);
    }

    async checkEmail(token) {
        const delay = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds));
        };
        await delay(3000);
        axios.get(`${window.api_url}api/email/verify/${token}`)
        .then(async res => {
            if (res.data.status) {
                this.setState({isVerified:true, loading:false})
                await delay(2000);
                this.props.history.push('/auth/login');
            } else {
                this.props.history.push('/auth/login', {token:true});
                return false;
            }
        })
        .catch(err => {this.setState({isVerified:false, loading:false})});
    }

    render() {
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Verifying your email"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                    {this.state.loading ?
                                        <>
                                            <ClipLoader
                                                color={"blue"}
                                                loading={true}
                                                size={50}
                                            />
                                            <p>Please wait!</p>
                                        </>
                                    :
                                        (
                                            this.state.isVerified ?
                                                <>
                                                    <img src={require("assets/img/icons/verified.png")} width={75}/>
                                                    <p className="text-success">Successfully Verified. You will be redirected to login page!</p>
                                                </>
                                            :
                                                <>
                                                    <img src={require("assets/img/icons/failed.png")} width={60}/>
                                                    <p className="text-danger">Verification Failed. Please check your token and try again!</p>
                                                </>
                                        )
                                    }
                                    </div>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

export default VerifyEmail;
