/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Button, Card, CardBody, Form, Container, Row, Col } from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import questionnaireItems from "./JSON-ALL";
import Question from "./Question/Question";
import axios from "axios";

export default ({ notify }) => {
  const [questionnaire, setQuestionnaire] = useState(questionnaireItems);
  const [sent, setSent] = useState(false);
  const setTheQuestion = ({ path, value }) => {
    modifyObject(questionnaire, path, value);
  };

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  const modifyObject = (object, jsonPath, value) => {
    jsonPath = jsonPath.replace(/[\]]/g, "");
    jsonPath = jsonPath.replace(/[\[]/g, ".");
    if (jsonPath.indexOf(".") === 0) jsonPath = jsonPath.slice(1);
    const keys = jsonPath.split(".");
    const key = keys.splice(0, 1);
    if (keys.length > 0) {
      modifyObject(object[key], keys.join("."), value);
    } else {
      object[isNumeric(key) ? parseInt(key) : key].answer = formatValue(value);
      setQuestionnaire([...questionnaire]);
    }
  };

  const formatValue = value => {
    if (value === "true") return true;
    if (value === "false") return false;
    return value;
  };

  const sendQuestionnaire = () => {
    axios
      .post(`${window.api_url}api/form`, {
        questionnaire
      })
      .then(res => {
        if (res.data.status) {
          notify("success", "Success", "Form successfully submitted");
        } else {
          //error
          notify(
            "danger",
            "Error",
            "There was an error while submitting the form"
          );
        }
      })
      .catch(err => {
        notify(
          "danger",
          "Error",
          "There was an error while submitting the form"
        );
      });
  };

  return (
    <>
      <AuthHeader
        title="Welcome to Mobioptions questionnaire!"
        lead="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam volutpat pharetra ex splittedPath ultricies. Pellentesque iaculis tellus in ipsum ornare ultrices. In in hendrerit eros. Donec tempus volutpat euismod. Donec nunc lectus, pretium quis condimentum ac, blandit eu metus. Nullam suscipit ut massa at faucibus. Etiam feugiat laoreet sem, at tincidunt neque blandit nec. Nulla ipsum quam, consectetur sed justo et, egestas faucibus arcu. Aliquam quis leo ac risus rutrum feugiat at in quam."
        xl={10}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="8" md="10">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Please fill in the form</small>
                </div>

                <Form role="form">
                  <Row>
                    <Col md="12">
                      <Question
                        questionArray={questionnaire}
                        setTheQuestion={setTheQuestion}
                        path={""}
                      />
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      disabled={sent}
                      onClick={() => {
                        setSent(true);
                        sendQuestionnaire();
                      }}
                    >
                      Send Questionnaire
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
