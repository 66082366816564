/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Breadcrumb,
  Modal,
  Table,
  BreadcrumbItem
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import "./styles.scss";
import cuid from "cuid";
import Space from "../../Space";
import { HttpConfig } from "tools/Http";
import { text, thirdParty } from "./data";
import ReactHtmlParser from "react-html-parser";
import { variables as vs } from "./data";
import FormStep from "./Form";
import Submit from "./Submit";
import moment from "moment";
import { useDispatch } from "react-redux";

export default ({ notify, fromAdmin }) => {
  const [pressed, setPressed] = useState(false);
  const [done, setDone] = useState(false);
  const [captcha, setCaptcha] = useState();
  const [defaultModal, setDefaultModal] = useState(false);
  const [networks, setNetworks] = useState([]);
  const [privacyPolicies, setPrivacyPolicies] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const arr = {};
  const dispatch = useDispatch()

  useEffect(() => {
    getPrivacyPolicies()
  }, [0]);

  const getPrivacyPolicies = () => {
    HttpConfig(dispatch).get("privacy-policy")
    .then(res => {
      if (res.data.status) {
        setPrivacyPolicies(res.data.privacy)
      } else {
      }
    })
    .catch(err => {});
  }

  Object.keys(vs).map(el => {
    arr[[el]] = vs[el].replaceAll("__", "`");
  });
  const [variables, setVariables] = useState(arr);
  
  const key = useMemo(() => cuid(), []);
  const sendPrivacy = () => {
    HttpConfig(dispatch).post('save-privacy-policy', {
        cuid: key,
        content: getText()
      })
      .then(res => {
        if (res.data.status) {
          notify("success", "Success", "privacy policy successfully created");
          setDone(true)
          getPrivacyPolicies()
        } else {
          //error
          notify(
            "danger",
            "Error",
            "There was an error while adding your privacy policy"
          );
        }
      })
      .catch(err => {
        notify(
          "danger",
          "Error",
          "There was an error while adding your privacy policy"
        );
      });
  };

  const renderModal = () => {
    return (
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={defaultModal}
        toggle={() => setDefaultModal(!defaultModal)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            My Saved Parivacy Policies
          </h6>
        </div>
        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Link</th>
                <th scope="col">Created</th>
                <th scope="col">Copy</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {privacyPolicies.map((el, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => window.open(window.privacy_url+"?token="+el.token, '_blank')}
                      >
                        Open Link
                      </button>
                    </td>
                    <td>{el.created_at}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => copy(window.privacy_url+"?token="+el.token)}
                      >
                        Copy
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => deletePrivacyPolicy(el.uuid)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            size="sm"
            data-dismiss="modal"
            type="button"
            onClick={() => setDefaultModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(
        function() {
          notify("success", "Copied", "Text copied to clipboard");
        },
        function(err) {
          notify("danger", "Error", "Could not copy to clipboard");
        }
      )
      .catch(err => {
        console.log(err);
        notify("danger", "Error", "Could not copy to clipboard");
      });
  }

  const deletePrivacyPolicy = (uuid) => {
    if (uuid) {
      HttpConfig(dispatch)
        .delete("delete-privacy-policy/" + uuid)
        .then(res => {
          getPrivacyPolicies()
          notify(
            "success",
            "Deleted",
            "Your Privacy Policy is successfully deleted"
          );
        })
        .catch(err => {
          notify(
            "danger",
            "Error",
            "There was an error while deleting your privacy policy!"
          );
        });
    }
  }

  const getText = () =>
    text
      .replaceAll(vs.APP_NAME, variables.APP_NAME)
      .replaceAll(vs.DEVELOPER, variables.DEVELOPER)
      .replaceAll(vs.PERSONAL, variables.PERSONAL)
      .replaceAll(vs.EMAIL, variables.EMAIL)
      .replaceAll(vs.TODAY, moment().format("YYYY-MM-DD"))
      .replaceAll(
        vs.SERVICES,
        `${networks.map(el => `<li>${el}</li>`)}`.replaceAll(",", "")
      );
  return (
    <>
      {!fromAdmin ? (
        <AuthHeader
          title="Welcome to Mobioptions simple privacy policy generator"
          lead="Generate and host you applications privacy policy with one click, no account needed"
          xl={10}
        />
      ) : (
        <div
          className={`header header-dark bg-info pb-6 content__title content__title--calendar`}
        >
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Tools
                  </h6>
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-wrench" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Privacy policy generator
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
                  <Button
                    className="btn-neutral"
                    color="default"
                    size="sm"
                    onClick={() => setDefaultModal(true)}
                  >
                    <i className="far fa-arrow-alt-circle-down fa-lg"></i>&nbsp;&nbsp;Load Privacy Policies ({privacyPolicies.length})
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
      <Container className={`${fromAdmin ? "mt--6" : "mt--8"} pb-5`}>
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-4 py-lg-4">
                <Form role="form">
                  <div className={"ppg-page"}>
                    <Row className={"flex"}>
                      {activeStep === 0 && (
                        <FormStep
                          setVariables={setVariables}
                          variables={variables}
                        />
                      )}
                      {activeStep === 1 && [
                        <Col xl={6} className={"flex2"}>
                          <h2>Include Third Party Services</h2>
                          <div className={"scrollable"}>
                            {thirdParty.map(({ img, title }) => (
                              <div className={"aligned-row"}>
                                <input
                                  type="checkbox"
                                  onChange={e => {
                                    if (e.target.checked) {
                                      networks.push(title);
                                      setNetworks([...networks]);
                                    } else {
                                      setNetworks(
                                        networks.filter(el => el !== title)
                                      );
                                    }
                                  }}
                                />
                                <label htmlFor="list-switch-gps">
                                  <img
                                    src={img}
                                    alt="Google Play Services"
                                    className="thirdparty-logo"
                                  />
                                  <span className="thirdparty-text">
                                    {title}
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>
                        </Col>
                      ]}
                      {activeStep === 2 && (
                        <Submit captcha={captcha} setCaptcha={setCaptcha} />
                      )}
                      <Col xl={6} className={"flex2"}>
                        <div className={"scrollable"}>
                          {ReactHtmlParser(getText())}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center">
                    <Space h={12} />
                    {pressed && done && (
                      <div className={"link-holder"}>
                        <strong>{window.privacy_url+"?token="+key}</strong>
                        <i
                          className="fas fa-copy ml-3"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.privacy_url+"?token="+key
                            );
                            notify("success", "Link Copied");
                          }}
                        />
                      </div>
                    )}
                    {!pressed && (
                      <>
                      <Button
                        className="my-4"
                        color="info"
                        type="button"
                        disabled={
                          (activeStep === 0 &&
                            variables.APP_NAME === "`appname`") ||
                          (activeStep === 2 && !captcha)
                        }
                        onClick={() => {
                          if (activeStep < 2)
                            setActiveStep(activeStep => activeStep + 1);
                          else {
                            sendPrivacy();
                            setPressed(true);
                          }
                        }}
                      >
                        {activeStep === 2 ? "Save and host" : "Next"}
                      </Button>
                      <Button
                        className="my-4 btn-success"
                        type="button"
                        style={{ marginRight: 10 }}
                        hidden={activeStep !== 2}
                        onClick={() =>
                          copy(getText())
                        }
                      >
                        Copy to clipboard
                      </Button>
                      </>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {renderModal()}
    </>
  );
};
