/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Button,
    Card, CardHeader, Col,
    Container, Row, CardBody, Input
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import {HttpConfig} from "../../tools/Http";
import Cookies from "js-cookie";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { withRouter } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class ChangePricingPlan extends React.Component {
    constructor(props) { 
        super(props);
        this.state = {
            loading: false,
            first: true,
            paid: false,
            plans: [{id: 2, name: '1 Month', price: 39, saved: 0, percentage: 0, description: `The Basic package is $39 for 1 Month, with full access to all features.`},
                    {id: 3, name: '3 Months', price: 78, saved: 39, percentage: 33, description: `The Basic package is $78 for 3 Months, with full access to all features.`},
                    {id: 4, name: '6 Months', price: 140, saved: 94, percentage: 40, description: `The Basic package is $140 for 6 Months, with full access to all features.`},
                    {id: 5, name: '1 Year', price: 234, saved: 234, percentage: 50, description: `The Basic package is $234 for 1 Year, with full access to all features.`}],
            basePlans: [{id: 2, name: '1 Month', price: 39, saved: 0, percentage: 0},
                        {id: 3, name: '3 Months', price: 78, saved: 39, percentage: 33},
                        {id: 4, name: '6 Months', price: 140, saved: 94, percentage: 40},
                        {id: 5, name: '1 Year', price: 234, saved: 234, percentage: 50}],
            selectedPlan: 0,
            meta_data_paypal: "",
            description: "",
            coupon: '',
            selectedCoupon: '',
            selectedCouponPercentage: 0,
            couponLoading: false,
            generatedPlan: null,
            token: '',
            modalVisible: false,
            initialOptions: {
                clientId: window.client_id_paypal,
                currency: "USD",
                intent: "capture",
            }
        };
    }
    

    componentDidMount() {
        // this.getData();
        // const script = document.createElement('script');
        // script.src = 'https://www.paypal.com/sdk/js?client-id='+window.client_id_paypal;
        // script.async = true;
        // document.body.appendChild(script);
    }

    applyCoupon(e) {
        e.preventDefault()
        if(this.state.coupon === this.state.selectedCoupon)
            return;
        this.setState({couponLoading: true})
        HttpConfig().post("apply-code-promo", {
            "code_coupon": this.state.coupon
        }).then(response => {
            if (response.data.status) {
                const plans = this.state.plans.map(plan => {
                    const price = plan.price - (plan.price * response.data.discount / 100)
                    const saved = plan.saved  + (plan.price * response.data.discount / 100)
                    return {
                        ...plan,
                        price: price % 1 === 0 ? price : price.toFixed(2),
                        percentage: plan.percentage !== '' ? parseInt(plan.percentage + ((100 - plan.percentage) * response.data.discount / 100)) : parseInt(response.data.discount),
                        saved: saved % 1 === 0 ? saved : saved.toFixed(2),
                        description: `The Basic package is $${price} for ${this.state.plans[this.state.selectedPlan].name}, with full access to all features.`
                    };
                });
                this.setState({plans, selectedCoupon: this.state.coupon, selectedCouponPercentage: response.data.discount, couponLoading: false})
                this.props.notify('success', 'Successfully Applied', response.data.message)
            }
            else {
                this.setState({plans: this.state.basePlans, selectedCoupon: '', selectedCouponPercentage: 0, couponLoading: false})
                this.props.notify('danger', 'Not Applied', response.data.message)
            }
        }).catch(err => {
            console.log(err);
            this.setState({couponLoading: false})
        })
    }
    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="10">
                            <div className="pricing card-group flex-column flex-md-row mb-3">
                                <Card className="card-pricing border-0 text-center mb-4">
                                    <CardHeader className="bg-transparent">
                                        <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                                            Basic Pack
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row style={{marginBottom: 25, paddingRight: 40}}>
                                            <Col>
                                                <Input value={this.state.coupon} placeholder={"Coupon Code"} onChange={e => this.setState({coupon: e.target.value})} />
                                            </Col>
                                            <Button className={"btn btn-success"} disabled={this.state.coupon === ""} onClick={(e) => this.applyCoupon(e)}>
                                                {
                                                    this.state.couponLoading ?
                                                        <ClipLoader
                                                            color={"white"}
                                                            loading={true}
                                                            size={10}
                                                        />
                                                    :
                                                    <>
                                                        <i className="fas fa-tags"></i>&nbsp;&nbsp;Apply Coupon
                                                    </>
                                                }
                                            </Button>
                                        </Row>
                                        <Row style={{marginRight: 5}}>
                                            {this.state.plans.map((el, index) => {
                                                return(
                                                    <Col key={el.id} xs={3} className="p-0 m-0 d-flex justify-content-center align-content-center">
                                                        <Card style={{borderRadius: 25, width: '90%', cursor: "pointer"}} onClick={() => this.setState({selectedPlan:index})}>
                                                            {el.percentage !== 0  && (
                                                                <div style={{ position: 'absolute', top: 6, right: 7, transform: 'translate(50%, -50%)', zIndex: 1 }}>
                                                                    <div title='Most Popular' className='bg-gradient-orange' style={{ width: 25, height: 25, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <h5 style={{ color: 'white', margin: 0 }}>{el.percentage + "%"}</h5>
                                                                    </div>
                                                                </div>                                                              
                                                            )}
                                                            <CardBody className={this.state.selectedPlan === index ? "bg-gradient-success" : "bg-gradient-info"} style={{borderRadius: 25, padding: 10}}>
                                                                <h4 className="text-white m-0">
                                                                    {el.name}
                                                                </h4>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        {this.state.selectedCoupon !== "" && <div className="text-blue">{"Applied Coupon: " + this.state.selectedCoupon + " -" + this.state.selectedCouponPercentage + "%"}</div>}
                                        <div className="display-2 text-success">{"$" + this.state.plans[this.state.selectedPlan].price}</div>
                                        <span className={this.state.plans[this.state.selectedPlan].percentage === 0 ? "text-muted" : "text-muted text-blue text-through"}>{this.state.plans[this.state.selectedPlan].percentage === 0 ? "Per Month" : "Save $" + this.state.plans[this.state.selectedPlan].saved}</span>
                                        <ul className="list-unstyled my-4 px-lg-6">
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-fire" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Google Niches</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-trophy" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Android Top Rankings</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-rocket" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">
                                                            My Published Apps
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-wrench " />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Privacy Policy Generator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Description Generator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Graphics Creator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Screenshots Editor</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-drafting-compass" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Keywords Analyzer</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-drafting-compass" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Keywords Suggestion</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-file-signature" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">AAB Signer</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-laptop-code" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Developer Landing Page</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-cart-plus" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Order Your Code</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-comment" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Support</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                                            <i className="fas fa-comments" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2">Forum</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <Button className="mb-1" color="info" disabled={this.state.loading} type="button"
                                                onClick={() => {
                                                    this.selectPlan()
                                                }}>
                                            {
                                                this.state.loading ?
                                                    <ClipLoader
                                                        color={"white"}
                                                        loading={true}
                                                        size={10}
                                                    />
                                                :
                                                <>
                                                    <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;{"Select " + this.state.plans[this.state.selectedPlan].name + " For $" + this.state.plans[this.state.selectedPlan].price}
                                                </>
                                            }
                                        </Button>
                                    </CardBody>
                                    {/* <CardFooter>
                                        <a className="text-light"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}>
                                            Contact sales
                                        </a>
                                    </CardFooter> */}
                                </Card>
                                <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
                                    <CardHeader className="bg-transparent">
                                        <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                                            Premium pack
                                        </h4>
                                    </CardHeader>
                                    <CardBody className="px-lg-7">
                                        <div className="display-1 text-white">Soon</div>
                                        <span className="text-white">Stay tuned</span>
                                        <ul className="list-unstyled my-4">
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-fire" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Google Niches</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-trophy" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Android Top Rankings</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-rocket" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">My Published Apps</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Privacy Policy Generator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Description Generator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Graphics Creator</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-wrench" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Screenshots Editor</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-drafting-compass" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Keywords Analyzer</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-drafting-compass" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Keywords Suggestion</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-file-signature" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">AAB Signer</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-laptop-code" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Developer Landing Page</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-cart-plus" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Order Your Code</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-comment" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Support</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-comments" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Forum</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-ad" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">Ads Intellegence</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                                            <i className="fas fa-cubes" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="pl-2 text-white">App Builder</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <Button className="mb-1 disabled" color="info" type="button"
                                                onClick={() => {
                                                }}>
                                            <i className="fas fa-clock " />  Coming Soon

                                        </Button>
                                    </CardBody>
                                    {/* <CardFooter className="bg-transparent">
                                        <a
                                            className="text-white"
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Contact sales
                                        </a>
                                    </CardFooter> */}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center p-4" id="payment-section" hidden={!this.state.modalVisible || this.state.selectedPlan !== this.state.generatedPlan}>
                        <Col lg="6">
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <i className="fas fa-credit-card fa-lg"></i>&nbsp;&nbsp;<strong>Credit Card or CashPlus</strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div id="error-container"></div>
                                        <div className="p-2" id="payment-container"></div>
                                        <div className="justify-content-center d-flex p-2">
                                            <Button className="btn btn-success" id="pay" disabled={this.state.loading || this.state.paid}>
                                                {
                                                    this.state.loading ?
                                                        <ClipLoader
                                                            color={"white"}
                                                            loading={true}
                                                            size={10}
                                                        />
                                                    :
                                                        <>
                                                            <i className="fas fa-shopping-cart"></i>&nbsp;&nbsp;Pay
                                                        </>
                                                }
                                            </Button>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <i className="fas fa-university fa-lg"></i>&nbsp;&nbsp;<strong>Wise</strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <strong>Email: contact@mobioptions.com</strong>
                                        <br/>
                                        <br/>
                                        <h5>P.S: After making the payment, please send a screenshot of the transaction to this number, along with your email, to activate your account. <strong>+212771774741</strong></h5>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <i className="fab fa-cc-paypal fa-lg"></i>&nbsp;&nbsp;<strong>PayPal</strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        
                                        <PayPalScriptProvider options={this.state.initialOptions}>
                                            <PayPalButtons 
                                                style={{layout:'vertical',color:'blue',shape:'rect',label:'paypal'}}
                                                createOrder={(data, actions) => {
                                                    const _description = this.state.plans[this.state.selectedPlan].description;
                                                    return actions.order.create({
                                                        intent: "CAPTURE",
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    currency_code: "USD",
                                                                    value: this.state.plans[this.state.selectedPlan].price,
                                                                },
                                                                description: _description,
                                                                custom_id: Cookies.get("full_name"),
                                                                shipping: {
                                                                    name: {
                                                                      full_name: Cookies.get("full_name"),
                                                                    }
                                                                },
                                                            },
                                                        ],
                                                    }).catch((error) => {
                                                        // Handle any errors that occur during order creation
                                                        console.error("Error creating PayPal order:", error);
                                                    });
                                                }}
                                                onApprove={this.onApprove}
                                                onCancel={()=>{
                                                    this.props.notify("danger", "Ops","Payment was canceled.");
                                                }}
                                                onError={(error) => {
                                                    console.log("show this error ", error);
                                                    this.props.notify("danger", "Ops","An error has occurred");
                                                }}/>
                                        </PayPalScriptProvider>
                                        {/* <strong>Email: allabouchpay@gmail.com</strong>
                                        <br/>
                                        <br/>
                                        <h5>P.S: After making the payment, please send a screenshot of the transaction to this number, along with your email, to activate your account. <strong>+212771774741</strong></h5> */}
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton className='accordion__button'>
                                            <i className="fab fa-bitcoin fa-lg"></i>&nbsp;&nbsp;<strong>USDT</strong>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <strong>USDT: TKPwJ9x3NSZEhYzbxxrJ87Lc5V3679v7T4</strong>
                                        <br/>
                                        <strong>Network: TRX Tron (TRC 20)</strong>
                                        <br/>
                                        <br/>
                                        <h5>P.S: After making the payment, please send a screenshot of the transaction to this number, along with your email, to activate your account. <strong>+212771774741</strong></h5>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    getHeader() {
        return (
            <>
                <div
                    className="header pb-2 d-flex align-items-center"
                    style={{
                        minHeight: "500px",
                        backgroundImage:
                            'url("' + require("assets/img/theme/profile-cover_.jpg") + '")',
                        backgroundSize: "cover",
                        backgroundPosition: "center top"
                    }}
                >
                    <span className="mask bg-gradient-info opacity-8"/>

                    <Container className="d-flex align-items-center" fluid>
                        <Row>
                            <Col lg="7" md="10">
                                <h1 className="display-2 text-white">Pricing</h1>
                                <p className="text-white mt-0 mb-5">
                                    This is pricing page. You can see all packs and choose the one you want and pay using your credit card, Cash Plus, PayPal, Wise or USDT
                                </p>
                                {/* <Link
                                    className="btn btn-neutral"
                                    color="default"
                                    to={'contact-us'}
                                >
                                    You don't know what to choose?
                                </Link> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
    onApprove = (data, actions) => {
        this.props.notify("info", "Success","The payment was processed successfully");
        const {meta_data_paypal} = this.state;
        const app = this;
        return actions.order.capture().then(function(orderData) {
            HttpConfig().post("success-transaction-paypal", {
                data: data,
                orderdata: orderData,
                ids_token: meta_data_paypal
            }).then((response) => {
                if (response.data.status) {
                    app.props.notify("success", "Success", response.data.message);
                    setTimeout(() => {
                        app.props.history.push('/profile');
                    }, 1000)
                }
            }).catch(err => {
                console.log(err);
            });
        });
    }
    selectPlan() {
        if(this.state.selectedPlan === this.state.generatedPlan)
            return ;
        this.setState({loading: true})
        HttpConfig().get('plan-selection/' + this.state.plans[this.state.selectedPlan].id + "?code_coupon=" + this.state.selectedCoupon)
            .then(res => {
                if (res.data.status) {
                    this.setState({
                            token: res.data.token,
                            meta_data_paypal: res.data.ids_token,
                        })
                    if (this.state.first) {
                        const ycPay = new window.YCPay(window.pub_key_youcan_pay, {
                            formContainer: '#payment-container',
                            errorContainer: '#error-container',
                            isSandbox: false,
                            locale: 'en'
                        });
                        ycPay.renderAvailableGateways();
                        document.getElementById('pay').addEventListener('click', () => {
                            this.setState({loading: true})
                            // execute the payment
                            ycPay.pay(this.state.token)
                            .then(response => {
                                this.setState({loading: false, paid: true})
                                this.props.notify("success", "Success","The payment was processed successfully");
                                setTimeout(() => {
                                    this.props.history.push('/profile');
                                }, 2000)
                            })
                            .catch(err => {
                                HttpConfig().get('plan-selection/2')
                                .then(res => {
                                    if (res.data.status) {
                                        this.setState({
                                            token: res.data.token,
                                            meta_data_paypal: res.data.ids_token,
                                            loading: false
                                        })
                                    } else {
                                        this.setState({loading: false})
                                    }
                                }).catch(err => {
                                    this.setState({loading: false})
                                })
                                this.setState({loading: false})
                                this.props.notify("danger", "Ops","An error has occurred");
                            });
                        })
                        this.setState({first: false})
                    }
                    this.setState({loading: false, modalVisible: true, generatedPlan: this.state.selectedPlan})
                    setTimeout(() => {
                        const paymentSection = document.getElementById("payment-section")
                        if (paymentSection)
                            paymentSection.scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                            })
                    }, 500)
                } else {
                    this.setState({loading: false})
                }
            }).catch(err => {
                this.setState({loading: false})
            })
    }

    makePayment() {
        HttpConfig().get('create-cmi-payment/2/' + Cookies.get('id'))
            .then(res => {
                if (res.data.status) {
                    let arr = "<html>\n" +
                        "<head>\n" +
                        "<title>Mobioptions Payment</title>\n" +
                        "<meta http-equiv=\"Content-Language\" content=\"tr\">\n" +
                        "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=ISO-8859-9\">\n" +
                        "<meta http-equiv=\"Pragma\" content=\"no-cache\">\n" +
                        "<meta http-equiv=\"Expires\" content=\"now\">\n" +
                        "</head>\n" +
                        "\n" +
                        "<body >\n" +
                        "<form name=\"pay_form\" method=\"post\" action=\"https://payment.cmi.co.ma/fim/est3Dgate\">";
                    for (let key in res.data.result) {
                        if (res.data.result.hasOwnProperty(key)) {
                            arr = arr + "<input type=\"hidden\" name=\"" + key + "\" value=\"" + res.data.result[key] + "\" /><br />";
                        }
                    }
                    arr = arr + "" +
                        "</form>" +
                        "<script type=\"text/javascript\" language=\"javascript\">" +
                        "     (function () {\n" +
                        "   setTimeout(function(){document.pay_form.submit(); }, 500);\n" +
                        "})();" +
                        "    </script>" +
                        "</body>" +
                        "</html>"
                    // var wnd = window.open("about:blank", "", "_blank");
                    document.write(arr);
                } else {
                    //error
                }
            }).catch(err => {

        })
    }

}

export default withRouter(ChangePricingPlan);