import axios from 'axios';
import Cookies from "js-cookie";
import {GetToken} from "./JwtToken";
import { updateMembership } from './MembershipReducer';

export function HttpConfig(dispatch, history, url = window.api_url+'api/', header) {
    const token = GetToken();
    let headers = {...header, 'Authorization': 'Bearer '+token};

    const axiosInstance = axios.create({
        baseURL: url,
        headers: headers
    });

    axiosInstance.interceptors.response.use(
        response => {
          if (response.data && response.data.code_status === 403) {
            if (dispatch){
              dispatch(updateMembership("fuhqweijasdokzxc"))
              window.location.href = '/pricing';
            }
              
          }
          else if (response.data && response.data.code_status === 410) {
            Cookies.set("access_token", "undefined", { expires: 365 });
            window.location.href = '/auth/login?logout=1';
          }
          else if (response.data && response.data.code_status === 200) {
            if (dispatch){
              dispatch(updateMembership("tediuywskjhqamnb"))
            }
          }
          else if (response.data && response.data.code_status === 250) {
            if (dispatch){
              dispatch(updateMembership("fuhqweijasdokzxc"))
            }
          }
          else if (response.data && response.data.code_status === 200) {
            if (dispatch){
              dispatch(updateMembership("tediuywskjhqamnb"))
            }
          }
          return response
        },
        error => {
        /*   if (error.response && error.response.status === 401) {
            Cookies.set("access_token", "undefined", { expires: 365 });
            window.location.href = '/auth/pricing';
          } */
          return Promise.reject(error);
        }
    );

    return axiosInstance;
}
