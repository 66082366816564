/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';

export default class SmallStars extends React.Component {

    render() {
        return (
            <div style={{display:'flex', flexDirection:'row', alignItems:"center", justifyContent:'flex-start'}}>
                <div style={{position:"relative", fontSize:11}}>
                    <i className="fas fa-star text-yellow" style={{marginRight:2}} />
                    <i className="fas fa-star text-yellow" style={{marginRight:2}} />
                    <i className="fas fa-star text-yellow" style={{marginRight:2}} />
                    <i className="fas fa-star text-yellow" style={{marginRight:2}} />
                    <i className="fas fa-star text-yellow" style={{}} />
                    <div style={{width:((5-this.props.score)*20)+'%', height:'100%',position:'absolute', top:0, right:0, backgroundColor:'#ffffffbb'}}>

                    </div>
                </div>
            </div>
        )
    }

}
