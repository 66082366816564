/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {HttpConfig} from "../../tools/Http";
import Cookies from "js-cookie";

class Pricing extends React.Component {
  render() {
    return (
        <>
          <AuthHeader title="Choose the best plan for your business" lead="" />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="10">
                <div className="pricing card-group flex-column flex-md-row mb-3">
                  <Card className="card-pricing border-0 text-center mb-4">
                    <CardHeader className="bg-transparent">
                      <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                        Basic Pack
                      </h4>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-2">350 Dhs</div>
                      <span className="text-muted">per month</span>
                      <ul className="list-unstyled my-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-trophy" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Ios Top Rankings</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-trophy" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Android Top Rankings</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-paint-brush" />
                              </div>
                            </div>
                            <div>
                                <span className="pl-2">
                                Graphics Creator
                                </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-comment" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Description Generator</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-mobile" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Related Apps</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-drafting-compass" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Keyword Ranking</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-gradient-info shadow rounded-circle text-white">
                                <i className="fas fa-fire" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2">Google Niches</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <Button className="mb-1" color="info" type="button"
                              onClick={() => {
                                this.makePayment()
                              }}>
                        <i className="fas fa-shopping-cart"/>  Select Plan

                      </Button>
                    </CardBody>
                    <CardFooter>
                      <a
                          className="text-light"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                      >
                        Contact sales
                      </a>
                    </CardFooter>
                  </Card>
                  <Card className="card-pricing bg-gradient-success zoom-in shadow-lg rounded border-0 text-center mb-4">
                    <CardHeader className="bg-transparent">
                      <h4 className="text-uppercase ls-1 text-white py-3 mb-0">
                        Premium pack
                      </h4>
                    </CardHeader>
                    <CardBody className="px-lg-7">
                      <div className="display-1 text-white">Soon</div>
                      <span className="text-white">Stay tuned</span>
                      <ul className="list-unstyled my-4">
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-trophy" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Ios Top Rankings</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-trophy" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Android Top Rankings</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-paint-brush" />
                              </div>
                            </div>
                            <div>
                                <span className="pl-2 text-white">
                                Graphics Creator
                                </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-comment" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Description Generator</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-mobile" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Related Apps</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-credit-card" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Vanilla Credit Cards</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-drafting-compass" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Keyword Ranking</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-fire" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Google Niches</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              <div className="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted">
                                <i className="fas fa-code" />
                              </div>
                            </div>
                            <div>
                              <span className="pl-2 text-white">Limit Library</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <Button className="mb-1 disabled" color="info" type="button"
                              onClick={() => {
                              }}>
                        <i className="fas fa-clock " />  Coming Soon

                      </Button>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                      <a
                          className="text-white"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                      >
                        Contact sales
                      </a>
                    </CardFooter>
                  </Card>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-lg-center px-3 mt-5">
              <div>
                <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i className="ni ni-building text-primary" />
                </div>
              </div>
              <Col lg="6">
                <p className="text-white">
                  <strong>The Arctic Ocean</strong> freezes every winter and much
                  of the sea-ice then thaws every summer, and that process will
                  continue whatever.
                </p>
              </Col>
            </div>
            <Row className="row-grid justify-content-center">
              <Col lg="10">
                <Table className="table-dark mt-5" responsive>
                  <thead>
                  <tr>
                    <th className="px-0 bg-transparent" scope="col">
                      <span className="text-light font-weight-700">
                        Features
                      </span>
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Basic Pack
                    </th>
                    <th className="text-center bg-transparent" scope="col">
                      Premium Pack
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td className="px-0">Ios Top Charts</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Android Top Charts</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                  <td className="px-0">
                    Graphics Creator
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Description Generator</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Related Apps
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td className="px-0">Vanilla Credit Cards</td>*/}
                  {/*  <td className="text-center">*/}
                  {/*    <i className="fas fa-check text-success" />*/}
                  {/*  </td>*/}
                  {/*  <td className="text-center">*/}
                  {/*    <i className="fas fa-check text-success" />*/}

                  {/*  </td>*/}
                  {/*</tr>*/}
                  <tr>
                    <td className="px-0">Keywords Ranking</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />

                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Google Niches</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />

                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Published Apps Tracking</td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Support Team</td>
                    <td className="text-center">
                        <span className="text-sm text-light">
                        Limited to 1 call monthly
                      </span>
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Android RT Analytics Library</td>
                    <td className="text-center">
                        <span className="text-sm text-light">
                        x
                      </span>
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Android Ads Library</td>
                    <td className="text-center">
                        <span className="text-sm text-light">
                        x
                      </span>
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">IOS Libraries</td>
                    <td className="text-center">
                        <span className="text-sm text-light">
                        x
                      </span>
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-0">Dedicated APK decompiler</td>
                    <td className="text-center">
                        <span className="text-sm text-light">
                        x
                      </span>
                    </td>
                    <td className="text-center">
                      <i className="fas fa-check text-success" />
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
    );
  }

  makePayment(){
    HttpConfig().get('create-cmi-payment/2/'+Cookies.get('id'))
        .then(res => {
          if(res.data.status){
            let arr = "<html>\n" +
                "<head>\n" +
                "<title>Mobioptions Payment</title>\n" +
                "<meta http-equiv=\"Content-Language\" content=\"tr\">\n" +
                "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=ISO-8859-9\">\n" +
                "<meta http-equiv=\"Pragma\" content=\"no-cache\">\n" +
                "<meta http-equiv=\"Expires\" content=\"now\">\n" +
                "</head>\n" +
                "\n" +
                "<body >\n" +
                "<form name=\"pay_form\" method=\"post\" action=\"https://payment.cmi.co.ma/fim/est3Dgate\">";
            for (let key in res.data.result) {
              if (res.data.result.hasOwnProperty(key)) {
                arr = arr+ "<input type=\"hidden\" name=\"" +key +"\" value=\""+res.data.result[key]+"\" /><br />";
              }
            }
            arr = arr + "" +
                "</form>" +
                "<script type=\"text/javascript\" language=\"javascript\">" +
                "     (function () {\n" +
                "   setTimeout(function(){document.pay_form.submit(); }, 500);\n" +
                "})();"+
                "    </script>" +
                "</body>" +
                "</html>"
            // var wnd = window.open("about:blank", "", "_blank");
            document.write(arr);
          }
          else{
            //error
          }
        }).catch(err => {

    })
  }
}

export default Pricing;
