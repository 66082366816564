/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {Link} from "react-router-dom";

export default class ContactUs extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            screenshots: [
                'img/screenshots/1.png',
                'img/screenshots/2.png',
                'img/screenshots/3.png',
                'img/screenshots/4.png',
                'img/screenshots/5.png',
                'img/screenshots/6.png',
            ],
        }
    }

    render() {
        return (
            <div style={{}}>
                {this.getHeader()}
                <main id="main">
                    {/*{this.firstSection()}*/}
                    {/*{this.getTestim()}*/}
                    {/*{this.getStarted()}*/}
                    {/*{this.aboutUs()}*/}
                    {/*{this.features()}*/}
                    {/*{this.getScreenShots()}*/}
                    {/*{this.getPricing()}*/}
                    {this.contact()}
                </main>
                {this.getFooter()}
                {this.scripts()}

                <a href="#" className="back-to-top" style={{display: "inline"}}><i className="fa fa-chevron-up"></i></a>

            </div>
        )
    }
    componentWillMount() {
        this.addScript("homepage-assets/vendor/jquery/jquery.min.js");
        this.addScript("homepage-assets/vendor/jquery.easing/jquery.easing.min.js");

        this.addLink("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Roboto:100,300,400,500,700|Philosopher:400,400i,700,700i");
        this.addLink("homepage-assets/vendor/bootstrap/css/bootstrap.min.css");
        this.addLink("homepage-assets/vendor/animate.css/animate.min.css");
        this.addLink("homepage-assets/vendor/font-awesome/css/font-awesome.min.css");
        this.addLink("homepage-assets/vendor/modal-video/css/modal-video.min.css");
        this.addLink("homepage-assets/vendor/owl.carousel/assets/owl.carousel.min.css");
        this.addLink("homepage-assets/css/style.css");


        this.addScript("homepage-assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
        this.addScript("homepage-assets/vendor/php-email-form/validate.js");
        this.addScript("homepage-assets/vendor/wow/wow.min.js");
        this.addScript("homepage-assets/vendor/modal-video/js/modal-video.min.js");
        this.addScript("homepage-assets/vendor/owl.carousel/owl.carousel.min.js");
        this.addScript("homepage-assets/vendor/superfish/superfish.min.js");
        this.addScript("homepage-assets/vendor/hoverIntent/hoverIntent.js");
        this.addScript("homepage-assets/js/main.js");
    }

    addScript(src){
        let link = document.createElement( "script" );
        link.src = src;
        document.getElementsByTagName( "body" )[0].appendChild( link );
    }
    addLink(href, rel = "stylesheet"){
        let link = document.createElement( "link" );
        link.href = href;
        link.rel = rel;
        document.getElementsByTagName( "head" )[0].appendChild( link );
    }
    getHeader(){
        return(
            <header id="header" className="header header-hide">
                <div className="container">

                    <div id="logo" className="pull-left">
                        <h1><a href="/" className="scrollto"><span>Mobi</span>Options</a></h1>
                    </div>

                    <nav id="nav-menu-container">
                        <ul className="nav-menu">
                            {/*<li className="menu-active"><a href="#hero">Home</a></li>*/}
                            {/*<li><a href="#about-us">About</a></li>*/}
                            {/*<li><a href="#about-us">Rankings</a></li>*/}
                            {/*<li><a href="#about-us">Alerts</a></li>*/}
                            {/*<li><a href="#features">Features</a></li>*/}
                            {/*<li><a href="#screenshots">Screenshots</a></li>*/}
                            {/*<li><a href="#pricing">Pricing</a></li>*/}
                            <li><a href="#contact">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }

    firstSection(){
        return(
            <section id="hero" className="wow fadeIn">
                <div className="hero-container">
                    <h1>Welcome to MobiOptions</h1>
                    <h2>The place where your can make your apps more successful</h2>
                    <img src="homepage-assets/img/2739354.jpg" alt="Hero Imgs" style={{}}/>
                    <a href="#contact" className="btn-get-started scrollto">Create Account or Login</a>
                    {/*<div className="btns">*/}
                    {/*    <a href="#"><i className="fa fa-apple fa-3x"></i> App Store</a>*/}
                    {/*    <a href="#"><i className="fa fa-play fa-3x"></i> Google Play</a>*/}
                    {/*    <a href="#"><i className="fa fa-windows fa-3x"></i> windows</a>*/}
                    {/*</div>*/}
                </div>
            </section>
        )
    }

    getStarted(){
        return (<section id="get-started" className="padd-section text-center wow fadeInUp">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>MobiOptions Ranking</h2>
                        <p className="separator">Tracking Apps On Multiple Markets</p>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Track-your-app.png" alt="img" className="img-fluid"/>
                                <h4>Keep Track of Your Apps</h4>
                                <p>Track your ranks in every market alongside the competition Hourly app ranks</p>
                                {/*<a href="#">read more</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-block">

                                <img src="homepage-assets/img/icons/Trends.png" alt="img" className="img-fluid"/>
                                <h4>Discover Trends</h4>
                                <p>Get an overlook of the hottest trending app in different markets, even for previous months</p>
                                {/*<a href="#">read more</a>*/}
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Top-Apps.png" alt="img" className="img-fluid"/>
                                <h4>Practical Top App Lists</h4>
                                <p>View the top 400 apps in every store instantly and navigate across countries and categories</p>
                                {/*<a href="#">read more</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    aboutUs(){
        return(
            <section id="about-us" className="about-us padd-section wow fadeInUp">
                <div className="container">
                    <div className="row justify-content-center" style={{alignItems:'center'}}>

                        <div className="col-md-7 col-lg-7">
                            <img src="img/4--Get-a-complete-picture-of-all-your-apps-at-once.png" alt="About" className={"about-img"}/>
                        </div>

                        <div className="col-md-7 col-lg-5" style={{alignItems:'center'}}>
                            <div className="about-content">
                                <h2>Get a complete picture of all your apps at once</h2>
                                <p>Real time alerts:</p>
                                <p>We'll let you know as soon as something interesting happens with any of your apps. Set up a general alert or specify custom filters that fit your team's unique workflow. We speak email, slack, push notifications, and more.</p>
                                <ul className="list-unstyled">
                                    <li><i className="fa fa-angle-right"></i>Get notified once your app goes live</li>
                                    <li><i className="fa fa-angle-right"></i>Get notified if your app is rejected</li>
                                    <li><i className="fa fa-angle-right"></i>Get notified if your app gets suspended oe removed</li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )
    }

    features(){
        return(
            <section id="features" className="padd-section text-center wow fadeInUp">

                <div className="container">
                    <div className="section-title text-center">
                        <h2>MobiOptions Features</h2>
                        <p className="separator">Make key strategic decisions with valuable data from millions of mobile apps.</p>
                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/History-Log.png" alt="img" className="img-fluid"/>
                                <h4>History Log</h4>
                                <p>Get the Full Picture with Complete Historic Data Dig into granular history of every app to identify trends and size up growth</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Timeless-Navigation.png" alt="img" className="img-fluid"/>
                                <h4>Timeless Navigation</h4>
                                <p>Go back to day one, analyze the last 30 days, or specify any other time period. There are no limits. a timeless experience</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/One-Click-Download.png" alt="img" className="img-fluid"/>
                                <h4>One Click Download</h4>
                                <p>You can download everything you need for your store listing, from the screenshots and icon to the app description</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Photo-Editor.png" alt="img" className="img-fluid"/>
                                <h4>Online Photo Editor</h4>
                                <p>Generate or copy a full successful app metadata design in minutes and download it to your Device in second.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/ASO.png" alt="img" className="img-fluid"/>
                                <h4>ASO Tools</h4>
                                <p>Pick any app description to edit or create your own using our keyword density tool and words & characters counter.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Management.png" alt="img" className="img-fluid"/>
                                <h4>Apps Management</h4>
                                <p>By adding multiples categories to separate your favorite apps, you can have a clean list for each niche,. </p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Discord-Chat.png" alt="img" className="img-fluid"/>
                                <h4>Discord Chat</h4>
                                <p>Join our VIP Discord group where you will found the latest tricks ASO and best practice to rank your apps better than ever</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="feature-block">
                                <img src="homepage-assets/img/icons/Card.png" alt="img" className="img-fluid"/>
                                <h4>Gift Cards Solution</h4>
                                <p>Create you android play console account within seconds with our trusted Gif Card solution</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }

    getScreenShots(){
        return (
            <section id="screenshots" className="padd-section text-center wow fadeInUp">

                <div className="container">
                    <div className="section-title text-center">
                        <h2>Screenshots</h2>
                        <p className="separator">Live Screenshots From MobiOptions</p>
                    </div>
                </div>

                <div className="container">
                    <div className="owl-carousel owl-theme">
                        {this.state.screenshots.map((el,i) => (
                            <img src={el} alt="img" style={{}}/>
                        ))}
                    </div>
                </div>
            </section>
        )
    }

    getTestim(){
        return(
            <section id="testimonials" className="padd-section text-center wow fadeInUp">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-md-8">

                            <div className="testimonials-content">
                                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">

                                    <div className="carousel-inner" role="listbox">

                                        <div className="carousel-item  active">
                                            <div className="top-top">

                                                <h2>About Mobioptions</h2>
                                                <p>Mobi Options delivers the best and easiest tools for your apps or games to get the complete picture of the mobile landscape you need to acquire and retain customers, prioritize your roadmap, enter new markets, and optimize ROI.</p>
                                                {/*<h4>Kimberly Tran<span>manager</span></h4>*/}

                                            </div>
                                        </div>

                                        <div className="carousel-item ">
                                            <div className="top-top">

                                                <h2>Our growing experience with mobile apps</h2>
                                                <p>Our company has a wonderful experience in mobile app development for Android, We started as app developers and published apps and games that were downloaded by more than a hundred million users.</p>
                                                {/*<h4>Henderson<span>manager</span></h4>*/}

                                            </div>
                                        </div>

                                        <div className="carousel-item ">
                                            <div className="top-top">

                                                <h2>But we always needed a make our work easier</h2>
                                                <p>As developers, we saw the need for more information about apps and created Mobi Options, the leading source of information about the Android ecosystem.</p>
                                                {/*<h4>David Spark<span>manager</span></h4>*/}

                                            </div>
                                        </div>

                                    </div>

                                    <div className="btm-btm">

                                        <ul className="list-unstyled carousel-indicators">
                                            <li data-target="#carousel-example-generic" data-slide-to="0"
                                                className="active"></li>
                                            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
                                            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )
    }

    getPricing(){
        return (
            <section id="pricing" className="padd-section text-center wow fadeInUp">

                <div className="container">
                    <div className="section-title text-center">

                        <h2>Meet With Price</h2>

                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="col-md-6 col-lg-3">
                            <div className="block-pricing">
                                <div className="table">
                                    <h4>1 Account</h4>
                                    <h2>$50.61</h2>
                                    <span>500DH</span>
                                    <ul className="list-unstyled">
                                        <li><i>per month</i></li>
                                        <li>Top Rankings</li>
                                        <li>Graphics Creator</li>
                                        <li>Description Generator</li>
                                        <li>Related Apps</li>
                                        <li>Vanilla Credit Cards</li>
                                    </ul>
                                    <div className="table_btn">
                                        <a href="#contact" className="btn"><i className="fa fa-shopping-cart"></i> Select Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="block-pricing">
                                <div className="table">
                                    <h4>2 Accounts</h4>
                                    <h2>$80.98</h2>
                                    <span>800DH</span>
                                    <ul className="list-unstyled">
                                        <li><i>per month</i></li>
                                        <li>Top Rankings</li>
                                        <li>Graphics Creator</li>
                                        <li>Description Generator</li>
                                        <li>Related Apps</li>
                                        <li>Vanilla Credit Cards</li>
                                    </ul>
                                    <div className="table_btn">
                                        <a href="#contact" className="btn"><i className="fa fa-shopping-cart"></i> Select Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="block-pricing">
                                <div className="table">
                                    <h4>5 Accounts</h4>
                                    <h2>$200.99</h2>
                                    <span>2000DH</span>
                                    <ul className="list-unstyled">
                                        <li><i>per month</i></li>
                                        <li>Top Rankings</li>
                                        <li>Graphics Creator</li>
                                        <li>Description Generator</li>
                                        <li>Related Apps</li>
                                        <li>Vanilla Credit Cards</li>
                                    </ul>
                                    <div className="table_btn">
                                        <a href="#contact" className="btn"><i className="fa fa-shopping-cart"></i> Select Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                            <div className="block-pricing">
                                <div className="table">
                                    <h4>10 Accounts</h4>
                                    <h2>$400.99</h2>
                                    <span>4000DH</span>
                                    <ul className="list-unstyled">
                                        <li><i>per month</i></li>
                                        <li>Top Rankings</li>
                                        <li>Graphics Creator</li>
                                        <li>Description Generator</li>
                                        <li>Related Apps</li>
                                        <li>Vanilla Credit Cards</li>
                                    </ul>
                                    <div className="table_btn">
                                        <a href="#contact" className="btn"><i className="fa fa-shopping-cart"></i> Select Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        )
    }

    contact(){
        return(
            <section id="contact" className="padd-section wow fadeInUp">
                <br/><br/><br/>
                <div className="container">
                    <div className="section-title text-center">
                        <h2>Contact US</h2>
                        <p className="separator">We care about your opinion toward us</p>
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-3 col-md-4">

                            <div className="info">
                                <div className="email">
                                    <i className="fa fa-envelope"></i>
                                    <p>contact@mobioptions.com</p>
                                </div>
                                <div>
                                    <i className="fa fa-map-marker"></i>
                                    <p>IMM E 19 ETAGE 1, APPT 1-B<br/>RIAD ZITOUNE, MEKNES</p>
                                </div>
                                {/*<div>*/}
                                {/*    <i className="fa fa-phone"></i>*/}
                                {/*    <p>+1 5589 55488 55s</p>*/}
                                {/*</div>*/}
                            </div>

                            <div className="social-links">
                                <a href="https://twitter.com/MobiOptions" className="twitter"><i className="fa fa-twitter"></i></a>&nbsp;&nbsp;
                                <a href="https://www.facebook.com/mobioptions" className="facebook"><i className="fa fa-facebook"></i></a>&nbsp;&nbsp;
                                <a href="https://www.instagram.com/mobioptions/" className="instagram"><i className="fa fa-instagram"></i></a>
                            </div>

                        </div>

                        <div className="col-lg-5 col-md-8">
                            <div className="form">
                                <form action="api/contact" method="post" role="form" className="php-email-form">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" id="name"
                                               placeholder="Your Name" data-rule="minlen:4"
                                               data-msg="Please enter at least 4 chars"/>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name="email" id="email"
                                               placeholder="Your Email" data-rule="email"
                                               data-msg="Please enter a valid email"/>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" name="subject" id="subject"
                                               placeholder="Subject" data-rule="minlen:4"
                                               data-msg="Please enter at least 8 chars of subject"/>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="message" rows="5" data-rule="required"
                                                  data-msg="Please write something for us"
                                                  placeholder="Message"/>
                                        <div className="validate"></div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/><br/>
            </section>
        )
    }

    footer(){
        return (
            <footer className="footer">

                <div className="copyrights">
                    <div className="container">
                        <p>&copy; Copyrights eStartup. All rights reserved.</p>
                        <div className="credits">
                            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                        </div>
                    </div>
                </div>

            </footer>
        )
    }

    getFooter(){
        return (
            <div style={{width:'100%', display:'flex', alignItems:'center',justifyContent:'center',flexDirection:"column",backgroundColor:'#f3fcfe'}}>
                <div className="footer-copyright text-center py-3 home-page-footer-end" style={{display:'flex', alignItems:"space-between", justifyContent:"space-between"}}>
                    <span>© 2020 Copyright, All Rights Reserved by Mobi Options</span>
                    <div style={{ display:'flex', flexDirection:'row'}} className={'home-page-footer-superend'}>
                        {/*<Link to={'terms'} target={'blank'} style={{marginRight:25, fontSize:12, textDecoration:'none'}}>Terms & Conditions</Link>*/}
                        <Link to={'privacy'} target={'blank'}  style={{marginRight:25, fontSize:12, textDecoration:'none'}}>Privacy Policy</Link>
                        <Link to={'contact-us'} target={'blank'}  style={{marginRight:25, fontSize:12, textDecoration:'none'}}>Contact Us</Link>
                    </div>
                </div>
            </div>
        );
    }

    scripts(){
        return(
            <div>

            </div>
        )
    }










}
