/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  FormGroup,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { Tree, TreeNode } from 'react-organizational-chart';
import "./styles.scss";
import { HttpConfig } from "tools/Http";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";


export default(props) => {
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadingKeyword, setLoadingKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [disabledKeywords, setDisabledKeywords] = useState([]);
  const [clickedKeywords, setClickedKeywords] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    // getKeywords();
  }, [0]);

  // const searchForKeyword = () => {
  //   HttpConfig()
  //     .get("suggestion?q=" + searchQuery)
  //     .then((res) => {
  //       if (res.data.status) {
  //         setKeywords(res.data.list)
  //       } else {
  //         //error
  //       }
  //     })
  //     .catch((err) => {
  //       //err
  //     });
  // }

  const isJsonEmpty = (json) => {
    return Object.keys(json).length === 0;
  };

  const searchForKeyword = async (keyword, search) => {
    if(search == true) {
      setDisabledKeywords([])
      setClickedKeywords([])
      setKeywords([])
    }
    setLoadingKeyword(keyword)
    setLoading(true)
    HttpConfig(dispatch)
      .get("suggestion?q=" + keyword)
      .then((res) => {
        if (res.data.status) {
          var count = 0;
          const updatedKeywords = []
          if(search == true) {
            res.data.list.forEach((subKeyword) => {
              if(keyword.toLowerCase() !== subKeyword)
                updatedKeywords[subKeyword] = [];
            });
          }
          else{
          res.data.list.forEach((keyword) => {
            const searched = searchKeyInJson(keywords, keyword)
            if (searched == null)
              updatedKeywords[keyword] = [];
            else 
              count++;
          });}
          if (isJsonEmpty(keywords) || search == true) {
            setKeywords(updatedKeywords)
          }
          else {
            const updatedData = updateJsonValue(keywords, keyword, updatedKeywords);
            setKeywords(updatedData)
          }
          setLoading(false)
          if((res.data.list.includes(keyword.toLowerCase()) && res.data.list.length == 1) || count == res.data.list.length) {
            setDisabledKeywords(prevKeywords => [...prevKeywords, keyword])
          }
          if(res.data.list.length == 1 && search == true) {
            setNotFound(true);
            props.notify(
              "danger",
              "Oops!",
              "Could not found any subkeywords for " + keyword
            );
          }
          setClickedKeywords(prevKeywords => [...prevKeywords, keyword])
        } else {
          setLoading(false)
        }
      })
      .catch((err) => {
        setLoading(false)
      });
  };

  const searchKeyInJson = (data, key) => {
    if (typeof data !== 'object' || data === null) {
      return null; // Reached a non-object value, key not found
    }

    if (key in data) {
      return data[key]; // Key found, return its value
    }

    // Traverse the nested objects recursively
    for (let k in data) {
      const result = searchKeyInJson(data[k], key);
      if (result !== null) {
        return result; // Key found in nested object, return its value
      }
    }

    return null; // Key not found
  };

  const updateJsonValue = (data, key, value) => {
    if (typeof data !== 'object' || data === null) {
      return data; // Reached a non-object value, return as is
    }

    // Create a copy of the data object to avoid mutation
    const updatedData = { ...data };

    if (key in updatedData) {
      // Update the value of the key
      updatedData[key] = value;
    } else {
      // Traverse the nested objects recursively
      for (let k in updatedData) {
        updatedData[k] = updateJsonValue(updatedData[k], key, value);
      }
    }

    return updatedData;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        props.notify(
          "success",
          "Done!",
          "Your keyword has been copied successfully"
        );
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };
  
  const renderSubKeywords = (keywords) => {
    if (!keywords) {
      return null;
    }
  
    return Object.entries(keywords).map(([keyword, subKeywords]) => (
      <TreeNode
        key={keyword}
        label={<StyledNode disabled={clickedKeywords.includes(keyword) || loading} style={{background: disabledKeywords.includes(keyword) ? 'linear-gradient(to top, #b3deff, #4db1ff )' : 'linear-gradient(to top, #0090ff, #175cff)'}} onClick={() => searchForKeyword(keyword, false)} onContextMenu={(e) => {e.preventDefault();copyToClipboard(keyword)}}>{
          loading && loadingKeyword === keyword ?
            <ClipLoader
              color={"white"}
              loading={true}
              size={15}
            />
          :
            keyword
        }</StyledNode>}
      >
        {renderSubKeywords(subKeywords)}
      </TreeNode>
    ))
  };

  const renderSearch = () => {
    return (
      <Card>
          <CardHeader>
              <Row>
                  <Col lg="6" xs="7">
                      <h3 className="mb-0">Search Keyword</h3>
                  </Col>
              </Row>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col xs="10">
                  <Input
                    placeholder="Enter a Keyword..."
                    type="text"
                    value={searchQuery}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          searchForKeyword(searchQuery, true);
                        }
                    }}
                    onChange={e => setSearchQuery(e.target.value)}/>
                </Col>
                <Col xs={"2"}>
                  <FormGroup>
                    <Button className="btn btn-info"
                      disabled={searchQuery === ""}
                      onClick={() => {
                        searchForKeyword(searchQuery, true)
                      }}
                    >
                      <i className="fas fa-search-dollar"></i>&nbsp;&nbsp;Search
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
      </Card>
    );
  }

  const StyledNode = styled.button`
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
    color: white;
    border: 0px;
  `;

  const renderResults = () => {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col lg="6" xs="6">
              <h3 className="mb-0">Keyword</h3>
            </Col>
            <Col lg="6" xs="6">
              <div className="float-right">
                <h4 className="mb-0 text-muted">Left click to expend. Right click to copy </h4>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{overflow: 'auto'}}>
          <Tree
            lineWidth={'2px'}
            lineColor={'#0090ff'}
            lineBorderRadius={'10px'}
            label={
              <StyledNode disabled={clickedKeywords.includes(searchQuery)} style={{background: notFound ? '#0090ff' : 'linear-gradient(to top, #0090ff, #175cff)'}}>{
                loading && loadingKeyword === searchQuery ?
                  <ClipLoader
                    color={"white"}
                    loading={true}
                    size={15}
                  />
                :
                  searchQuery
              }</StyledNode>
            }>
            {renderSubKeywords(keywords)}
          </Tree>
        </CardBody>
      </Card>
    )
  }

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Analytics
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-drafting-compass" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Keywords Suggestion
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row className="justify-content-center">
          <Col className="card-wrapper" lg="12">
            {renderSearch()}
            {renderResults()}
          </Col>
        </Row>
      </Container>
    </>
  );

  // .catch(err => {
  //   this.setState({loading: false})
  //   this.props.notify(
  //     "danger",
  //     "Error",
  //     "There was an error deleting selected app"
  //   );
  //   //err
  // });
}