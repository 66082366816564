export default class Utils {
    static formatInstalls(d) {
        let original = d;
        if (d == null)
            return {"d": "", "t": ""};

        else {
            // d = d.replace(/,/g,"").replace("+","")
            d = parseInt(d);
            if (d >= 1000000000)
                return {"d": (d / 1000000000) + "B+", "t": "Billion"};
            if (d >= 1000000)
                return {"d": (d / 1000000) + "M+", "t": "Million"};
            if (d >= 1000)
                return {"d": (d / 1000) + "K+", "t": "Thousand"};
        }

        return {"d": original, "t": ""};
    }

    static formatRecents(d) {
        let original = d;
        if (d == null)
            return {"d": "", "t": ""};

        else {
            // d = d.replace(/,/g,"").replace("+","")
            d = parseInt(d);
            if (d >= 1000000000)
                return {"d": (d / 1000000000).toFixed(2) + "B+", "t": "Billion"};
            if (d >= 1000000)
                return {"d": (d / 1000000).toFixed(2) + "M+", "t": "Million"};
            if (d >= 1000)
                return {"d": (d / 1000).toFixed(2) + "K+", "t": "Thousand"};
        }

        return {"d": original, "t": ""};
    }

    static formatReviews(amount) {
        if (!amount)
            return 0
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }

    static sortByAttr(arr, attr, isString, desc) {
        if (isString)
            arr.sort((a, b) => a[attr].localeCompare(b[attr]));
        else
            arr.sort((a, b) => a[attr] - b[attr]);
        if (desc)
            arr.reverse();
    }

    static formatAge(ageInYears) {
        const totalMonths = Math.round(ageInYears * 12);
  
        let formattedAge = '';

        if (totalMonths > 0) {
            formattedAge += totalMonths === 1 ? '1 month' : `${totalMonths} months`;
        }

        return formattedAge;
    }

    static keywordCountries = [
        {US: "United States"},
        {AT: "Austria"},
        {AU: "Australia"},
        {BE: "Belgium"},
        {BR: "Brazil"},
        {CA: "Canada"},
        {CN: "China"},
        {CZ: "Czech Republic"},
        {DE: "Germany"},
        {DK: "Denmark"},
        {FR: "France"},
        {GB: "Great Britain"},
        {HK: "Hong Kong"},
        {HU: "Hungary"},
        {JP: "Japan"},
        {ID: "Indonesia"},
        {IN: "India"},
        {IT: "Italy"},
        {IL: "Israel"},
        {RU: "Russia"},
        {MY: "Malaysia"},
        {MX: "Mexico"},
        {NL: "Netherlands"},
        {PL: "Poland"},
        {SA: "Saudi Arabia"},
        {KR: "South Korea"},
        {ZA: "South Africa"},
        {SE: "Sweden"},
        {SG: "Singapore"},
        {ES: "Spain"},
        {CH: "Switzerland"},
        {TW: "Taiwan"},
        {TH: "Thailand"},
        {TR: "Turkey"},
        {AE: "United Arab Emirates"},
        {VN: "Vietnam"}
    ]
}
