import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";


import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Container,
    Row,
    Col, InputGroup, Input
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {addPhone, verifyPin} from "../../tools/JwtToken";

class PhoneVerification extends React.Component {
    state = {
        phone: '',
        pin: '',
        hasClicked : false,
        verificationCodeSent: false,
        error: {has_error: false, message: ''},
        isLoading: false,
        rememberMe: false,
    };

    render() {
        return (
            <>
                <AuthHeader
                    title="Welcome to MobiOptions"
                    lead="Please start by typing your phone number"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Please type-in your phone number</small>
                                        {this.validationMessage()}
                                    </div>
                                    <Form role="form">
                                        {!this.state.verificationCodeSent &&
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: this.state.focusedphone
                                            })}
                                        >
                                            <PhoneInput
                                                country={'ma'}
                                                value={this.state.phone}
                                                onChange={phone => this.setState({phone})}
                                                disabled={this.state.verificationCodeSent}
                                                containerStyle={{
                                                    height: 46,
                                                    borderRadius: '.25rem',
                                                    boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                                    border: 0
                                                }}
                                                inputStyle={{
                                                    width: '100%',
                                                    borderWidth: 0,
                                                    height: '100%'
                                                }}
                                                buttonStyle={{
                                                    backgroundColor: "white",
                                                    borderWidth: 0
                                                }}
                                            />
                                        </FormGroup>
                                        }
                                        {this.state.verificationCodeSent &&
                                        <FormGroup
                                            className={classnames({
                                                focused: this.state.focusedPassword
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <Input
                                                    placeholder="Verification Code"
                                                    type="number"
                                                    onChange={(e) => this.setState({pin: e.target.value})}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        }
                                        <div className="text-center">
                                            {!this.state.verificationCodeSent &&
                                            <Button className="my-4" color="info" type="button"
                                                    disabled={this.state.hasClicked}
                                                    onClick={() => this.handleSubmit()}>
                                                Send Verification Code
                                            </Button>
                                            }
                                            {this.state.verificationCodeSent &&
                                            <Button className="my-4" color="success" type="button"
                                                    onClick={() => this.verifyPhoneNumber()}>
                                                Verify Phone Number
                                            </Button>
                                            }
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    handleSubmit() {
        this.setState({isLoading: true,hasClicked : true});
        let data = {
            number: this.state.phone,
        }
        addPhone(data, this.phoneValidation.bind(this));
        // addPhone(data, () => {
            // window.location.href = '/auth/sign-up?phone='+this.state.phone;
        // });
    }

    verifyPhoneNumber() {
        this.setState({isLoading: true});
        let data = {
            pin: this.state.pin,
            number: this.state.phone,
        }
        verifyPin(data, this.pinValidation.bind(this));
    }

    phoneValidation(message, success) {
        if (success) {
            this.setState({error: {has_success: true, message: message}, isLoading: false, verificationCodeSent: true})
        } else
            this.setState({error: {has_error: true, message: message}, isLoading: false})
    }

    pinValidation(message, success) {
        if (success) {
            window.location.href = '/auth/sign-up?phone='+this.state.phone;
            //redirect
        } else
            this.setState({error: {has_error: true, message: message}, isLoading: false})
    }

    validationMessage() {
        if (this.state.error.has_error) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-red">
                        {this.state.error.message}
                    </div>
                </div>
            )
        } else if (this.state.error.has_success) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-success">
                        {this.state.error.message}
                    </div>
                </div>
            )
        }
        return '';
    }


}

export default PhoneVerification;
