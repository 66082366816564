import React, { useState } from "react";
import { Input, Col, FormGroup, InputGroup } from "reactstrap";
import classnames from "classnames";
import ReactDatetime from "react-datetime";

const Component = ({ label, onChange, value, ...rest }) => {
  const [yourName, setYourName] = useState(false);

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        {label}
      </label>
      <InputGroup
        className={classnames("input-group-merge", {
          focused: yourName
        })}
      >
        <Input
          placeholder="Your name"
          type="text"
          // onFocus={e => setYourName(true)}
          // onBlur={e => setYourName(false)}
          onChange={e => {
            onChange(e.target.value);
          }}
          value={value}
        />
      </InputGroup>
    </FormGroup>
  );
};

export default Component;
