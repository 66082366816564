/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';

export default class PrivacyPolicy extends React.Component {

    render() {
        return (
            <div className="container-fluid privacy-container bg-gradient-white pt-4">
                <br/>
                <p><h2>Mobi Options is committed to protecting your privacy.</h2>

                    This privacy policy describes the privacy practices for the Mobi Options (the "Site"), located at
                    www.mobioptions.com).
                </p>

                <p><h3>Opt Out</h3>

                    Information about how can opt out of "Mobi Options" Just don't pay again, Or you can contact us to permanently close your account.
                </p>


                <p><h3>Information we collect</h3>

                    We may collect the following information when your register on or fill out a form on our site:<br/>
                    <br/>
                    First name and/or last name<br/>
                    E-mail address<br/>
                    Application Names<br/>
                    Phone Number<br/>
                    Keywords<br/>
                    If you elect, we may also collect Connected Account Data, as such term is defined in our Terms of
                    Service. We will use Connected Account Data only in an anonymized manner for commercial purposes.
                </p>

                <p><h3> Collected information uses</h3>

                    Any information provided by you is used to understand your needs and provide a better service. We
                    may use your information for the following reasons:<br/>
                    <br/>
                    To send periodic e-mails and to contact you for market research purposes<br/>
                    To send notificaton e-mails about Application Ranking Changes<br/>
                    To personalize your website experience according to your interests<br/>
                    To improve our products or services based on your needs
                </p>

                <p><h3> Collected information security</h3>

                    In order to prevent any unauthorised access or disclosure of the personal information that we
                    gather,we have in place electronic and managerial procedures to secure and guard these information.
                </p>
                <br/>

                <p><h3> Data Retention</h3>

                    Mobi Options stores some information indefinitely for operational purposes, such as technical
                    support and abuse prevention. Other data such as web server logs or store sales reports are only
                    kept as long as space is available for them. We take measures to delete your Personal Information or
                    keep it in a form that does not permit identifying you unless such information is necessary to
                    resolve disputes or enforce our agreements. The retention period for data retained is determined by
                    various criteria such as the type of user, the product serviced, and the nature of our relationship.
                    The retention period can be lengthened or shortened based on the cancellation or reenrollment of our
                    services with products. The retention period can also be modified based on internal changes in
                    auditing requirements and/or mandatory retention periods provided by law and statue of limitations.
                    Upon request to forgetme@mobioptions.com, Mobi Options will delete your account along with your
                    personal information within 45 business days. A request consists of an email with your first name,
                    last name. The email address on the account to be deleted must match with the requester's email
                    address. Mobi Options reserves the right to reject deletion requests that are unduly burdensome or
                    repetitive in nature. Mobi Options also reserves the right to reject deletion requests if there are
                    legal obligations to maintain the data due to a dispute or a need to protect our agreements. If you
                    would like to request the personal information Mobi Options is storing about you, please email
                    data-protection@mobioptions.com.
                    Links to other websites<br/>
                    The Site may contain links to other websites of interest. We cannot be responsible for the
                    protection and privacy of any information which you provide while visiting such sites; such sites
                    are not goverened by this privacy statement.
                </p>


                <p><h3> Date of Last Update.</h3>

                    This policy was last updated on MAY 1, 2020.
                </p>
                <br/>
            </div>
        )
    }
}
