/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, Col,
    Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {HttpConfig} from "../../tools/Http";

export default class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            not_app_pub:false,
            not_app_removed:false,
            not_sub_exp:false,
            email_app_pub:false,
            email_app_removed:false,
            email_sub_exp:false,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        HttpConfig().get("settings/get")
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        not_app_pub:res.data.settings.not_app_pub,
                        not_app_removed:res.data.settings.not_app_removed,
                        not_sub_exp:res.data.settings.not_sub_exp,
                        email_app_pub:res.data.settings.email_app_pub,
                        email_app_removed:res.data.settings.email_app_removed,
                        email_sub_exp:res.data.settings.email_sub_exp,
                    })
                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="align-items-center">
                        <Col lg={3}></Col>
                        {this.renderNotifications()}
                    </Row>
                    <Row>
                        <Col lg={3}></Col>
                        {this.renderEmails()}
                    </Row>
                </Container>
            </>
        )
    }

    renderNotifications(){
        return(
            <Col lg={6} className={""}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="10">
                                <h3 className="mb-0">Notifications Settings</h3>
                            </Col>
                            <Col className="text-md-right" lg="6" xs="2">
                                <i className={"fa fa-bell text-muted"}></i>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={"mb-4"}>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive a notification when an app gets published
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                    <input checked={this.state.not_app_pub}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("not_app_pub",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row className={"mb-4"}>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive a notification when an app gets removed
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                     <input checked={this.state.not_app_removed}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("not_app_removed",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive a notification when my subscription is about to expire
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                     <input checked={this.state.not_sub_exp}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("not_sub_exp",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }
    toggleStuff = (state,val) => {
        this.setState({
            [state]: val
        });
    };
    renderEmails(){
        return(
            <Col lg={6} className={""}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="10">
                                <h3 className="mb-0">Emails Settings</h3>
                            </Col>
                            <Col className="text-md-right" lg="6" xs="2">
                                <i className={"fa fa-envelope text-muted"}></i>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className={"mb-4"}>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive an email when an app gets published
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                     <input checked={this.state.email_app_pub}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("email_app_pub",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row className={"mb-4"}>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive an email when an app gets removed
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                     <input checked={this.state.email_app_removed}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("email_app_removed",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row className={"mb-4"}>
                            <Col xs={10}>
                                <label
                                    className="form-control-label"
                                    htmlFor="exampleDatepicker"
                                >
                                    Receive an email when my subscription is about to expire
                                </label>
                            </Col>
                            <Col xs={2}>
                                <label className="custom-toggle custom-toggle-success mr-1">
                                     <input checked={this.state.email_sub_exp}
                                           type="checkbox"
                                           onChange={(e) => this.toggleStuff("email_sub_exp",e.target.checked)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <FormGroup>
                                    <Button  className="btn-default" onClick={e => this.postSettings()}>Update</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Settings
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-cog" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Settings
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            {/*<Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">*/}
                            {/*    <Button className="btn-neutral" color="default" size="sm" onClick={()=>this.setState({defaultModal:true})}>*/}
                            {/*        General Settings*/}
                            {/*    </Button>*/}
                            {/*</Col>*/}
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    postSettings(){
        HttpConfig().post('settings/set', this.state)
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success",'Setting updated');
                }
                else{
                    //error
                        this.props.notify("danger", "Error",'There was an error editing your settings');

                }
            }).catch(err => {
            this.props.notify("danger", "Error",'There was an error editing your settings');
            //err
        })
    }



}
