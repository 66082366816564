/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, CardTitle, Col,
    Container, Row,

} from "reactstrap";
import {HttpConfig} from "../../tools/Http";
import Chart from "chart.js";
import {Line} from "react-chartjs-2";
import {
    chartOptions,
    parseOptions,
    chartExample3,
} from "variables/charts.js";
import Cookies from "js-cookie";
import CryptoJSAesJson from "../../tools/cryptojs-aes-format";
const queryString = require('query-string');
var CryptoJS = require("crypto-js");



export default class AdsStats extends React.Component {
    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }

        let params = queryString.parse(props.props.location.search);
        if(!params['id'] || !params['pkg'])
            window.location.href = "/404"

        this.state = {
            id:params['id'],
            pkg:params['pkg'],
            installsDataSet : {labels: [], datasets: [{data: []}]},
            installs_0: 0,
            installs_1: 0,
            installs_2: 0,
            installs_3: 0,

            openDataSet :[],
            open_0: 0,
            open_1: 0,
            open_2: 0,
            open_3: 0,

            adsDataSet :[],
            ads_0: 0,
            ads_1: 0,
            ads_2: 0,
            ads_3: 0,
            ads_4: 0,
            ads_5: 0,
        };
    }

    componentDidMount() {
        this.getInstallsData();
        this.getInstallsGrowth();
        this.getOpenData();
        this.getOpenGrowth();
        this.getAdsData();
        this.getAdsGrowth();
    }



    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            {this.realTimeInstalls()}
                            {this.getRealActivity()}
                            {this.getAdsView()}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Real Time Statistics
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>

                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    realTimeInstalls(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Real Time Installs for <b>{this.state.pkg}</b></h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={4}>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Total Installs
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                        {this.state.installs_0}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's PlayStore Installs
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.installs_1}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Other Networks Installs
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.installs_2}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        This Month's Total Installs
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.installs_3}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <h5 className="h3 m-2">This Month's Total Installs</h5>
                            <div className="chart">
                                <Line
                                    data={this.state.installsDataSet}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
    getRealActivity(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Real Time Activity for <b>{this.state.pkg}</b></h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={4}>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Total App Open
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                        {this.state.open_0}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Unique App Open
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.open_1}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Retained Users
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.open_2}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        This Month's Total App Open
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.open_3}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={8}>
                            <h5 className="h3 m-2">Monthly Total Unique App open</h5>
                            <div className="chart">
                                <Line
                                    data={this.state.openDataSet}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
    getAdsView(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Real Time Ads for <b>{this.state.pkg}</b></h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col lg={4}>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Total Shown Ads
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                        {this.state.ads_4}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        This Month's Total Shown Ads
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.ads_5}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Admob Banners Impressions
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.ads_0}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's FB Banners Impressions
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.ads_1}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's Admob Interstitials Impressions
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.ads_2}
                                    </span>
                                </CardBody>
                            </Card>
                            <Card className="card-stats mb-3">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                    >
                                        Today's FB Interstitials Impressions
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                                 {this.state.ads_3}
                                    </span>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <h5 className="h3 m-2">Monthly Total Ads Impressions</h5>
                            <div className="chart">
                                <Line
                                    data={this.state.adsDataSet}
                                    options={chartExample3.options}
                                    id="chart-sales"
                                    className="chart-canvas"
                                />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }



    getInstallsGrowth(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectinstalls/monthly/'+this.state.id)
            .then(res =>{
                if(res.status){
                    let labels = [];
                    let values = [];
                    res.data.entries.map(el =>{
                        labels.push(el.end)
                        values.push(el.count)
                    });
                    labels.reverse();
                    values.reverse();
                    let installsDataSet = {
                        labels:labels,
                        datasets: [{data: values}]
                    };
                    this.setState({installsDataSet})
                }
            }).catch(err =>{

        })
    }


    getInstallsData(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectinstalls/show/'+this.state.id)
            .then(res =>{
                    if(res.status){
                        if(res.data.installs){
                            this.setState({
                                installs_0: res.data.installs.play_store_installs +res.data.installs.other_installs,
                                installs_1: res.data.installs.play_store_installs,
                                installs_2:  res.data.installs.other_installs,
                            })
                        }
                        this.setState({
                            installs_3:  res.data.month,
                        })
                    }
                }
            )
    }

    getOpenGrowth(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectopen/monthly/'+this.state.id)
            .then(res =>{
                if(res.status){
                    let labels = [];
                    let values = [];
                    res.data.entries.map(el =>{
                        labels.push(el.end)
                        values.push(el.count)
                    });
                    labels.reverse();
                    values.reverse();
                    let openDataSet = {
                        labels:labels,
                        datasets: [{data: values}]
                    };
                    this.setState({openDataSet})
                }
            }).catch(err =>{

        })
    }

    getOpenData(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectopen/show/'+this.state.id)
            .then(res =>{
                    if(res.status){
                        if(res.data.open){
                            this.setState({
                                open_0: res.data.open.total,
                                open_1: res.data.open.unique,
                                open_2:  res.data.open.second,
                            })
                        }
                        this.setState({
                            open_3:  res.data.month,
                        })
                    }
                }
            )
    }


    getAdsGrowth(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectadstats/monthly/'+this.state.id)
            .then(res =>{
                if(res.status){
                    let labels = [];
                    let values = [];
                    res.data.entries.map(el =>{
                        labels.push(el.end)
                        values.push(el.count)
                    });
                    labels.reverse();
                    values.reverse();
                    let adsDataSet = {
                        labels:labels,
                        datasets: [{data: values}]
                    };
                    this.setState({adsDataSet})
                }
            }).catch(err =>{

        })
    }

    getAdsData(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('adsprojectadstats/show/'+this.state.id)
            .then(res =>{
                    if(res.status){
                        if(res.data.ads){
                            this.setState({
                                ads_0: res.data.ads.admob_banner,
                                ads_1: res.data.ads.facebook_banner,
                                ads_2:  res.data.ads.admob_interstitial,
                                ads_3:  res.data.ads.facebook_interstitial,
                                ads_4:  res.data.ads.total,
                            })
                        }
                        this.setState({
                            ads_5:  res.data.month,
                        })
                    }
                }
            )
    }


}
