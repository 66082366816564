/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge, Button, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form,
    Media, Modal, Progress, Row,
    Table, UncontrolledDropdown, UncontrolledTooltip,
} from "reactstrap";
import SmallStars from "./SmallStars";
import 'tui-image-editor/dist/tui-image-editor.css'
import {Link} from "react-router-dom";
import Utils from "../../Utils";
import {HttpConfig} from "../../tools/Http";
import ImageEditor from '@toast-ui/react-image-editor'
// import Loader from "react-loader-spinner";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Select2 from "react-select2-wrapper";


// import HorizontalGallery from "react-dynamic-carousel";
// import Pagination from "react-js-pagination";
var JSZip = require("jszip");
var FileSaver = require('file-saver');


const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');

const blackTheme = {
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconb,
};


export default class EpicTableIOS extends React.Component {
    constructor(props){
        super(props);
        this.state={
            showEditor:0,//0 show loader, 1 show screenshots, 2 show editor
            selectedApp:null,
            categories:this.props.userCats ? this.props.userCats : [],
            isAddingAppToCollection:false,
            URLS:[],
            screenshotToEdit:'',
            activePage:1,
            defaultModal:false,
            collectionModal:false,
            selectedCategory:null,
            rankDesc:[true, true, true ]

        }
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };


    componentWillReceiveProps(nextProps){
        if(nextProps.userCats && nextProps.userCats !== this.state.categories){
            //Perform some operation
            let arr = [];
            nextProps.userCats.map(el =>{
                arr.push({id:el.id, text:el.title+ " ("+el.total+" app)"})
            })
            this.setState({categories:arr});
        }
    }


    render() {
        return (
            <div className="table-responsive" ref="first-list">
                <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                        {!this.props.hideRank &&
                        <th className="sort" data-sort="name" scope="col"
                                 onClick={() => {this.props.setSortFilter("rank_num",false, this.state.rankDesc[0]);
                                     this.state.rankDesc[0] = !this.state.rankDesc[0];
                                     this.setState({})}}>
                        Rank
                        </th>}
                        <th >
                        Application
                        </th>
                        <th >
                            Content Rating
                        </th>
                        <th className="sort" data-sort="status" scope="col"
                            onClick={() => {this.props.setSortFilter("averageUserRatingForCurrentVersion", false, this.state.rankDesc[1]);
                                this.state.rankDesc[1] = !this.state.rankDesc[1];
                                this.setState({})}}>
                        Rating
                        </th>
                        <th className="sort" data-sort="status" scope="col"
                            onClick={() => {this.props.setSortFilter("userRatingCountForCurrentVersion", false,this.state.rankDesc[2]);
                                this.state.rankDesc[2] = !this.state.rankDesc[2];
                                this.setState({})}}>
                            Reviews</th>
                        <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.props.apps.map(el => {
                        return this.getRow(el);
                    })}
                    </tbody>
                </Table>
                {this.renderModal()}
                {/*{this.addToCollectionModal()}*/}
            </div>
        )
    }

    getRow(app){
        return(
            <tr style={{backgroundColor: app.market_status !== "PUBLISHED" ? "#ff000005 " : ""}}>
                {!this.props.hideRank &&
                <td >
                    {this.getAppRank(app)}
                </td>
                }
                <td scope="row">
                    {this.getAppIcon(app)}
                </td>
                <td >
                    <div style={{display:"flex", justifyContent:'flex-end', marginRight:30}}>
                        <h5>{app.contentAdvisoryRating}&nbsp;&nbsp;</h5>

                    </div>
                </td>
                <td>
                    <Row >
                        {/*<h5 className={"mb-0"}>{app.averageUserRatingForCurrentVersion ? app.averageUserRatingForCurrentVersion.toString().match(/\d+\.\d{1}/) : ''}</h5> &nbsp;&nbsp;*/}
                        <h5>{app.averageUserRatingForCurrentVersion ? app.averageUserRatingForCurrentVersion.toString().match(/\d+\.\d{1}/) : ''}</h5>&nbsp;&nbsp;
                        <SmallStars score={app.averageUserRatingForCurrentVersion}/>
                    </Row>
                </td>
                <td>
                    <div style={{display:"flex", justifyContent:'flex-end', paddingRight:40}}>
                        <h5>{Utils.formatReviews(app.userRatingCountForCurrentVersion)}&nbsp;&nbsp;<i className={"fa fa-user"} style={{fontSize:10}}/></h5>
                    </div>
                </td>
                <td className={"pl-0"}>
                    {this.renderLongRow(app)}
                </td>
            </tr>
        );
    }

    renderLongRow(row){
        return (
            <div className={"ml-0 mr-4"} style={{display:'flex', flex:1, flexDirection:'row', justifyContent:'space-between'}}>
                <img src={require("assets/img/icons/app-store-link.png")} className="cursor-pointer" onClick={() => this.playStoreClicked(row)}/>
                {/*<img src={require("assets/img/icons/appbrain.png")} className="cursor-pointer" onClick={() => this.appBrainClicked(row)}/>*/}
                {/*<img src={require("assets/img/icons/app-sdk.png")} className="cursor-pointer" onClick={() => this.appSDKClicked(row)}/>*/}
                <img src={require("assets/img/icons/setting.png")} className="cursor-pointer" onClick={() => this.appEditorClicked(row)}/>
                <img src={require("assets/img/icons/download.png")} className="cursor-pointer" onClick={() => this.download(row)}/>
                {/*{!this.props.hideAdd && <img src={require("assets/img/icons/add.png")} className="cursor-pointer" onClick={() => this.addToCollectionClicked(row)}/>}*/}
                {/*{!this.props.hideDelete && <img src={require("assets/img/icons/delete.png")} className="cursor-pointer" onClick={() => this.removeFromCollection(row)}/>}*/}
            </div>
        );
    }

    getAppIcon(app){
        return(
            <Row className="align-items-center" style={{minWidth:400}}>
                <Col className="col-auto">
                    <a
                        className="avatar avatar-lg rounded"
                        href={"details-ios?id="+app.bundleId}
                        target={"_blank"}
                    >
                        <img
                            alt="..."
                            src={app.artworkUrl512}
                        />
                    </a>
                </Col>
                <div className="col ml--2">
                    <h4 className="mb-0 mt-2" style={{lineHeight:"1.5em", maxHeight:"1.5em",display: 'block',
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                        overflow: 'hidden',}}>
                        <a  href={"details-ios?id="+app.bundleId}
                            target={"_blank"} >
                            {app.trackCensoredName.slice(0,26)}&nbsp;&nbsp;{app.market_status === "PUBLISHED" ? " " : <span className={"bold text-danger h5"}>[APP DELETED]</span>}
                        </a>
                    </h4>
                    <p className="text-sm text-muted">
                        By &nbsp;<b>{app.artistName? app.artistName.slice(0,26) : ""}</b>
                    </p>
                </div>
            </Row>
        )
    }

    renderModal(){
        return (
            <Modal
                size={"lg"}
                className="modal-lg modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
            >
                {this.state.showEditor === 2 && this.getEditorInstance('/img/screenshots-p.jpg')}
                {this.state.showEditor === 1 && this.getScreenshots()}
                {this.state.showEditor === 0 && <div className="modal-content" style={{ height:600}}>
                </div>}
            </Modal>
        )
    }

    // addToCollectionModal(){
    //     return (
    //         <Modal
    //             size={"md"}
    //             className="modal-md modal-dialog-centered"
    //             isOpen={this.state.collectionModal}
    //             toggle={() => this.toggleModal("collectionModal")}
    //         >
    //             <>
    //                 <div className="modal-header">
    //                     <h6 className="modal-title" id="modal-title-default">
    //                         Selecting Collection
    //                     </h6>
    //                     <button
    //                         aria-label="Close"
    //                         className="close"
    //                         data-dismiss="modal"
    //                         type="button"
    //                         onClick={() => this.toggleModal("collectionModal")}
    //                     >
    //                         <span aria-hidden={true}>×</span>
    //                     </button>
    //                 </div>
    //                 <div className="modal-body" >
    //                     <Form>
    //                         <Select2
    //                             className="form-control"
    //                             options={{
    //                                 placeholder: "Select"
    //                             }}
    //                             value={this.state.selectedCategory}
    //                             onSelect={(e) => {
    //                                 this.setState({selectedCategory: e.target.value});
    //                             }}
    //                             data={this.state.categories}
    //                         />
    //                     </Form>
    //                 </div>
    //                 <div className="modal-footer">
    //                     <Button color="primary" type="button">
    //                         + New Collection
    //                     </Button>
    //                     <Button
    //                         className="ml-auto"
    //                         color="success"
    //                         data-dismiss="modal"
    //                         type="button"
    //                         disabled={!this.state.selectedCategory}
    //                         onClick={() => {this.toggleModal("collectionModal"); this.addToCollectionPost(); }}
    //                     >
    //                         Add
    //                     </Button>
    //                 </div>
    //             </>
    //         </Modal>
    //     )
    // }

    getScreenshots(){
        return(
            <>
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Select the asset you want to edit
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" >
                    <div style={{ display:'flex', flexDirection:'row', minHeight:600, maxHeight:600, height:600,overflow:"auto"}}>
                        <div style={{display:'flex', height:550,flexDirection:'row', padding:20}}>
                            {this.state.URLS.map((el,i) => {
                                return (
                                    <div style={{width:350, height:550, display:'flex', alignItems:'center', justifyContent:'center',marginRight:25, position: "relative"}}>
                                        <img src={el} alt="" className={'img-fluid rounded'} style={{width:i === 0 ? 150: 300,boxShadow: '0px 0px 7px -1px rgba(0,0,0,0.28)',cursor:'pointer' }}
                                             onClick={() => {
                                                 this.setState({screenshotToEdit:el,showEditor:2})
                                             }}/>
                                        {/*<div style={{width:'100%', height:'100%' , position:'absolute', top:0, left:0, backgroundColor:'#ffffff99',  display:'flex',alignItems:'center', justifyContent:'center'}}*/}
                                        {/*     onClick={() => {*/}
                                        {/*         this.setState({screenshotToEdit:el,showEditor:2})*/}
                                        {/*     }} className={'pointer-cursor hidden-hover'}>*/}
                                        {/*    <span style={{}} className={'btn btn-outline-primary btn-sm  pointer-cursor'} >Edit</span>*/}
                                        {/*</div>*/}
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </>
        );
    }

    getEditorInstance(path){
        return (
            <div className="modal-content">
                <ImageEditor
                    // ref={this.editorRef}
                    includeUI={{
                        loadImage: {
                            path: this.state.screenshotToEdit,
                            name: 'SampleImage'
                        },
                        theme: blackTheme,
                        initMenu: 'filter',
                        uiSize: {
                            width: '1000px',
                            height: '700px'
                        },
                        menuBarPosition: 'bottom'
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={1000}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70
                    }}
                    usageStatistics={true}
                />
            </div>
        );
    }
    closeEditor(){
        this.setState({showEditor:false})
    }

    lampClicked(row){
        window.location.href = '/detail?id='+row.app_id
    }


    playStoreClicked(row){
        window.open(row.trackViewUrl, "_blank")
    }

    appBrainClicked(row){
        // window.open(row.appbrain, "_blank")
        this.props.setLoading(true)
        this.getAppbrain(row);
    }

    appSDKClicked(row){
        this.props.setLoading(true)
        HttpConfig().get('app/ios/download/'+row.bundleId+'/'+row.title)
            .then(res => {
                if(res.data.status){
                    // window.open(decodeURI(res.data.link), )
                    navigator.clipboard.writeText(decodeURI(res.data.link)).then(() => {
                        this.props.notify("success", "Copied","Download Link copied to clipboard");
                    }, function(err) {
                        console.log(err);
                    }).catch(err =>{console.log(err);
                    })}
                else{
                    //error
                }
                this.props.setLoading(false)

            }).catch(err => {
            this.props.setLoading(false)

        })
    }

    appEditorClicked(row){
        // this.setState({showEditor:true});
        this.props.setLoading(true)
        this.screenshotsAndIcon(row);
    }

    downloadClicked(row){

    }

    addToCollectionClicked(row){
        this.toggleModal("collectionModal")
        this.setState({selectedApp:row})
    }
    removeFromCollection(row){
        this.props.deleteApp(row)
    }

    getCategories(){
        HttpConfig().get('categories')
            .then(res => {
                if(res.data.status){
                    let arr = [];
                    res.data.categories.map(el =>{
                        arr.push({id:el.id, text:el.title+ "("+el.total+" apps)"})
                    })
                    this.setState({categories:arr});
                }
                else{
                    //error
                }
            }).catch(err => {

        })

    }

    goToDetails(row){
        window.open('/details-ios')
    }

    addToCollectionPost(){
        if(!this.state.selectedCategory){
            this.props.notify("danger", "Error","Please select a collection first")
            return;
        }

        this.props.setLoading(true)
        HttpConfig().post('favourite',{
            bundleId:this.state.selectedApp.bundleId,
            category_id:this.state.selectedCategory
        })
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Saved","App successfully added to your collection");
                    this.getCategories();
                }
                else{
                    //error
                    this.props.setLoading(false)
                    this.props.notify("danger", "Error","there was an error while adding your app")

                }
            }).catch(err => {
            this.props.setLoading(false)
            this.props.notify("danger", "Error","there was an error while adding your app")
        })
    }


    getAppbrain(row){
        this.props.setLoading(true)
        HttpConfig().get('appbrain/'+row.bundleId+'/'+row.title+'/true')
            .then(res => {
                if(res.data.status){
                    let externalWindow = window.open("about:blank", '', 'width=600,height=400,left=200,top=200');
                    let doc = externalWindow.document;
                    doc.open("text/html");
                    doc.write(res.data.page);

                }
                else{
                    //error
                    window.open(row.appbrain, "_blank")

                }
                this.props.setLoading(false)
            }).catch(err => {
            window.open(row.appbrain, "_blank")
            this.props.setLoading(false)
        })
    }

    download(row){
        this.props.setLoading(true)
        let URLS = [row.artworkUrl512];
        let arr = JSON.parse(row.screenshotUrls);
        arr.map(el => {
            URLS.push(el);
        })
        this.saveToZip(row.trackCensoredName.replace(/ /g,'_')+'.zip',URLS)
    }

    screenshotsAndIcon(row){
        this.setState({
            URLS:[],
            screenshotToEdit:'',
            showEditor:0
        })
        let URLS = []
        URLS = [row.artworkUrl512];
        let arr = JSON.parse(row.screenshotUrls);
        arr.map(el => {
            URLS.push(el);
        })
        // URLS = res.data.app.screenshotUrls;
        this.setState({URLS,showEditor:1, defaultModal:true})

    }

    saveToZip (filename, urls) {
        const zip = new JSZip()
        const folder = zip.folder('assets')
        urls.forEach((url,i)=> {
            const blobPromise = fetch(url)
                .then(r => {
                    if (r.status === 200)
                        return r.blob()
                    return Promise.reject(new Error(r.statusText))
                });
            const name = i === urls.length - 1 ? 'icon.png' : 'screen_'+i+'.png';
            folder.file(name, blobPromise)
        })

        zip.generateAsync({type:"blob"})
            .then(blob => FileSaver.saveAs(blob, filename))
            .catch(e => console.log(e));
    }

    getAppRank(app){
        return(
            <Col className={"mr-0"}>
                <h3>{app.rank.rank}
                    <span className={"mr-2 ml-2 text-"+this.getRankUpdate(app.rank.change)} style={{fontSize:12}}>
                  <i className={"fa "+this.getRankArrow(app.rank.change)} />
                        &nbsp;&nbsp;{app.rank.change === 0 ? '' : app.rank.change}
                </span>
                </h3>
            </Col>
        )
    }


    getRankArrow(rank){
        if(rank === 'new')
            return ("fa-angle-double-up");
        if(rank > 0)
            return ("fa-arrow-up");
        if(rank < 0)
            return ("fa-arrow-down");
        return ("fa-circle");
    }

    getRankUpdate(rank){
        if(!rank)
            return "default";
        if(rank >= 0 || rank === "new")
            return ("success");
        if(rank < 0)
            return ("danger");
        return null;
    }

}
