/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
  Badge, Button, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form,
  Media, Modal, Progress, Row,
  Table, UncontrolledDropdown, UncontrolledTooltip,
} from "reactstrap";
import SmallStars from "./SmallStars";
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import {Link} from "react-router-dom";
import Utils from "../../Utils";
import {HttpConfig} from "../../tools/Http";

// import Loader from "react-loader-spinner";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import Select2 from "react-select2-wrapper";
import {Line} from "react-chartjs-2";
import {
  chartExample4,
} from "variables/charts.js";
import moment from "moment";

// import Pagination from "react-js-pagination";
var JSZip = require("jszip");
var FileSaver = require('file-saver');


const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');

const blackTheme = {
  'menu.normalIcon.path': icond,
  'menu.activeIcon.path': iconb,
  'menu.disabledIcon.path': icona,
  'menu.hoverIcon.path': iconc,
  'submenu.normalIcon.path': icond,
  'submenu.activeIcon.path': iconb,
};


export default class EpicTable extends React.Component {
  constructor(props){
    super(props);
    this.state={
      showEditor:0,//0 show loader, 1 show screenshots, 2 show editor
      selectedApp:null,
      categories:this.props.userCats ? this.props.userCats : [],
      isAddingAppToCollection:false,
      URLS:[],
      screenshotToEdit:'',
      activePage:1,
      defaultModal:false,
      rankingModal:false,
      collectionModal:false,
      today:moment(),
      selectedCategory:null,
      rankDesc:[true, true, true ,true, true],
      fastRankingClicked:null,
    }
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };


  componentWillReceiveProps(nextProps){
    if(nextProps.userCats && nextProps.userCats !== this.state.categories){
      //Perform some operation
      let arr = [];
      nextProps.userCats.map(el =>{
        arr.push({id:el.id, text:el.title+ " ("+el.total+" app)"})
      })
      this.setState({categories:arr});
    }
  }


  render() {
    return (
      <div className="table-responsive" ref="first-list" style={{maxHeight: this.props.maxHeight ?? this.props.maxHeight}}>
        <Table className="align-items-center table-flush">
          <thead className="thead-light">
          <tr>
            {!this.props.hideRank &&
            <th className="sort" data-sort="rank" scope="col"
                onClick={() => {this.props.setSortFilter("rank", false, this.state.rankDesc[0]);
                  this.state.rankDesc[0] = !this.state.rankDesc[0];
                  this.setState({})}}>
              RANK
            </th>}
            <th >
              APP
            </th>
            <th 
                className="sort" data-sort="installs" scope="col"
                onClick={() => {this.props.setSortFilter("installs", false, this.state.rankDesc[1]);
                  this.state.rankDesc[1] = !this.state.rankDesc[1];
                  this.setState({})}}
                >
              INSTALLS
            </th>
            <th className="sort" data-sort="recent" scope="col"
                onClick={() => {this.props.setSortFilter("recent", false, this.state.rankDesc[2]);
                  this.state.rankDesc[2] = !this.state.rankDesc[2];
                  this.setState({})}}
                >
              RECENT
            </th>
            <th className="sort" data-sort="release_age" scope="col"
                onClick={() => {this.props.setSortFilter("relase_date", true,this.state.rankDesc[3]);
                  this.state.rankDesc[3] = !this.state.rankDesc[3];
                  this.setState({})}}>
              RELEASE AGE
            </th>
            <th >
              STATUS
            </th>
            <th className="sort" scope="col" data-sort="rating"
                onClick={() => {this.props.setSortFilter("rating", false, this.state.rankDesc[4]);
                  this.state.rankDesc[4] = !this.state.rankDesc[4];
                  this.setState({})}}>
              RATING
            </th>
            { this.props.favorate &&
              <th >
                ACTIONS
              </th>
            }
            <th scope="col"/>
          </tr>
          </thead>
          <tbody className="list">
          {this.props.apps.map((el, index) => {
            return this.getRow(el, index);
          })}
          </tbody>
        </Table>
        {this.renderModal()}
        {this.addToCollectionModal()}
        {this.props.showFastGrowing && this.renderRankingModal()}
      </div>
    )
  }

  getRow(app, index){
    return(
      <tr key={index}>
        {!this.props.hideRank &&
        <td>
          {this.getAppRank(app)}
        </td>
        }
        <td scope="row" >
          {this.getAppIcon(app)}
        </td>
        <td >
          <div style={{display:"flex", justifyContent:'flex-end', marginRight:30}}>
            <h5>{Utils.formatInstalls(app.installs).d}</h5>
          </div>
        </td>
        <td >
          <div style={{display:"flex", justifyContent:'flex-end', marginRight:30}}>
            <h5>{Utils.formatRecents(app.recent).d}</h5>
          </div>
        </td>
        <td>
          <div style={{display:"flex", justifyContent:'flex-end', marginRight:30}}>
            <h5>{app.relase_date && moment(app.relase_date).from(this.state.today)}&nbsp;&nbsp;</h5>
          </div>
        </td>
        <td>
          { app.activity_status &&
            <div style={{display:"flex", justifyContent:'flex-end', marginRight:30}}>
              { app.activity_status.app_is_ads == 1 &&
                <Badge color="success">ADS</Badge>
              }
              { app.activity_status.app_is_pub == 1 &&
                <Badge color="info">PUB</Badge>
              }
              { app.activity_status.app_is_deleted == 1 &&
                <Badge color="danger">DELETED</Badge>
              }
            </div>
          } 
        </td>
        <td>
          <Row >
            <h5 className={"mb-0"}>{app.rating ? app.rating.toString().match(/\d+\.\d{1}/) : ''}</h5> &nbsp;&nbsp;
            <SmallStars score={app.rating}/>
          </Row>
        </td>
        { this.props.favorate &&
          <td>
            <Button title="Delete" className={"btn-danger"} size={"sm"}
              onClick={() => this.props.removeApp(app.app_id)}
            ><i className="fas fa-trash-alt fa-lg"></i></Button>
          </td>
        }
      </tr>
    );
  }


  getTooltips(){
    return(
        <>
          <UncontrolledTooltip delay={0} target="tooltip_000">
            Open app in playstore
          </UncontrolledTooltip>
          <UncontrolledTooltip delay={0} target="tooltip_001">
            Get APK Link
          </UncontrolledTooltip>
          <UncontrolledTooltip delay={0} target="tooltip_002">
            Edit screenshots and icon
          </UncontrolledTooltip>
          <UncontrolledTooltip delay={0} target="tooltip_003">
            Download screenshots and icon
          </UncontrolledTooltip>
          {!this.props.hideAdd && <UncontrolledTooltip delay={0} target="tooltip_004">
            Add this app to your collection
          </UncontrolledTooltip>}
          {!this.props.hideDelete && <UncontrolledTooltip delay={0} target="tooltip_005">
            remove this app from your collection
          </UncontrolledTooltip>}
        </>
    )
  }

  getAppIcon(app){
    return(
      <div className="" style={{minWidth:400,maxWidth:500, display:'flex', flexDirection:'row', alignItems:'center', overflow:'hidden'}}>
        {this.props.showFastGrowing &&
        <div className="col-auto">
          <img
            alt="..."
            className={"cursor-pointer"}
            width={25}
            height={25}
            loading='lazy'
            src={require("assets/img/icons/rocket.png")}
            onClick={()=>{this.toggleModal("rankingModal"); this.setState({fastRankingClicked:app.app_id})}}
          />
        </div>
        }
        <div className="col-auto">
          <a
            className="avatar avatar-lg rounded"
            href={"details?id=" + app.app_id}
            target={"_blank"}
          >
            <img
              alt="..."
              src={app.icon}
            />
          </a>
        </div>
        <div className="ml-2">
          <h4 className="mb-0 mt-2" style={{
            lineHeight:"1.5em",
            maxHeight:"1.5em",
            display: 'block',
            overflow: 'hidden',}}>
            <a
              href={"details?id=" + app.app_id}
              target={"_blank"}
            >
              {app.name}
            </a>
          </h4>
          {app.developer &&
          <p className="text-sm text-muted">
            By &nbsp;
            <a
              href={"android-dev?id=" + app.developer}
              target={"_blank"}
            >
            <b>{app.developer.slice(0,26)}</b>
            </a>
          </p>
          }
        </div>
      </div>
    )
  }

  renderModal(){
    return (
      <Modal
        size={"lg"}
        className="modal-lg modal-dialog-centered"
        isOpen={this.state.defaultModal}
        toggle={() => this.toggleModal("defaultModal")}
      >
        {this.state.showEditor === 2 && this.getEditorInstance('/img/screenshots-p.jpg')}
        {this.state.showEditor === 1 && this.getScreenshots()}
        {this.state.showEditor === 0 && <div className="modal-content" style={{ height:600}}>
        </div>}
      </Modal>
    )
  }

  renderRankingModal(){
    let data = [];
    let labels = [];
    if(this.props.factGrowingApps[this.state.fastRankingClicked]){
      this.props.factGrowingApps[this.state.fastRankingClicked].map(el => {
        data.push(el)
        labels.push("")
      })
    }
    let dataObject = {
      labels,
      datasets: [
        {
          label: "Ranking History",
          data: data,
          borderColor:"#ffffffbb",
          pointBorderColor:"#ffffffbb",
          pointBackgroundColor:"#ffffff99",
          BackgroundColor:"#ffffff44",

        }
      ]
    }
    let options= {
      legend:{
        labels:{
          fontColor:"#ffffff"
        }
      },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "#ffffff44",
                zeroLineColor: "#ffffff44"
              },
              ticks: {
                reverse: true,
                fontColor: "#ffffff",
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: "#ffffff44",
                zeroLineColor: "#ffffff44"
              },
              ticks: {
                reverse: true,
              }
            }
          ]
        }
      };
    return (
      <Modal
        size={"lg"}
        className="modal-lg modal-dialog-centered bg-transparent"
        isOpen={this.state.rankingModal}
        toggle={() => this.toggleModal("rankingModal")}>
        <Card className="bg-gradient-orange mb-0">
          <CardHeader className="bg-transparent">
            <h6 className="surtitle text-white">Growth</h6>
            <h5 className="h3 mb-0 text-white">Ranking History Growth</h5>
          </CardHeader>
          <CardBody className={"bg-transparent"}>
            <div className="chart">
              <Line
                data={dataObject}
                options={options}
                id="chart-points"
                className="chart-canvas"
              />
            </div>
          </CardBody>
          <CardFooter className={"bg-transparent"}/>
        </Card>
      </Modal>
    )
  }

  addToCollectionModal(){
    return (
      <Modal
        size={"md"}
        className="modal-md modal-dialog-centered"
        isOpen={this.state.collectionModal}
        toggle={() => this.toggleModal("collectionModal")}
      >
        <>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Selecting Collection
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("collectionModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" >
            <Form>
              <Select2
                className="form-control"
                options={{
                  placeholder: "Select"
                }}
                value={this.state.selectedCategory}
                onSelect={(e) => {
                  this.setState({selectedCategory: e.target.value});
                }}
                data={this.state.categories}
              />
            </Form>
          </div>
          <div className="modal-footer">
            <Button color="primary" type="button"
                    onClick={() => {
                      window.open('/favourite', "_blank")
                    }}>
              + New Collection
            </Button>
            <Button
              className="ml-auto"
              color="success"
              data-dismiss="modal"
              type="button"
              disabled={!this.state.selectedCategory}
              onClick={() => {this.toggleModal("collectionModal"); this.addToCollectionPost(); }}
            >
              Add
            </Button>
          </div>
        </>
      </Modal>
    )
  }

  getScreenshots(){
    return(
      <>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Select the asset you want to edit
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("defaultModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" >
          <div style={{ display:'flex', flexDirection:'row', minHeight:600, maxHeight:600, height:600,overflow:"auto"}}>
            <div style={{display:'flex', height:550,flexDirection:'row', padding:20}}>
              {this.state.URLS.map((el,i) => {
                return (
                  <div style={{width:350, height:550, display:'flex', alignItems:'center', justifyContent:'center',marginRight:25, position: "relative"}}>
                    <img src={el} alt="" className={'img-fluid rounded'} style={{width:i === 0 ? 150: 300,boxShadow: '0px 0px 7px -1px rgba(0,0,0,0.28)',cursor:'pointer' }}
                         onClick={() => {
                           this.setState({screenshotToEdit:el,showEditor:2})
                         }}/>
                    {/*<div style={{width:'100%', height:'100%' , position:'absolute', top:0, left:0, backgroundColor:'#ffffff99',  display:'flex',alignItems:'center', justifyContent:'center'}}*/}
                    {/*     onClick={() => {*/}
                    {/*         this.setState({screenshotToEdit:el,showEditor:2})*/}
                    {/*     }} className={'pointer-cursor hidden-hover'}>*/}
                    {/*    <span style={{}} className={'btn btn-outline-primary btn-sm  pointer-cursor'} >Edit</span>*/}
                    {/*</div>*/}
                  </div>
                )
              })}
            </div>

          </div>
        </div>
      </>
    );
  }

  getEditorInstance(path){
    return (
      <div className="modal-content">
        <ImageEditor
          // ref={this.editorRef}
          includeUI={{
            loadImage: {
              path: this.state.screenshotToEdit,
              name: 'SampleImage'
            },
            theme: blackTheme,
            initMenu: 'filter',
            uiSize: {
              width: '1000px',
              height: '700px'
            },
            menuBarPosition: 'bottom'
          }}
          cssMaxHeight={500}
          cssMaxWidth={1000}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70
          }}
          usageStatistics={true}
        />
      </div>
    );
  }
  closeEditor(){
    this.setState({showEditor:false})
  }

  lampClicked(row){
    window.location.href = '/detail?id='+row.app_id
  }


  playStoreClicked(row){
    window.open(row.url.replace("42matters.com", "mobioptions.com"), "_blank")
  }

  appBrainClicked(row){
    // window.open(row.appbrain, "_blank")
    this.props.setLoading(true)
    this.getAppbrain(row);
  }

  appSDKClicked(row){
    this.props.setLoading(true)
    HttpConfig().get('app/download/'+row.app_id+'/'+row.title)
      .then(res => {
        if(res.data.status){
          navigator.clipboard.writeText(decodeURI(res.data.link)).then(() => {
            this.props.notify("success", "Copied","Download Link copied to clipboard");
          }, function(err) {
            console.log(err);
          }).catch(err =>{console.log(err);
          })
        }
        else{
          //error
          this.props.notify("error", "Error","This APK file isn't yet uploaded to apkpure");

        }
        this.props.setLoading(false)

      }).catch(err => {
      this.props.setLoading(false)
      this.props.notify("error", "error","This APK file isn't yet uploaded to apkpure");

    })
  }

  appEditorClicked(row){
    // this.setState({showEditor:true});
    this.props.setLoading(true)
    this.screenshotsAndIcon(row);
  }

  downloadClicked(row){

  }

  addToCollectionClicked(row){
    this.toggleModal("collectionModal")
    this.setState({selectedApp:row})
  }
  removeFromCollection(row){
    this.props.deleteApp(row)
  }

  getCategories(){
    HttpConfig().get('categories')
      .then(res => {
        if(res.data.status){
          let arr = [];
          res.data.categories.map(el =>{
            arr.push({id:el.id, text:el.title+ "("+el.total+" apps)"})
          })
          this.setState({categories:arr});
        }
        else{
          //error
        }
      }).catch(err => {

    })

  }

  goToDetails(row){
    window.open('/details')
  }

  addToCollectionPost(){
    if(!this.state.selectedCategory){
      this.props.notify("danger", "Error","Please select a collection first")
      return;
    }

    this.props.setLoading(true)
    HttpConfig().post('favourite',{
      app_id:this.state.selectedApp.app_id,
      category_id:this.state.selectedCategory
    })
      .then(res => {
        if(res.data.status){
          this.props.notify("success", "Saved","App successfully added to your collection");
          this.getCategories();
        }
        else{
          //error
          this.props.setLoading(false)
          this.props.notify("danger", "Error","there was an error while adding your app")

        }
      }).catch(err => {
      this.props.setLoading(false)
      this.props.notify("danger", "Error","there was an error while adding your app")
    })
  }


  getAppbrain(row){
    this.props.setLoading(true)
    HttpConfig().get('appbrain/'+row.app_id+'/'+row.title+'/true')
      .then(res => {
        if(res.data.status){
          let externalWindow = window.open("about:blank", '', 'width=600,height=400,left=200,top=200');
          let doc = externalWindow.document;
          doc.open("text/html");
          doc.write(res.data.page);

        }
        else{
          //error
          window.open(row.appbrain, "_blank")

        }
        this.props.setLoading(false)
      }).catch(err => {
      window.open(row.appbrain, "_blank")
      this.props.setLoading(false)
    })
  }

  download(row){
    this.props.setLoading(true)
    let URLS = [row.icon];
    let arr = JSON.parse(row.screenshots);
    arr.map(el => {
      URLS.push(el.replace("w720-h310","w1440-h620"));
    })
    this.saveToZip(row.title.replace(/ /g,'_')+'.zip',URLS)
  }

  screenshotsAndIcon(row){
    this.setState({
      URLS:[],
      screenshotToEdit:'',
      showEditor:0
    })
    let URLS = []
    URLS = [row.icon];
    let arr = JSON.parse(row.screenshots);
    arr.map(el => {
      URLS.push(el.replace("w720-h310","w1440-h620"));
    })
    // URLS = res.data.app.screenshots;
    this.setState({URLS,showEditor:1, defaultModal:true})

  }

  saveToZip (filename, urls) {
    const zip = new JSZip()
    const folder = zip.folder('assets')
    urls.forEach((url,i)=> {
      const blobPromise = fetch(url)
        .then(r => {
          if (r.status === 200)
            return r.blob()
          return Promise.reject(new Error(r.statusText))
        });
      const name = i === urls.length - 1 ? 'icon.png' : 'screen_'+i+'.png';
      folder.file(name, blobPromise)
    })

    zip.generateAsync({type:"blob"})
      .then(blob => FileSaver.saveAs(blob, filename))
      .catch(e => console.log(e));
  }

  getAppRank(app){
    return(
      <Col className={"mr-0"}>
        <h3>{app.rank}
          <span className={"mr-2 ml-2 text-"+this.getRankUpdate(app.rank_yesterday - app.rank)} style={{fontSize:12}}>
                  <i className={"fa "+this.getRankArrow(app.rank_yesterday - app.rank)} />
            &nbsp;&nbsp;{app.rank_yesterday - app.rank === 0 ? '' : app.rank_yesterday - app.rank}
                </span>
        </h3>
      </Col>
    )
  }


  getRankArrow(rank){
    if(rank === 'new')
      return ("fa-angle-double-up");
    if(rank > 0)
      return ("fa-arrow-up");
    if(rank < 0)
      return ("fa-arrow-down");
    return ("fa-circle");
  }

  getRankUpdate(rank){
    if(!rank)
      return "default";
    if(rank >= 0 || rank === "new")
      return ("success");
    if(rank < 0)
      return ("danger");
    return null;
  }

}
