/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Modal, Row} from "reactstrap";
import Select2 from "react-select2-wrapper";
import {HttpConfig} from "../../tools/Http";
import TagsInput from "react-tagsinput";
import ReactDatetime from "react-datetime";
import moment from "moment";

export default class SelectRowIOS extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            collections:[],
            countries:[],
            categories:[],
            tags:[],
            showDateRange:false,
            //selected stuff
            collection:null,
            country:null,
            category:null,
            categoryObject:null,
            subCategory:null,
            publishingDate:null,
            downloads:0,
            arabicApps:false,
            removedApps:false,
            allowedSurfing: true,
            loading: true,
            tagsModal: false,
            url : this.props.url,
            pubday: 'Publishing Day',

        };
    }

    componentWillUpdate(nextProps, nextState){
        this.formatUrl(nextState);
    }

    minMaxDate( currentDate ){
        return currentDate <= moment();
    }

    render() {
        let subCategories = [];
        if (this.state.categoryObject != null && this.state.categoryObject.content != null ){
            Object.keys(this.state.categoryObject.content).map((el,i) => {
                // if(i===0)
                //     subCategories.push({id:"-1", text:"OverAll"})
                subCategories.push({id:el, text:this.state.categoryObject.content[el]})
            });
        }

        let countries = [];
        Object.keys(this.state.countries).map((el,i) => {
            countries.push({id:el, text:this.state.countries[el]})
        });

        let collections = [];
        Object.keys(this.state.collections).map((el,i) => {
            collections.push({id:el, text:this.state.collections[el]})
        });

        let categories = [];
        Object.keys(this.state.categories).map((el,i) => {
            categories.push({id:el, text:this.state.categories[el].name})
        });

        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h3 className="mb-0">Filter Results</h3>
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" xl="2" lg="6">
                            <div style={{height:'100%', display:'flex'}}>
                                <label
                                    id="input-username"
                                    className="form-control-label text-muted mb-0">
                                    Deleted Apps&nbsp;&nbsp;</label>
                                <div>
                                    <label className="custom-toggle custom-toggle-warning mr-1">
                                        <input checked={this.state.removedApps}
                                               type="checkbox" onChange={(e) => this.setState({removedApps:e.target.checked})}/>
                                        <span
                                            className="custom-toggle-slider rounded-circle"
                                        />
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col className="mt-3 mt-md-0 text-md-right" lg="4" xs="12">
                            {/*<Button className={"btn-info"} size={"sm"}>Change Date</Button>*/}
                            <ReactDatetime
                                inputProps={{
                                    placeholder: "Today"
                                }}
                                closeOnSelect={true}
                                onChange={(v) => this.setState({publishingDate:v.format('YYYY-MM-DD')})}
                                timeFormat={false}
                                validDate={ this.minMaxDate }
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                <Select2
                                    className="form-control"
                                    value={this.state.collection}
                                    onSelect={e => this.setState({collection:e.target.value})}
                                    options={{
                                        placeholder: "Collection"
                                    }}
                                    data={collections}
                                />
                            </Col>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                <Select2
                                    className="form-control"
                                    value={this.state.country}
                                    onSelect={e => this.setState({country:e.target.value})}
                                    options={{
                                        placeholder: "Country"
                                    }}
                                    data={countries}
                                />
                            </Col>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                <Select2
                                    className="form-control"
                                    value={this.state.category}
                                    onSelect={e => this.setState({category:e.target.value,categoryObject:this.state.categories[e.target.value],
                                        subCategory:Object.keys(this.state.categories[e.target.value].content).length > 0 ?
                                            Object.keys(this.state.categories[e.target.value].content)[0] : null})}
                                    options={{
                                        placeholder: "Category"
                                    }}
                                    data={categories}
                                />
                            </Col>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                {this.state.categoryObject &&
                                    // this.state.category !== "all" &&
                                    <Select2
                                        className="form-control"
                                        value={this.state.subCategory}
                                        defaultValue={"-1"}
                                        onSelect={e => {
                                            this.setState({subCategory:e.target.value})
                                        }}
                                        options={{
                                            placeholder: "Sub Category"
                                        }}
                                        data={subCategories}
                                    />
                                    // :
                                    // <Select2
                                    //     className="form-control"
                                    //     options={{
                                    //         placeholder: "Sub Category"
                                    //     }}
                                    //     data={[]}
                                    // />
                                }
                            </Col>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                {/*    <Select2*/}
                                {/*        className="form-control"*/}
                                {/*        value={this.state.downloads}*/}
                                {/*        onSelect={e => this.setState({downloads:e.target.value})}*/}
                                {/*        options={{*/}
                                {/*            placeholder: "Downloads"*/}
                                {/*        }}*/}
                                {/*        data={[*/}
                                {/*            {id:"100" ,text:"+100"},*/}
                                {/*            {id:"1000" ,text:"+1K"},*/}
                                {/*            {id:"10000" ,text:"+10K"},*/}
                                {/*            {id:"100000" ,text:"+100K"},*/}
                                {/*            {id:"500000" ,text:"+500K"},*/}
                                {/*            {id:"1000000" ,text:"+1M"},*/}
                                {/*            {id:"500000000" ,text:"+500M"},*/}
                                {/*            {id:"1000000000" ,text:"+1B"},*/}
                                {/*        ]}*/}
                                {/*    />*/}
                            </Col>
                            <Col lg={2} md={6} sm={12} className={"mb-2"}>
                                <Input
                                    className="form-control"
                                    onClick={() => {
                                        this.setState({tagsModal:true});
                                    }}
                                    readonly={true}
                                    value={this.state.tags.length === 0 ? "No Keywords" : this.state.tags.length+" Keywords"}
                                />
                            </Col>
                            {this.renderTagsModal()}
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        )
    }



    componentDidMount(){
        this.getSelects();
        this.setState({allowedSurfing:true})

    }

    getSelects(){
        HttpConfig().get('selects/ios')
            .then(res => {
                if(res.data.status){
                    res.data = res.data.data;
                    let categoryObject = res.data.categories[Object.keys(res.data.categories)[0]];
                    let subCategory= Object.keys(categoryObject.content).length > 0 ? Object.keys(categoryObject.content)[0] : null;
                    this.setState({
                        collections:res.data.collections,
                        collection:Object.keys(res.data.collections)[0],

                        countries:res.data.countries,
                        country:Object.keys(res.data.countries)[0],

                        categories:res.data.categories,
                        category:Object.keys(res.data.categories)[0],
                        categoryObject,
                        subCategory,

                        loading:false,
                    },() => this.formatUrl(this.state));
                }
                else{
                    //error
                }
            }).catch(err => {
            //err
        })
    }

    handleTagsinput = tagsinput => {
        this.setState({ tags:tagsinput });
    };


    renderTagsModal(){
        return (
            <Modal
                size={"md"}
                className="modal-md modal-dialog-centered"
                isOpen={this.state.tagsModal}
                toggle={() => this.setState({tagsModal:false})}
            >
                <>
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            Add Keywords
                        </h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({tagsModal:false})}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <TagsInput
                                onlyUnique
                                className="bootstrap-tagsinput"
                                onChange={this.handleTagsinput}
                                value={this.state.tags}
                                tagProps={{ className: "tag badge mr-1" }}
                                inputProps={{
                                    className: "",
                                    placeholder: "Insert Keywords here"
                                }}
                            />
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={() => this.setState({tagsModal:false})}
                        >Close</button>
                    </div>
                </>
            </Modal>
        )
    }


    formatUrl(state){
        if(this.state.loading)
            return;
        let url = "apps/top/ios?";
        if(state.collection)
            url += "collection="+state.collection+"&";
        else
            url += "collection=top_free&";
        if(state.country)
            url += "gl="+state.country+"&";
        if(state.category)
            if(state.subCategory)
                url += "ca="+state.subCategory+"&";
            else
                url += "ca="+state.category+"&";
        if(state.publishingDate)
            url += "date="+state.publishingDate+"&";
        if(state.downloads)
            url += "downloads="+state.downloads+"&";
        if(state.tags.length > 0){
            url += "keywords=";
            state.tags.map((el,i) => {
                    url += el;
                    if(i != state.tags.length - 1)
                        url+=",";
                }
            );
            url += "&";
        }
        if(state.arabicApps)
            url += "arabic=1&";

        if(state.removedApps)
            url += "removed=1&";

        this.props.getData(url)

    }
}
