/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
  Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardFooter, CardHeader, Col,
  Container, Form, Modal, Pagination, PaginationItem, PaginationLink, Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import ClipLoader from "react-spinners/ClipLoader";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import TagsInput from "react-tagsinput";
import {HttpConfig} from "../../tools/Http";
import EpicTable from "../../components/Mobi/EpicTable";
import Utils from "../../Utils";
import { connect } from 'react-redux';

class Niches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNiche:null,
      niches:[],
      loading: true,
      publishingDate:null,
      searchQuery:"",
      tagsModal: false,
      emptyTable: true,
      found: -1,
      tags:[],
      apps:[],
      userCars:[],
      downloadData:[
        {id:"100" ,text:"+100"},
        {id:"1000" ,text:"+1K"},
        {id:"10000" ,text:"+10K"},
        {id:"100000" ,text:"+100K"},
        {id:"500000" ,text:"+500K"},
        {id:"1000000" ,text:"+1M"},
        {id:"500000000" ,text:"+500M"},
        {id:"1000000000" ,text:"+1B"},
      ],
      ratingMin:[
        {id:"0" ,text:"0 stars"},
        {id:"1" ,text:"1 star"},
        {id:"2" ,text:"2 stars"},
        {id:"3" ,text:"3 stars"},
        {id:"4" ,text:"4 stars"},
        {id:"5" ,text:"5 stars"},
      ],
      ratingMax:[
        {id:"0" ,text:"0 stars"},
        {id:"1" ,text:"1 star"},
        {id:"2" ,text:"2 stars"},
        {id:"3" ,text:"3 stars"},
        {id:"4" ,text:"4 stars"},
        {id:"5" ,text:"5 stars"},
      ],
      canSearch:true,
      downloads_min:null,
      downloads_max:null,
      ratings_min:null,
      ratings_max:null,
      published_after:null,
      unpublished_after:null,
      market_status:null,
      contains_ads:null,
      activePage : 1,
    };
  }

  componentDidMount() {
    // this.getCategories();
    this.getNiches();
  }

  render() {
    return (
      <>
        {this.state.loading ?
          <div
          style={{
              position:'fixed',
              display:'flex',
              zIndex:1,
              height:'100%',
              width:'100%',
              top:0,
              alignItems:'center',
              justifyContent:'center',
              backgroundColor:'rgba(0,0,0,.2)',
          }}
          >
          <ClipLoader
              color={"white"}
              loading={true}
              size={50}
          />
          </div>
          : null  
      }
        {this.getHeader()}
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="12">
              {this.renderSearch()}
              {/* {this.renderAdvanced()} */}
              {this.state.found !== -1 && this.renderResults()}
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  renderSearch(){
    return(
      <Card>
        <CardHeader>
          <Row>
            <Col lg="6" xs="7">
              <h3 className="mb-0">Select a Niche to see new profitable apps</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            {this.state.niches.map((el, i) => {
              return (
                <Col xl={3} key={i} style={{display: 'inline-block'}}>
                  <div className="card" style={{backgroundColor: el.id === this.state.selectedNiche ? '#b9ceff' : 'white', cursor: 'pointer'}} onClick={() => {
                      this.setState({selectedNiche: el.id},
                        () => this.getNicheApps()
                    );
                    }}>
                    <img src={el.icon} alt="..." className="card-img-top" />
                    <div className="card-body">
                      <div className="h2 mb-0 card-title">{el.text}</div>
                    </div>
                  </div>
                </Col>
              );
            })}
            {/* <Row>
              <Col sm="12" xl={12}>
                <Select2
                  className="form-control"
                  value={this.state.selectedNiche}
                  options={{
                    placeholder: "Select"
                  }}
                  data={this.state.niches}
                  onSelect={(e) => {
                    this.setState({selectedNiche: e.target.value},() =>
                      this.getNicheApps()
                    );
                  }}
                />
              </Col>
            </Row> */}
          </Form>
        </CardBody>
      </Card>
    );
  }

  getHeader(){
    return(
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Advanced
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-fire" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Google Play Niches
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }


  minMaxDate( currentDate ){
    return currentDate <= moment();
  }

  renderTagsModal(){
    return (
      <Modal
        size={"md"}
        className="modal-md modal-dialog-centered"
        isOpen={this.state.tagsModal}
        toggle={() => this.setState({tagsModal:false})}
      >
        <>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Add Keywords
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({tagsModal:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <TagsInput
                onlyUnique
                className="bootstrap-tagsinput"
                onChange={this.handleTagsinput}
                value={this.state.tags}
                tagProps={{ className: "tag badge mr-1" }}
                inputProps={{
                  className: "",
                  placeholder: "Insert Keywords here"
                }}
              />
            </Form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                    onClick={() => this.setState({tagsModal:false})}
            >Close</button>
          </div>
        </>
      </Modal>
    )
  }


  handleTagsinput = tagsinput => {
    this.setState({ tags:tagsinput });
  };

  validate(){
    if(parseInt(this.state.downloads_min) > parseInt(this.state.downloads_max)){
      this.props.notify("danger", "Error",'The download numbers don\'t make sense');
      this.setState({canSearch:false});
      return;
    }
    if(parseInt(this.state.ratings_min) > parseInt(this.state.ratings_max)){
      this.props.notify("danger", "Error",'The rating numbers don\'t make sense');
      this.setState({canSearch:false});
      return;
    }
    if(moment(this.state.published_after).isAfter(this.state.unpublished_after)){
      this.props.notify("danger", "Error",'The publishing dates don\'t make sense');
      this.setState({canSearch:false});
      return;
    }

    this.setState({canSearch:true});


  }
  getApps(){
    return this.state.apps.slice((this.state.activePage-1)*50, (this.state.activePage-1)*50+50);
  }
  renderResults() {
    return(
      <Row id={"result-section"}>
        <Col xl={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col lg="6" xs="7">
                  <h3 className="mb-0">Found {this.state.found} Apps</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className={"p-0"}>
              {this.state.found > 0 ?
                <EpicTable
                    apps={this.getApps()}
                    setLoading={this.setLoading.bind(this)}
                    userCats={this.state.userCars}
                    notify={this.props.notify}
                    hideDelete={true}
                    hideRank={true}
                    setSortFilter={this.setSortFilter.bind(this)}
                />
                :
                    <p className='text-muet text-center mt-3'>No apps available for this niche. Choose another one !</p>
                }
            </CardBody>
            <CardFooter className="py-4">
              {this.state.apps.length > 0 &&
              <nav aria-label="...">
                <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                >
                  <PaginationItem >
                    <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          if (this.state.activePage != 1) {
                            document.getElementById("result-section").scrollIntoView({
                              block: 'start',
                              behavior: 'smooth'
                            })
                            this.setState({activePage:1})
                          }
                        }}
                        tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  {this.state.activePage-1 >= 1 &&
                  <PaginationItem>
                    <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          this.setState({activePage:this.state.activePage-1})
                          document.getElementById("result-section").scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                          })

                        }}
                    >
                      {this.state.activePage-1}
                    </PaginationLink>
                  </PaginationItem>
                  }
                  <PaginationItem
                      className="active"
                  >
                    <PaginationLink
                        onClick={e => e.preventDefault()}

                    >
                      {this.state.activePage}
                      <span className="sr-only">(current)</span>
                    </PaginationLink>
                  </PaginationItem>

                  {this.state.activePage + 1 <= this.state.apps.length/50 &&
                  <PaginationItem>
                    <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          document.getElementById("result-section").scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                          })
                          this.setState({activePage:this.state.activePage+1})
                        }}
                    >
                      {this.state.activePage+1}
                    </PaginationLink>
                  </PaginationItem>
                  }
                  {this.state.activePage + 2 <= this.state.apps.length/50 &&
                  <PaginationItem>
                    <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          document.getElementById("result-section").scrollIntoView({
                            block: 'start',
                            behavior: 'smooth'
                          })
                          this.setState({activePage:this.state.activePage+2})
                        }}
                    >
                      {this.state.activePage+2}
                    </PaginationLink>
                  </PaginationItem>
                  }

                  <PaginationItem>
                    <PaginationLink
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          if(this.state.activePage != Math.ceil(this.state.apps.length/50)){
                            document.getElementById("result-section").scrollIntoView({
                              block: 'start',
                              behavior: 'smooth'
                            })
                            this.setState({activePage:Math.ceil(this.state.apps.length/50)})
                          }
                        }}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>

                </Pagination>
              </nav>
              }
            </CardFooter>
          </Card>
        </Col>
      </Row>)
  }

  setLoading(loading){
    this.setState({loading})
  }

  searchForApp(){
    this.setState({emptyTable:true, apps:[],ranking:null, loading:true})

    let query = {};
    query.full_text_term = this.state.searchQuery;
    this.state.tags.map(el => {
      query.full_text_term = " and "+el
    })
    if(this.state.downloads_max)
      query.downloads_lte = this.state.downloads_max;
    if(this.state.downloads_min)
      query.downloads_gte = this.state.downloads_min;

    if(this.state.ratings_max)
      query.rating_lte = this.state.ratings_max;
    if(this.state.ratings_min)
      query.rating_gte = this.state.ratings_min;

    if(this.state.published_after)
      query.released_after = this.state.published_after;
    if(this.state.unpublished_after)
      query.unpublished_after = this.state.unpublished_after;

    if(this.state.contains_ads)
      query.contains_ads = this.state.contains_ads === "true";
    if(this.state.market_status)
      query.market_status = this.state.market_status;

    let payload = {
      "query":{
        "query_params":query
      }
    }

    HttpConfig().post('/app/search',{payload: payload})
      .then(res => {
        if(res.data.status){

          let apps = res.data.data.results;
          apps.map(el => {
            el.app_id = el.package_name;
            el.url = el.market_url
            el.screenshots = JSON.stringify(el.screenshots)
          })
          this.setState({
            apps:res.data.data.results,
            emptyTable:res.data.data.results.length === 0,
            found:res.data.data.number_results,loading:false
          });
        }
        else{
          this.setState({emptyTable:true,loading:false})
        }
      }).catch(err => {
      this.setState({emptyTable:true,loading:false})
    })
  }


  getCategories(){
    HttpConfig().get('categories')
      .then(res => {
        if(res.data.status){
          let arr = [];
          res.data.categories.map(el =>{
            arr.push({id:el.id, text:el.title+ "("+el.total+" apps)"})
          })
          this.setState({userCars:arr});
        }
        else{
          //error
        }
      }).catch(err => {

    })

  }



  getNiches(){
    HttpConfig(this.props.dispatch).get('list-google-niches')
      .then(res => {
        if(res.data.status){
          let arr = [];
          // let arr = [{id:0, text:'Choose a niche to start searching apps'}];
          res.data.lists.map(el =>{
            arr.push({id:el.uuid, text:el.name, icon: el.icon})
          })
          this.setState({niches:arr, loading:false});
          // this.getNicheApps()
        }
        else{
          this.setState({loading:false});
        }
      }).catch(err => {
        this.setState({loading:false});
    })

  }

  setSortFilter(filter, bool, desc){
    let apps = this.state.apps;
    Utils.sortByAttr(apps,filter, bool,desc)
    this.setState({apps});
  }
  getNicheApps(){
    // if(this.state.selectedNiche === 0)
    //   return false;
    this.setState({emptyTable: true, apps: [], found:-1, ranking: null, loading: true})
    HttpConfig(this.props.dispatch).get('get-niches/'+this.state.selectedNiche)
      .then(res => {
        if(res.data.status){
          this.setState({
            apps: res.data.applications,
            emptyTable: res.data.applications.length <= 0,
            found: res.data.applications.length,
            loading: false,
            activePage: 1
          }, () => {
            setTimeout(() => {
                if (document.getElementById("result-section"))
                    document.getElementById("result-section").scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    })
            }, 500)
          });
        }
        else{
          this.setState({emptyTable: true, found:0, loading: false})
        }
      }).catch(err => {
        this.setState({emptyTable: true, found:0, loading: false})
    })
  }
}

export default connect()(Niches)