/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, Col,
    Container, Form, FormGroup, Input, ListGroup, ListGroupItem, Modal, Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import TagsInput from "react-tagsinput";
import ClipLoader from "react-spinners/ClipLoader";
import {HttpConfig} from "../../tools/Http";
import EpicTable from "../../components/Mobi/EpicTable";
import Utils from "../../Utils";
import { connect } from 'react-redux';

const queryString = require('query-string');

class Search extends React.Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(props.props.location.search);
        this.state = {
            publishingDate: null,
            searchQuery: params['q'] || '',
            tagsModal: false,
            emptyTable: true,
            new: false,
            found: -1,
            searchHistory: [],
            hiddenHistory: true,
            tags: [],
            apps: [],
            userCars: [],
            downloadData: [
                {id: "100", text: "+100"},
                {id: "1000", text: "+1K"},
                {id: "10000", text: "+10K"},
                {id: "100000", text: "+100K"},
                {id: "500000", text: "+500K"},
                {id: "1000000", text: "+1M"},
                {id: "500000000", text: "+500M"},
                {id: "1000000000", text: "+1B"},
            ],
            ratingMin: [
                {id: "0", text: "0 stars"},
                {id: "1", text: "1 star"},
                {id: "2", text: "2 stars"},
                {id: "3", text: "3 stars"},
                {id: "4", text: "4 stars"},
                {id: "5", text: "5 stars"},
            ],
            ratingMax: [
                {id: "0", text: "0 stars"},
                {id: "1", text: "1 star"},
                {id: "2", text: "2 stars"},
                {id: "3", text: "3 stars"},
                {id: "4", text: "4 stars"},
                {id: "5", text: "5 stars"},
            ],
            canSearch: true,
            downloads_min: null,
            downloads_max: null,
            ratings_min: null,
            ratings_max: null,
            published_after: null,
            unpublished_after: null,
            market_status: null,
            contains_ads: null,
            loading:true,
        };
    }

    componentDidMount() {
        if(this.state.searchQuery !== "")
            this.searchForApp()
        this.getSearchHistory();
    }

    // getData(){
    //     this.setState({emptyTable:false,ranking:null, loading:true})
    //     HttpConfig().get('/apps/top?collection=topselling_new_free&gl=us&ca=overall&')
    //         .then(res => {
    //             if(res.data.status){
    //                 this.setState({apps:res.data.apps, emptyTable:res.data.length === 0,
    //                     ranking:res.data.ranking,loading:false
    //                 });
    //             }
    //             else{
    //                 this.setState({emptyTable:true,loading:false})
    //             }
    //         }).catch(err => {
    //         this.setState({emptyTable:true,loading:false})
    //     })
    // }

    render() {
        return (
            <>
                {this.state.loading ?
                    <div
                    style={{
                        position:'fixed',
                        display:'flex',
                        zIndex:9999,
                        height:'100%',
                        width:'100%',
                        top:0,
                        alignItems:'center',
                        justifyContent:'center',
                        backgroundColor:'rgba(0,0,0,.2)',
                    }}
                    >
                    <ClipLoader
                        color={"white"}
                        loading={true}
                        size={50}
                    />
                    </div>
                    : null  
                }
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            {this.renderSearch()}
                            {/* {this.renderAdvanced()} */}
                            {this.state.found !== -1 && this.renderResults()}
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    renderSearch() {
        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Search By Keywords</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col sm={9}>
                                <Input
                                    placeholder="Enter a Keyword..."
                                    type="text"
                                    value={this.state.searchQuery}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.searchForApp();
                                        }
                                    }}
                                    onChange={e => this.setState({searchQuery: e.target.value})}
                                    onFocus={e => this.setState({hiddenHistory: false})}
                                    onBlur={e => setTimeout(() => { this.setState({hiddenHistory: true}) }, 200)}
                                />
                                <ListGroup hidden={this.state.hiddenHistory} style={{position: 'absolute', zIndex: 9999, textWrap: 'nowrap', overflowY: 'scroll', maxHeight: 230}}>
                                    {this.state.searchHistory.length > 0 && this.state.searchHistory.filter(item => item.toLowerCase().includes(this.state.searchQuery.toLowerCase())).map((el, index) => {
                                        return (
                                            <ListGroupItem key={index} action tag="button" onClick={(e) => {e.preventDefault(); this.setState({searchQuery: el})}}>
                                                <span><i className="fas fa-history"></i>&nbsp;&nbsp;&nbsp;&nbsp;{el}</span>
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>
                            </Col>
                            <Col sm={1}>
                                <div className="custom-control custom-control-alternative custom-checkbox" style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                                    <input
                                        className="custom-control-input"
                                        id=" customCheckLogin"
                                        type="checkbox"
                                        value={this.state.new} onChange={e => this.setState({new:e.target.checked})}

                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor=" customCheckLogin"
                                    >
                                        <span className="text-muted">New</span>
                                    </label>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <Button className={"btn-info"}
                                        disabled={!this.state.canSearch || this.state.searchQuery === ""}
                                        onClick={() => {
                                            this.searchForApp();
                                            // if(!this.state.token)
                                        }}
                                    >
                                        <i className="fas fa-search-dollar"></i>&nbsp;&nbsp;Search
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        );
    }

    getHeader() {
        return (
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Search
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-search"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Advanced Search
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }


    renderAdvanced() {
        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">Advanced Filters</h3>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Download Min
                                    </label>
                                    <Select2
                                        className="form-control"
                                        value={this.state.downloads_min}
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={this.state.downloadData}
                                        onSelect={(e) => {
                                            this.setState({downloads_min: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className=" form-control-label">
                                        Download Max
                                    </label>
                                    <Select2
                                        className="form-control"
                                        value={this.state.downloads_max}
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={this.state.downloadData}
                                        onSelect={(e) => {
                                            this.setState({downloads_max: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Rating Min
                                    </label>
                                    <Select2
                                        className="form-control"
                                        defaultValue="0"
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={this.state.ratingMin}
                                        value={this.state.ratings_min}
                                        onSelect={(e) => {
                                            this.setState({ratings_min: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className=" form-control-label">
                                        Rating Max
                                    </label>
                                    <Select2
                                        className="form-control"
                                        defaultValue="5"
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={this.state.ratingMax}
                                        value={this.state.ratings_max}
                                        onSelect={(e) => {
                                            this.setState({ratings_max: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Published After
                                    </label>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Not Specified"
                                        }}
                                        closeOnSelect={true}
                                        onChange={(v) => this.setState({published_after: v}, () =>
                                            this.validate())}
                                        timeFormat={false}
                                        validDate={this.minMaxDate}
                                        dateFormat={"YYYY-MM-DD"}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Unpublished After
                                    </label>
                                    <ReactDatetime
                                        inputProps={{
                                            placeholder: "Not Specified"
                                        }}
                                        closeOnSelect={true}
                                        onChange={(v) => this.setState({unpublished_after: v}, () =>
                                            this.validate())}
                                        timeFormat={false}
                                        validDate={this.minMaxDate}
                                        dateFormat={"YYYY-MM-DD"}
                                    />
                                </FormGroup>

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label className=" form-control-label">
                                        Keywords
                                    </label>
                                    <Input
                                        className="form-control"
                                        onClick={() => {
                                            this.setState({tagsModal: true});
                                        }}
                                        readonly={true}
                                        value={this.state.tags.length === 0 ? "No Keywords" : this.state.tags.length + " Keywords"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Contains Ads
                                    </label>
                                    <Select2
                                        className="form-control"
                                        value={() => this.state.contains_ads}
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={[
                                            {id: "true", text: "Yes"},
                                            {id: "false", text: "No"}
                                        ]}
                                        onSelect={(e) => {
                                            this.setState({contains_ads: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6} xl={4}>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="exampleDatepicker"
                                    >
                                        Market Status
                                    </label>
                                    <Select2
                                        className="form-control"
                                        value={() => this.state.market_status}
                                        options={{
                                            placeholder: "Select"
                                        }}
                                        data={[
                                            {id: "PUBLISHED", text: "published"},
                                            {id: "UNPUBLISHED", text: "unpublished"},
                                            {id: "PREREGISTERED", text: "preregistered"}
                                        ]}
                                        onSelect={(e) => {
                                            this.setState({market_status: e.target.value}, () =>
                                                this.validate()
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.renderTagsModal()}
                    </Form>
                </CardBody>
            </Card>
        );
    }

    minMaxDate(currentDate) {
        return currentDate <= moment();
    }

    renderTagsModal() {
        return (
            <Modal
                size={"md"}
                className="modal-md modal-dialog-centered"
                isOpen={this.state.tagsModal}
                toggle={() => this.setState({tagsModal: false})}
            >
                <>
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                            Add Keywords
                        </h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({tagsModal: false})}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form>
                            <TagsInput
                                onlyUnique
                                className="bootstrap-tagsinput"
                                onChange={this.handleTagsinput}
                                value={this.state.tags}
                                tagProps={{className: "tag badge mr-1"}}
                                inputProps={{
                                    className: "",
                                    placeholder: "Insert Keywords here"
                                }}
                            />
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={() => this.setState({tagsModal: false})}
                        >Close
                        </button>
                    </div>
                </>
            </Modal>
        )
    }


    handleTagsinput = tagsinput => {
        this.setState({tags: tagsinput});
    };

    validate() {
        if (parseInt(this.state.downloads_min) > parseInt(this.state.downloads_max)) {
            this.props.notify("danger", "Error", 'The download numbers don\'t make sense');
            this.setState({canSearch: false});
            return;
        }
        if (parseInt(this.state.ratings_min) > parseInt(this.state.ratings_max)) {
            this.props.notify("danger", "Error", 'The rating numbers don\'t make sense');
            this.setState({canSearch: false});
            return;
        }
        if (moment(this.state.published_after).isAfter(this.state.unpublished_after)) {
            this.props.notify("danger", "Error", 'The publishing dates don\'t make sense');
            this.setState({canSearch: false});
            return;
        }

        this.setState({canSearch: true});


    }

    renderResults() {
        return (
            <Row id={"result-section"}>
                <Col xl={12}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col lg="6" xs="7">
                                    <h3 className="mb-0">Found {this.state.found} Apps</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className={"p-0"}>
                            {this.state.found > 0 ?
                            <EpicTable
                                apps={this.state.apps}
                                setLoading={this.setLoading.bind(this)}
                                userCats={this.state.userCars}
                                notify={this.props.notify}
                                hideDelete={true}
                                hideRank={true}
                                setSortFilter={this.setSortFilter.bind(this)}
                            />
                            :
                                <p className='text-muet text-center mt-3'>No apps available for this keyword. Try another one !</p>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>)
    }

    getApps() {
        return this.state.apps.slice((this.state.activePage - 1) * 50, (this.state.activePage - 1) * 50 + 50);
    }

    setLoading(loading) {
        this.setState({loading})
    }
    setSortFilter(filter, bool, desc){
        let apps = this.state.apps;
        Utils.sortByAttr(apps,filter, bool,desc)
        this.setState({apps});
    }
    searchForApp() {
        this.setState({emptyTable: true, apps: [], found:-1, ranking: null, loading: true})
        // let query = {};
        // query.full_text_term = this.state.searchQuery;
        // this.state.tags.map(el => {
        //     query.full_text_term += " AND " + el
        // })

        // if (this.state.downloads_max)
        //     query.downloads_lte = this.state.downloads_max;
        // if (this.state.downloads_min)
        //     query.downloads_gte = this.state.downloads_min;

        // if (this.state.ratings_max)
        //     query.rating_lte = this.state.ratings_max;
        // if (this.state.ratings_min)
        //     query.rating_gte = this.state.ratings_min;

        // if (this.state.published_after)
        //     query.released_after = this.state.published_after;
        // if (this.state.unpublished_after)
        //     query.unpublished_after = this.state.unpublished_after;

        // if (this.state.contains_ads)
        //     query.contains_ads = this.state.contains_ads === "true";
        // if (this.state.market_status)
        //     query.market_status = this.state.market_status;

        // let payload = {
        //     "query": {
        //         "query_params": query
        //     }
        // }

        HttpConfig(this.props.dispatch).get('search-on-apps?q='+this.state.searchQuery+"&new="+this.state.new)
            .then(res => {
                if (res.data.status) {
                    // apps.map(el => {
                    //     el.app_id = el.app_id;
                    //     el.published_in = el.created;
                    //     el.url = el.market_url
                    //     el.screenshots = JSON.stringify(el.screenshots)
                    // })
                    this.setState({
                        apps: res.data.applications,
                        emptyTable: res.data.applications.length <= 0,
                        found: res.data.applications.length,
                        loading: false
                    }, () => {
                        setTimeout(() => {
                            if (document.getElementById("result-section"))
                                document.getElementById("result-section").scrollIntoView({
                                    block: 'start',
                                    behavior: 'smooth'
                                })
                        }, 500)
                    });
                    if(this.state.searchHistory.includes(this.state.searchQuery)) {
                        this.state.searchHistory.splice(this.state.searchHistory.indexOf(this.state.searchQuery), 1)
                    }
                    this.state.searchHistory.unshift(this.state.searchQuery)
                } else {
                    this.setState({emptyTable: true, found:0, loading: false})
                }
            }).catch(err => {
            this.setState({emptyTable: true, found:0, loading: false})
        })
    }


    getSearchHistory() {
        this.setState({loading: true})
        HttpConfig(this.props.dispatch).get('all-history-search')
            .then(res => {
                if (res.data.status) {
                    this.setState({loading: false, searchHistory: res.data.history})
                } else {
                    this.setState({loading: false})
                }
            }).catch(err => {
                this.setState({loading: false})
        })

    }
}

export default connect()(Search)