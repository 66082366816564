/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import ReactDOM from 'react-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Modal,
  Row,
  Table,
  UncontrolledAlert
} from "reactstrap";
import { HttpConfig } from "../../tools/Http";
import moment from "moment";
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { connect } from "react-redux";

const keyword_extractor = require("keyword-extractor");

class DescriptionGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appName: "",
      titleColor: "btn-danger",
      titleCharNumber: 0,

      shortDescription: "",
      shortDescriptionColor: "btn-danger",
      shortDescriptionCharNumber: 0,

      description: "",
      descriptionColor: "btn-danger",
      descriptionCharNumber: 0,

      workID: "",

      editorState: EditorState.createEmpty(),

      keywords: [],
      totalKeywords: 0,

      savedWork: [],
      work: {
        title: "",
        short_description: "",
        description: ""
      }
    };
    this.onChange = editorState => this.setState({editorState});
  }

  componentDidMount() {
    this.getSavedWork();
  }

  getSavedWork() {
    HttpConfig(this.props.dispatch)
      .get("contents")
      .then(res => {
        if (res.data.status) {
          this.setState({ savedWork: res.data.contents });
        } else {
          //error
        }
      })
      .catch(err => {})
  }

  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Tools
                  </h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-wrench" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        Description Generator
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
                  <Button
                    className="btn-neutral"
                    color="default"
                    size="sm"
                    onClick={() => {
                      this.setState({
                        appName: '',
                        shortDescription: '',
                        description: '',
                        work: {
                          title: "",
                          short_description: "",
                          description: ""
                        }
                      })
                    }}
                  >
                    <i className="fas fa-broom"></i>&nbsp;&nbsp;Clear All
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    size="sm"
                    onClick={() => this.setState({ defaultModal: true })}
                  >
                    <i className="far fa-arrow-alt-circle-down fa-lg"></i>&nbsp;&nbsp;Load Work ({this.state.savedWork.length})
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          {this.getBody()}
          {this.renderModal()}
        </Container>
      </>
    );
  }

  getBody() {
    return (
      <Row className="justify-content-center">
        <Col className="card-wrapper" lg="12">
          {this.renderTitle()}
          {/* <Editor editorState={this.state.editorState} onChange={this.onChange} /> */}
          {this.renderShort()}
          {this.renderDescription()}
          {this.renderTable()}
        </Col>
      </Row>
    );
  }

  renderDescription() {
    return (
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col lg="6" xs="7">
              <h3 className="mb-0">Description</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
              <button
                type="button"
                className="btn btn-info btn-sm"
                style={{ marginRight: 10 }}
                onClick={() =>
                  this.copy(this.state.work.description, this.props)
                }
              >
                Copy to clipboard
              </button>
              <button
                type="button"
                className={"btn btn-sm " + this.state.descriptionColor}
                style={{ cursor: "context-menu" }}
              >
                {this.state.descriptionCharNumber}/4000 characters
              </button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div
            className="alert alert-secondary text-muted border-secondary"
            role="alert"
          >
            The Play Store algorithm takes app descriptions into account, so
            don't forget to use relevant and important keywords in your
            description to increase your discoverability. Make sure to optimize
            your keyword density. Use our Keywords Analysis to find the best
            keywords for your app.
          </div>
          <Form>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="12"
              style={{ marginTop: 10 }}
              value={this.state.description}
              maxLength="4000"
              placeholder={"Test a new Description for your app"}
              onChange={e => {
                this.descriptionChangeHandler(e.target.value);
              }}
            />
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderTable() {
    return (
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col lg="6" xs="7">
              <h3 className="mb-0">keywords Count and Density</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className="overflow-hidden align-items-center table-flush"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  style={{ minWidth: "70.66%", maxWidth: "70.66%" }}
                >
                  Keywords
                </th>
                <th scope="col">Count</th>
                <th scope="col">Density</th>
              </tr>
            </thead>
            <tbody style={{ overflowX: "hidden" }}>
              {this.state.keywords.map((e, i) => {
                return e.count > 1 ? (
                  <tr key={"keyword" + i}>
                    <th
                      style={{
                        wordBreak: "break-all"
                      }}
                      scope="row"
                    >
                      <div
                        style={{
                          whiteSpace: "normal",
                          height: 30,
                          display: "inline-block"
                        }}
                      >
                        {e.element}
                      </div>
                    </th>
                    <td>
                      <b>{e.count}</b>
                    </td>
                    <td>
                      <b>
                        {this.formatPercentage(
                          (e.count / this.state.totalKeywords) * 100
                        )}
                        %
                      </b>
                    </td>
                  </tr>
                ) : null;
              })}
            </tbody>
          </Table>
          <CardFooter>
            <Row className="align-items-center">
              <Col lg="6" xs="7"></Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    this.download("dsdsd", "aa.txt");
                  }}
                >
                  Download Your Work
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={() => this.saveWork()}
                >
                  Save Your Work
                </button>
              </Col>
            </Row>
          </CardFooter>
        </CardBody>
      </Card>
    );
  }
  renderShort() {
    return (
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col lg="6" xs="7">
              <h3 className="mb-0">Short Description</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
              <Button
                className="btn-info"
                size={"sm"}
                style={{ marginRight: 10 }}
                onClick={() => this.copy(this.state.work.short_description, this.props)}
              >
                Copy to clipboard
              </Button>
              <Button
                className={"btn btn-sm " + this.state.shortDescriptionColor}
                style={{ cursor: "context-menu" }}
              >
                {this.state.shortDescriptionCharNumber}/80 characters
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div
            className="alert alert-secondary text-muted border-secondary"
            role="alert"
          >
            Your short description should explain in one sentence what your app
            does. Don’t forget to include your strongest keywords as they will
            be taken into account by the Google Play Store algorithm. We
            recommend to use all available 80 characters.
          </div>
          <Form>
            <Input
              placeholder="Test a new Short Description"
              type="text"
              onFocus={e => this.setState({ yourName: true })}
              onBlur={e => this.setState({ yourName: false })}
              value={this.state.shortDescription}
              maxLength="80"
              onChange={e => {
                this.shortDescriptionChangeHandler(e.target.value);
              }}
            />
          </Form>
        </CardBody>
      </Card>
    );
  }

  shortDescriptionChangeHandler(short) {
    let work = this.state.work;
    work.short_description = short;
    this.setState(
      {
        shortDescription: short,
        shortDescriptionCharNumber: short.length,
        work
      },
      () => {
        if (this.state.shortDescription.length > 80)
          this.setState({ shortDescriptionColor: "btn-danger" });
        else if (
          this.state.shortDescription.length > 70 &&
          this.state.shortDescription.length <= 80
        )
          this.setState({ shortDescriptionColor: "btn-success" });
        else if (
          this.state.shortDescription.length > 10 &&
          this.state.shortDescription.length <= 70
        )
          this.setState({ shortDescriptionColor: "btn-warning" });
        else this.setState({ shortDescriptionColor: "btn-danger" });
      }
    );
  }

  descriptionChangeHandler(long) {
    let work = this.state.work;
    work.description = long;
    this.setState(
      {
        work,
        description: long,
        descriptionCharNumber: long.length
      },
      () => {
        let extraction_result = keyword_extractor.extract(
          this.state.description,
          {
            // language:"english",
            remove_digits: true,
            return_changed_case: true,
            remove_duplicates: false
            // return_chained_words: true
          }
        );
        let hashMap = [];
        let totalKeywords = extraction_result.length;
        extraction_result.map((ol, i) => {
          let found = false;
          hashMap.map((el, j) => {
            if (ol == el["element"]) {
              found = true;
              hashMap[j].count = hashMap[j].count + 1;
            }
          });
          if (!found) hashMap.push({ element: ol, count: 1 });
        });
        let keywords = [];
        hashMap.map(el => {
          let found = false;
          keywords.map((k, i) => {
            if (k.count == el.count) {
              found = true;
              k.element = k.element + ", " + el.element;
            }
          });
          if (!found) keywords.push({ element: el.element, count: el.count });
        });
        keywords.sort((a, b) => b.count - a.count);
        this.setState({ keywords, totalKeywords });

        if (this.state.description.length > 4000)
          this.setState({ descriptionColor: "btn-danger" });
        else if (
          this.state.description.length > 2000 &&
          this.state.description.length <= 4000
        )
          this.setState({ descriptionColor: "btn-success" });
        else if (
          this.state.description.length > 10 &&
          this.state.description.length <= 2000
        )
          this.setState({ descriptionColor: "btn-warning" });
        else this.setState({ descriptionColor: "btn-danger" });
      }
    );
  }

  renderTitle() {
    return (
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col lg="6" xs="7">
              <h3 className="mb-0">App title</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
              <Button
                className="btn-info"
                size={"sm"}
                style={{ marginRight: 10 }}
                onClick={() => this.copy(this.state.work.title, this.props)}
              >
                Copy to clipboard
              </Button>
              <Button
                className={"btn btn-sm " + this.state.titleColor}
                style={{ cursor: "context-menu" }}
              >
                {this.state.titleCharNumber}/30 characters
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div
            className="alert alert-secondary text-muted border-secondary"
            role="alert"
          >
            Keywords in your App Name have a lot of a weight in the Google Play
            algorithm. Try adding your strongest keywords in your title to
            increase your app discoverability. You can use up to 30 characters.
          </div>
          <Form>
            <Input
              placeholder="Try New App Name"
              type="text"
              onFocus={e => this.setState({ yourName: true })}
              onBlur={e => this.setState({ yourName: false })}
              value={this.state.appName}
              maxLength="30"
              onChange={e => {
                this.appNameChangeHandler(e.target.value);
              }}
            />
          </Form>
        </CardBody>
      </Card>
    );
  }

  copy(text, props) {
    navigator.clipboard
      .writeText(text)
      .then(
        function() {
          props.notify("success", "Copied", "Text copied to clipboard");
        },
        function(err) {
          console.log(err);
          props.notify("danger", "Error", "Could not copy to clipboard");
        }
      )
      .catch(err => {
        console.log(err);
        props.notify("danger", "Error", "Could not copy to clipboard");
      });
  }

  appNameChangeHandler(title) {
    let work = this.state.work;
    work.title = title;
    this.setState(
      { work, appName: title, titleCharNumber: title.length },
      () => {
        if (this.state.appName.length > 30)
          this.setState({ titleColor: "btn-danger" });
        else if (
          this.state.appName.length > 25 &&
          this.state.appName.length <= 30
        )
          this.setState({ titleColor: "btn-success" });
        else if (
          this.state.appName.length > 5 &&
          this.state.appName.length <= 25
        )
          this.setState({ titleColor: "btn-warning" });
        else this.setState({ titleColor: "btn-danger" });
      }
    );
  }

  formatPercentage(num) {
    let roundedString = Number(num.toFixed(2));
    return Number(roundedString);
  }

  saveWork() {
    if (this.state.workID)
      HttpConfig(this.props.dispatch)
        .post("update-content/"+this.state.workID, this.state.work)
        .then(res => {
          if(res.data.code_status == 200){
            this.getSavedWork();
            this.props.notify(
              "success",
              "Saved",
              "Your Work is successfully updated"
            );
          }
        })
        .catch(err => {
          this.props.notify(
            "danger",
            "Error",
            "There was an error saving your work!"
          );
        });
    else
      HttpConfig(this.props.dispatch)
        .post("save-content", this.state.work)
        .then(res => {
          if(res.data.code_status == 200){
            this.getSavedWork();
            this.props.notify(
              "success",
              "Saved",
              "Your Work is successfully updated"
            );
          }
        })
        .catch(err => {
          this.props.notify(
            "danger",
            "Error",
            "There was an error saving your work!"
          );
        });
  }

  deleteWork(work, i) {
    // toast("Deleting selected work  in progress..");
    if (work.uuid) {
      let id = work.uuid
      HttpConfig(this.props.dispatch)
        .delete("delete-content/" + work.uuid)
        .then(res => {
          if(res.data.code_status == 200){
            this.getSavedWork();
            if (id == this.state.workID) {
              this.setState({
                workID: '',
                appName: '',
                shortDescription: '',
                description: '',
                keywords: []
              })
            }
            this.props.notify(
              "success",
              "Deleted",
              "Your Work is successfully deleted"
            );
          }
        })
        .catch(err => {
          this.props.notify(
            "danger",
            "Error",
            "There was an error while deleting your work!"
          );
        });
      let savedWork = this.state.savedWork;
      savedWork.splice(i, 1);
      this.setState({ savedWork });
    }
  }

  download() {
    let text = "/***************** APP TITLE *****************/\n\n\n\n";
    text += this.state.work.title + "\n\n\n\n";
    text += "/***************** SHORT DESCRIPTION *****************/\n\n\n\n";
    text += this.state.work.short_description + "\n\n\n\n";
    text += "/***************** LONG DESCRIPTION *****************/\n\n\n\n";
    text += this.state.work.description + "\n\n\n\n";

    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute(
      "download",
      this.state.work.title === "" ? "New Work.txt" : this.state.work.title
    );

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    // toast.success("Work file generated successfully");
  }

  renderModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={this.state.defaultModal}
        toggle={() => this.setState({ defaultModal: !this.state.defaultModal })}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-notification">
            My Saved Work
          </h6>
        </div>
        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Title</th>
                <th scope="col">Created At</th>
                <th scope="col">Last Update</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.state.savedWork.map((el, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>{
                        this.setState({
                          work: {
                            title: el.title,
                            short_description: el.short_description,
                            description: el.description
                          },
                          workID: el.uuid,
                          appName: el.title,
                          shortDescription: el.short_description,
                          defaultModal: false,
                          description: el.description
                        });
                        this.appNameChangeHandler(el.title)
                        this.shortDescriptionChangeHandler(el.short_description)
                        this.descriptionChangeHandler(el.description)
                      }
                      }
                    >
                      {el.title}
                    </td>
                    <td>{moment(el.created_at).fromNow()}</td>
                    <td>{moment(el.updated_at).fromNow()}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => this.deleteWork(el, i)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            size="sm"
            data-dismiss="modal"
            type="button"
            onClick={() => this.setState({ defaultModal: false })}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }
}

export default connect()(DescriptionGenerator)