/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import Chart from "chart.js";
import {
    Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader,
    Col,
    Container, FormGroup, Modal, Row, Table, UncontrolledTooltip,

} from "reactstrap";
import {HttpConfig} from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import Utils from "../../Utils";
import {Line} from "react-chartjs-2";
import {
    chartExample1,
    parseOptions,
    chartOptions,
} from "../../variables/charts";
import Flag from 'react-world-flags'

export default class Error404 extends React.Component {
    constructor(props) {
        super(props);
        let countries = [];
        Utils.keywordCountries.map(el => {
            countries.push(
                {id: Object.keys(el)[0], text: Object.keys(el)[0] + ' - ' + el[Object.keys(el)[0]]}
            )
        })
        this.state = {
            apps: [],
            appsSelect: [],
            keywords: [],
            labels: [],
            data: [],
            countries,
            selectedCountry: countries[0].id,
            selectedStore: 1,
            selectedAppStore: 1,
            selectedApp: null,
            isLoading: true,
            loadingId: true,
            showModal: false,
            showAppModal: false,
            selectedCountryKeyword: countries[0].id,
            selectedCountryApp: countries[0].id,
        };

        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        HttpConfig().get('keywords/all')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        apps: res.data.apps,
                        selectedApp: res.data.apps.length > 0 ? res.data.apps[0].keyapp_id : null
                    }, () => {
                        this.getApp();
                    });
                    let arr = [];
                    res.data.apps.map(el => {
                        arr.push({
                            id: el.keyapp_id,
                            text: el.name
                        })
                    })
                    this.setState({appsSelect: arr})

                } else {
                    //error
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    getApp() {
        HttpConfig().get('keywords/app/' + this.state.selectedApp)
            .then(res => {
                if (res.data.status) {
                    this.setState({keywords: res.data.app.keywords});
                    res.data.app.keywords.length > 0 && this.getHistory(res.data.app.keywords[0].keyapp_id)
                } else {
                    //error
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    {this.getCards()}
                    {this.getCharts()}
                    {this.getKeywords()}
                    {this.renderKeywordModal()}
                    {this.renderAppModal()}
                </Container>
            </>
        )
    }

    renderKeywordModal() {
        return (
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.showModal}
                toggle={() => this.setState({showModal: !this.state.showModal})}
            >
                <div className="modal-header">
                    <h6
                        className="modal-title"
                        id="modal-title-notification"
                    >
                        Add Keyword
                    </h6>
                </div>
                <div className="modal-body">
                    <form>
                        <Row>
                            <Col lg={6} xs={12} className={"mb-2"}>
                                <FormGroup>
                                    <label htmlFor="recipient-name" className="col-form-label">Select Country</label>
                                    <Select2
                                        className="form-control"
                                        value={this.state.selectedCountryKeyword}
                                        onSelect={e => this.setState({selectedCountryKeyword: e.target.value})}
                                        options={{
                                            placeholder: "Country"
                                        }}
                                        data={this.state.countries}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6} xs={12} className={"mb-2"}>
                                <FormGroup>
                                    <label htmlFor="recipient-name" className="col-form-label">Add Keyword</label>
                                    <input type="text" className="form-control" id="recipient-name"
                                           value={this.state.keywordToAdd}
                                           onChange={(e) => this.setState({keywordToAdd: e.target.value})}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </div>
                <div
                    className="modal-footer"
                >
                    <Row>
                        <Button
                            className="ml-auto"
                            color="link"
                            size="sm"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({showModal: false})}
                        >
                            Close
                        </Button>
                        <Button
                            className="ml-auto"
                            color="success"
                            size="sm"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                // this.setState({showModal: false});
                                this.addKeyword()
                            }}
                        >
                            Add
                        </Button>
                    </Row>
                </div>
            </Modal>
        );
    }

    renderAppModal() {
        return (
            <Modal
                className="modal-dialog-centered"
                size="lg"
                isOpen={this.state.showAppModal}
                toggle={() => this.setState({showModal: !this.state.showAppModal})}
            >
                <div className="modal-header">
                    <h6
                        className="modal-title"
                        id="modal-title-notification"
                    >
                        Add App
                    </h6>
                </div>
                <div className="modal-body">
                    <form>
                        <Row>
                            <Col lg={4} xs={12} className={"mb-2"}>
                                <FormGroup>
                                    <label htmlFor="recipient-name" className="col-form-label">Select Country</label>
                                    <Select2
                                        className="form-control"
                                        value={this.state.selectedCountryApp}
                                        onSelect={e => this.setState({selectedCountryApp: e.target.value})}
                                        options={{
                                            placeholder: "Country"
                                        }}
                                        data={this.state.countries}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} xs={12} className={"mb-2"}>
                                <FormGroup>
                                    <label htmlFor="recipient-name" className="col-form-label">Select Store</label>
                                    <Select2
                                        className="form-control"
                                        value={this.state.selectedAppStore}
                                        onSelect={e => this.setState({selectedAppStore: e.target.value})}
                                        options={{
                                            placeholder: "Store"
                                        }}
                                        data={[
                                            {id: 1, text: "Play Store"},
                                            {id: 2, text: "App Store"},
                                        ]}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={4} xs={12} className={"mb-2"}>
                                <FormGroup>
                                    <label htmlFor="recipient-name" className="col-form-label">Package name (or App
                                        Id)</label>
                                    <input type="text" className="form-control" id="recipient-name"
                                           value={this.state.appToAdd}
                                           onChange={(e) => this.setState({appToAdd: e.target.value})}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </div>
                <div
                    className="modal-footer"
                >
                    <Row>
                        <Button
                            className="ml-auto"
                            color="link"
                            size="sm"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({showAppModal: false})}
                        >
                            Close
                        </Button>
                        <Button
                            className="ml-auto"
                            color="success"
                            size="sm"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => {
                                // this.setState({showModal: false});
                                this.addApp()
                            }}
                        >
                            Add
                        </Button>
                    </Row>
                </div>
            </Modal>
        );
    }

    getCharts() {
        return (
            <Row>
                <Col xl="12">
                    <Card className="bg-default">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h6 className="text-light text-uppercase ls-1 mb-1">
                                        Overview
                                    </h6>
                                    <h5 className="h3 text-white mb-0">Keyword ranking</h5>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Line
                                    height={200}
                                    options={{...chartExample1.options, maintainAspectRatio: false}}
                                    data={{
                                        labels: this.state.labels,
                                        datasets: [
                                            {
                                                label: "Keyword History",
                                                data: this.state.data
                                            }
                                        ]
                                    }}
                                    id="chart-sales-dark"
                                    className="chart-canvas"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>)
    }

    getCards() {
        return (
            <Row className="justify-content-center">
                <Col className="card-wrapper" lg="12">
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className="mb-0">Select App</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {/*<Col lg={4} xs={12} className={"mb-2"}>*/}
                                {/*    <Select2*/}
                                {/*        className="form-control"*/}
                                {/*        value={this.state.selectedCountry}*/}
                                {/*        onSelect={e => this.setState({selectedCountry: e.target.value})}*/}
                                {/*        options={{*/}
                                {/*            placeholder: "Country"*/}
                                {/*        }}*/}
                                {/*        data={this.state.countries}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                {/*<Col lg={4} xs={12} className={"mb-2"}>*/}
                                {/*    <Select2*/}
                                {/*        className="form-control"*/}
                                {/*        value={this.state.selectedStore}*/}
                                {/*        onSelect={e => this.setState({selectedStore: e.target.value})}*/}
                                {/*        options={{*/}
                                {/*            placeholder: "Store"*/}
                                {/*        }}*/}
                                {/*        data={[*/}
                                {/*            {id: 1, text: "Play Store"},*/}
                                {/*            {id: 2, text: "App Store"},*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                <Col lg={4} xs={12} className={"mb-2"}>
                                    <Select2
                                        className="form-control"
                                        value={this.state.selectedApp}
                                        onSelect={e => this.setState({selectedApp: e.target.value}, () => this.getApp())}
                                        options={{
                                            placeholder: "Select App"
                                        }}
                                        data={this.state.appsSelect}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    getHeader() {
        return (
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    {"Keywords"}
                                </h6>
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-credit-card"/>
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Keywords Ranking
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Button className="btn-neutral" color="default" size="sm"
                                        onClick={() => this.setState({showAppModal: true})}
                                >
                                    New App
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    getKeywords() {
        return (
            <Row className="justify-content-center">
                <Col className="card-wrapper" lg="12">
                    <Card>
                        <CardHeader>
                            <Row className="align-items-center">
                                <Col>
                                    <h3 className="mb-0">Keywords Ranking</h3>
                                </Col>
                                <Col className="mt-3 mt-md-0 text-md-right" lg="8" xs="5">
                                    <Button className={"btn-info"} size={"sm"} onClick={() => {
                                        this.setState({showModal: true})
                                    }}>Add Keyword</Button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">Keyword</th>
                                    <th scope="col">Country</th>
                                    <th scope="col" id={"tooltip36177092"}>
                                        score &nbsp;<bold className={"text-black"}>?</bold>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip36177092"
                                        >
                                            the quality of the traffic (10 means the keyword has a good traffic)
                                        </UncontrolledTooltip>
                                    </th>
                                    <th scope="col">rank</th>
                                    <th scope="col">change</th>
                                    <th scope="col">chart</th>
                                    <th scope="col">action</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.state.keywords.map((el) => {
                                    return (
                                        <tr>
                                            <th>
                                                {el.keyword}
                                            </th>

                                            <th>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    {el.country}
                                                    <div style={{height: 24, width: 24, marginLeft: 5}}>
                                                        <Flag style={{borderRadius: 4}} code={el.country}/>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>
                                                {el.score}
                                            </th>

                                            <th>
                                                {el.rank}
                                                <img src={require("assets/img/icons/refresh.png")}
                                                     className={`cursor-pointer ${this.state.loadingId === el.keyapp_id ? 'rotate' : ''}`}
                                                     style={{marginLeft: 5}}
                                                     onClick={() => this.refresh(el.keyapp_id)}/>
                                            </th>
                                            <th>
                                                <span
                                                    className={" mr-1 " + (el.difference >= 0 ? 'text-success' : "text-danger")}>
                                                   {el.difference === 0 ? "=" : el.difference}
                                                </span>
                                            </th>
                                            <th>
                                                <img src={require("assets/img/icons/chart.png")}
                                                     className={`cursor-pointer`}
                                                     onClick={() => {
                                                         this.getHistory(el.keyapp_id)
                                                     }}/>
                                            </th>
                                            <th>
                                                <Button size={"sm"} color={"danger"}
                                                        onClick={() => this.deleteKeyword(el.id)}>Delete</Button>
                                            </th>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }

    refresh(loadingId) {
        this.setState({loadingId})
        HttpConfig().get('keywords/rank/' + loadingId)
            .then(res => {
                if (res.data.status) {
                    this.getApp()
                    this.setState({loadingId: null})
                } else {
                    //error
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    getHistory(keyapp_id) {
        HttpConfig().get('keywords/history/' + keyapp_id)
            .then(res => {
                if (res.data.status) {
                    const labels = [];
                    const data = [];
                    res.data.app.map(({date, rank}) => {
                        labels.push(date);
                        data.push(rank);
                    })
                    this.setState({labels, data})
                } else {
                    //error
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    addKeyword() {
        HttpConfig().post('keywords/add', {
            keyword: this.state.keywordToAdd,
            idapp: this.state.selectedApp,
            country: this.state.selectedCountryKeyword,
        })
            .then(res => {
                if (res.data.status) {
                    this.props.notify("success", "Success", "Keyword Successfully added");
                    this.getApp()
                    this.setState({showModal: false})
                } else {
                    this.props.notify("danger", "Error", "There was an error while adding your keyword");
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    addApp() {
        let query = `package=${this.state.appToAdd}&store=${this.state.selectedAppStore}&country=${this.state.selectedCountryApp}`;
        HttpConfig().post('keywords/apps', {
            query
        })
            .then(res => {
                if (res.data.status) {
                    this.props.notify("success", "Success", "App Successfully added");
                    this.setState({showAppModal: false, selectedApp: this.state.appToAdd}, () => {
                        this.getApp()
                    })
                } else {
                    this.props.notify("danger", "Error", "There was an error while adding your app");
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    deleteKeyword(id) {
        HttpConfig().post('keywords/delete/' + id)
            .then(res => {
                if (res.data.status) {
                    this.props.notify("success", "Success", "Keyword deleted");
                    this.getApp()
                } else {
                    this.props.notify("danger", "Error", "There was an error while deleting the keyword");
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

}
