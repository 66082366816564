/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, Col,
    Container, Form, FormGroup, Input, Modal, Row, Table
} from "reactstrap";
// import Loader from "react-loader-spinner";
import moment from "moment";
import {HttpConfig} from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import Slider from "nouislider";
import Cookies from "js-cookie";
import CryptoJSAesJson from "../../tools/cryptojs-aes-format";
var CryptoJS = require("crypto-js");

export default class MobioptionsAdmobLimit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projects:[],
            banners: [{admob:"", facebook:""}],
            interstitials:  [{admob:"", facebook:""}],
            adsProvider:"default",
            adsActivated:true,
            autoDisable:true,
            adsPerc:100,
            package:null,
            token:null,
            isUpdating:false,
            showProjectAdd: false,
            tooltipOpen: false,
            slider1Value: 100,
            click:2,
            banDuration:2,
            delay:250,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var slider1 = this.refs.slider1;
        if(slider1 && !this.state.sliderInit){
            Slider.create(slider1, {
                start: [this.state.adsPerc],
                connect: [true, false],
                step: 10,
                range: { min: 0.0, max: 100.0 }
            }).on(
                "update",
                function(values, handle) {
                    this.setState({
                        slider1Value: values[0]
                    });
                }.bind(this)
            );
            this.setState({sliderInit:true})
        }
    }

    componentDidMount() {

        this.getProjects();
    }

    getProjects(){
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).get('limitproject/all')
            .then(res => {
                if(res.data.status){
                    this.setState({projects:res.data.limitProjects});
                }
                else{
                    //error
                    // toast.error("There was an error while loading your projects")

                }
            }).catch(err => {
            // toast.error("There was an error while loading your projects")
        })
    }
    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            {this.renderProjects()}
                            <Row>
                                <Col xl={6}>
                                    {this.renderAdd()}
                                    {this.renderControl()}
                                </Col>
                                <Col xl={6}>
                                    {this.renderAds()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {this.renderModal()}
                </Container>
            </>
        )
    }


    renderProjects(){
        return(
            <Card>
                <CardHeader className="border-0">
                    <Row className="align-items-center">
                        <Col lg="6" xs="7">
                            <h3 className="mb-0">My Projects</h3>
                        </Col>
                        {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            {this.state.loading &&
                            <Loader
                                type="Puff"
                                color="#5E72E4"
                                height={30}
                                width={30}

                            />}
                        </Col> */}
                    </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>Package Name</th>
                        <th>Token</th>
                        <th>Ads Status</th>
                        <th>Created At</th>
                        <th>Last Update</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.projects.map(el => {
                        return (
                            <tr>
                                <th>{el.app_id}</th>
                                <td>{el.token}</td>
                                <td> {el.ads_activated ? <b><p className="text-success">Active</p></b>: <b><p className="text-danger">Not Active</p></b>}</td>
                                <td>{moment(el.created_at).format('MMM DD YYYY hh:mm')}</td>
                                <td>{moment(el.updated_at).format('MMM DD YYYY hh:mm')}</td>
                                <td>
                                    <div className={"btn btn-primary btn-sm"} onClick={() => this.editProject(el)}>Edit</div>
                                    {/*<Link target={"blank"} to={'/ads-stats?id='+el.id+'&pkg='+el.app_id} className={"btn btn-success btn-sm"} style={{textDecoration:'none'}}>Stats</Link>*/}
                                    <div className={"btn btn-warning btn-sm"} onClick={() => this.deleteProject(el)}>Delete</div>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </Card>
        )
    }

    renderAdd(){
        return(<>
                {this.state.showProjectAdd &&
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="7">
                                <h3 className="mb-0">{!this.state.isUpdating ? "Creating New" : "Editing"} Project</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-4">
                                Token Generating
                            </h6>
                            <div className="pl-lg-4">
                                <Row className={"align-items-center"}>
                                    <Col lg="9">
                                        <FormGroup>
                                            <Input
                                                id="input-username"
                                                placeholder="Package Name"
                                                type="text"
                                                size={"sm"}
                                                value={this.state.package}
                                                disabled={!!this.state.token}
                                                onChange={e => this.setState({package:e.target.value})}/>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"3"}>
                                        <FormGroup>
                                            <Button className={"btn-success"} size={"sm"} disabled={!!this.state.token || !this.state.package} onClick={() =>{
                                                // if(!this.state.token)
                                                this.generateToken(30)}
                                            }
                                            >Get Token</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <label
                                                id="input-username"
                                                className="form-control-label"
                                            >{this.state.token && "Generated Token: "+ this.state.token}&nbsp;</label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                }
            </>
        );
    }
    renderAds(){
        return(<>
                {(this.state.showProjectAdd && this.state.token) &&
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="4" className={"mr-0"} xs="7">
                                <h3 className="mb-0">Defining Ads</h3>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="8" xs="5">
                                {/*<Button className={"btn-info"} size={"sm"}  onClick={() => {*/}
                                {/*    this.state.banners.push({admob:"", facebook:""});*/}
                                {/*    this.setState({})*/}
                                {/*}}>New Banner</Button>*/}
                                <Button className={"btn-info"} size={"sm"}  onClick={() => {
                                    this.state.interstitials.push({admob:"", facebook:""});
                                    this.setState({})
                                }}>New Interstitial</Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <h6 className="heading-small text-muted mb-1">
                                Interstitial Ads
                            </h6>
                            <div className="pl-lg-4">
                                {this.state.interstitials.map((el,i) => {
                                    return(
                                        <Row>
                                            <Col lg="5">
                                                <FormGroup>
                                                    <label
                                                        id="input-username"
                                                        className="form-control-label"
                                                    >INTERSTITIAL_{i+1}</label>
                                                    <Input
                                                        id="input-username"
                                                        placeholder="Admob Interstitial Id"
                                                        value={el.admob}
                                                        size={"sm"}
                                                        onChange={e => {
                                                            this.state.interstitials[i].admob = e.target.value;
                                                            this.setState({})
                                                        }}/>
                                                </FormGroup>
                                            </Col>
                                            {/*<Col lg="5">*/}
                                            {/*    <FormGroup>*/}
                                            {/*        <label*/}
                                            {/*            id="input-username"*/}
                                            {/*            className="form-control-label"*/}
                                            {/*            style={{opacity: 0}}*/}
                                            {/*        >banner_0</label>*/}
                                            {/*        <Input*/}
                                            {/*            placeholder="Facebook Interstitial Id"*/}
                                            {/*            size={"sm"}*/}
                                            {/*            value={el.facebook} onChange={e => {*/}
                                            {/*            el.facebook = e.target.value;*/}
                                            {/*            this.setState({})*/}
                                            {/*        }}/>*/}
                                            {/*    </FormGroup>*/}
                                            {/*</Col>*/}
                                            <Col lg={"2"}>
                                                <FormGroup>
                                                    <label
                                                        id="input-username"
                                                        className="form-control-label"
                                                        style={{opacity: 0}}
                                                    >banner_0</label>
                                                    <Button className={"btn-warning"}
                                                            size={"sm"}
                                                            onClick={() => {
                                                                this.state.interstitials.splice(i,1);
                                                                this.setState({})
                                                            }}
                                                    >Delete</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>)
                                })}
                            </div>
                        </Form>
                        {/*<Form>*/}
                        {/*    <h6 className="heading-small text-muted mt-4">*/}
                        {/*        Banner Ads*/}
                        {/*    </h6>*/}
                        {/*    <div className="pl-lg-4">*/}
                        {/*        {this.state.banners.map((el,i) => {*/}
                        {/*            return(*/}
                        {/*                <Row>*/}
                        {/*                    <Col lg="5">*/}
                        {/*                        <FormGroup>*/}
                        {/*                            <label*/}
                        {/*                                id="input-username"*/}
                        {/*                                className="form-control-label"*/}
                        {/*                            >BANNER_{i+1}</label>*/}
                        {/*                            <Input*/}
                        {/*                                size={"sm"}*/}
                        {/*                                id="input-username"*/}
                        {/*                                placeholder="Admob Banner Id"*/}
                        {/*                                value={el.admob}*/}
                        {/*                                onChange={e => {*/}
                        {/*                                    this.state.banners[i].admob = e.target.value;*/}
                        {/*                                    this.setState({})*/}
                        {/*                                }}/>*/}
                        {/*                        </FormGroup>*/}
                        {/*                    </Col>*/}
                        {/*                    <Col lg="5">*/}
                        {/*                        <FormGroup>*/}
                        {/*                            <label*/}
                        {/*                                id="input-username"*/}
                        {/*                                className="form-control-label"*/}
                        {/*                                style={{opacity: 0}}*/}
                        {/*                            >banner_0</label>*/}
                        {/*                            <Input*/}
                        {/*                                size={"sm"}*/}
                        {/*                                placeholder="Facebook Banner Id"*/}
                        {/*                                value={el.facebook} onChange={e => {*/}
                        {/*                                el.facebook = e.target.value;*/}
                        {/*                                this.setState({})*/}
                        {/*                            }}/>*/}
                        {/*                        </FormGroup>*/}
                        {/*                    </Col>*/}
                        {/*                    <Col lg={"2"}>*/}
                        {/*                        <FormGroup>*/}
                        {/*                            <label*/}
                        {/*                                id="input-username"*/}
                        {/*                                className="form-control-label"*/}
                        {/*                                style={{opacity: 0}}*/}
                        {/*                            >banner_0</label>*/}
                        {/*                            <Button className={"btn-warning"}*/}
                        {/*                                    size={"sm"}*/}
                        {/*                                    onClick={() => {*/}
                        {/*                                        this.state.banners.splice(i,1);*/}
                        {/*                                        this.setState({})*/}
                        {/*                                    }}*/}
                        {/*                            >Delete</Button>*/}
                        {/*                        </FormGroup>*/}
                        {/*                    </Col>*/}
                        {/*                </Row>)*/}
                        {/*        })}*/}
                        {/*    </div>*/}
                        {/*</Form>*/}
                        <br/>
                        <Row>
                            <Col lg="9">
                            </Col>
                            <Col lg={"3"} className={"text-md-right"}>
                                <FormGroup>
                                    <Button className={"btn-success"} size={"sm"} disabled={!this.state.token || !this.state.package} onClick={() =>{
                                        // if(!this.state.token)
                                        this.publishProject()}
                                    }
                                    >Publish App</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                }
            </>
        );
    }
    renderControl(){
        return(<>
                {(this.state.showProjectAdd && this.state.token) &&
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="7">
                                <h3 className="mb-0">Admob Limit</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                                <Row lg={"12"} className={"mt-2"}>
                                    <Col lg={"3"} className={"mr-0 pr-0"}>
                                        <FormGroup>
                                            <label className="form-control-label">
                                                Ad Click Limits
                                            </label>
                                            <Input placeholder="Ad Click Limits" type="number"
                                            value={this.state.click}
                                                   onChange={e => this.setState({click:e.target.value})}/>
                                        </FormGroup>
                                    </Col>
                                </Row>


                            <Row lg={"12"} className={"mt-4"}>
                                <Col lg={"3"} className={"mr-0 pr-0"}>
                                    <FormGroup>
                                        <label className="form-control-label">
                                            Visitor Ban Duration (hours)
                                        </label>
                                        <Input placeholder="Visitor Ban Duration" type="number"
                                               value={this.state.banDuration}
                                               onChange={e => this.setState({banDuration:e.target.value})}/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row lg={"12"} className={"mt-4"}>
                                <Col lg={"3"} className={"mr-0 pr-0"}>
                                    <FormGroup>
                                        <label className="form-control-label">
                                            Ad Serving Delay
                                        </label>
                                        <Input placeholder="Ad Serving Delay (ms)" type="number"
                                               value={this.state.delay}
                                               onChange={e => this.setState({delay:e.target.value})}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                }
            </>
        );
    }

    generateToken(n) {
        var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var token = '';
        for(var i = 0; i < n; i++) {
            token += chars[Math.floor(Math.random() * chars.length)];
        }
        this.setState({token:token+""})
        // return token;
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            MobiOptions Ads Projects
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Button className="btn-neutral" color="default" size="sm" onClick={() => {
                                    this.setState({
                                        banners: [],
                                        interstitials:  [],
                                        package:"",
                                        token:null,
                                        isUpdating:false,
                                        showProjectAdd: true,
                                        adsPerc:100,
                                        slider1Value:100,
                                    })}}>
                                    New Project
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }



    publishProject(){
        if(this.state.isUpdating){
            this.updateProject();
            return;
        }
        let ads = [];
        this.state.banners.map((el,i) => {
            if(el.admob !== "null" && el.admob !== "null")
                ads.push({
                    type:'banner',
                    admob_id:el.admob,
                    facebook_id:el.facebook,
                    name:'banner_'+(i+1),

                })
        });
        this.state.interstitials.map((el,i) => {
            if(el.admob !== "null" && el.admob !== "null")
                ads.push({
                    type:'interstitial',
                    admob_id:el.admob,
                    facebook_id:el.facebook,
                    name:'interstitial_'+(i+1),

                })
        });
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).post('limitproject/add', {
            app_id: this.state.package,
            token: this.state.token,
            ads_activated: this.state.adsActivated,
            ads_provider: this.state.adsProvider,
            auto_disable:this.state.autoDisable,
            clicks:this.state.click,
            ban_hours:this.state.banDuration,
            delay_seconds:this.state.delay,
            serve_perc:this.state.slider1Value/100,
            ads
        })
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Project successfully created");
                    this.getProjects();
                }
                else{
                    //error
                    this.props.notify("danger", "Error","There was an error while adding your project")
                }
            }).catch(err => {
            this.props.notify("danger", "Error","There was an error while adding your project")
        })
    }

    updateProject(){
        let ads = [];
        this.state.banners.map((el,i) => {
            ads.push({
                type:'banner',
                admob_id:el.admob,
                facebook_id:el.facebook,
                name:'banner_'+(i+1),

            })
        });
        this.state.interstitials.map((el,i) => {
            ads.push({
                type:'interstitial',
                admob_id:el.admob,
                facebook_id:el.facebook,
                name:'interstitial_'+(i+1),

            })
        });
        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).post('limitproject/update', {
            project_id: this.state.updatingId,
            ads_activated: this.state.adsActivated,
            ads_provider: this.state.adsProvider,
            auto_disable:this.state.autoDisable,
            serve_perc:this.state.slider1Value/100,
            clicks:this.state.click,
            ban_hours:this.state.banDuration,
            delay_seconds:this.state.delay,
            ads
        })
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Project successfully updated");
                    this.getProjects();
                }
                else{
                    //error
                    this.props.notify("danger", "Error","There was an error while updating your project")

                }
            }).catch(err => {
            this.props.notify("danger", "Error","There was an error while updating your project")

        })
    }

    deleteProject(el){

        HttpConfig(window.ads_url,{
            challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
        }).post('limitproject/del', {
            token: el.token,
        })
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Project successfully deleted");
                    this.getProjects();
                }
                else{
                    //error
                    this.props.notify("danger", "Error","There was an error while deleting your project")


                }
            }).catch(err => {
            this.props.notify("danger", "Error","There was an error while deleting your project")
        })
    }

    editProject(el){
        let bnrs = [];
        let intrs = [];
        el.ads.map((el,i) => {
            if(el.type === "interstitial")
                intrs.push({
                    admob:el.admob_id,
                    facebook:el.facebook_id,
                })
            if(el.type === "banner")
                bnrs.push({
                    admob:el.admob_id,
                    facebook:el.facebook_id,
                })
        });
        this.setState({
            isUpdating:true,
            updatingId:el.id,
            package:el.app_id,
            token:el.token,
            banners:bnrs,
            interstitials: intrs,
            showProjectAdd:true,
            adsProvider:el.ads_provider,
            adsActivated:el.ads_activated === 1,
            autoDisable:el.auto_disable === 1,
            adsPerc:el.serve_perc *100,
            slider1Value:el.serve_perc *100,
            click:el.clicks,
            banDuration:el.ban_hours,
            delay:el.delay_seconds,
        })
    }

    toggleStuff(str, bool){
        if(str === "default" && bool)
            this.setState({adsProvider:'default'})
        if(str === "default" && !bool)
            this.setState({adsProvider:'admob'})
        if(str === "rotation" && bool)
            this.setState({adsProvider:'rotation'})
        if(str === "rotation" && !bool)
            this.setState({adsProvider:'default'})
        if(str === "admob" && bool)
            this.setState({adsProvider:'admob'})
        if(str === "admob" && !bool)
            this.setState({adsProvider:'default'})
        if(str === "facebook" && bool)
            this.setState({adsProvider:'facebook'})
        if(str === "facebook" && !bool)
            this.setState({adsProvider:'default'})

    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    renderModal(){
        return(
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Select Ad
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{display:'flex', alignItems:'center', justifyContent:'center', overflow:'hidden'}}>
                    <Select2
                        className="form-control"
                        value={this.state.adsProvider}
                        onSelect={e => this.setState({adsProvider:e.target.value})}
                        options={{
                            placeholder: "Select Ad Network"
                        }}
                        data={[
                            {id:"admob", text:"admob"},
                            {id:"facebook", text:"facebook"},
                        ]}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="primary" type="button"
                            onClick={() => {
                                this.toggleModal("defaultModal");
                            }}>
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            this.toggleModal("defaultModal");
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }
}
