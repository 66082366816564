import React from 'react';
import Cookies from "js-cookie";
import axios from 'axios';

export function JWTRegister(data,validate) {
    let link = window.api_url+'api/register';
    if(!data.accept){
        validate('You must accept our Terms Of Service');
        return;
    }
    axios.post(link, data).then(function (response) {
        if (response.data.status){
            //currentDateTime.setTime(currentDateTime.getTime() + (response.data.expires_in*3600));
            // Cookies.set('access_token', response.data.access_token);
            // Cookies.set('full_name', response.data.full_name);
            // Cookies.set('avatar', response.data.avatar);
            // Cookies.set('user_status', response.data.user_status);
            this.props.history.push({
                pathname: '/auth/login',
                state: { register: true }
            });
        }else {
            let errorsObject = response.data.errors;
            if(!errorsObject){
                validate("an error occurred");
                return;
            }
            if(Object.keys(errorsObject).length > 0){
                let firstError = errorsObject[Object.keys(errorsObject)[0]];
                validate(firstError);
            }else
                validate('There was an error signing you up, please come back later');
            return;
        }

    }).catch(function (error) {
        validate('There was an error signing you up, please come back later');
        return;

        // var errorEmail = error.response.data.errors.email[0];
        // let message = errorEmail;

        // if(typeof errorEmail === 'undefined'){
        //     if(typeof error.response.data.message != 'undefined'){
        //         message = error.response.data.message;
        //     }else{
        //         message = "Please retry later";
        //     }
        // }

        // validate(message);
    });
}


export function JWTLogin(dataLogin,is_ouath=false,validate) {
    let link = window.api_url+'api/login';
    axios.post(link, dataLogin).then(function (response) {
        if(!response.data.status){
            validate(response.data.message);
        }
        if(response.data.status){
            let rem = dataLogin.rememberMe ?  { expires: 365 } : null;
            Cookies.set('access_token', response.data.access_token,rem);
            Cookies.set('full_name', response.data.full_name,rem);
            Cookies.set('avatar', response.data.avatar,rem);
            Cookies.set('user_status', response.data.user_status,rem);
            window.location.href = '/dash';
        }
    }).catch(function (error) {
        validate('There was an error logging you in, please come back later');
    });
}

export function JWTIs_connected(){
    const token = Cookies.get('access_token');
    if(token == null || token == undefined || typeof token == 'undefined' || token == 'undefined') {
        return false;
    }
    return true;
}

export function JWT_hasPlan(){
    //const plan = Cookies.get('plan');
    const user_status = Cookies.get('user_status');
    //plan && plan !== 'null' &&
    if(user_status === 'true') {
        return true;
    }
    return false;
}

export function GetToken(){
    let token = "";
    if(JWTIs_connected()){
        token = Cookies.get('access_token');
    }else{
        window.location ='/auth/login';
    }
    return token;

}


export function RefreshToken() {
    let link = '/api/refresh';
    axios.post(link).then(function (response) {
        let expireTokenIn = response.data.expires_in;
        //currentDateTime.setTime(currentDateTime.getTime() + (response.data.expires_in*3600));
        Cookies.set('user', response.data.access_token,{ expires:expireTokenIn });

    }).catch(function (error) {
        let message = error.response.data.message;
        alert(message);
        window.location.href = '/login';
    });
}

export function addPhone(data, validate) {
    let link = window.api_url+'api/phone/add';
    axios.post(link, data).then(function (response) {
        if(!response.data.status)
            validate(response.data.message);
        else
            validate('Please enter the verification code that was sent to your phone number', true);
    }).catch(function (error) {
        // let message = error.response.data.message;
        validate('There was an error while validating your phone number');
    });
}

export function verifyPin(data, validate) {
    let link = window.api_url+'api/phone/verify';
    axios.post(link, data).then(function (response) {
        if(!response.data.status)
            validate(response.data.message);
        else
            validate(null, true);
    }).catch(function (error) {
        // let message = error.response.data.message;
        validate('There was an error while processing your phone number');
    });
}