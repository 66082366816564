import { createSlice } from "@reduxjs/toolkit";

const membership = localStorage.getItem('membership') || "tediuywskjhqamnb"

const membershipSlice = createSlice({
    name: "membership",
    initialState: membership,
    reducers: {
        updateMembership: (state, action) => {
            localStorage.setItem('membership', action.payload)
            return action.payload
        }
    }
})

export const { updateMembership } = membershipSlice.actions
export default membershipSlice.reducer