/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.scss";
import { HttpConfig } from "tools/Http";
import EpicTable from "components/Mobi/EpicTable";
import Utils from "../../Utils";
import { useDispatch } from "react-redux";


export default ({notify}) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()

  useEffect(() => {
    checkFree();
  }, [0]);

  const checkFree = () => {
    setLoading(true)
    HttpConfig(dispatch).get('check-free')
    .then(res => {
        if (res.data.status) {
          setLoading(false)
        } else {
          setLoading(false)
        }
    })
    .catch(err => {
        console.log("Error")
        setLoading(false)
    });
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            zIndex: 1,
            height: "100%",
            width: "100%",
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,.2)",
          }}
        >
          <ClipLoader color={"white"} loading={true} size={50} />
        </div>
      ) : null}
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  AAB Signer
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-file-signature" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Android AAB Signer
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Card style={{height: "80vh", display: "flex", overflowY: 'auto'}}>
          <Row style={{marginTop: 15, marginBottom: 10}}>
            <Col xs={12} style={{display: "flex", justifyContent: "center"}}>
              <h2>Unlock Success with our AAB Signer: Fast, Secure, and Effortless App Signing!</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{display: "flex", justifyContent: "center"}}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/BvuHDDw68rM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              {/* <img src="https://play-lh.googleusercontent.com/dbhsYpoo488wZXCEFeWg_NuGspb9VEGe6vXb12SAeghJdxAncZpmJM-7qo6bRkU8qsE" /> */}
            </Col>
          </Row>
          <Row style={{display: "flex", marginTop: 20, justifyContent: "center"}}>
            <Col xs={6} style={{display: "flex", justifyContent: "flex-end"}}>
              <a href="https://mobioptions.com/storage/tools/mobioptions-signer.zip" target="_blank">
                <Button color="primary">
                  <i className="fas fa-download fa-lg"></i>&nbsp;&nbsp;Download AAB Signer
                </Button>
              </a>
            </Col>
            <Col xs={6} style={{display: "flex", justifyContent: "flex-start"}}>
              <a href="https://www.oracle.com/java/technologies/javase/jdk14-archive-downloads.html" target="_blank">
                <Button color="primary">
                  <i className="fas fa-download fa-lg"></i>&nbsp;&nbsp;Download JDK
                </Button>
              </a>
            </Col>
          </Row>
          <Row style={{display: "flex", marginTop: 20, marginBottom: 20, justifyContent: "center"}}>
            <Col xs={6}>
              <div className="hAYaRF">
                <span style={{fontSize: "inherit", fontFamily: "monospace", background: "rgb(40, 42, 54)", color: "rgb(248, 248, 242)", borderRadius: 3, display: "flex", lineHeight: 1.42857, overflowX: "auto", whiteSpace: "pre"}}>
                  <code style={{fontSize: "inherit", fontFamily: "monospace", lineHeight: 1.66667, padding: 8}}>
                    <span style={{color: "rgb(248, 248, 242)"}}>
                      ./java -jar .\mobioptions-signer.jar
                    </span>
                  </code>
                </span>
                <span style={{cursor: "pointer"}} className="bFNYQa" onClick={() => {
                  navigator.clipboard.writeText("./java -jar .\\mobioptions-signer.jar");
                  notify("success", "Code Copied Successfully");
                }} >
                  <svg viewBox="0 0 384 512" width="16pt" height="16pt" fill="#f8f8f2">
                    <path d="M280 240H168c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0 96H168c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM112 232c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 48c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.2-16 16-16zm144 408c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V120c0-4.4 3.6-8 8-8h40v32c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16v-32h40c4.4 0 8 3.6 8 8v336z"></path>
                  </svg>
                </span>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );

  // .catch(err => {
  //   this.setState({loading: false})
  //   this.props.notify(
  //     "danger",
  //     "Error",
  //     "There was an error deleting selected app"
  //   );
  //   //err
  // });
}