import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Input from "./Items/Input";
import Radio from "./Items/Radio";
import DatePicker from "./Items/DatePicker";
import cuid from "cuid";

const Question = ({
  questionArray: jsonArray,
  setTheQuestion,
  path,
  marginLeft = 0,
  key
}) => {
  const TYPE = {
    input: Input,
    undefined: Radio,
    date: DatePicker
  };

  return (
    <div>
      {jsonArray.map((el, i) => {
        const Component = TYPE[el.type];
        return (
          <div key={key} style={{ marginLeft }}>
            <Component
              label={el.question}
              onChange={v => {
                setTheQuestion({ path: `${path}[${i}]`, value: v });
              }}
              value={el.answer}
              path={`${path}[${i}]`}
              {...el}
            />
            {el.answer &&
              el.options &&
              el.options.map(
                (option, index) =>
                  option.v === el.answer && (
                    <>
                      {option.subQuestions && (
                        <Question
                          path={`${path}[${i}].options[${index}].subQuestions`}
                          key={cuid()}
                          questionArray={option.subQuestions}
                          setTheQuestion={setTheQuestion}
                          marginLeft={marginLeft + 15}
                        />
                      )}
                      {option.options && (
                        <Question
                          path={`${path}[${i}].options[${index}].options`}
                          key={cuid()}
                          questionArray={option.options}
                          setTheQuestion={setTheQuestion}
                          marginLeft={marginLeft + 15}
                        />
                      )}
                    </>
                  )
              )}
            {el.answer && el.subQuestions && (
              <Question
                path={`${path}[${i}].subQuestions`}
                key={cuid()}
                questionArray={el.subQuestions}
                setTheQuestion={setTheQuestion}
                marginLeft={marginLeft + 50}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Question;
