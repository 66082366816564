/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody, CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,

} from "reactstrap";
import {Link} from "react-router-dom";
import Select2 from "react-select2-wrapper";
import EpicTable from "../../components/Mobi/EpicTable";
import classnames from "classnames";
import SelectRow from "../../components/Mobi/SelectRow";
import {HttpConfig} from "../../tools/Http";
import moment from "moment";
import Utils from "../../Utils";
import Axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from 'react-redux';

class TopCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apps:[],
      userCars:[],
      factGrowingApps:[],
      lastUrl:"",
      publishingDate:"",
      downloads:0,
      tags:[],
      arabicApps:false,
      removedApps:false,
      url:null,
      emptyTable:false,
      ranking:null,
      loading:true,
      activePage:1,
      fastGrowing:false,
    };
  }

  componentDidMount() {
    // this.getData();
    this.getTopCharts();
  }

  setLoading(loading){
    this.setState({loading:loading})
  }

  getApps(){
    return this.state.apps.slice((this.state.activePage-1)*50, (this.state.activePage-1)*50+50);
  }
  
  setSortFilter(filter, bool, desc){
    let apps = this.state.apps;
    Utils.sortByAttr(apps,filter,bool,desc)
    this.setState({apps});
  }

  handleData = (collection, country, category) => {
    this.setLoading(true)
    HttpConfig(this.props.dispatch).post('filter-in-charts', {
        country :country,
        collection :collection,
        category:category
    })
      .then(res => {
        if (res.data.status) {
          this.setState({
            apps: res.data.applications.sort((a, b) => a.rank - b.rank),
            // collection: Object.keys(res.data.collections)[0],
            // countries: res.data.countries,
            // country: Object.keys(res.data.countries)[0],
            // categories: res.data.categories,
            // category: Object.keys(res.data.categories)[0],
            // categoryObject: res.data.categories[Object.keys(res.data.categories)[0]],
            activePage: 1
          },
          //  () => this.formatUrl(this.state)  
          );
          this.setLoading(false)
        } else {
          this.props.notify( 
            "danger", 
            "Not Found", 
            res.data.message
          );
          this.setLoading(false)
        }
      })
      .catch(err => {
        console.log("Error")
        this.setLoading(false)
      });
  };
 

  render() {
    return (
      <>
        {this.state.loading ?
        <div
          style={{
            position:'fixed',
            display:'flex',
            zIndex:1,
            height:'100%',
            width:'100%',
            top:0,
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:'rgba(0,0,0,.2)',
          }}
        >
          <ClipLoader
            color={"white"}
            loading={true}
            size={50}
          />
        </div>
        : null  
        }
        {this.getHeader()}
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="12">
            </Col>
            <Col className="card-wrapper" lg="12">
              <SelectRow
                onData={this.handleData}
                loading={this.state.loading}
              />
            </Col>
            <Col className="card-wrapper" lg="12" id="result-section">
              <Card>
                <EpicTable 
                  apps={this.getApps()}
                  setLoading={this.setLoading.bind(this)}
                  userCats={this.state.userCars}
                  factGrowingApps={this.state.factGrowingApps}
                  notify={this.props.notify}
                  hideDelete={true}
                  setSortFilter={this.setSortFilter.bind(this)}
                  showFastGrowing={this.state.fastGrowing}
                />
                <CardFooter className="py-4">
                  {this.state.apps.length > 0 &&
                  <nav aria-label="...">
                    <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                    >
                      <PaginationItem >
                        <PaginationLink
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              if (this.state.activePage != 1) {
                                document.getElementById("result-section").scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                                })
                                this.setState({activePage:1})
                              }
                            }}
                            tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.activePage-1 >= 1 &&
                      <PaginationItem>
                        <PaginationLink
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              this.setState({activePage:this.state.activePage-1})
                              document.getElementById("result-section").scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              })

                            }}
                        >
                          {this.state.activePage-1}
                        </PaginationLink>
                      </PaginationItem>
                      }
                      <PaginationItem
                          className="active"
                      >
                        <PaginationLink
                            onClick={e => e.preventDefault()}

                        >
                          {this.state.activePage}
                          <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>

                      {this.state.activePage + 1 <= this.state.apps.length/50 &&
                      <PaginationItem>
                        <PaginationLink
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              document.getElementById("result-section").scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              })
                              this.setState({activePage:this.state.activePage+1})
                            }}
                        >
                          {this.state.activePage+1}
                        </PaginationLink>
                      </PaginationItem>
                      }
                      {this.state.activePage + 2 <= this.state.apps.length/50 &&
                      <PaginationItem>
                        <PaginationLink
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              document.getElementById("result-section").scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              })
                              this.setState({activePage:this.state.activePage+2})
                            }}
                        >
                          {this.state.activePage+2}
                        </PaginationLink>
                      </PaginationItem>
                      }

                      <PaginationItem>
                        <PaginationLink
                            href="#pablo"
                            onClick={e => {
                              e.preventDefault();
                              if(this.state.activePage != Math.ceil(this.state.apps.length/50)){
                                document.getElementById("result-section").scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth'
                                })
                                this.setState({activePage:Math.ceil(this.state.apps.length/50)})
                              }
                            }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>

                    </Pagination>
                  </nav>
                  }
                </CardFooter>
              </Card>
            </Col> 
          </Row>
        </Container>
      </>
    )
  }

  getHeader(){
    return(
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">
                  Applications
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-trophy" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Android Top Charts
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  };

  getTableTitle(){
    if(this.state.ranking){
      let str = moment(this.state.ranking.date).format('MMMM Do')+', '+
        this.state.ranking.collection.replace(/_/g,' ').toUpperCase()+' '+
        this.state.ranking.category.replace(/_/g,' ').toUpperCase()+
        ' in '+this.state.ranking.country.toUpperCase()+"  ";
      return str;
    }
    return null;
  };

  getTopCharts() {
    HttpConfig(this.props.dispatch).get("charts-google-play").then( res => {
      this.setState({apps:res.data.applications.sort((a, b) => a.rank_today - b.rank_today)})
      this.setLoading(false)
    }).catch(err => {
      this.props.notify( 
        "danger", 
        "Error", 
        "Error while getting data!" 
      );
      this.setLoading(false)
    });
  }
    
}

export default connect()(TopCharts)