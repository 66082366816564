import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";


import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {resetPassword} from "./JwtToken";
import Submit from "./PrivacyPolicy/Submit";

class ResetPassword extends React.Component {
    state = {
        password: '',
        email: '',
        error: {has_error: false, message: ''},
        isLoading: false,
        rememberMe: false,
        captcha: ''
    };

    setCaptcha = (value) => {
        this.setState({captcha:value})
    }

    render() {
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Reset your Mobioptions password"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Email Confirmation</small>
                                        {this.validationMessage()}
                                    </div>


                                    <Form role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: this.state.focusedEmail
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => this.setState({focusedEmail: true})}
                                                    onBlur={() => this.setState({focusedEmail: false})}
                                                    onChange={(e) => this.setState({email: e.target.value})}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <Submit captcha={this.state.captcha} setCaptcha={this.setCaptcha} />
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button"
                                                disabled={this.state.captcha === ""}
                                                onClick={() => this.handleSubmit()}
                                            >
                                                Reset Password
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    //
    // formValidation(message) {
    //
    //     if(!this.state.showCaptcha){
    //         this.setState({showCaptcha:true});
    //         return;
    //     }
    //     this.setState({error: {has_error: true, message: message}, isLoading:false})
    // }

    handleSubmit() {
        if(this.captcha === "")
            return;
        if (!this.validateEmail())
            return;

        this.setState({isLoading: true});
        let data = {
            email: this.state.email,
        }

        resetPassword(data, this.formValidation.bind(this));
    }

    formValidation(message, success) {
        if (success)
            this.setState({error: {has_success: true, message: message}, isLoading: false})
        else
            this.setState({error: {has_error: true, message: message}, isLoading: false})
    }

    validationMessage() {
        if (this.state.error.has_error) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-red">
                        {this.state.error.message}
                    </div>
                </div>
            )
        } else if (this.state.error.has_success) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-success">
                        {this.state.error.message}
                    </div>
                </div>
            )
        }
        return '';
    }

    validateEmail() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.state.email).toLowerCase())) {
            let error = {message: "Please insert a valid email", has_error: true};
            this.setState({error});
            return false;
        }
        let error = {message: "", has_error: false};
        this.setState({error});
        return true;
    }


}

export default ResetPassword;
