/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.scss";
import { HttpConfig } from "tools/Http";
import EpicTable from "components/Mobi/EpicTable";
import Utils from "../../Utils";
import { useDispatch } from "react-redux";


export default() => {
  const [loading, setLoading] = useState(true);
  const [keywordLoading, setKeywordLoading] = useState(true);
  const [showMore, setShowMore] = useState(10);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [keywordList, setKeywordList] = useState([]);
  const [installs, setInstalls] = useState('');
  const [ratings, setRatings] = useState('');
  const [reskStatus, setReskStatus] = useState('');
  const [age, setAge] = useState('');
  const [histogramFrequency, setHistogramFrequency] = useState([]);
  const [histogramInstalls, setHistogramInstalls] = useState([]);
  const [applications, setApplications] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    getKeywords();
  }, [0]);

  const getRankArrow = (rank) =>{
    if(rank === 0)
      return ("fa-angle-double-up");
    if(rank > 0)
      return ("fa-arrow-up");
    if(rank < 0)
      return ("fa-arrow-down");
    return ("fa-circle");
  }

  const getRankUpdate = (rank) => {
    if(!rank)
      return "default";
    if(rank == 0)
      return ("dark");
    if(rank > 0)
      return ("success");
    if(rank < 0)
      return ("danger");
    return null;
  }

  const getKeywords = () => {
    HttpConfig(dispatch)
      .get("list-keywords")
      .then((res) => {
        if (res.data.status) {
          setKeywordList(res.data.keywords);
          setLoading(false);
          if(res.data.keywords.length > 0)
            getKeyword(res.data.keywords[0].uuid, 0)
        } else {
          //error
        }
      })
      .catch((err) => {
        //err
      });
  }

  const getKeyword = (keyword, index) => {
    setKeywordLoading(true)
    setSelectedIndex(index);
    HttpConfig(dispatch)
      .get("top-frequency-keywords/" + keyword)
      .then((res) => {
        if (res.data.status) {
          setApplications(res.data.applications)
          setAge(res.data.old_age)
          setInstalls(res.data.avg_installs)
          setRatings(res.data.avg_ratings)
          setReskStatus(res.data.resk_status)
          setHistogramFrequency(res.data.histogram_frequency)
          setHistogramInstalls(res.data.histogram_installs)
          setSuggestions(res.data.suggestion)
          setKeywordLoading(false)
        } else {
          //error
        }
      })
      .catch((err) => {
        //err
      });
  }

  const getApps = () => {
    return applications.slice((activePage-1)*50, (activePage-1)*50+50);
  }
  
  const setSortFilter = (filter, bool, desc) => {
    let apps = applications;
    Utils.sortByAttr(applications,filter,bool,desc)
    setApplications(apps);
  }

  const getCard = (v1, v2, v3) => {
    return (
      <Card className="bg-gradient-white">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase text-muted mb-0">
                {v1}&nbsp;
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{v2}&nbsp;</span>
            </div>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Analytics
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-drafting-compass" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Keywords Analyzer
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <Col lg={4} sm={4}>
            <Card style={{height: "80vh", display: "flex"}}>
              <CardHeader>
                <h3 className="mb-0">Keywords</h3>
              </CardHeader>
              <CardBody style={{overflowY: 'auto'}}>
                {/* .sort((a,b) =>  b.installs_today - a.installs_today) */}
                {loading ? 
                  <div style={{alignItems:'center', justifyContent:'center', height:"60vh", display:'flex'}}>
                    <ClipLoader
                      color={"blue"}
                      loading={true}
                      size={50}
                    />
                  </div>
                :
                  keywordList.length > 0 ?
                    <ListGroup style={{ listStyleType: 'none', padding: '0' }}>
                      {keywordList.map((keyword, index) => (
                        <ListGroupItem key={index} action tag="button" active={index==selectedIndex} onClick={() => {getKeyword(keyword.uuid, index);}}>
                          <Row>
                            <Col sm={3} className="m-0 p-0 pl-2">
                              <span className={"m-0 p-0 text-" + getRankUpdate(((keyword.installs_today - keyword.installs_yesterday) / keyword.installs_yesterday * 100).toFixed(2))} style={{fontSize:12}}>
                                <i className={"fa " + getRankArrow(((keyword.installs_today - keyword.installs_yesterday) / keyword.installs_yesterday * 100).toFixed(2)) + " fa-xs"} />
                                &nbsp;&nbsp;
                                {keyword.installs_yesterday == 0 ? "0%" : ((keyword.installs_today - keyword.installs_yesterday) / keyword.installs_yesterday * 100).toFixed(2) + "%"}
                              </span>
                            </Col>
                            {/* <Col title={keyword.installs_today} sm={3}>{((keyword.installs_today - keyword.installs_yesterday) / keyword.installs_yesterday * 100).toFixed(2)}%</Col> */}
                            <Col sm={7} className="m-0 p-0 pl-2">{keyword.name}</Col>
                            <Col sm={2} style={{textAlign:'right'}}><i className="fas fa-chevron-right"></i></Col>
                          </Row>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                    :
                  <p>No available keywords for now</p>
                }
              </CardBody>
            </Card>
          </Col>
          <Col lg={8} sm={8}>
            <Card style={{height: "80vh", display: "flex"}}>
              <CardHeader>
                <h3 className="mb-0">Keyword Detail</h3>
              </CardHeader>
              <CardBody style={{overflowY: 'auto'}}>
                {keywordLoading ? 
                  <div style={{alignItems:'center', justifyContent:'center', height:"60vh", display:'flex'}}>
                    <ClipLoader
                      color={"blue"}
                      loading={true}
                      size={50}
                    />
                  </div>
                :
                  <>
                    <div style={{alignItems:'center', justifyContent:'center', display: 'inline-block'}}>
                      {suggestions.slice(0, showMore).map((el, index) => {
                        return (
                          <Badge color="primary" pill key={index}>
                            {el.keywords}
                          </Badge>
                        )
                      })}
                    </div>
                    <Row style={{alignItems:'center', justifyContent:'center', display: 'flex'}}>
                      <Button className={"btn btn-sm mt-3"} color='primary' onClick={() => setShowMore(showMore == suggestions.length ? 10 : suggestions.length)}><i className={showMore == suggestions.length ? "fas fa-eye-slash fa-lg" : "fas fa-eye fa-lg"}></i>&nbsp;&nbsp;{showMore == suggestions.length ? "Show Less" : "Show More..."}</Button>
                    </Row>
                    <Row style={{marginTop: 20}}>
                      <Col xm={3}>
                        {getCard("Average Installs", Utils.formatRecents(installs).d)}
                      </Col>
                      <Col xm={3}>
                        {/* {getCard("Average Rating", ratings)} */}
                        {getCard("Risk status", reskStatus + "%")}
                      </Col>
                      <Col xm={3}>
                        {getCard("Oldest Appearance", age)}
                      </Col>
                    </Row>
                    <Row>
                      <h4>Competitors</h4>
                      <EpicTable 
                        apps={applications}
                        hideDelete={true}
                        hideRank={true}
                        setSortFilter={setSortFilter}
                      />
                    </Row>
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );

  // .catch(err => {
  //   this.setState({loading: false})
  //   this.props.notify(
  //     "danger",
  //     "Error",
  //     "There was an error deleting selected app"
  //   );
  //   //err
  // });
}