 /**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from "reactstrap";

// import Loader from "react-loader-spinner";
import Stars from "../../components/Mobi/Stars";

import { Link } from "react-router-dom";
import { HttpConfig } from "../../tools/Http";
import SmallStars from "../../components/Mobi/SmallStars";
import VanillaCard from "../../components/Mobi/VanillaCard";
import Cookies from "js-cookie";
import CryptoJSAesJson from "../../tools/cryptojs-aes-format";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import { updateMembership } from "tools/MembershipReducer";
var CryptoJS = require("crypto-js");

let realTimeInterval = null;
class Dash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topApps: [],
      usedCardNumber: 0,
      unusedCardNumber: 0,
      cards: [{"serial": "67676768789097867", "expires": "01/12"}],
      cardsNumber: 0,
      isLoadingCards: true,
      apps: [[], [], []],
      titles: [[], [], []],
      searchQuery: "",
      searchApps: [],
      isSearching: false,
      favApps: null,
      header_0: 0,
      header_1: 0,
      header_2: 0,
      header_3: 0
    };
  }

  componentDidMount() {
  //   this.getHeaderData();
    this.getTopRanking();
  //   // this.getIOSTopRanking();
  //   this.getCards();
  //   this.getFavApps();
  //   realTimeInterval = setInterval(() => {
  //     this.getHeaderData();
  //   }, 8000);
  }

  componentWillUnmount() {
    clearInterval(realTimeInterval);
  }

  getFavApps() {
    HttpConfig()
      .get("published")
      .then(res => {
        if (res.data.status) {
          let arr = res.data.apps;
          if (res.data.apps.length < 6) {
            while (arr.length < 6) arr.push({ dummy: true });
          }
          this.setState({ favApps: arr.slice(0, 6) });
        } else {
          //error
        }
      })
      .catch(err => {
        //err
      });
  }

  getHeaderData() {
    HttpConfig(window.ads_url, {
      challenge: CryptoJS.AES.encrypt(
        JSON.stringify(Cookies.get("id")),
        "sup4rsd3cr3tP4asFu",
        { format: CryptoJSAesJson }
      ).toString()
    })
      .get("adsproject/dashboard")
      .then(res => {
        if (res.status) {
          this.setState({
            header_0: res.data.total_pub,
            header_1: res.data.total_pen,
            header_2: res.data.total_sus,
            header_3: res.data.total_contents
          });
        }
      });
  }

  getTopRanking() {
    HttpConfig(this.props.dispatch)
      .get("top/dashboard")
      .then(res => {
        if (res.data.status) {
          // this.setState({topApps:res.data.apps});
          let apps = [];
          let titles = [];
          res.data.top_collections.map(el => {
            apps.push(el.applications);
            titles.push(el.title);
          });
          this.setState({
            apps,
            titles,
            favApps: res.data.my_apps,
            header_0: res.data.total_pub,
            header_1: res.data.total_pen,
            header_2: res.data.total_sus,
            header_3: res.data.total_contents
          });
        } else {
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  getIOSTopRanking() {
    HttpConfig()
      .get("/top/ios/dashboard")
      .then(res => {
        if (res.data.status) {
          // this.setState({topApps:res.data.apps});
          // let apps = [];
          // let titles = [];
          // res.data.data.map(el => {
          //     apps.push(el.apps);
          //     titles.push(el.name);
          // });
          // // console.table(apps)
          // this.setState({apps,titles});
        } else {
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  getCards() {
    HttpConfig()
      .get("cards")
      .then(res => {
        if (res.data.status) {
          let used = 0;
          let unused = 0;
          let cards = [];
          res.data.cards.map(el => {
            cards.push(el);
            if (el.used) used++;
            else unused++;
          });
          while (cards.length < 3) {
            cards.push({ blocked: true });
          }
          if (cards.length > 3) cards = cards.slice(0, 3);
          this.setState({
            usedCardNumber: used,
            unusedCardNumber: unused,
            cards: cards,
            cardsNumber: res.data.cards.length,
            isLoadingCards: false
          });
        } else {
          //error
        }
      })
      .catch(err => {});
  }

  render() {
    return (
      <>
        {this.getHeader()}
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="12">
              {/* {this.renderSearch()} */}
              {/* {this.renderCards()}  */}
              <Row>
                {this.state.titles.map((el, i) => {
                  return this.renderTopRanks(i);
                })}
              </Row>
              {this.renderFavApps()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  renderTopRanks(i) {
    let title = this.state.titles[i];
    let apps = this.state.apps[i];
    return (
      <Col xs={'12'} sm={'12'} lg={"4"} md={"12"} key={"da" + i}>
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="..."
                  className="img-fluid mr-1"
                  style={{ width: 20, height: 20 }}
                  src={"/img/play-store.png"}
                />
                &nbsp;
                <h3 className="mb-0">{this.titleCase(title)}</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Col lg={12}>
              {apps &&
                apps.length !== 0 ?
                apps.map((value, i) => {
                  return this.getApp(value, i);
                }):
                <div className="justify-content-center d-flex">
                <ClipLoader
                  color={"blue"}
                  loading={true}
                  size={50}
                />
                </div>
                }
            </Col>
          </CardBody>
        </Card>
      </Col>
    );
  }

  titleCase(str) {
    if (str.toLowerCase) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    }
    return str;
  }

  getApp(app, i) {
    return (
      <Row className="align-items-center" key={"appd"+i}>
        <Col className="col-auto">
          <a
            className="avatar avatar-lg rounded"
            href={"details?id=" + app.app_id}
            target={"_blank"}
          >
            <img alt="..." src={app.icon} />
          </a>
        </Col>
        <div className="col ml--2">
          <h4
            className="mb-0 mt-2"
            style={{
              lineHeight: "1.5em",
              maxHeight: "1.5em",
              display: "block",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
              overflow: "hidden"
            }}
          >
            <a href={"details?id=" + app.app_id} target={"_blank"}>
              {app.name}
            </a>
          </h4>
          {app.developer &&
          <p className="text-sm text-muted">
            By &nbsp;
            <a
              href={"android-dev?id=" + app.developer}
              target={"_blank"}
            >
            <b>{app.developer.slice(0,26)}</b>
            </a>
          </p>
          }
        </div>
        <Col className="col-auto">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end"
            }}
          >
            <SmallStars score={app.rating} />
            <h5>
              {app.recent}&nbsp;
              <i className={"fa fa-user"} style={{ fontSize: 10 }}></i>
            </h5>
          </div>
        </Col>
      </Row>
    );
  }

  renderFavApps() {
    if (this.state.favApps && this.state.favApps.length > 0)
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col lg="6" xs="7">
              <h3 className="mb-0">My Published Apps</h3>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
              <Link className="btn btn-sm btn-neutral" to={"list"}>
                see more
              </Link>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className={"justify-content-center"}>
            {this.state.favApps.map((el, i) => {
              return el.status_application === "published" ? (
                this.favApp(el, i)
              ) : el.status_application === "pending" ? (
                this.unpubFavApp(el, i, "info")
              ) : (
                this.unpubFavApp(el, i, "danger")
              );
            })}
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderSearch() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col lg="6" xs="7">
              <h3 className="mb-0">Advanced Search</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col sm="11">
                <Input
                  id="input-username"
                  placeholder="Enter a Keyword.."
                  type="text"
                  value={this.state.searchQuery}
                  onChange={e => this.setState({ searchQuery: e.target.value })}
                />
              </Col>
              <Col sm={"1"}>
                <FormGroup>
                  <Button
                    className={"btn-success"}
                    onClick={() => {
                      // if(!this.state.token)
                    }}
                  >
                    Search
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  }

  renderCards() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col lg="6" xs="7">
              <h3 className="mb-0">My Credit Cards</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {this.state.cards.map((el, i) => {
              return <VanillaCard card={el} i={i} />;
            })}
          </Row>
        </CardBody>
      </Card>
    );
  }

  favApp(app, i) {
    return (
      <Col xl={2} sm={6} key={"fav" + i}>
        <Row>
          <Col className="col-auto">
            <a
              className="avatar avatar-lg rounded"
              href={"details?id=" + app.app_id}
              target={"_blank"}
            >
              <img alt="..." src={app.icon} />
            </a>
          </Col>
          <div
            className="col ml--2 pr-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <h5 className="mb-0">
              <a href={"details?id=" + app.app_id} target={"_blank"}>
                {app.name}
              </a>
            </h5>
            <SmallStars score={app.rating} />
            <h6><i className={"fa fa-user"} style={{fontSize:9}}></i>{app.installs}</h6>
          </div>
        </Row>
      </Col>
    );
  }

  unpubFavApp(app, i, col) {
    return (
      <Col xl={2} sm={6} className={"pr-0"} key={"unp" + i}>
        <Row>
          <Col className="col-auto">
            <a
              className="avatar avatar-lg rounded"
              href={"details?id=" + app.app_id}
              target={"_blank"}
            >
              <img alt="..." src={app.icon} />
            </a>
          </Col>
          <div
            className="col ml--2 pr-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <h5 className="mb-0">
              <a href={"details?id=" + app.app_id} target={"_blank"}>
                {/* {app.name.substring(0, 15)} */}
              </a>
            </h5>
            <h6 className={"text-" + col}>
              {app.status}
              <i style={{ fontSize: 9 }}></i>
            </h6>
          </div>
        </Row>
      </Col>
    );
  }

  getHeader() {
    return (
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Dashboard</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark">
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-th-large" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      My Dashboard
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
            <Row>
               {this.renderHeaderCard("PUBLISHED APPS", this.state.header_0, "fa fa-check", "green")}
               {this.renderHeaderCard("PENDING APPS", this.state.header_1, "fa fa-clock", "warning")}
               {this.renderHeaderCard("SUSPENDED APPS", this.state.header_2, "fa fa-ban", "red")}
               {this.renderHeaderCard("DESCRIPTIONS", this.state.header_3, "fa fa-cogs", "info")}
            </Row>
          </div>
        </Container>
      </div>
    );
  }

  renderHeaderCard(title, value, icon, color) {
    return (
      <Col md="6" xl="3" onClick={() => this.getTopRanking()}>
        <Card className="card-stats">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  {title}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{value}</span>
              </div>
              <Col className="col-auto">
                <div
                  className={
                    "icon icon-shape text-white rounded-circle shadow bg-gradient-" +
                    color
                  }
                >
                  <i className={icon} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
export default connect()(Dash)