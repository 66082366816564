/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem,
    Button,
    Card, CardHeader,
    Col,
    Container, Row,

} from "reactstrap";

const img1 = require("assets/img/theme/img-1-1000x600.jpg");
export default class AdsTutorial2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };


    }

    componentDidMount() {
        this.getData();
    }

    getData(){

    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row >
                        {this.getCards("Tutorial for a new project",
                            'Start serving ads immediately quickly with a  ',img1, "Create Icon", () => this.setState({img: 'img/icon-p.jpg'}, () => this.refreshEditor()))}
                        {this.getCards("Tutorial for an existing project",img1, "Create ScreenShot",() => this.setState({img: 'img/screenshots-p.jpg'}, () => this.refreshEditor()))}
                    </Row>
                </Container>
            </>
        )
    }
    getCards(title,text,image,button,click){
        return(
            <Col xl="6" md={"12"}>
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col>
                                <h3 className="mb-0">{title}</h3>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Button className="btn-neutral" color="default" size="sm" onClick={click}>
                                    {button}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <div style={{width:'100%', height:300, textAlign:"center", display:"flex",
                        alignItems:'center',justifyContent:'center'}}>
                        {text}
                    </div>
                </Card>
            </Col>
        )
    }
    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Graphics Creator
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }



}
