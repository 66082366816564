/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Container

} from "reactstrap";
// import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";

export default class Error404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };


    }

    componentDidMount() {
        this.getData();
    }

    getData(){

    }

    render() {
        return (
            <>
                <Container className="mt--6" fluid style={{backgroundColor:"#F3F4F8"}}>
                    <div style={{width:'100%' ,marginTop:50, paddingBottom:15, marginBottom:10, display:'flex', flexDirection:"column", alignItems:'center', justifyContent:'center'}}>
                        <img
                            src={require("assets/img/error404.jpg")}
                            // src="img/error404.jpg"
                            style={{height:'80vh'}} className={"img-fluid"} alt=""/>
                        <br/>
                        <Link to={"/dash"} className={'btn btn-primary'} style={{textDecoration:'none'}}>
                            Go Back Home
                        </Link>
                    </div>
                </Container>
            </>
        )
    }



}
