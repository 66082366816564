import React, { useEffect, useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import cuid from "cuid";

const BOOLEAN_OPTIONS = [
  {
    label: "No",
    v: false
  },
  {
    label: "Yes",
    v: true
  }
];

const Component = ({
  label,
  options = BOOLEAN_OPTIONS,
  onChange,
  value,
  key,
  path
}) => {
  const style = {
    display: "flex",
    flexDirection: "row"
  };
  const l = label;
  return (
    <FormGroup key={key} id={key}>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        {label}
      </label>
      <div className="">
        <div
          style={style}
          onChange={event => {
            onChange(event.target.value);
          }}
        >
          {options.map(({ label, v, denominator }, i) => (
            <div
              style={{ style, width: 150 }}
              key={i}
              className={"custom-control custom-radio mb-3"}
            >
              <input
                className="custom-control-input"
                id={`${path}-${l}-${v}`}
                name={`${path}-${l}-${v}`}
                value={`${v}`}
                type="radio"
                checked={`${v}` === `${value}`}
              />
              <label
                className="custom-control-label"
                htmlFor={`${path}-${l}-${v}`}
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </FormGroup>
  );
};

export default Component;
