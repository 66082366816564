/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
export default class GeneralTermofUse extends React.Component {

    render() {
        return (
<div className="container-fluid  bg-gradient-white pt-4">
                <h1>TERMS AND CONDITIONS</h1>

                <p>Welcome to MobiOptions.</p>

                <h2>1-Introduction</h2>
                <p>These General Terms of Service govern your use of our website located at
               <a href="https://www.mobioptions.com/"> www.mobioptions.com</a></p>

                <p>Your agreement with us includes these Terms and our Privacy Policy.
                 You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>

                <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service,
                 but please let us know by emailing at <b>contact@mobioptions.com</b> so we can try to find a solution.
                These Terms apply to all visitors, users and others who wish to access or use our Service.
                </p>

                <h2>2-Communications</h2>

                <p>By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send.
                 However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at contact@mobioptions.com.</p>

                <h2>3-Purchases</h2>

                <p>If you wish to purchase any product or service made available, you may be asked to supply certain information relevant to your Purchase including but not limited to,
                 your credit or debit card number, the expiration date of your card, your billing address.<br />
                 You represent and warrant that:<br /> (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase;
                 <br /> (ii) the information you supply to us is true, correct and complete.<br />
                 (iii) By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
                 We reserve the right to refuse or cancel your order at any time for reasons including but not limited to:
                 product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                 We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>

                <h2>4-Subscriptions</h2>

                <p>Some parts of Service are billed on a subscription basis.
                You will be billed in advance on a recurring and periodic basis. <br />
                Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.
                At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Mobioptions cancels it.
                You may cancel your Subscription renewal either through your online account management page or by contacting contact@mobioptions.com customer support team.
                A valid payment method is required to process the payment for your subscription. <br />
                You shall provide Mobioptions with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information.<br />
                By submitting such payment information, you automatically authorize Mobioptions to charge all Subscription fees incurred through your account to any such payment instruments.<br />
                Should automatic billing fail to occur for any reason, Mobioptions reserves the right to terminate your access to the Service with immediate effect. </p>


                <h2>5-Content</h2>

               <p> Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material.
               You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.<br/>
               By posting Content on or through Service, You represent and warrant that: <br/><br/>
               (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms. <br/>
               (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.
               We reserve the right to terminate the account of anyone found to be infringing on a copyright.<br/>
               You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights.
               We take no responsibility and assume no liability for Content you or any third party posts on or through Service. <br/><br/>
               However, by posting Content using Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through Service.
               You agree that this license includes the right for us to make your Content available to other users of Service, who may also use your Content subject to these Terms.
               Mobioptions has the right but not the obligation to monitor and edit all Content provided by users.</p>


                <h2>6-Prohibited Uses</h2>

               <p>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>
            <p>a. In any way that violates any applicable national or international law or regulation.</p>
            <p>b. For the purpose of exploiting, harming, or attempting to exploit or harm minors
            in any way by exposing them to inappropriate content or otherwise.</p>
            <p>c. To transmit, or procure the sending of, any advertising or promotional material,
             including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
            <p>d. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.</p>
            <p>e. In any way that infringes upon the rights of others, or in any way is illegal,
             threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
            <p>f. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service,
             or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.</p>
            <p>Additionally, you agree not to:</p>
            <p>a. Use Service in any manner that could disable, overburden, damage,
             or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>
            <p>b. Use any robot, spider, or other automatic device, process,
             or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
            <p>c. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
            <p>d. Use any device, software, or routine that interferes with the proper working of Service.</p>
            <p>e. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
            <p>f. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service,
             the server on which Service is stored, or any server, computer, or database connected to Service.</p>
            <p>g. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
            <p>h. Take any action that may damage or falsify Company rating.</p>
            <p>i. Otherwise attempt to interfere with the proper working of Service.</p>


                <h2>7-Accounts</h2>

                <p>When you create an account with us, you guarantee that your under the age of 13 and the information you provide us is accurate, complete, and current at all times.
                Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service. <br />
                You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. <br />
                You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. <br />
                You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. <br />
                You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization.
                You may not use as a username any name that is offensive, vulgar or obscene. <br />
                We reserve the right to refuse service, terminate accounts, remove or edit content.</p>


                <h2>8-Error Reporting and Feedback</h2>
                <p>You may provide us either directly at contact@mobioptions.com with information and feedback concerning errors, suggestions for improvements,
                 ideas, problems, complaints, and other matters related to our Service. <br />
                 You acknowledge and agree that: <br />
                 (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; <br />
                 (ii) Company may have development ideas similar to the Feedback; <br />
                 (iii) Feedback does not contain confidential information or proprietary information from you; <br />
                 (iv) Company is not under any obligation of confidentiality with respect to the Feedback. <br />
                 In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive,
                 transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize)
                 Feedback in any manner and for any purpose.</p>

                <h2>9-Links To Other Web Sites</h2>

                <p>Our Service may contain links to third party web sites or services that are not owned or controlled by Mobioptions.</p>
                <p>Mobioptions has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services.
                 We do not warrant the offerings of any of these entities/individuals or their websites.</p>
                 <p>YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY,
                 FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                 GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
                <p>WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>


                <h2>10-Termination</h2>

                <p>We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion,
                    for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
                <p>If you wish to terminate your account, you may simply discontinue using Service.</p>
                <p>All provisions of Terms which by their nature should survive termination shall survive termination, including,
                    without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

                <h2>11-Refund Policy</h2>

                <p>Once your subscription stars you can't get a refund for any payments you have already made, If you don't want to subscribe any more, cancel your subscription from your profile page.</p>
                <p>You will still have access to the subscription until the end of the period you have paid for.</p>


                <h2>12-Governing Law</h2>

                <p>These Terms shall be governed and construed in accordance with the laws of Morocco, which governing law applies to agreement without regard to its conflict of law provisions.</p>
                <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
                If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.
                These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>


                <h2>13-Changes To Service</h2>

                <p>We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice.
                 We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users</p>

                <h2>14-Amendments To Terms</h2>

                <p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
                <p>Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. <br />
                You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
</p>
                <h2>15-Acknowledgement</h2>

                <p>BY USING SERVICE OR OTHER SERVICES PROVIDED BY US,
                 YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>

                <h2>16-Contact Us</h2>

                <p>Please send your feedback, comments, requests for technical support by email: <b>contact@mobioptions.com</b></p>
                <br/>
            </div>
 )
    }
}
