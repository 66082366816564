/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  Row,
  UncontrolledAlert
} from "reactstrap";
// import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import { HttpConfig } from "../../tools/Http";
import Stars from "../../components/Mobi/Stars";
import Cookies from "js-cookie";
import QRCode from "react-qr-code";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.scss";
import { connect } from "react-redux";

class PublishedApps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publishedApps: [],
      pendingApps: [],
      suspendedApps: [],
      searchQuery: "",
      searchingForApp: false,
      updating: false,
      defaultModal: false,
      appBeatModal: Cookies.get("appBeatModal") == 1 || Cookies.get("appBeatModal") == null,
      loading: true,
      showWarmApps: false
    };
    this.handleFocus = this.handleFocus.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleFocus);
  }
  handleFocus(e) {
    navigator.clipboard
      .readText()
      .then(searchQuery => {
        this.setState({ searchQuery });
      })
      .catch(err => {
        console.error("Failed to read clipboard contents: ", err);
      });
  }

  componentDidMount() {
    // setInterval(() => {
    //   this.getData();
    // }, 60000);
    this.getData();
    this.getToken();
    window.addEventListener("focus", this.handleFocus);
    window.addEventListener("focus", function(event) {});
  }

  getToken() {
    HttpConfig(this.props.dispatch)
      .get("my-token")
      .then(res => {
        this.setState({ token: res.data.token });
      })
      .catch(err => {});
  }

  getData(url = "lists-my-apps") {
    this.setState({ loading: true });
    HttpConfig(this.props.dispatch)
      .get(url)
      .then(res => {
        if (res.data.status) {
          let publishedApps = [];
          let pendingApps = [];
          let suspendedApps = [];

          res.data.list.map(el => {
            if (el.status_application === "published") {
              publishedApps.push(el);
            }
            else if (el.status_application === "removed") {
              suspendedApps.push(el);
            }
            else if (el.status_application === "pending"){
              pendingApps.push(el);
            }
          });
          this.setState({
            publishedApps: publishedApps,
            pendingApps: pendingApps,
            suspendedApps: suspendedApps,
            updating: false,
            loading: false
          });
        } else {
          //error
          // toast.error('There was an error loading your apps');
          this.props.notify(
            "danger",
            "Error",
            "There was an error loading your apps"
          );
          this.setState({ loading: false });
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.notify(
          "danger",
          "Error",
          "There was an error loading your apps"
        );
      });
  }

  render() {
    return (
      <>
        {this.state.loading ?
          <div
            style={{
              position:'fixed',
              display:'flex',
              zIndex:1,
              height:'100%',
              width:'100%',
              top:0,
              alignItems:'center',
              justifyContent:'center',
              backgroundColor:'rgba(0,0,0,.2)',
            }}
          >
            <ClipLoader
              color={"white"}
              loading={true}
              size={50}
            />
          </div>
            : null 
          }
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Applications
                  </h6>
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <i className="fas fa-rocket" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        My Published Apps
                      </a>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12">
                  <Button
                    className="btn-neutral"
                    color="default"
                    title="Android Companion"
                    size="sm"
                    onClick={() => {
                      this.setState({ appBeatModal: true });
                    }}
                  >
                    <i className="fas fa-mobile-alt fa-lg"></i>
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    title="Refresh"
                    size="sm"
                    onClick={() => {
                      this.props.notify(
                        "default",
                        "Refreshing",
                        "Please wait while we update your apps, this may take a few moments..."
                      );
                      this.getData();
                    }}
                  >
                    <i className="fas fa-sync-alt fa-lg"></i>
                  </Button>
                  <Button
                    className="btn-neutral"
                    color="default"
                    title="Add New"
                    size="sm"
                    onClick={() => this.setState({ defaultModal: true })}
                  >
                    <i className="fas fa-plus fa-lg"></i>
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          {this.getBody()}
          {this.renderModal()}
          {this.renderAppBeatModal()}
        </Container>
      </>
    );
  }

  getBody() {
    return (
      <Row className="justify-content-center">
        <Col className="card-wrapper" lg="12">
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col lg="6" xs="7">
                  <h3 className="mb-0 row">
                    Published Apps {this.state.showWarmApps && "(warm)"}
                    &nbsp;
                    {/* {this.state.loading && (
                      <Loader
                        type="Puff"
                        color="#5E72E4"
                        height={15}
                        width={15}
                      />
                    )} */}
                    
                  </h3>
                </Col>
                <Col className="text-md-right" lg="6" xs="12">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      this.setState({ showWarmApps: !this.state.showWarmApps });
                    }}
                  >
                    <i className="fas fa-eye fa-lg"></i>&nbsp;&nbsp;
                    {this.state.showWarmApps ? "Show apps" : "Show warm apps"}
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                {this.state.publishedApps.filter(el => this.state.showWarmApps ? el.warm === 1 : el.warm === 0 ).length > 0 ?
                  this.state.publishedApps
                  .filter(el =>
                    this.state.showWarmApps ? el.warm === 1 : el.warm === 0
                  )
                  .map((el, i) => {
                    return this.getApp(el);
                  })
                :
                (this.state.loading ? null : <p className='text-muet text-center mt-3'>No published apps yet!</p>)}
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-info row">
                Pending Apps {this.state.showWarmApps && "(warm)"}
                &nbsp;
                {/* {this.state.loading && (
                  <Loader type="Puff" color="#5E72E4" height={15} width={15} />
                )} */}
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                {this.state.pendingApps.filter(el => this.state.showWarmApps ? el.warm === 1 : el.warm === 0 ).length > 0 ?
                this.state.pendingApps
                  .filter(el =>
                    this.state.showWarmApps ? el.warm === 1 : el.warm === 0
                  )
                  .map((el, i) => {
                    return this.getPendingApp(el);
                  })
                :(this.state.loading ? null : <p className='text-muet text-center mt-3'>No pending apps yet!</p>)}
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h3 className="mb-0 text-danger row">
                Suspended Apps {this.state.showWarmApps && "(warm)"}
                &nbsp;
                {/* {this.state.loading && (
                  <Loader type="Puff" color="#5E72E4" height={15} width={15} />
                )} */}
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                {this.state.suspendedApps.filter(el => this.state.showWarmApps ? el.warm === 1 : el.warm === 0 ).length > 0 ?
                this.state.suspendedApps
                  .filter(el =>
                    this.state.showWarmApps ? el.warm === 1 : el.warm === 0
                  )
                  .map((el, i) => {
                    return this.getApp(el, i);
                  })
                : (this.state.loading ? null : <p className='text-muet text-center mt-3'>No suspended apps yet!</p>)}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  getApp(el, i) {
    return (
      <Col sm={6} md={3} xl={2} key={"published" + i}>
        <Card>
          <CardBody>
            <a
              href={
                "https://play.google.com/store/apps/details?id=" + el.app_id
              }
              target={"_blank"}
              className="pointer-cursor"
            >
              <img
                alt="..."
                className="rounded img-center img-fluid  shadow-lg--hover"
                src={el.icon}
                style={{ width: "80px", boxShadow: "0 0 2rem 0 #2DCE8927 " }}
              />
            </a>
            <div
              style={{
                top: 15,
                right: 15,
                position: "absolute",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -6
              }}
            >
              <a
                href=""
                onClick={e => {
                  e.preventDefault();
                  this.deleteApp(el.uuid);
                }}
              >
                <i
                  className="fas fa-times text-muted"
                />
              </a>
            </div>
            <div className="pt-4 text-center">
              <h5 className="h3 title">
                <span
                  className="d-block mb-1"
                  style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    maxHeight: "3.0em",
                    lineHeight: "1.5em",
                    textAlign: "center"
                  }}
                >
                  {el.name}
                </span>
                <small className="h4 font-weight-light text-muted">
                  {el.age_app}
                </small>
              </h5>
              {this.Divider(0)}
              <div className="my-2">
                <small className="h4 font-weight-bold text-muted">
                  {el.rating ? el.rating : "No Reviews"}
                </small>
                <Stars score={el.rating ? el.rating : 0} />
              </div>
              
              <h5>
                &nbsp;{el.recent ? el.recent : "0"}&nbsp;
                <i className={"fa fa-user"} style={{ fontSize: 10 }} />
              </h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  getPendingApp(el) {
    return (
      <Col sm={6} md={3} xl={2}>
        <Card>
          <CardBody>
            <a href="#" onClick={e => e.preventDefault()}>
              <img
                alt="..."
                className="rounded img-center img-fluid  shadow-lg--hover"
                src={require("assets/img/android.png")}
                style={{ width: "80px", boxShadow: "0 0 2rem 0 #2946fc27 " }}
              />
            </a>
            <div
              style={{
                top: 15,
                right: 15,
                position: "absolute",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -6
              }}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.deleteApp(el.uuid);
                }}
              >
                <i
                  className="fas fa-times text-muted"
                />
              </a>
            </div>
            <div className="pt-4 text-center">
              <h5 className="h3 title">
                <span
                  className="d-block mb-1"
                  style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    maxHeight: "1.5em",
                    lineHeight: "1.5em",
                    textAlign: "center"
                  }}
                >
                  {el.app_id} <br />
                  <br />
                </span>
              </h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  getSuspendedApp(el) {
    return (
      <Col sm={6} md={3} xl={2}>
        <Card>
          <CardBody>
            <a href="#" onClick={e => e.preventDefault()}>
              <img
                alt="..."
                className="rounded img-center img-fluid  shadow-lg--hover"
                src={require("assets/img/android.png")}
                style={{ width: "80px", boxShadow: "0 0 2rem 0 #f5365c27 " }}
              />
            </a>
            <div
              style={{
                top: 15,
                right: 15,
                position: "absolute",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -6
              }}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.deleteApp(el.uuid);
                }}
              >
                <i
                  className="fas fa-times text-muted"
                />
              </a>
            </div>
            <div className="pt-4 text-center">
              <h5 className="h3 title">
                <span
                  className="d-block mb-1"
                  style={{
                    display: "block",
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    maxHeight: "1.5em",
                    lineHeight: "1.5em",
                    textAlign: "center"
                  }}
                >
                  {el.app_id} <br />
                  <br />
                </span>
              </h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  deleteApp(app_id) {
    this.setState({loading: true})
    HttpConfig()
      .delete("delete-my-app/" + app_id)
      .then(res => {
        if (res.data.status) {
          this.getData();
          this.props.notify("success", "Removed", "App Removed Successfully");
        } else {
          this.setState({loading: false})
          this.props.notify(
            "danger",
            "Error",
            "There was an error deleting selected app"
          );
        }
      })
      .catch(err => {
        this.setState({loading: false})
        this.props.notify(
          "danger",
          "Error",
          "There was an error deleting selected app"
        );
        //err
      });
  }

  renderAppBeatModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={this.state.appBeatModal}
        toggle={() => this.toggleModal("appBeatModal")}
        contentClassName={"m-0 p-0"}
      >
        <div className="modal-body p-0 m-0">
          <Card className={"mb-0"}>
            {/* <CardImg alt="..." src={require("assets/img/appbeat.jpg")} top /> */}
            <CardBody>
              <CardTitle className="h2 mb-0">MobiOptions App</CardTitle>
              <div style={{ height: "auto", paddingTop:20, display:"flex", justifyContent:"center", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                { false ?
                  <ClipLoader
                    color={"blue"}
                    loading={true}
                    size={50}
                  />
                  : 
                    <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={this.state.token || ''}
                    viewBox={`0 0 256 256`}
                    />
                }
              </div>
              <CardText className="mt-4">
                With MobiOptions On Android you can easily track your published
                apps on real time, and get notified on the fly once your apps
                get accepted or if they get suspended
              </CardText>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <a
                  className="px-0"
                  color="link"
                  href={window.url_apk_my_published}
                  target={"_blank"}
                  onClick={e => this.toggleModal("appBeatModal")}
                >
                  Download MobiOptions
                </a>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    this.toggleModal("appBeatModal");
                    Cookies.set("appBeatModal", 0, { expires: 365 });
                  }}
                >
                  Never Show Again
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }

  renderModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={this.state.defaultModal}
        toggle={() => this.setState({ defaultModal: !this.state.defaultModal })}
      >
        <div className="modal-header">
          <Col>
            <h6 className="modal-title" id="modal-title-notification">
              Add Application
            </h6>
            <FormGroup className={"pt-4"}>
              <label className="form-control-label">
                Application Package Name
              </label>
              <Input
                placeholder="Ex: com.netflix.media"
                type="text"
                value={this.state.searchQuery}
                onChange={e => this.setState({ searchQuery: e.target.value })}
              />
            </FormGroup>
            <div style={{ display: "flex", alignItems: "space-between" }}>
              <Button
                color="success"
                size="sm"
                type="button"
                onClick={() => this.addApp()}
              >
                Add{`${this.state.showWarmApps ? " warm" : ""}`} App
              </Button>
              <Button
                className="ml-auto"
                color="link"
                size="sm"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  this.setState({ defaultModal: !this.state.defaultModal })
                }
              >
                Close
              </Button>
            </div>
          </Col>
        </div>
      </Modal>
    );
  }

  addApp() {
    this.setState({ searchingForApp: true, defaultModal: false });
    const packageName = this.state.searchQuery.includes("id=")
      ? this.state.searchQuery.split("id=")[1]
      : this.state.searchQuery;
    HttpConfig(this.props.dispatch)
      .post("save-my-app", {
        app_id: packageName,
        warm: this.state.showWarmApps
      })
      .then(res => {
        this.setState({ searchingForApp: false });
        if (res.data.status) {
          this.getData();
          this.setState({searchQuery:''})
          this.props.notify("success", "Success", "App successfully added");
        } else {
          //error
          if (res.data.code === 501)
            this.props.notify(
              "danger",
              "Error",
              "This app is already in your list!"
            );
          if (res.data.code === 500)
            this.props.notify(
              "danger",
              "Error",
              "There was an error adding your app"
            );
        }
      })
      .catch(err => {
        this.setState({ searchingForApp: false });
        this.getData();
        // this.props.notify(
        //   "danger",
        //   "Error",
        //   "There was an error adding your app"
        // );
        //err
      });
  }

  Divider(margin) {
    return (
      <div
        style={{
          height: 1,
          width: "100%",
          marginTop: margin,
          backgroundColor: "#EFEFEF"
        }}
      />
    );
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
}

export default connect()(PublishedApps)