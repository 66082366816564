/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardBody, CardHeader, Col,
    Container, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {HttpConfig} from "../../tools/Http";
import Cookies from "js-cookie";
import Avatar from "react-avatar-edit";

export default class Profile extends React.Component {
    constructor(props) {
        super(props);
        const src = null
        this.state = {
            me: {
                name:'',
                email:'',
                phone:'',
                password:'.....',
            },
            associates: [],
            editables: [],
            preview:null,
            defaultModal:null,
            src
        };
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        HttpConfig().get("get-user")
            .then(res => {
                if (res.data.status) {
                    // this.setState({me:res.data.user});
                    //associates:res.data.associated
                    let t = res.data.plan.accounts;
                    if(t === 1)
                        t = 0;
                    let arr = [];
                    let editables = [];
                    if(t)
                        for (let i = 0; i < t; i++) {
                            if(i < res.data.associated.length) {
                                arr.push(res.data.associated[i]);
                                editables.push(true);
                            }
                            else{
                                arr.push({});
                                editables.push(false);
                            }
                        }
                    res.data.user.password = ".....";
                    this.setState({me:res.data.user,associates:arr,editables});

                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="align-items-center">
                        <Col lg={3}></Col>
                        {this.renderProfile()}
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={3}></Col>
                        {this.renderAssosicated()}
                    </Row>
                    {this.renderUploadImageModal()}
                </Container>
            </>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Home
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-user" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Profile
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Link to={'settings'} className=" btn btn-sm btn-neutral" color="default" size="sm">
                                    More Settings
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    renderProfile(){
        return(
            <Col lg={6} className={""}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="12">
                                <h3 className="mb-0">My Profile</h3>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames("input-group-merge", {
                                                focused: this.state.yourName
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-user" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Your name"
                                                type="text"
                                                onFocus={e => this.setState({ yourName: true })}
                                                onBlur={e => this.setState({ yourName: false })}
                                                onChange={e => {
                                                    let o = this.state.me;
                                                    o.name = e.target.value;
                                                    this.setState({me:o});
                                                }}
                                                value={this.state.me.name}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames("input-group-merge", {
                                                focused: this.state.phone
                                            })}
                                        >
                                            <Input
                                                placeholder="Phone Number"
                                                type="text"
                                                onFocus={e => this.setState({ phone: true })}
                                                onBlur={e => this.setState({ phone: false })}
                                                value={this.state.me.phone}
                                                onChange={e => {
                                                    let o = this.state.me;
                                                    o.phone = e.target.value;
                                                    this.setState({me:o});
                                                }}/>
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>
                                                    <i className="fas fa-phone" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <InputGroup
                                            className={classnames("input-group-merge", {
                                                focused: this.state.emailAddress
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-envelope" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Email address"
                                                type="email"
                                                disabled={true}
                                                value={this.state.me.email}
                                                onFocus={e =>
                                                    this.setState({ emailAddress: true })
                                                }
                                                onBlur={e =>
                                                    this.setState({ emailAddress: false })
                                                }
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                {/*<Col md="6">*/}
                                {/*    <FormGroup>*/}
                                {/*        <InputGroup*/}
                                {/*            className={classnames("input-group-merge", {*/}
                                {/*                focused: this.state.passwords*/}
                                {/*            })}*/}
                                {/*        >*/}
                                {/*            <Input*/}
                                {/*                placeholder="Password"*/}
                                {/*                type="password"*/}
                                {/*                disabled={true}*/}
                                {/*                onFocus={e => this.setState({ passwords: true })}*/}
                                {/*                onBlur={e => this.setState({ passwords: false })}*/}
                                {/*                value={this.state.me.password}*/}
                                {/*            />*/}
                                {/*            <InputGroupAddon addonType="append">*/}
                                {/*                <InputGroupText>*/}
                                {/*                    <i className="fas fa-eye" />*/}
                                {/*                </InputGroupText>*/}
                                {/*            </InputGroupAddon>*/}
                                {/*        </InputGroup>*/}
                                {/*    </FormGroup>*/}
                                {/*</Col>*/}
                            </Row>
                            <Row>
                                <Col md="3">
                                    <FormGroup>
                                        <Button  className="btn-default" onClick={e => this.updateUser()}>Update</Button>
                                    </FormGroup>
                                </Col>
                                <Col md="4" className={"ml-0 mr-0 pr-0 pl-0"}>
                                    <FormGroup>
                                        <Button  className="btn-default"
                                                 onClick={() => this.toggleModal("defaultModal")}
                                        >Change Avatar</Button>
                                    </FormGroup>

                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    renderUploadImageModal(){
        return(
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Upload An Avatar
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{display:'flex', alignItems:'center', justifyContent:'center', overflow:'hidden'}}>
                    <Avatar
                        width={390}
                        height={295}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        src={this.state.src}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="primary" type="button"
                            onClick={() => {
                                this.setAvatar();
                                this.toggleModal("defaultModal");
                            }}>
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            this.toggleModal("defaultModal");
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    renderAssosicated(){
        return(
            <Col lg={6} className={""}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="12">
                                <h3 className="mb-0">Associated Accounts</h3>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            </Col>
                        </Row>
                    </CardHeader>
                        <CardBody>
                            <Form>
                                {this.state.associates.map((el,i) => {
                                    return(
                                    [<Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleDatepicker"
                                                >
                                                    Email of Account {i+1}
                                                </label>
                                                <Input
                                                    placeholder="Email address"
                                                    type="email"
                                                    disabled={this.state.editables[i]}
                                                    onChange={e => {
                                                        let arr = this.state.associates;
                                                        arr[i].email = e.target.value;
                                                        this.setState({associates:arr})
                                                    }}
                                                    value={el.email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleDatepicker"
                                                >
                                                    Password of Account {i+1}
                                                </label>
                                                <Input
                                                    type="password"
                                                    disabled={this.state.editables[i]}
                                                    onChange={e => {
                                                        let arr = this.state.associates;
                                                        arr[i].password = e.target.value;
                                                        this.setState({associates:arr})
                                                    }}
                                                    value={el.password}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>,
                                        <div>
                                            {i === this.state.associates.length -1 &&
                                            <Row>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Button  className="btn-default" onClick={e => this.setAssociates()}>Update</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            }
                                        </div>
                                    ]
                                    )
                                })
                                }
                            </Form>
                    </CardBody>
                </Card>
            </Col>
        )
    }


    setAssociates(){
        let payload = [];
        let i = 0;
        let err = false;
        for (i = 0; i < this.state.associates.length; i++){
            let el = this.state.associates[i];
            if(el.email && el.password && !el.id){
                if(this.validateEmail(el.email) && this.validatePassword(el.password))
                    payload.push({email:el.email, password:el.password});
                else
                    err = true;
            }
        }

        if(err){
            this.props.notify("error", "Invalid Input","there was an error with one of your inputs, please provide valid email addresses and strong passwords!");

            return;
        }
        if(payload.length === 0)
            return;


        // toast('Please wait while we set up your associated account!');
        HttpConfig().post('associated',{payload: payload})
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Your associated accounts are ready to be used!");
                    // this.setState({category:res.data.category, apps:[]},() => this.getData());
                }
                else{
                    //error
                    this.props.notify("error", "there was an error while adding your associated accounts");
                }
            }).catch(err => {
            this.props.notify("error", "there was an error while adding your associated accounts");
        })
    }


    updateUser(){
        if(!this.state.me.name || this.state.me.name.length < 3){
            // toast.error("Please insert a valid name");
            this.props.notify("error", "Invalid Input","Please insert a valid name");
            return;
        }
        if(!this.state.me.phone || this.state.me.name.phone < 10){
            // toast.error("Please insert a valid phone number");
            this.props.notify("error", "Invalid Input","Please insert a valid phone number");
            return;
        }
        // toast('Please wait while we update your account!');

        HttpConfig().post('user/update',this.state.me)
            .then(res => {
                if(res.data.status){
                    // toast.success('Your profile is successfully updated!');
                    this.props.notify("success", "Updated","Your profile is successfully updated!");
                    Cookies.set('user-name', this.state.me.name,{ expires: 365 });
                    setTimeout(() => window.location.reload(),700);
                    // this.setState({category:res.data.category, apps:[]},() => this.getData());
                }
                else{
                    //error
                    // toast.error('there was an error while updating your account');
                    this.props.notify("error", "Error","there was an error while updating your account");
                }
            }).catch(err => {
            this.props.notify("error", "Error","there was an error while updating your account");
            // toast.error('there was an error while updating your account');
        })
    }

    validateEmail(em) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(em).toLowerCase())){
            return false;
        }
        return true;
    }

    validatePassword(pass) {
        if(pass.length <= 5){
            return false;
        }
        return true;
    }

    onClose() {
        // this.setState({preview: null})
    }

    onCrop(preview) {
        this.setState({preview})
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 2071680){
            alert("File is too big!");
            elem.target.value = "";
        };
    }


    setAvatar(){
        HttpConfig().post('user/avatar',{avatar: this.state.preview})
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Your avatar is updated");
                    Cookies.set('avatar', res.data.link,{ expires: 365 });
                    setTimeout(() => window.location.reload(),700);
                    // this.setState({category:res.data.category, apps:[]},() => this.getData());
                }
                else{
                    //error
                    this.props.notify("error", "there was an error while adding your avatar");
                }
            }).catch(err => {
            this.props.notify("error", "there was an error while adding your avatar");
        })
    }
}
