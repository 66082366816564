import React from "react";
import { FormGroup } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";

const Component = ({ label, onChange, value, ...rest }) => {
  return (
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        {label}
      </label>
      <ReactDatetime
        inputProps={{
          placeholder: label
        }}
        onChange={v => {
          onChange(moment(v).format("MM-DD-YYYY"));
        }}
        timeFormat={false}
        value={moment(value)}
        {...rest}
      />
    </FormGroup>
  );
};

export default Component;
