
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import PublicLayout from "./layouts/Public";
import { JWT_hasPlan, JWTIs_connected } from "./tools/JwtToken";
import HomePage from "./views/pages/HomePage";
import PrivacyPolicy from "./views/pages/static/PrivacyPolicy";
import GeneralTermsofUse from "./views/pages/static/GeneralTermsofUse";
import Help from "./views/pages/static/Help";
import Terms from "./views/pages/static/Terms";
import ContactUs from "./views/pages/static/ContactUs";
import Error404 from "./views/pages/Error404";
import PrivacyPolicyPage from "./views/pages/admin/PrivacyPolicy/Page";

// import IndexView from "./views";

export default class App extends React.Component {
  render() {
    window.api_url = "https://api.mobioptions.com/";
    window.main_domain = "https://mobioptions.com/";
    window.privacy_url = "https://mobi-privacy-policy.com";
    window.telegram_url = "https://t.me/youceffel";
    window.order_code = "https://your-orders.mobioptions.com";
    window.url_screenshots = "https://screenshots-editor.mobioptions.com/";
    window.forum_url = "https://discord.gg/GNGTTyX58";
    window.url_apk_my_published = "https://play.google.com/store/apps/details?id=com.mobioptions&hl=en";
    window.client_id_paypal = "AUXFtHg8hhlkKkbCBISaDmd9dHr5Cge2JTFGsR9e_HX2yJV_om5RpxmdboppBYjxglup2raWZl3tqhqM";
    window.pub_key_youcan_pay = "pub_2469669e-c622-4704-8c5d-4ba40287";
    //ORGINAL PUB KEY YOUCAN PAY YOUSSEF APIWI  : 'pub_1f53a6c1-ce51-4a8e-b160-f2f211d0'
    //window.api_url = "http://apiwi-mobioptions.test/";
    // window.ads_url = "https://optionmobi.com/api/";
    // window.api_url ='http://164.90.236.47/'
    return this.getAppropriateRoutes();
  }

  getAppropriateRoutes() {
    if (JWTIs_connected() && JWT_hasPlan()) {
      //JWT = OK, HAS_PLAN = OK
      return (
        <>
          <BrowserRouter>
            <Switch>
              {/*static section*/}
              <Route path="/home" component={HomePage} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/GeneralTermsofUse" component={GeneralTermsofUse} />
              <Route path="/help" component={Help} />
              <Route path="/terms" component={Terms} />
              {/* <Route path="/contact-us" component={ContactUs} /> */}
              <Route path="/privacy-policy" component={PrivacyPolicyPage} />
              {/*main section*/}
              <Route path="/" render={props => <AdminLayout {...props} />} />
              {/*default section*/}
              <Route component={Error404} />
            </Switch>
          </BrowserRouter>
          <div
            style={{position: 'fixed', bottom: 20, right: 20}}
          >
            <a href={window.telegram_url}  target="_blank">
              <img width={50} src="https://moein.video/wp-content/uploads/2022/12/Telegram-Logo-GIF-Telegram-Icon-GIF-Royalty-Free-Animated-Icon-GIF-350px-after-effects-project-2.gif" />
            </a>
          </div>
        </>
      );
    } else if (JWTIs_connected() && !JWT_hasPlan()) {
      //JWT = OK, HAS_PLAN = KO
      return (
        <BrowserRouter>
          <Switch>
            {/*static section*/}
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/GeneralTermsofUse" component={GeneralTermsofUse} />
            <Route path="/help" component={Help} />
            <Route path="/terms" component={Terms} />
            {/* <Route path="/contact-us" component={ContactUs} /> */}
            {/*main section*/}
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Route
              path="/public"
              render={props => <PublicLayout {...props} />}
            />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Redirect from="/pricing" to="/auth/pricing" />
            <Redirect from="/post-pricing" to="/auth/post-pricing" />
            <Redirect path="/" to="/auth/login" />
            {/*default section*/}
            <Route component={HomePage} />
          </Switch>
        </BrowserRouter>
      );
    } else {
      //JWT = KO
      return (
        <BrowserRouter>
          <Switch>
            {/*static section*/}
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/GeneralTermsofUse" component={GeneralTermsofUse} />
            <Route path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route path="/help" component={Help} />
            <Route path="/terms" component={Terms} />
            {/* <Route path="/contact-us" component={ContactUs} /> */}
            {/*main section*/}
            <Route path="/public"
              render={props => <PublicLayout {...props} />}
            />
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            {/*redirect section*/}
            <Redirect from="/form" to="/public/form" />
            <Redirect from="/login" to="/auth/login" />
            <Redirect from="/sign-up" to="/auth/sign-up" />
            <Redirect from="/reset" to="/auth/reset" />
            <Redirect from="/pricing" to="/auth/pricing" />

            <Route path="/home" component={HomePage} />
            <Redirect path="/" to="/auth/login" />
            {/*default section*/}
          </Switch>
        </BrowserRouter>
      );
    }
  }
}
