/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { HttpConfig } from "tools/Http";
import ClipLoader from "react-spinners/ClipLoader";
import "tui-image-editor/dist/tui-image-editor.css";
import { connect } from "react-redux";
const white = require("assets/img/templates/white.png");
const generic = require("assets/img/templates/generic.png");
const dark = require("assets/img/templates/dark.jpeg");
const instagram = require("assets/img/templates/instagram.jpeg");
const circle = require("assets/img/templates/circle.jpeg");
const facebook = require("assets/img/templates/facebook.PNG");
const twitch = require("assets/img/templates/twitch.jpeg");
const discord = require("assets/img/templates/discord.jpg");

class GraphicsCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: "img/icon-p.jpg",
      showEditor: false,
      loading: true,
      templates: [
        { title: "Instagram template", image: instagram, link: "instagram" },
        { title: "White template", image: white, link: "white" },
        { title: "Generic template", image: generic, link: "generic" },
        { title: "Dark template", image: dark, link: "dark" },
        { title: "Circle template", image: circle, link: "circle" },
        { title: "Facebook template", image: facebook, link: "facebook" },
        { title: "Twitch template", image: twitch, link: "twitch" },
        { title: "Discord template", image: discord, link: "discord" },
      ],
    };
  }

  componentDidMount() {
    this.checkPaid()
  }

  checkPaid() {
    this.setState({loading: true});
    HttpConfig(this.props.dispatch).get('check-paid')
    .then(res => {
        if (res.data.status) {
            this.setState({loading: false});
        } else {
            this.setState({loading: false});
        }
    })
    .catch(err => {
        console.log("Error")
        this.setState({loading: false});
    });
  }

  render() {
    return (
      <>
        {this.state.loading ?
          <div
            style={{
              position:'fixed',
              display:'flex',
              zIndex:1,
              height:'100%',
              width:'100%',
              top:0,
              alignItems:'center',
              justifyContent:'center',
              backgroundColor:'rgba(0,0,0,.2)',
            }}
          >
            <ClipLoader
              color={"white"}
              loading={true}
              size={50}
            />
          </div>
            : null
        }
        {this.getHeader()}
        <Container className="mt--6" fluid>
          <Row>
            {this.state.templates.map((el, index) =>
              this.getCards(el.title, el.image, el.link, index)
            )}
          </Row>
        </Container>
      </>
    );
  }

  getCards(title, image, link, index) {
    return (
      <Col xl="4" lg={"6"} md={"12"} key={index}>
        <a
          href={`${window.url_screenshots}${link}`}
        >
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col>
                  <h3 className="mb-0">{title}</h3>
                </Col>
              </Row>
            </CardHeader>
            <img alt="..." className="img-fluid rounded" src={image} />
          </Card>
        </a>
      </Col>
    );
  }

  getHeader() {
    return (
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Tools</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-wrench" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      ScreenShots Editor
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default connect()(GraphicsCreator)