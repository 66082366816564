import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {Link} from "react-router-dom";
import Submit from "./admin/PrivacyPolicy/Submit";
import axios from 'axios';

const trustedEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'hotmail.fr',
    'outlook.com',
    'icloud.com',
    'protonmail.com',
    'zoho.com',
    'mail.com',
    'yandex.com',
    'yandex.ru',
    'mail.ru',
];

class Register extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            error: {has_error: false, message: ''},
            isLoading: false,
            acceptedTerms: false,
            acceptedPrivacy: false,
            firstName: '',
            lastName: '',
            email: '',
            captcha: '',
            // address: '',
            phone: '',
            password: '',
            password_confirmation: '',
            name: '',
            focusedFirstName: '',
            focusedAddress: '',
            focusedLastName: '',
            focusedEmail: '',
            focusedPhone: '06',
            focusedPassword: '',
            focusedPassword_confirmation: '',
            showCaptcha: false,
        }
    }

    setCaptcha = (value) => {
        this.setState({captcha:value})
    }

    render() {
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Sign up To MobiOptions"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="8" md="10">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Please fill in the form</small>
                                        {this.validationMessage()}
                                    </div>
                                    <Form >

                                        <Row>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                        focused: this.state.focusedFirstName
                                                    })}>
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="fa fa-user" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="First Name"
                                                            type="text"
                                                            onFocus={() => this.setState({ focusedFirstName: true })}
                                                            onBlur={() => this.setState({ focusedFirstName: false })}
                                                            onChange={(e) => this.setState({ firstName: e.target.value })}

                                                        />

                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedLastName
                                                    })}
                                                >
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="fa fa-user" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Last Name"
                                                            type="text"
                                                            onFocus={() =>
                                                                this.setState({ focusedLastName: true })
                                                            }
                                                            onBlur={() =>
                                                                this.setState({ focusedLastName: false })
                                                            }
                                                            onChange={(e) => this.setState({ lastName: e.target.value })}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                        focused: this.state.focusedEmail
                                                    })}>
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-email-83" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Email"
                                                            type="email"
                                                            onFocus={() => this.setState({ focusedEmail: true })}
                                                            onBlur={() => this.setState({ focusedEmail: false })}
                                                            onChange={(e) => this.setState({ email: e.target.value })}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                        focused: this.state.focusedPhone
                                                    })}>
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="fa fa-phone" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Phone Number"
                                                            type="number"
                                                            onFocus={() => this.setState({ focusedPhone: true })}
                                                            onBlur={() => this.setState({ focusedPhone: false })}
                                                            onChange={(e) => this.setState({ phone: e.target.value })}
                                                            value={this.state.phone}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedPassword
                                                    })}
                                                >
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-lock-circle-open" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Password"
                                                            type="password"
                                                            onFocus={() =>
                                                                this.setState({ focusedPassword: true })
                                                            }
                                                            onBlur={() =>
                                                                this.setState({ focusedPassword: false })
                                                            }
                                                            onChange={(e) => this.setState({ password: e.target.value })}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} sm={12}>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedPassword_confirmation
                                                    })}
                                                >
                                                    <InputGroup className="input-group-merge input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-lock-circle-open" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder="Confirm Password"
                                                            type="password"
                                                            onFocus={() =>
                                                                this.setState({ focusedPassword_confirmation: true })
                                                            }
                                                            onBlur={() =>
                                                                this.setState({ focusedPassword_confirmation: false })
                                                            }
                                                            onChange={(e) => this.setState({ password_confirmation: e.target.value })}
                                                        />
                                                    </InputGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Submit captcha={this.state.captcha} setCaptcha={this.setCaptcha} />
                                        <Row>
                                            <Col xl={12}>
                                                <FormGroup
                                                    className={classnames({
                                                        focused: this.state.focusedLastName
                                                    })}
                                                >
                                                    {/* <InputGroup className="input-group-merge input-group-alternative">
                                                        <Input
                                                            placeholder="Full Address"
                                                            rows="3"
                                                            type="textarea"
                                                            onFocus={() =>
                                                                this.setState({ focusedAddress: true })
                                                            }
                                                            onBlur={() =>
                                                                this.setState({ focusedAddress: false })
                                                            }
                                                            onChange={(e) => this.setState({ address: e.target.value })}
                                                        />
                                                    </InputGroup> */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col xl={6} sm={12}>
                                                <div className="custom-control custom-control-alternative custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id=" customCheckLogin"
                                                        type="checkbox"
                                                        value={this.state.acceptedTerms} onChange={e => this.setState({acceptedTerms:e.target.checked})}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor=" customCheckLogin"
                                                    >
                                                        <span className="text-muted">I agree to the <a href="/terms" target={"_blank"}>Terms Of Service</a></span>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xl={6} sm={12}>
                                                <div className="custom-control custom-control-alternative custom-checkbox">
                                                    <input
                                                        className="custom-control-input"
                                                        id=" customCheckRogin"
                                                        type="checkbox"
                                                        value={this.state.acceptedPrivacy}
                                                        onChange={e => this.setState({acceptedPrivacy:e.target.checked})}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor=" customCheckRogin"
                                                    >
                                                        <span className="text-muted">I agree to the <a href="/privacy" target={"_blank"}>Privacy Policy</a></span>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={"justify-content-center"}>
                                            <Button className="my-4" color="info" type="button" disabled={this.state.isLoading || this.state.captcha === ""} onClick={() => this.handleSubmit()}>
                                                Sign Up
                                            </Button>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <Link className="text-light" to="/auth/recover"><small>Forgot password?</small></Link>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <Link className="text-light" to="/auth/login"><small>Sign In</small></Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    handleSubmit(e){
        if(this.state.captcha === "")
            return;
        if(!this.validateFirstName())
            return;
        if(!this.validateLastName())
            return;
        if(!this.validateEmail())
            return;
        if(!this.validatePhoneNumber())
            return;
        if(!this.validatePassword())
            return;
        if(!this.validatePasswordConfirmation())
            return;
        // if(!this.validateAddress())
        //     return;
        if(!this.validateTerms())
            return;
        if(!this.validatePrivacy())
            return;
        
        /*  this.setState({showCaptcha:true});
            if(!this.state.showCaptcha){
                this.setState({showCaptcha:true});
                return;
        }

        if(!this.validateCaptcha())
            return; */

        this.setState({name: this.state.firstName+" "+this.state.lastName, isLoading:true},() => {
            let data = {
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                phone: this.state.phone,
                email: this.state.email,
                password: this.state.password,
                password_confirmation: this.state.password_confirmation,
                accept: this.state.acceptedTerms,
            }
            if(!data.accept){
                this.formValidation('You must accept our Terms Of Service');
                return;
            }
            axios.post(window.api_url+'api/register', data)
            .then((response) => {
                if (response.data.status === true){
                    //currentDateTime.setTime(currentDateTime.getTime() + (response.data.expires_in*3600));
                    // Cookies.set('access_token', response.data.access_token);
                    // Cookies.set('full_name', response.data.full_name);
                    // Cookies.set('avatar', response.data.avatar);
                    // Cookies.set('user_status', response.data.user_status);
                    this.props.history.push({
                        pathname: '/auth/login',
                        state: { register: true }
                    });
                }else {
                    let errorsObject = response.data.errors;
                    if(Object.keys(errorsObject).length > 0){
                        let firstError = errorsObject[Object.keys(errorsObject)[0]];
                        this.formValidation(firstError[0]);
                    }else
                        this.formValidation('There was an error signing you up, please come back later');
                    return;
                }
        
            })
            .catch((error) => {
                    this.formValidation('There was an error signing you up, please come back later');
            //     return;
        
            //     // var errorEmail = error.response.data.errors.email[0];
            //     // let message = errorEmail;
        
            //     // if(typeof errorEmail === 'undefined'){
            //     //     if(typeof error.response.data.message !== 'undefined'){
            //     //         message = error.response.data.message;
            //     //     }else{
            //     //         message = "Please retry later";
            //     //     }
            //     // }
        
            //     // validate(message);
            });
        });
    }

    formValidation(message) {
        this.setState({error: {has_error: true, message: message}, isLoading:false})
    }

    validationMessage() {
        if (this.state.error.has_error) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-red">
                        {this.state.error.message}
                    </div>
                </div>
            )
        }
        return '';
    }

    validateEmail() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(this.state.email===''){
            let error = {message:"Please enter your email", has_error:true};
            this.setState({error});
            return false;
        }
        if(!re.test(String(this.state.email).toLowerCase())){
            let error = {message:"Please insert a valid email", has_error:true};
            this.setState({error});
            return false;
        }
        const domain = this.state.email.match(/^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/)[1].toLowerCase();
        if(!trustedEmailDomains.includes(domain)) {
            let error = {message:"Enter an email address you use regularly, please.", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    validatePassword() {
        if(this.state.password.length === 0){
            let error = {message:"Please enter your password", has_error:true};
            this.setState({error});
            return false;

        }else if(this.state.password.length <= 5){
            let error = {message:"Please insert a valid password", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    validatePhoneNumber() {
        if( this.state.phone === '06'){
            let error = {message:"Please enter your phone number", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    validateFirstName() {
        if( this.state.firstName === '' || this.state.firstName.length < 3){
            let error = {message:"The first name must be between 3 and 50 characters.", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    // validateAddress() {
    //     if( this.state.address.length < 5){
    //         let error = {message:"Please enter a valid address", has_error:true};
    //         this.setState({error});
    //         return false;
    //     }
    //     let error = {message:"", has_error:false};
    //     this.setState({error});
    //     return true;
    // }

    validateLastName() {
        if( this.state.lastName === '' || this.state.lastName.length < 3){
            let error = {message:"The last name must be between 3 and 50 characters.", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }
    validateTerms() {
        if( !this.state.acceptedTerms){
            let error = {message:"Please Accept our Terms Of Service", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }
    validatePrivacy() {
        if( !this.state.acceptedPrivacy){
            let error = {message:"Please Accept our Privacy Policy", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

    validatePasswordConfirmation() {
        if( this.state.password !== this.state.password_confirmation){
            let error = {message:"Passwords don't match", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    }

   /*  validateCaptcha(){
        if(!this.state.captchaValue){
            let error = {message:"Please fill out the reCAPTCHA", has_error:true};
            this.setState({error});
            return false;
        }
        let error = {message:"", has_error:false};
        this.setState({error});
        return true;
    } */
}

export default Register;
