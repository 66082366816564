/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb, BreadcrumbItem, Button,
    Card, CardHeader, Col,
    Container, Modal, Row,

} from "reactstrap";
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import ClipLoader from "react-spinners/ClipLoader";
import { HttpConfig } from 'tools/Http';
import { connect } from 'react-redux';

const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');

const blackTheme = {
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'submenu.normalIcon.path': icond,
    'submenu.activeIcon.path': iconb
};
const img1 = require("assets/img/theme/img-1-1000x600.jpg");
class GraphicsCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            img:'img/icon-p.jpg',
            showEditor:false,
            loading: true
        }
    }

    componentDidMount() {
        this.checkFree();
    }

    checkFree() {
        this.setState({loading: true});
        HttpConfig(this.props.dispatch).get('check-free')
        .then(res => {
            if (res.data.status) {
                this.setState({loading: false});
            } else {
                this.setState({loading: false});
            }
        })
        .catch(err => {
            console.log("Error")
            this.setState({loading: false});
        });
    }

    render() {
        return (
            <>
                {this.state.loading ?
                    <div
                    style={{
                        position:'fixed',
                        display:'flex',
                        zIndex:1,
                        height:'100%',
                        width:'100%',
                        top:0,
                        alignItems:'center',
                        justifyContent:'center',
                        backgroundColor:'rgba(0,0,0,.2)',
                    }}
                    >
                    <ClipLoader
                        color={"white"}
                        loading={true}
                        size={50}
                    />
                    </div>
                    : null  
                }
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row >
                        {this.getCards("Icons",img1, "Icon", () => this.setState({img: 'img/icon-p.jpg'}, () => this.refreshEditor()))}
                        {this.getCards("Screenshots",img1, "ScreenShot",() => this.setState({img: 'img/screenshots-p.jpg'}, () => this.refreshEditor()))}
                        {this.getCards("Banners",img1, "Banner",() => this.setState({img: 'img/banner-p.jpg'}, () => this.refreshEditor()))}
                    </Row>
                </Container>
                {this.renderModal()}
            </>
        )
    }


    refreshEditor(){
        this.setState({showEditor:false}, () => this.setState({showEditor:true}))
    }
    getCards(title,image,button,click){
        return(
            <Col xl="4" lg={"6"} md={"12"} key={title}>
                <Card>
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col key={'titlecol'}>
                                <h3 className="mb-0">{title}</h3>
                            </Col>
                            <Col key={'buttoncol'} className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Button  color="primary" size="sm" onClick={click}>
                                    <i className="fas fa-plus fa-lg"></i>&nbsp;&nbsp;{button}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <img
                        alt="..."
                        className="img-fluid rounded"
                        src={image}
                    />
                </Card>
            </Col>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Tools
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-wrench" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Graphics Creator
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }


    renderModal(){
        return (
            <Modal
                size={"lg"}
                className="modal-lg modal-dialog-centered"
                isOpen={this.state.showEditor}
                toggle={() => this.setState({showEditor:false})}
            >
                <ImageEditor
                    // ref={this.editorRef}
                    includeUI={{
                        loadImage: {
                            path: this.state.img,
                            name: 'SampleImage'
                        },
                        theme: blackTheme,
                        initMenu: 'filter',
                        uiSize: {
                            width: '1000px',
                            height: '700px'
                        },
                        menuBarPosition: 'bottom'
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={1000}
                    selectionStyle={{
                        cornerSize: 20,
                        rotatingPointOffset: 70
                    }}
                    usageStatistics={true}
                />
            </Modal>
        )
    }

}
export default connect()(GraphicsCreator)