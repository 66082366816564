/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import Helmet from "react-helmet"
import Carousel from "react-img-carousel";
import { TagCloud } from 'react-tagcloud'
import "react-img-carousel/lib/carousel.css";
import "tui-image-editor/dist/tui-image-editor.css";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  Progress,
  Row,
  Table,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import Stars from "../../components/Mobi/Stars";
import { HttpConfig } from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import Utils from "../../Utils";
import TagsInput from "react-tagsinput";
import ImageEditor from "@toast-ui/react-image-editor";
import { Line } from "react-chartjs-2";
import {
  chartExample1,
  chartOptions,
  parseOptions,
} from "../../variables/charts";
import Chart from "chart.js";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import Axios from "axios";
const keyword_extractor = require("keyword-extractor");
const queryString = require("query-string");
let rankOrder = false;
let countryOrder = false;
var JSZip = require("jszip");
var FileSaver = require("file-saver");
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");

const blackTheme = {
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
  "submenu.normalIcon.path": icond,
  "submenu.activeIcon.path": iconb,
};

const data = [
  { value: 'JavaScript', count: 38 },
  { value: 'React', count: 30 },
  { value: 'Nodejs', count: 28 },
  { value: 'Express.js', count: 25 },
  { value: 'HTML5', count: 33 },
  { value: 'MongoDB', count: 18 },
  { value: 'CSS3', count: 20 },
]

class Details extends React.Component {
  editorRef = React.createRef();
  constructor(props) {
    super(props);
    let params = queryString.parse(props.props.location.search);
    this.state = {
      showEditor: false,
      application: null,
      app: null,
      originalTitle: "MobiOptions Platform",
      originalShortDescription: "",
      originalIcon: "/favicon.ico",
      similarApps: [],
      developerApps: [],
      rankingCountries: [],
      showMoreClicked: false,
      rankingHistory: [],
      keywords: [],
      hasTopRanked: false,
      developer: null,
      check_status: false,
      labels: [],
      loading: true,
      data: [],
      devapps: [],
      id: params["id"],
      loadingDetails: true,
      loadingDev: true,
      loadingTopRanks: true,
      libs: [],
      categories: [],
      age: null,
      short: null,
      showMoreLibs: null,
      showMorePermissions: null,
      screenshotToEdit: null,
      collectionModal: false,
      rankingHistoryTotal: 0,
      sdkTags: [
        "Development Tool",
        "Ad Network",
        "Analytics",
        "Attribution",
        "Backend",
        "CRM",
        "Commerce",
        "Communication",
        "Data Intelligence",
        "Game Engine",
        "Marketing Automation",
        "Social",
        "Survey",
      ],
      priorityCountries: [
        "United States",
        "United Kingdom",
        "Australia",
        "South Korea",
        "New Zealand",
        "Belgium",
        "Germany",
      ],
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.props.location.key !== this.props.props.location.key) {
      let params = queryString.parse(this.props.props.location.search);
      // document.documentElement.scrollTop = 0;
      window.scrollTo(0, 0);
      this.setState({ loadingDetails: true, id: params["id"] }, () => {
        this.getApplicationDetail();
      });
    }
  }

  componentDidMount() {
    this.getApplicationDetail();
    this.getSimilarAndDeveloper();
    this.getCollections();
  }
  
/*   componentWillUnmount() {
    document.title = this.state.originalTitle;
    const link = document.querySelector("link[rel*='icon']");
    const favicon = document.createElement('link');

    this.setState({originalIcon: link.href})

    favicon.type = 'image/x-icon';
    favicon.rel = 'icon';

    // Set the path to the new favicon for this app
    favicon.href = this.state.originalIcon;

    // Remove the existing favicon
    if (link) {
      document.head.removeChild(link);
    }

    // Append the new favicon to the head
    document.head.appendChild(favicon);
  } */

  getKeywordDensity(description) {
    let extraction_result = keyword_extractor.extract(
      description,
      {
        // language:"english",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: false
        // return_chained_words: true
      }
    );
    let hashMap = [];
    let totalKeywords = extraction_result.length;
    extraction_result.map((ol, i) => {
      let found = false;
      hashMap.map((el, j) => {
        if (ol == el["value"]) {
          found = true;
          hashMap[j].count = hashMap[j].count + 1;
        }
      });
      if (!found) hashMap.push({ value: ol, count: 1 });
    });
    let keywords = [];
    hashMap.sort((a, b) => b.count - a.count).map(el => {
      if(el.count > 1)
        keywords.push({ value: el.value, count: el.count });
    });
    this.setState({ keywords });
  }

  getApplicationDetail() {
    HttpConfig(this.props.dispatch)
      .post("details-application", { package_name: this.state.id })
      .then((res) => {
        if (res.data.status) {
          let labels = [];
          let data = [];
          if (res.data.application.daily_installs)
            res.data.application.daily_installs.reverse().map((el, i) => {
              if (
                el[0] !== "null" &&
                el[1] !== "null" &&
                i != res.data.application.daily_installs.length - 1
              ) {
                labels.push(moment(el[0]).format("DD/MM"));
                data.push(el[1]);
              }
            });
          this.setState({
            application: res.data.application,
            check_status: res.data.check_status,
            rankingCountries: res.data.application.top
              ? Object.entries(res.data.application.top).sort(
                  (a, b) => a[1] - b[1]
                )
              : [],
            labels: labels,
            data: data,
          });
          this.getKeywordDensity(res.data.application.description)
          const link = document.querySelector("link[rel*='icon']");
          if (link) {
            document.head.removeChild(link);
          }
          this.setState({
            originalIcon: res.data.application.icon,
            originalTitle: res.data.application.name,
            originalShortDescription: res.data.application.short_description
          });

          // app.screenshots = JSON.parse(app.screenshots);
          // app.permissions = JSON.parse(app.permissions);
          // app.categories = JSON.parse(app.categories);
          // let permissions = [];
          // app.permissions.map((el, i) => {
          //     permissions.push(el.id);
          // })
          // this.setState({app, loadingDetails: false, permissions}, () => {
          //     this.getDeveloper();
          //     this.getSimilar();
          //     this.getLibs();
          //     this.getRankingHistory();
          // });
          this.setState({ loading: false });
        } else {
          //error
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  getSimilarAndDeveloper() {
    HttpConfig(this.props.dispatch)
    .post("similar-apps", { package_name: this.state.id })
      .then((res) => {
        if (res.data.status) {
          this.setState({
            similarApps: res.data.similar_apps,            
            devapps: res.data.dev_apps
          });
        }
      })
      .catch((err) => {
        this.setState({ loadingDev: false });
      });
  }

  refresh() {
    if (this.state.check_status) {
      this.setState({ loading: true });
      HttpConfig(this.props.dispatch)
        .get("check-status-app/" + this.state.id)
        .then((res) => {
          if (res.data.status) {
            let labels = [];
            let data = [];
            if (res.data.application.daily_installs)
              res.data.application.daily_installs.reverse().map((el, i) => {
                if (
                  el[0] !== "null" &&
                  el[1] !== "null" &&
                  i != res.data.application.daily_installs.length - 1
                ) {
                  labels.push(moment(el[0]).format("DD/MM"));
                  data.push(el[1]);
                }
              });
            this.setState({
              application: res.data.application,
              check_status: res.data.check_status,
              rankingCountries: res.data.application.top
                ? Object.entries(res.data.application.top).sort(
                    (a, b) => a[1] - b[1]
                  )
                : [],
              labels: labels,
              data: data,
            });
            // document.getElementsByTagName("title")[0].text = this.state.application.name;
            // app.screenshots = JSON.parse(app.screenshots);
            // app.permissions = JSON.parse(app.permissions);
            // app.categories = JSON.parse(app.categories);
            // let permissions = [];
            // app.permissions.map((el, i) => {
            //     permissions.push(el.id);
            // })
            // this.setState({app, loadingDetails: false, permissions}, () => {
            //     this.getDeveloper();
            //     this.getSimilar();
            //     this.getLibs();
            //     this.getRankingHistory();
            // });
            this.setState({ loading: false });
          } else {
            //error
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
        });
    }
  }

  getLibs() {
    HttpConfig(this.props.dispatch)
      .get("app/sdk/" + this.state.id)
      .then((res) => {
        if (res.data.status) {
          let libs = [];
          let a = this.state.app;
          a.libs = JSON.parse(res.data.sdk);
          a.libs.map((el, i) => {
            // if(i<6)
            libs.push(el);
          });
          this.setState({ libs, app: a });
        } else {
          //error
        }
      })
      .catch((err) => {});
  }



  getSimilar() {
    HttpConfig(this.props.dispatch)
      .get("/similar/" + this.state.id)
      .then((res) => {
        if (res.data.status) {
          this.setState({competitorsApps: res.data.similar});
        } else {
          //error
        }
      })
      .catch((err) => {
        //err
      });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{this.state.originalTitle}</title>
          <meta name="description" content={this.state.originalShortDescription}/>
          <meta property="og:title" content={this.state.originalTitle} />
          <meta property="og:description" content={this.state.originalShortDescription} />
          <link rel="shortcut icon" href={this.state.originalIcon} />
          <link type='image/x-icon' rel='icon' href={this.state.originalIcon} />
          <meta property="og:image" content={this.state.originalIcon} />
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              position: "fixed",
              display: "flex",
              zIndex: 1,
              height: "100%",
              width: "100%",
              top: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0,0,0,.2)",
            }}
          >
            <ClipLoader color={"white"} loading={true} size={50} />
          </div>
        ) : null}
        {!this.state.loading && (
          <>
            {this.state.application ? (
              <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                  <Row>
                    <Col className="card-wrapper" lg="12">
                      {this.state.application && [
                        this.renderIfDeleted(),
                        this.renderAppScreens(),
                        this.renderRankings(),
                        this.renderDescription(),
                        this.renderTagCloud(),
                        // <Row>
                        //     <Col lg={6}>
                        //         {this.renderPermissions()}
                        //     </Col>
                        //     <Col lg={6}>
                        //         {this.renderLibraries()}
                        //     </Col>
                        // </Row>,
                        this.renderSimilarApps(),
                        this.renderDevApps(),
                      ]}
                    </Col>
                  </Row>
                  {this.renderScreensModal()}
                  {this.addToCollectionModal()}
                  {this.ChartModal()}
                </Container>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "90vh",
                }}
              >
                <img src={require("assets/img/404.png")} />
              </div>
            )}
          </>
        )}
      </>
    );
  }

  renderIfDeleted() {
    if (this.state.application.status === 1)
      return (
        <UncontrolledAlert color="danger">
          <span className="alert-icon">
            <i className="fa fa-trash" />
          </span>
          <span className="alert-text ml-1">
            <strong>Alert: </strong> This app is <u>Unpublished</u> from Google
            Play
          </span>
        </UncontrolledAlert>
      );
  }

  addToCollectionModal() {
    return (
      <Modal
        size={"md"}
        className="modal-md modal-dialog-centered"
        isOpen={this.state.collectionModal}
        toggle={() => this.setState({ collectionModal: false })}
      >
        <>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Selecting Collection
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ collectionModal: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <Select2
                className="form-control"
                options={{
                  placeholder: "Select",
                }}
                value={this.state.selectedCategory}
                onSelect={(e) => {
                  this.setState({ selectedCategory: e.target.value });
                }}
                data={this.state.categories}
              />
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              type="button"
              onClick={() => {
                window.open("/favourite", "_blank");
              }}
            >
              + New Collection
            </Button>
            <Button
              className="ml-auto"
              color="success"
              data-dismiss="modal"
              type="button"
              disabled={!this.state.selectedCategory}
              onClick={() => {
                this.setState({ collectionModal: false });
                this.addToCollectionPost();
              }}
            >
              Add
            </Button>
          </div>
        </>
      </Modal>
    );
  }

  ChartModal() {
    return (
      <Modal
        size={"xl"}
        className="modal-md modal-dialog-centered"
        isOpen={this.state.chartModal}
        toggle={() => this.setState({ chartModal: false })}
      >
        <>
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Rank History
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({ chartModal: false })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="chart">
              <Line
                height={200}
                options={{
                  ...chartExample1.options,
                  maintainAspectRatio: false,
                }}
                data={{
                  labels: this.state.labels,
                  datasets: [
                    {
                      label: "Rank History",
                      data: this.state.data,
                    },
                  ],
                }}
                id="chart-sales-dark"
                className="chart-canvas"
              />
            </div>
          </div>
        </>
      </Modal>
    );
  }

  renderAppScreens() {
    return (
      <Card key={"details"}>
        <CardHeader>
          <Row className="align-items-center">
            <Col
              xs={12}
              xl={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                alt="..."
                className="img-fluid mr-1"
                style={{ width: 20, height: 20 }}
                src={"/img/play-store.png"}
              />
              &nbsp;
              <h3 className="mb-0">Play Store Listing</h3>
            </Col>
            <Col xs={12} xl={6}>
              <div className="text-md-right">
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.downloadAssets()}
                >
                  <i className="fas fa-download fa-lg"></i>&nbsp;&nbsp;Download Assets
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() =>
                    window.open(
                      "https://d.apkpure.com/b/APK/" +
                        this.state.id +
                        "?version=latest",
                      "_blank"
                    )
                  }
                >
                  <i className="fas fa-download fa-lg"></i>&nbsp;&nbsp;Download APK
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="align-items-center">
            <Col className="col-auto">
              <div style={{ position: "relative" }}>
                <a
                  href="#pablo"
                  className={"app-icon"}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showEditor: true,
                      screenshotToEdit: this.state.application.icon,
                    });
                  }}
                >
                  <img
                    alt="..."
                    className="avatar-details  rounded shadow"
                    src={this.state.application.icon}
                  />
                </a>
                <i className="fas fa-pen text-white pen-edit" />
              </div>
            </Col>
            <div className="col">
              <h2 className="mb-1">
                {this.state.application.name}
              </h2>
              <p className="text-md-left mb-0">
                <small>by </small>{" "}
                <Link
                  to={
                    "/android-dev?id=" + this.state.application.developer.name
                  }
                >
                  {this.state.application.developer.name}
                </Link>
              </p>
              <p className=" text-md-left mb-0">
                {this.state.application.short_description}
              </p>
              <p className="text-sm text-muted text-md-left">
                {this.state.application.category}
              </p>
            </div>
            {this.state.application.path_qr &&
            <Col className="col-auto">
              <div style={{ position: "relative" }}>
                <img
                  alt="..."
                  className="avatar-details  rounded shadow"
                  src={this.state.application.path_qr}
                />
              </div>
            </Col>}
          </Row>
          {this.state.application.images.length > 0 && (
            <Row>
              <Col xl={12} className={"mt-4"}>
                <Carousel lazyLoad={false} infinite={true} slideAlignment={"left"} cellPadding={10}>
                  {this.state.application.images.map((el, i) => (
                    <img
                      key={i}
                      src={el.replace("=s0", "") + "=h296-rw"}
                      className="img-fluid cursor-pointer rounded"
                      style={{ marginRight: 5 }}
                      alt=""
                      onClick={() =>
                        this.setState({
                          showEditor: true,
                          screenshotToEdit: el,
                        })
                      }
                    />
                  ))}
                </Carousel>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }

  getInstallsChart() {
    return (
      // <div className="chart">
      <Line
        data={{
          labels: this.state.labels,
          datasets: [
            {
              fill: true,
              label: "installs",
              data: this.state.data,
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.3)",
            },
          ],
        }}
      />
      // </div>
    );
  }

  renderRankings() {
    return (
      <Row key={"ranking"}>
        <Col xl={12} lg={12} sm={12} md={12}>
          <Row>
            <Col xl={4}>
              {this.getCard("Downloads", this.state.application.downloads, "")}
              {this.getCard(
                "Recent",
                this.state.application.recent_downloads,
                ""
              )}
              {this.getCard("Version", this.state.application.version, " ")}
            </Col>
            <Col xl={4}>
              {this.getCard("App age", this.state.application.age_app, "")}
              {this.getCard(
                "Last Updated",
                this.state.application.last_updated_at,
                ""
              )}
              {this.getCard(
                "Content rating",
                this.state.application.level_rating,
                this.state.application.level_rating
              )}
            </Col>
            <Col xl={4}>
              {/* {this.getCardEpic("Ranking", this.state.hasTopRanked ? 'Top Ranked ' : 'Not Ranked', (this.state.hasTopRanked && this.state.rankingHistoryTotal > 0) ? 'in ' + this.state.rankingHistoryTotal + ' country' : '')} */}
              {this.getCardEpic(
                "Ranking",
                this.state.application.top_country ||
                  this.state.rankingCountries.length > 0
                  ? "Top Ranked "
                  : "Not Ranked",
                this.state.application.top_country ||
                  this.state.rankingCountries.length > 0
                  ? "in " +
                      (this.state.application.top_country ||
                        this.state.rankingCountries[0][0]) +
                      " country"
                  : ""
              )}
              {this.getCard(
                "Rating",
                !this.state.application.rating
                  ? "Not Rated"
                  : this.state.application.rating.toString().match(/\d+\.\d{2}/)
              )}
              {this.getCard(
                "Pricing",
                this.state.application.price == 0 ? "FREE" : "PAID",
                parseInt(this.state.application.iap) > 0
                  ? "+ In-app Purchase"
                  : ""
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card style={{height: '50%', overflow: 'hidden', minHeight: 400}}>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <h3 className="mb-0">
                        Daily Installs (last {this.state.labels.length} days)
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
                  {this.state.data.length > 0 ? (
                    this.getInstallsChart()
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <h4>No available data for this app</h4>
                    </div>
                  )}
                </div>
              </Card>
             
            </Col>
          </Row>
        </Col>
        <Col xl={12} lg={12} md={12}>
          <Row className="align-items-center">
            <Col lg={6} md={12}  >
                <Card className="bg-ngradient-white" style={{minHeight: 325}}>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col xs={12}>
                            <h3 className="mb-0">Reviews</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>{this.renderRatings()}</CardBody>
                </Card>
            </Col>
            <Col lg={6} md={12} style={{minHeight: 325}}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col xs={12}>
                            <h3 className="mb-0">Top Countries</h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <div
                    style={{
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                        minHeight: 256,
                    }}
                    >
                    {this.state.rankingCountries.length > 0 ? (
                        this.getRankingTable()
                    ) : (
                        <div
                        style={{
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingBottom: 15,
                            textAlign: "center",
                        }}
                        >
                        <h4>No available data for this app</h4>
                        </div>
                    )}
                    </div>
                </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  renderRatings() {
    if (!this.state.application) return null;

    if (this.state.application.stars_ratings.length <= 0)
      return <h3> No Reviews Yet</h3>;
    let revs = this.state.application.stars_ratings;
    let x1 = (revs[0] / this.state.application.count_voters) * 100;
    let x2 = (revs[1] / this.state.application.count_voters) * 100;
    let x3 = (revs[2] / this.state.application.count_voters) * 100;
    let x4 = (revs[3] / this.state.application.count_voters) * 100;
    let x5 = (revs[4] / this.state.application.count_voters) * 100;

    let arr = [];
    arr.push({ val: revs[0], perc: x1, col: "danger" });
    arr.push({ val: revs[1], perc: x2, col: "warning" });
    arr.push({ val: revs[2], perc: x3, col: "yellow" });
    arr.push({ val: revs[3], perc: x4, col: "info" });
    arr.push({ val: revs[4], perc: x5, col: "success" });
    arr.reverse();
    return (
      <Col>
        {arr.map((el, i) => {
          return (
            <Row key={i.toString()}>
              <Col sm={4} className={"pl-0 pr-0 pb-1"}>
                <Stars score={5 - i} />
              </Col>
              <Col sm={5}>
                <Progress
                  className="progress-xs mt-2"
                  color={el.col}
                  max="100"
                  value={el.perc}
                />
              </Col>
              <Col sm={3} className={""}>
                <h4>{this.formatNumber(Math.floor(el.val))}</h4>
              </Col>
            </Row>
          );
        })}
      </Col>
    );
  }

  formatNumber(num) {
    num = parseInt(num);
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  renderLibraries() {
    return (
      <Card style={{ minHeight: 400, maxHeight: 400 }}>
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <h3 className="mb-0">Libraries and Dependencies</h3>
            </Col>
            {!this.state.showMoreLibs && (
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn"
                  color="primary"
                  role="button"
                  size="sm"
                >
                  Show Filters
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {this.state.sdkTags.map((sdk) => {
                    return (
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          let libs = [];
                          if (this.state.app.libs)
                            this.state.app.libs.map((el, i) => {
                              el.tags.map((t) => {
                                if (t === sdk) libs.push(el);
                              });
                            });
                          this.setState({ libs });
                        }}
                      >
                        {sdk}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Row>
        </CardHeader>
        <div
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
          className={""}
        >
          <Scrollbars
            hideTracksWhenNotNeeded
            style={{
              width: "99%",
              height: "80%",
              overflow: "hidden",
              position: "absolute",
            }}
          >
            <Row className={"p-0 ml-2 mt-2"} style={{ width: "98%" }}>
              {this.state.libs.map((el, i) => {
                return (
                  <Col
                    xl={4}
                    className={"mb-3 pt-2 sdk-unit pr-1"}
                    style={{ overflowX: "hidden" }}
                    onClick={() => this.gotoLibLink(el)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        minHeight: 40,
                        maxHeight: 40,
                        alignItems: "center",
                      }}
                    >
                      {el.icon && (
                        <img
                          src={el.icon}
                          className={"shadow rounded"}
                          alt=""
                          style={{ maxHeight: 40, maxWidth: 40 }}
                        />
                      )}
                      <h3 style={{ whiteSpace: "nowrap" }} className={"ml-2"}>
                        {el.title}
                      </h3>
                    </div>
                    <div style={{ paddingTop: 3 }}>
                      <h4
                        className={"text-sm"}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <strong>ID:</strong> {el.id}
                      </h4>
                    </div>
                    <h5 className={"text-muted"}>
                      {el.tags[0]}&nbsp;
                      {el.tags.length > 1 ? (
                        <>
                          &nbsp;
                          <u id={"print-tooltip-" + i}>
                            <span className={"h6 text-muter"}>
                              +{el.tags.length - 1} more
                            </span>
                          </u>
                          <UncontrolledTooltip
                            placement="top"
                            target={"print-tooltip-" + i}
                          >
                            {el.tags.map((t, i) => {
                              return t + (i === el.tags.length - 1 ? "" : ", ");
                            })}
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </h5>
                  </Col>
                );
              })}
            </Row>
          </Scrollbars>
        </div>
      </Card>
    );
  }

  renderPermissions() {
    return (
      <Card style={{ minHeight: 400, maxHeight: 400, overflow: "hidden" }}>
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <h3 className="mb-0">App Permissions</h3>
            </Col>
          </Row>
        </CardHeader>
        <div
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
          className={""}
        >
          <Scrollbars
            hideTracksWhenNotNeeded
            style={{
              width: "99%",
              height: "80%",
              overflow: "hidden",
              position: "absolute",
            }}
          >
            <Row className={"p-0 ml-2 mt-2"} style={{ width: "98%" }}>
              <Form>
                <TagsInput
                  disabled
                  onlyUnique
                  className="bootstrap-tagsinput"
                  onChange={this.handleTagsinput}
                  value={this.state.permissions}
                  tagProps={{ className: "tag badge mr-1" }}
                  inputProps={{
                    className: "",
                    placeholder: "",
                    opacity: 0,
                  }}
                />
              </Form>
            </Row>
          </Scrollbars>
        </div>
      </Card>
    );
  }

  customRenderer = (tag, size, color) => (
    <span
      key={tag.value}
      title={"Repeated " + tag.count + " times"}
      style={{
        fontSize: `${size / 2}em`,
        margin: '3px',
        padding: '3px',
        display: 'inline-block',
        color: `${color}`
      }}
    >
      {tag.value}
    </span>
  )

  renderTagCloud() {
    if (!this.state.application) return null;

    let desc = this.state.application.description.replace(
      'className="collapse"',
      ""
    );

    return (
      <Card key={"appKeyword"}>
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <h3 className="mb-0">Keyword Cloud</h3>
            </Col>
            <Col lg="6" xs="6">
              <div className="float-right">
                <h4 className="mb-0 text-muted">Click on any keyword to copy</h4>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <TagCloud
            minSize={2}
            maxSize={6}
            renderer={this.customRenderer}
            tags={this.state.keywords}
            onClick={tag => this.copyToClipboard(tag.value)}
          />
          {/* <div dangerouslySetInnerHTML={{ __html: desc }} /> */}
          {/* <div style={{whiteSpace: "pre-line"}}>{desc}</div> */}
        </CardBody>
      </Card>
    );
  }
  
  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        this.props.notify(
          "success",
          "Done!",
          "Your keyword has been copied successfully"
        );
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  renderDescription() {
    if (!this.state.application) return null;

    let desc = this.state.application.description.replace(
      'class="collapse"',
      ""
    );
    
    if (!this.state.showMoreClicked) {
      if (desc.length > 600) {
        desc = desc.slice(0, 600);
      }
    }

    return (
      <Card key={"appDescription"}>
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              <h3 className="mb-0">App Description</h3>
            </Col>
            <div className="col text-right">
              {!this.state.showMoreClicked && (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => this.setState({ showMoreClicked: true })}
                >
                  <i className="fas fa-eye fa-lg"></i>&nbsp;&nbsp;Show Full Description
                </Button>
              )}
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: desc }} />
          {/* <div style={{whiteSpace: "pre-line"}}>{desc}</div> */}
        </CardBody>
      </Card>
    );
  }

  renderSimilarApps() {
    if (this.state.similarApps.length > 0)
      return (
        <Card key={"similarApps"}>
          <CardHeader>
            <Row className="align-items-center">
              <Col>
                <h3 className="mb-0">Similar Apps</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className={"justify-content-center"}>
              {this.state.similarApps.map((el, i) => {
                if (i < 6)
                  return (
                    <Col
                      key={"app" + i.toString()}
                      lg={2}
                      sm={6}
                      className={"justify-content-center d-flex"}
                    >
                      <a
                        href={"details?id=" + el.app_id}
                        target={"_blank"}
                        className="pointer-cursor"
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt="..."
                          className="avatar-xl  rounded mb-3 shadow"
                          src={el.icon}
                        />
                        <h4 className={"text-center"}>
                          {el.name ? el.name.substring(0, 29) : ""}
                        </h4>
                        <small className="h4 font-weight-light mt-1 text-muted">
                          {el.installs}
                        </small>
                        <div className="mt--1 pl-2 pr-2">
                          <Stars score={el.rating ? el.rating : 0} />
                        </div>
                      </a>
                    </Col>
                  );
              })}
            </Row>
          </CardBody>
        </Card>
      );
  }

  renderDevApps() {
    if (this.state.devapps.length > 0)
      return (
        <Card key={'devapps'}>
          <CardHeader>
            <Row className="align-items-center">
              <Col>
                <h3 className="mb-0">
                  More from{" "}
                  {this.state.application
                    ? this.state.application.developer.name
                    : ""}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className={"justify-content-center"}>
              {this.state.devapps.map((el, i) => {
                if (i < 6)
                  return (
                    <Col
                      lg={2}
                      sm={6}
                      key={'devapp' + i}
                      className={"justify-content-center d-flex"}
                    >
                      <a
                        href={"details?id=" + el.app_id}
                        target={"_blank"}
                        className="pointer-cursor"
                        key={i}
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          alt="..."
                          className="avatar-xl  rounded mb-3 shadow"
                          src={el.icon}
                        />
                        <h4 className={"text-center"}>
                          {el.name ? el.name.substring(0, 29) : ""}
                        </h4>
                        <small className="h4 font-weight-light mt-1 text-muted">
                          {el.installs}
                        </small>
                        <div className="mt--1 pl-2 pr-2">
                          <Stars score={el.rating ? el.rating : 0} />
                        </div>
                      </a>
                    </Col>
                  );
              })}
            </Row>
          </CardBody>
        </Card>
      );
  }

  getHeader() {
    return (
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="12" key={"header0"}>
                <h6 className="h2 text-white d-inline-block mb-0">
                  Applications
                </h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-rocket" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Google App Details
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="12" key={"header1"}>
                <Button
                  className="btn-neutral"
                  color="default"
                  title="Refresh"
                  size="sm"
                  disabled={!this.state.check_status}
                  onClick={() => this.refresh()}
                >
                  <i className="fas fa-sync-alt fa-lg"></i>
                </Button>
                <a
                  className=" btn btn-sm btn-neutral"
                  color="default"
                  title="View on Play Store"
                  size="sm"
                  href={
                    "https://play.google.com/store/apps/details?id=" +
                    this.state.id
                  }
                  target="_blank"
                >
                  <i className="fab fa-google-play fa-lg"></i>
                </a>
                <Button
                  className="btn-neutral"
                  color="default"
                  title="Add to Favourite"
                  size="sm"
                  onClick={() => this.setState({ collectionModal: true })}
                >
                  <i className="fas fa-plus-square fa-lg"></i>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }

  renderScreensModal() {
    return (
      <Modal
        size={"lg"}
        className="modal-lg modal-dialog-centered"
        isOpen={this.state.showEditor}
        toggle={() => this.setState({ showEditor: !this.state.showEditor })}
      >
        {this.getEditorInstance()}
      </Modal>
    );
  }

  getCardEpic(v1, v2, v3) {
    return (
      <Card className="bg-gradient-white">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase text-muted mb-0">
                {v1}&nbsp;
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">
                <i className="fas fa-trophy"></i>&nbsp;{v2}
              </span>
            </div>
          </Row>
          <p className="mt-3 mb-0 text-sm">
            <span className="text-nowrap text-light">{v3}&nbsp;</span>
          </p>
        </CardBody>
      </Card>
    );
  }

  getCard(v1, v2, v3) {
    return (
      <Card className="bg-gradient-white">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase text-muted mb-0">
                {v1}&nbsp;
              </CardTitle>
              <span className="h2 font-weight-bold mb-0">{v2}&nbsp;</span>
            </div>
          </Row>
          <p className="mt-3 mb-0 text-sm">
            <span className="text-nowrap text-light">{v3}&nbsp;</span>
          </p>
        </CardBody>
      </Card>
    );
  }

  getRankingTable() {
    return (
      <Table className="align-items-center table-flush mt-2" responsive>
        <thead className="thead-light">
          <tr key={"tr"}>
            <th
            // onClick={() => {
            //     this.sortRankingHistory('country_name', true, countryOrder);
            //     countryOrder = !countryOrder
            // }}
            >
              Country
            </th>
            <th
            // onClick={() => {
            //     this.sortRankingHistory('position', false, rankOrder);
            //     rankOrder = !rankOrder
            // }}
            >
              Rank
            </th>
          </tr>
        </thead>
        <tbody className="list">
          {this.state.rankingCountries.map((el, i) => {
            return (
              <tr key={i+"tr"}>
                <td>
                  <span className="text-muted" id={"print-tooltip" + i}>
                    {el[0]}
                  </span>
                </td>
                <td>
                  <span className="rank_ text-muted ">{el[1]}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  addToCollectionPost(id) {
    // toast('please wait while we add your app to your favourite collection');
    HttpConfig(this.props.dispatch)
      .post("save-favourite", {
        app_id: this.state.id,
        uuid: this.state.selectedCategory,
      })
      .then((res) => {
        if (res.data.status) {
          // toast.success('App successfully added to your collection');
          this.getCollections();
          this.props.notify(
            "success",
            "Updated",
            "Application successfully added to your collection"
          );
        } else {
          //error
          // toast.error('there was an error while adding your app');
          this.props.notify(
            "danger",
            "Error",
            "there was an error while adding your app"
          );
        }
      })
      .catch((err) => {
        this.props.notify(
          "danger",
          "Error",
          "there was an error while adding your app"
        );
        // toast.error('there was an error while adding your app');
      });
  }

  getCollections() {
    HttpConfig(this.props.dispatch)
      .get("get-list-collections")
      .then((res) => {
        if (res.data.status) {
          let arr = [];
          res.data.collections.map((el) => {
            arr.push({
              id: el.uuid,
              text: el.name + " (" + el.total + " apps)",
            });
          });
          this.setState({ categories: arr });
        } else {
          //error
        }
      })
      .catch((err) => {});
  }

  formatBytes(a, b = 2) {
    if (0 === a) return "0 Bytes";
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      " " +
      ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
    );
  }

  gotoLibLink(el) {
    window.open(el.website, "_blank"); //to open new page
  }

  sortRankingHistory(attr, isString, desc) {
    let rankingHistory = this.state.rankingHistory;
    Utils.sortByAttr(rankingHistory, attr, isString, desc);
    this.setState({ rankingHistory });
  }

  getEditorInstance(path) {
    return (
      <div className="modal-content">
        <ImageEditor
          ref={this.editorRef}
          includeUI={{
            loadImage: {
              path: this.state.screenshotToEdit,
              name: "SampleImage",
            },
            theme: blackTheme,
            initMenu: "filter",
            uiSize: {
              width: "1000px",
              height: "700px",
            },
            menuBarPosition: "bottom",
          }}
          cssMaxHeight={500}
          cssMaxWidth={1000}
          selectionStyle={{
            cornerSize: 20,
            rotatingPointOffset: 70,
          }}
          usageStatistics={true}
        />
      </div>
    );
  }

  downloadAssets() {
    let URLS = [this.state.application.icon];
    let arr = this.state.application.images;
    arr.map((el) => {
      URLS.push(el);
    });
    this.saveToZip(
      this.state.application.name.replace(/ /g, "_") + ".zip",
      URLS
    );
  }

  saveToZip(filename, urls) {
    const zip = new JSZip();
    const folder = zip.folder("assets");
    urls.forEach((url, i) => {
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = i === urls.length - 1 ? "icon.png" : "screen_" + i + ".png";
      folder.file(name, blobPromise);
    });

    zip
      .generateAsync({ type: "blob" })
      .then((blob) => FileSaver.saveAs(blob, filename))
      .catch((e) => console.log(e));
  }

  downloadApk(row = this.state.app) {
    HttpConfig(this.props.dispatch)
      .get("app/download/" + row.app_id + "/" + row.title)
      .then((res) => {
        if (res.data.status) {
          navigator.clipboard
            .writeText(decodeURI(res.data.link))
            .then(
              () => {
                this.props.notify(
                  "success",
                  "Copied",
                  "Download Link copied to clipboard"
                );
              },
              function (err) {
                console.log(err);
              }
            )
            .catch((err) => {
              console.log(err);
            });
        } else {
          //error
          this.props.notify(
            "error",
            "Error",
            "This APK file isn't yet uploaded to apkpure"
          );
        }
      })
      .catch((err) => {
        this.props.notify(
          "error",
          "error",
          "This APK file isn't yet uploaded to apkpure"
        );
      });
  }
}

export default connect()(Details)