/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from "react";
import Cookies from "js-cookie";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
// import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import moment from "moment";
import { HttpConfig } from "../../tools/Http";
import Select2 from "react-select2-wrapper";
import Slider from "nouislider";
import Avatar from "react-avatar-edit";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import CryptoJSAesJson from "../../tools/cryptojs-aes-format";
var CryptoJS = require("crypto-js");

export default class AdsProjects2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      projects: [],
      availableAdUnits: ["Banner", "Interstitial", "Rewarded Video", "Native"],
      adsNetworks: [
        {
          name: "admob",
          adUnits: [true, true, true, true],
        },
        {
          name: "facebook",
          adUnits: [true, true, true, true],
        },
        {
          name: "Unity Ads",
          adUnits: [true, true, true, false],
        },
      ],
      selectedAdsNetworks: [true, true, true],
      tableData: [],
      banners: [],
      interstitials: [],
      wizzardTitle: [
        "Package name of your application",
        "Select your preferred Ad Networks",
        "Add your ad Units Information",
        "Configure how you display Ads",
        "",
      ],
      adsProvider: "default",
      adsActivated: true,
      autoDisable: true,
      adsPerc: 100,
      package: null,
      token: null,
      isUpdating: false,
      showProjectAdd: false,
      tooltipOpen: false,
      slider1Value: 100,
      showModal: false,
      modalSteps: 2,
      adsCounter: 0,
      unityGameID: null,
    };
    // var encrypted = CryptoJS.AES.encrypt(JSON.stringify("1"), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString();
    // var decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypted, "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var slider1 = this.refs.slider1;
    if (slider1 && !this.state.sliderInit) {
      Slider.create(slider1, {
        start: [this.state.adsPerc],
        connect: [true, false],
        step: 10,
        range: { min: 0.0, max: 100.0 },
      }).on(
        "update",
        function (values, handle) {
          this.setState({
            slider1Value: values[0],
          });
        }.bind(this)
      );
      this.setState({ sliderInit: true });
    }
  }

  componentDidMount() {
    this.getProjects();
  }

  getProjects() {
    HttpConfig(window.ads_url, {
      challenge: CryptoJS.AES.encrypt(
        JSON.stringify(Cookies.get("id")),
        "sup4rsd3cr3tP4asFu",
        { format: CryptoJSAesJson }
      ).toString(),
    })
      .get("adsproject/all", {})
      .then((res) => {
        if (res.data.status) {
          this.setState({ projects: res.data.adProjects });
        } else {
          //error
          // toast.error("There was an error while loading your projects")
        }
      })
      .catch((err) => {
        // toast.error("There was an error while loading your projects")
      });
  }

  render() {
    return (
      <>
        {this.getHeader()}
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="12">
              {this.renderProjects()}
              {/*<Row>*/}
              {/*    <Col xl={6}>*/}
              {/*        {this.renderAdd()}*/}
              {/*        {this.renderControl()}*/}
              {/*    </Col>*/}
              {/*    <Col xl={6}>*/}
              {/*        {this.renderAds()}*/}
              {/*    </Col>*/}
              {/*</Row>*/}
            </Col>
          </Row>
          {this.renderModal()}
          {this.renderWizardModal()}
        </Container>
      </>
    );
  }

  renderProjects() {
    return (
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col lg="6" xs="7">
              <h3 className="mb-0">My Projects</h3>
            </Col>
            {/* <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
              {this.state.loading && (
                <Loader type="Puff" color="#5E72E4" height={30} width={30} />
              )}
            </Col> */}
          </Row>
        </CardHeader>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th>Package Name</th>
              <th>Token</th>
              <th>Ads Status</th>
              <th>Created At</th>
              <th>Last Update</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="list">
            {this.state.projects.map((el) => {
              return (
                <tr>
                  <th>{el.app_id}</th>
                  <td>{el.token}</td>
                  <td>
                    {" "}
                    {el.ads_activated ? (
                      <b>
                        <p className="text-success">Active</p>
                      </b>
                    ) : (
                      <b>
                        <p className="text-danger">Not Active</p>
                      </b>
                    )}
                  </td>
                  <td>{moment(el.created_at).format("MMM DD YYYY hh:mm")}</td>
                  <td>{moment(el.updated_at).format("MMM DD YYYY hh:mm")}</td>
                  <td>
                    <div
                      className={"btn btn-primary btn-sm"}
                      onClick={() => this.editProject(el)}
                    >
                      Edit
                    </div>
                    <Link
                      target={"blank"}
                      to={"/ads-stats?id=" + el.id + "&pkg=" + el.app_id}
                      className={"btn btn-success btn-sm"}
                      style={{ textDecoration: "none" }}
                    >
                      Stats
                    </Link>
                    <div
                      className={"btn btn-warning btn-sm"}
                      onClick={() => this.deleteProject(el)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }

  generateToken(n) {
    var chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var token = "";
    for (var i = 0; i < n; i++) {
      token += chars[Math.floor(Math.random() * chars.length)];
    }
    this.setState({ token: token + "" });
    // return token;
  }

  getHeader() {
    return (
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">Tools</h6>{" "}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-wrench" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      MobiOptions Ads Projects
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                <Button
                  className="btn-neutral"
                  color="default"
                  size="sm"
                  onClick={() => {
                    this.setState({
                      showModal: true,
                      isUpdating: false,
                      updatingId: null,
                      package: null,
                      token: null,
                      banners: [],
                      interstitials: [],
                      showProjectAdd: true,
                      adsPerc: 100,
                      slider1Value: 100,

                      unityGameID: null,
                      adsProvider: "default",
                      adsActivated: true,
                      autoDisable: false,
                      modalSteps: 0,
                      tableData: [],
                    });
                  }}
                >
                  New Project
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }

  publishProject() {
    if (this.state.isUpdating) {
      this.updateProject();
      return;
    }
    let ads = [];
    this.state.tableData.map((el, i) => {
      let data = {
        type: el.type,
        name: el.name,
      };
      el.values.map((v) => {
        if (v.adsNetwork === "admob") data.admob_id = v.value;
        if (v.adsNetwork === "facebook") data.facebook_id = v.value;
        if (v.adsNetwork === "Unity Ads") data.unity_id = v.value;
      });
      ads.push(data);
    });
    HttpConfig(window.ads_url, {
      challenge: CryptoJS.AES.encrypt(
        JSON.stringify(Cookies.get("id")),
        "sup4rsd3cr3tP4asFu",
        { format: CryptoJSAesJson }
      ).toString(),
    })
      .post("adsproject/add", {
        app_id: this.state.package,
        token: this.state.token,
        unity_game_id: this.state.unityGameID,
        ads_activated: this.state.adsActivated,
        ads_provider: this.state.adsProvider,
        auto_disable: this.state.autoDisable,
        serve_perc: this.state.slider1Value / 100,
        ads,
      })
      .then((res) => {
        if (res.data.status) {
          this.props.notify(
            "success",
            "Success",
            "Project successfully created"
          );
          this.getProjects();
        } else {
          //error
          this.props.notify(
            "danger",
            "Error",
            "There was an error while adding your project"
          );
        }
      })
      .catch((err) => {
        this.props.notify(
          "danger",
          "Error",
          "There was an error while adding your project"
        );
      });
  }

  updateProject() {
    let ads = [];
    /*
        * (
           {adsNetwork: this.state.adsNetworks[i].name,
           value:'',
           disabled:!this.state.adsNetworks[i].adUnits[j]}
        *)
        * */
    this.state.tableData.map((el, i) => {
      let data = {
        type: el.type,
        name: el.name,
      };
      el.values.map((v) => {
        if (v.adsNetwork === "admob") data.admob_id = v.value;
        if (v.adsNetwork === "facebook") data.facebook_id = v.value;
        if (v.adsNetwork === "Unity Ads") data.unity_id = v.value;
      });
      ads.push(data);
    });

    HttpConfig(window.ads_url, {
      challenge: CryptoJS.AES.encrypt(
        JSON.stringify(Cookies.get("id")),
        "sup4rsd3cr3tP4asFu",
        { format: CryptoJSAesJson }
      ).toString(),
    })
      .post("adsproject/update", {
        project_id: this.state.updatingId,
        ads_activated: this.state.adsActivated,
        ads_provider: this.state.adsProvider,
        auto_disable: this.state.autoDisable,
        unity_game_id: this.state.unityGameID,
        serve_perc: this.state.slider1Value / 100,
        ads,
      })
      .then((res) => {
        if (res.data.status) {
          this.props.notify(
            "success",
            "Success",
            "Project successfully updated"
          );
          this.getProjects();
        } else {
          //error
          this.props.notify(
            "danger",
            "Error",
            "There was an error while updating your project"
          );
        }
      })
      .catch((err) => {
        this.props.notify(
          "danger",
          "Error",
          "There was an error while updating your project"
        );
      });
  }

  deleteProject(el) {
    HttpConfig(window.ads_url, {
      challenge: CryptoJS.AES.encrypt(
        JSON.stringify(Cookies.get("id")),
        "sup4rsd3cr3tP4asFu",
        { format: CryptoJSAesJson }
      ).toString(),
    })
      .post("adsproject/del", {
        token: el.token,
      })
      .then((res) => {
        if (res.data.status) {
          this.props.notify(
            "success",
            "Success",
            "Project successfully deleted"
          );
          this.getProjects();
        } else {
          //error
          this.props.notify(
            "danger",
            "Error",
            "There was an error while deleting your project"
          );
        }
      })
      .catch((err) => {
        this.props.notify(
          "danger",
          "Error",
          "There was an error while deleting your project"
        );
      });
  }

  editProject(el) {
    let tableData = [];
    el.ads.map((adsRow, i) => {
      let values = [];
      // if (/*adsRow.admob_id && this.state.selectedAdsNetworks[0])
      values.push({
        adsNetwork: "admob",
        value: adsRow.admob_id,
        disabled: false,
      });
      // if (/*adsRow.facebook_id && */this.state.selectedAdsNetworks[1])
      values.push({
        adsNetwork: "facebook",
        value: adsRow.facebook_id,
        disabled: false,
      });
      // if (/*adsRow.unity_id && */this.state.selectedAdsNetworks[2])
      values.push({
        adsNetwork: "Unity Ads",
        value: adsRow.unity_id,
        disabled: false,
      });
      tableData.push({
        name: adsRow.name,
        type: adsRow.type,
        values,
      });
    });
    this.setState({
      showModal: true,
      isUpdating: true,
      updatingId: el.id,
      package: el.app_id,
      token: el.token,
      unityGameID: el.unity_game_id,
      showProjectAdd: true,
      adsProvider: el.ads_provider,
      adsActivated: el.ads_activated === 1,
      autoDisable: el.auto_disable === 1,
      adsPerc: el.serve_perc * 100,
      slider1Value: el.serve_perc * 100,
      tableData,
      modalSteps: 0,
    });
  }

  toggleStuff(str, bool) {
    if (str === "default" && bool) this.setState({ adsProvider: "default" });
    if (str === "default" && !bool) this.setState({ adsProvider: "admob" });
    if (str === "rotation" && bool) this.setState({ adsProvider: "rotation" });
    if (str === "rotation" && !bool) this.setState({ adsProvider: "default" });
    if (str === "admob" && bool) this.setState({ adsProvider: "admob" });
    if (str === "admob" && !bool) this.setState({ adsProvider: "default" });
    if (str === "facebook" && bool) this.setState({ adsProvider: "facebook" });
    if (str === "facebook" && !bool) this.setState({ adsProvider: "default" });
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  renderModal() {
    let data = [];
    this.state.selectedAdsNetworks.map((el, i) => {
      if (el)
        data.push({
          id: this.state.adsNetworks[i].name,
          text: this.state.adsNetworks[i].name,
        });
    });

    return (
      <Modal
        className="modal-dialog-centered"
        isOpen={this.state.defaultModal}
        toggle={() => this.toggleModal("defaultModal")}
        size={"lg"}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Select Ad
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => this.toggleModal("defaultModal")}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className="modal-body"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Select2
            className="form-control"
            value={
              this.state.adsProvider === "unity"
                ? "Unity Ads"
                : this.state.adsProvider
            }
            onSelect={(e) =>
              this.setState({
                adsProvider:
                  e.target.value === "Unity Ads" ? "unity" : e.target.value,
              })
            }
            options={{
              placeholder: "Select Ad Network",
            }}
            data={data}
          />
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            onClick={() => {
              this.toggleModal("defaultModal");
            }}
          >
            Save changes
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              this.toggleModal("defaultModal");
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  renderWizardModal() {
    return (
      <Modal
        className="modal-dialog-centered"
        size={"lg"}
        isOpen={this.state.showModal}
        toggle={() => this.toggleModal("showModal")}
      >
        <ModalHeader>
          <h6 className="modal-title" id="modal-title-default">
            {this.state.wizzardTitle[this.state.modalSteps]}
          </h6>
        </ModalHeader>

        <ModalBody
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={"ml-0 mr-0 pl-0 pr-0"}
        >
          {this.state.modalSteps === 0 && this.renderWizardStep0()}
          {this.state.modalSteps === 1 && this.renderWizardStep1()}
          {this.state.modalSteps === 2 && this.renderWizardStep2()}
          {this.state.modalSteps === 3 && this.renderWizardStep3()}
        </ModalBody>
        <ModalFooter>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            size={"sm"}
            onClick={() => {
              this.toggleModal("showModal");
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            type="button"
            size={"sm"}
            onClick={() => {
              this.validateAndIncrementStep();
              // this.toggleModal("showModal");
            }}
          >
            {this.state.modalSteps === 3 ? "Publish" : "Next"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderWizardStep0() {
    return (
      <div style={{ width: "100%" }} className={"p-4"}>
        <Form>
          <Row>
            <Col sm="8" xl={9}>
              <Input
                placeholder="Package Name"
                type="text"
                disabled={!!this.state.token}
                value={this.state.package}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    // this.searchForApp();
                  }
                }}
                onChange={(e) => this.setState({ package: e.target.value })}
              />
            </Col>
            <Col sm={"4"} xl={3}>
              <FormGroup>
                <Button
                  className={"btn-success"}
                  disabled={!!this.state.token || !this.state.package}
                  onClick={() => {
                    // if(!this.state.token)
                    this.generateToken(30);
                  }}
                >
                  Get Token
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className={"mb-0"}>
              <FormGroup>
                <label id="input-username" className="form-control-label">
                  <span className="text-muted mb-0">
                    {" "}
                    {this.state.token && "Generated Token: "}
                  </span>
                  <b> {this.state.token}</b>&nbsp;
                </label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  renderWizardStep1() {
    return (
      <div style={{ width: "100%" }} className={"p-4"}>
        <Form>
          <Row>
            {this.state.adsNetworks.map((el, i) => {
              return (
                <Col sm="4" xl={4}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      id="input-username"
                      className="form-control-label text-muted mb-0 ml-2"
                    >
                      {el.name}&nbsp;&nbsp;
                    </label>
                    <div>
                      <label className="custom-toggle custom-toggle-primary mr-1">
                        <input
                          type="checkbox"
                          checked={this.state.selectedAdsNetworks[i]}
                          onChange={(e) => {
                            let selectedAdsNetworks =
                              this.state.selectedAdsNetworks;
                            selectedAdsNetworks[i] = e.target.checked;
                            this.setState({ selectedAdsNetworks });
                          }}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Form>
      </div>
    );
  }

  renderWizardStep2() {
    return (
      <div style={{ width: "100%" }}>
        <Row className={"ml-0 mr-0 pl-0 pr-0"}>
          <Col
            lg={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {this.state.selectedAdsNetworks[2] && (
              <Input
                style={{ maxWidth: 200 }}
                placeholder={"Unity Game ID"}
                type={"text"}
                size={"sm"}
                value={this.state.unityGameID}
                onChange={(e) => {
                  this.setState({ unityGameID: e.target.value });
                }}
              />
            )}
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn"
                color="primary"
                role="button"
                size="sm"
              >
                + Ad Unit
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {this.state.availableAdUnits.map((el, j) => {
                  return (
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        let arr = this.state.tableData;
                        let values = [];
                        this.state.adsNetworks.map((el, i) => {
                          if (el)
                            values.push({
                              adsNetwork: this.state.adsNetworks[i].name,
                              value: "",
                              disabled: !this.state.adsNetworks[i].adUnits[j],
                            });
                        });
                        arr.push({
                          type: el,
                          name:
                            el.replace(/\s/g, "").toLowerCase() +
                            "_" +
                            this.state.adsCounter,
                          values,
                        });
                        this.setState({
                          tableData: arr,
                          adsCounter: this.state.adsCounter + 1,
                        });
                      }}
                    >
                      {el}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col lg={12} className={"ml-0 mr-0 pl-0 pr-0 mt-2"}>
            <div className="table-responsive" ref="first-list">
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th>Ad Unit Name</th>
                    {this.state.selectedAdsNetworks.map((el, i) => {
                      if (el) return <th>{this.state.adsNetworks[i].name}</th>;
                      return null;
                    })}
                  </tr>
                </thead>
                <tbody className="list">
                  {this.state.tableData.map((el, i) => {
                    return [
                      <tr>
                        <td style={{ minWidth: 150 }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <i
                              className="fa fa-trash text-danger cursor-pointer font-weight-100"
                              onClick={() => {
                                let tableData = this.state.tableData;
                                tableData.splice(i, 1);
                                this.setState({ tableData });
                              }}
                              style={{ marginRight: 8 }}
                            />
                            <Input
                              size={"sm"}
                              placeholder={"Ad unit Name"}
                              type={"text"}
                              value={el.name}
                              onChange={(e) => {
                                let tableData = this.state.tableData;
                                tableData[i].name = e.target.value;
                                this.setState({ tableData });
                              }}
                            />
                          </div>
                        </td>
                        {el.values.map(
                          (adUnit, j) =>
                            this.state.selectedAdsNetworks[j] && (
                              <td>
                                <Input
                                  size={"sm"}
                                  placeholder={
                                    this.state.tableData[i].values[j].disabled
                                      ? "Not Supported!"
                                      : "Ad Id here"
                                  }
                                  type={"text"}
                                  disabled={
                                    this.state.tableData[i].values[j].disabled
                                  }
                                  value={adUnit.value}
                                  onChange={(e) => {
                                    let tableData = this.state.tableData;
                                    tableData[i].values[j].value =
                                      e.target.value;
                                    this.setState({ tableData });
                                  }}
                                />
                              </td>
                            )
                        )}
                      </tr>,
                    ];
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderWizardStep3() {
    return (
      <div style={{ width: "100%" }} className={"p-4"}>
        <Form>
          <Row>
            <Col lg={"6"}>
              <Row>
                <Col lg={6}>
                  <label id="input-username" className="form-control-label">
                    Ads status
                  </label>
                  <i
                    className="ml-2 fa fa-question-circle"
                    aria-hidden="true"
                    id="tt0"
                  />
                  <Tooltip
                    placement="bottom"
                    isOpen={this.state.tt0}
                    target="tt0"
                    toggle={() => this.setState({ tt0: !this.state.tt0 })}
                  >
                    Enable or disable Ads in your app
                  </Tooltip>
                </Col>
                <Col lg={6}>
                  {this.state.adsActivated ? (
                    <Button
                      className={"btn-success"}
                      onClick={() => this.setState({ adsActivated: false })}
                      size={"sm"}
                    >
                      {" "}
                      active
                    </Button>
                  ) : (
                    <Button
                      className={"btn-danger"}
                      onClick={() => this.setState({ adsActivated: true })}
                      size={"sm"}
                    >
                      {" "}
                      inactive
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            <Col lg={"6"}>
              <Row>
                <Col lg={6}>
                  <label id="TooltipExample" className="form-control-label">
                    Auto disable
                  </label>
                  <i
                    className="ml-2 fa fa-question-circle"
                    aria-hidden="true"
                    id="tt1"
                  />
                  <Tooltip
                    placement="bottom"
                    isOpen={this.state.tt1}
                    target="tt1"
                    toggle={() => this.setState({ tt1: !this.state.tt1 })}
                  >
                    The app will automatically stop serving ads if it gets
                    removed from playstore.
                  </Tooltip>
                </Col>
                <Col lg={6}>
                  {this.state.autoDisable ? (
                    <Button
                      className={"btn-success"}
                      onClick={() => this.setState({ autoDisable: false })}
                      size={"sm"}
                    >
                      {" "}
                      active
                    </Button>
                  ) : (
                    <Button
                      className={"btn-danger"}
                      onClick={() => this.setState({ autoDisable: true })}
                      size={"sm"}
                    >
                      {" "}
                      inactive
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row lg={"12"} className={"mt-4"}>
            <Col lg={"3"} className={"mr-0 pr-0"}>
              <label id="input-username" className="form-control-label">
                Serve Method
              </label>
            </Col>
            <Col lg={"9"}>
              <Row>
                {/*<Col lg={"4"} className={"mr-0 pr-0"}>*/}
                {/*    <div style={{height:'100%', display:'flex', alignItems:"center"}}>*/}
                {/*        <label*/}
                {/*            id="input-username"*/}
                {/*            className="form-control-label text-muted mb-0">*/}
                {/*            FillRate &nbsp;&nbsp;</label>*/}
                {/*        <div>*/}
                {/*            <label className="custom-toggle custom-toggle-success mr-1">*/}
                {/*                <input checked={this.state.adsProvider === "default"}*/}
                {/*                       type="checkbox" onChange={(e) => this.toggleStuff("default",e.target.checked)}/>*/}
                {/*                <span*/}
                {/*                    className="custom-toggle-slider rounded-circle"*/}
                {/*                />*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*        <i className="ml-2 fa fa-question-circle" aria-hidden="true"*/}
                {/*           id="tt2"*/}
                {/*        />*/}
                {/*        <Tooltip placement="bottom" isOpen={this.state.tt2} target="tt2" toggle={() => this.setState({tt2:!this.state.tt2})}>*/}
                {/*            FillRate displays facebook ads when admob ads fail to load. You can force the app to show only admob or facebook ads..                                              </Tooltip>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col lg={"6"} className={"mr-0 pr-0"}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      id="input-username"
                      className="form-control-label text-muted mb-0"
                    >
                      Single &nbsp;&nbsp;
                    </label>
                    <div>
                      <label className="custom-toggle custom-toggle-success mr-1">
                        <input
                          checked={
                            this.state.adsProvider === "admob" ||
                            this.state.adsProvider === "facebook" ||
                            this.state.adsProvider === "unity"
                          }
                          type="checkbox"
                          onChange={(e) => {
                            this.toggleModal("admob", e.target.checked);
                            if (e.target.checked)
                              this.toggleModal("defaultModal");
                          }}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </div>
                    <i
                      className="ml-2 fa fa-question-circle"
                      aria-hidden="true"
                      id="ttx2"
                    />
                    <Tooltip
                      placement="bottom"
                      isOpen={this.state.ttx2}
                      target="ttx2"
                      toggle={() => this.setState({ ttx2: !this.state.ttx2 })}
                    >
                      Show either Facebook Ads or Admob Ads
                    </Tooltip>
                  </div>
                </Col>
                <Col lg={"6"} className={"mr-0 pr-0"}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label
                      id="input-username"
                      className="form-control-label text-muted mb-0"
                    >
                      Rotation &nbsp;&nbsp;
                    </label>
                    <div>
                      <label className="custom-toggle custom-toggle-success mr-1">
                        <input
                          checked={this.state.adsProvider === "rotation"}
                          type="checkbox"
                          onChange={(e) =>
                            this.toggleStuff("rotation", e.target.checked)
                          }
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </div>
                    <i
                      className="ml-2 fa fa-question-circle"
                      aria-hidden="true"
                      id="ttc2"
                    />
                    <Tooltip
                      placement="bottom"
                      isOpen={this.state.ttc2}
                      target="ttc2"
                      toggle={() => this.setState({ ttc2: !this.state.ttc2 })}
                    >
                      1st interstitial will be served from admob, 2nd
                      interstitial will be served from facebook Ad Network...
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row lg={"12"} className={"mt-3"}>
            <Col lg={"3"} className={"mr-0 pr-0"}>
              <label id="input-username" className="form-control-label">
                Serve Percentage
              </label>
              <i
                className="ml-2 fa fa-question-circle"
                aria-hidden="true"
                id="tt3"
              />
              <Tooltip
                placement="bottom"
                isOpen={this.state.tt3}
                target="tt3"
                toggle={() => this.setState({ tt3: !this.state.tt3 })}
              >
                How much ads are displayed to the users
              </Tooltip>
            </Col>
            <Col lg="9">
              <div className="input-slider-container">
                <div className="input-slider" ref="slider1" />
                <Row className="mt-3">
                  <Col xs="6">
                    <span className="range-slider-value">
                      {Math.floor(this.state.slider1Value)}%
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  validateAndIncrementStep() {
    if (this.state.modalSteps === 0) {
      if (!this.state.token) {
        this.props.notify(
          "danger",
          "Error",
          "You need to specify a package name and generate a token"
        );
      } else {
        this.setState({ modalSteps: 1 });
      }
      return;
    }
    if (this.state.modalSteps === 1) {
      let hasCheckedOne = false;
      this.state.selectedAdsNetworks.map((el) => {
        if (el) {
          hasCheckedOne = true;
        }
      });
      if (!hasCheckedOne) {
        this.props.notify(
          "danger",
          "Error",
          "You must at least select one ad network!"
        );
      } else {
        this.setState({ modalSteps: 2 });
      }
      return;
    }
    if (this.state.modalSteps === 2) this.setState({ modalSteps: 3 });
    if (this.state.modalSteps === 3) this.publishProject();
  }
}
