/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Badge,
    Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle,
    Col,
    Container, Modal, Row,

} from "reactstrap";
import {Link} from "react-router-dom";
import {HttpConfig} from "../../tools/Http";
import VanillaCard from "../../components/Mobi/VanillaCard";

export default class Error404 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            isLoading: true,
            cardsNumber: 0,
            payPalClass:"",
            cihClass:"",
            quantity:1,
            orderProgress:0,// 0: quantity, 1: payment method, 2: CIH wizard
            availableCards:0,
            availableCardsFetching: false,
            availableCardsError: false,
            usedCardNumber:0,
            unusedCardNumber:0,
            pending:0
        };


    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        HttpConfig().get('cards')
            .then(res => {
                if (res.data.status) {
                    this.setState({cards: res.data.cards, cardsNumber: res.data.cards.length},() => {
                        setTimeout(() => {this.setState({isLoading:false})}, 4500);
                    });
                    let used = 0;
                    let unused = 0;
                    res.data.cards.map(el => {
                        if(el.used)
                            used++;
                        else
                            unused++;
                        this.setState({usedCardNumber:used, unusedCardNumber:unused})
                    })
                }
                else {
                    //error
                }
            }).catch(err => {
        }).catch(err => {

        })
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row >
                        {this.state.cards.map((el,i) => {
                            return this.getCards(el,i)}
                        )}
                    </Row>
                </Container>
            </>
        )
    }

    getCards(card,i){
        return(
            <VanillaCard card={card} i={i} switchCardState={this.switchCardState.bind(this)}/>
            );
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    {"Cards"}
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-credit-card" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            Vanilla Credit Cards
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                                <Button className="btn-neutral" color="default" size="sm"
                                        // onClick={/*()=>this.setState({defaultModal:true})*/}
                                        disabled>
                                    Purchase
                                </Button>
                            </Col>
                        </Row>
                        {this.renderModal()}
                    </div>
                </Container>
            </div>
        );
    }

    switchCardState(el){
        this.props.notify("secondary", "Saving","Please wait while switching your card's state.");
        HttpConfig().get('/cards/'+(el.used ? 'notused/' : 'used/') + el.id)
            .then(res => {
                if (res.data.status) {
                    this.getData();
                    this.props.notify("success", "Saved","Card successfully saved");
                }
                else {
                    this.props.notify("danger", "Error","An error occurred while updating your card");
                }
            }).catch(err => {
            this.props.notify("danger", "Error","An error occurred while updating your card");
        })
    }

    renderModal(){
        return (
            <Modal
                className="modal-lg modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.setState({defaultModal:!this.state.defaultModal})}
            >
                {this.state.orderProgress === 0 && this.getQuantityBody()}
                {this.state.orderProgress === 1 && this.getPaymentBody()}

            </Modal>
        )
    }

    getQuantityBody(){
        return(
            <div className="modal-body" style={{padding:0, margin:0}}>
                <div style={{display:'flex', width:'100%', height:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                    <div style={{width:'50%', boxShadow:'10px 0px 24px -6px rgba(0,0,0,0.34)', backgroundColor:'#f8f8fa'}}>
                        <img src={require("assets/img/vc.jpg")}
                             style={{width:'100%'}} />
                    </div>
                    <div style={{width:'50%', padding:25}}>
                        {!this.state.availableCardsError &&
                        <h3>Please select the number of cards:</h3>
                        }
                        {this.state.availableCardsError &&
                        <h5 style={{color:'#f44'}}>
                            {this.state.availableCards > 0 &&
                            <span>At the moment you can only buy <u>{this.state.availableCards}</u> cards</span>
                            }
                            {this.state.availableCards === 0 &&
                            <span>We are sorry we have no cards left :/ please check back later</span>
                            }
                        </h5>
                        }
                        <br/>

                        <form className={"card"} style={{padding:10, boxShadow:'0px 0px 17px -2px rgba(0,0,0,0.35)'}}>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Quantity</label>
                                <input type="number" className="form-control" value={this.state.quantity}
                                       onChange={(e) => this.setState({quantity:e.target.value})}/>
                            </div>

                            <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                                <label htmlFor="exampleInputEmail1">Sub Total:</label>
                                <label htmlFor="exampleInputEmail1"><b>{this.state.quantity*35}$</b></label>
                            </div>
                        </form>
                        <br/>

                        <form className={"card"} style={{padding:10, boxShadow:'0px 0px 17px -2px rgba(0,0,0,0.35)'}}>
                            <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                                <label htmlFor="exampleInputEmail1">items ({this.state.quantity})</label>
                                <label htmlFor="exampleInputEmail1"><b>{35*this.state.quantity}$</b></label>
                            </div>
                            <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                                <label htmlFor="exampleInputEmail1">Service Fee (PayPal Payment)</label>
                                <label htmlFor="exampleInputEmail1"><b>1.75$</b></label>
                            </div>
                            <hr/>
                            <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                                <label htmlFor="exampleInputEmail1"><b style={{color:'#007BFF'}}>Order Total:</b></label>
                                <label htmlFor="exampleInputEmail1"><b style={{color:'#007BFF'}}>{this.state.quantity*35+1.75}$</b></label>
                            </div>
                        </form>
                        <br/>
                        <div style={{display:'flex', width:'100%', flexDirection:'row', justifyContent:'space-between'}}>
                            <div/>

                            {!this.state.availableCardsFetching &&
                            <button type="button" className="btn btn-primary"
                                    onClick={() =>
                                    {this.setState({availableCardsFetching:true,availableCardsError:false},() => {
                                        this.getAvailableCards();
                                    })
                                    }}
                            >Place Order</button>
                            }

                            {this.state.availableCardsFetching &&
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                &nbsp;Loading...
                            </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getPaymentBody(){
        return (
            <div className="modal-body">
                <br/>
                <h5>Please select your preferred payment method</h5>
                <br/>
                <div style={{display:'flex', width:'100%', height:'100%', flexDirection:'row', alignItems:'center', justifyContent:'space-around'}}>
                    <div style={{ width:'42%', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <div className={this.state.payPalClass+" card hoverable-card"} style={{ width:'100%'}} onClick={() => {}/*this.setSelectedPaymentMethod(0)*/}>
                            <div>
                                <img src={require("assets/img/ppl_2.jpg")} className={" card-img-top pointer-cursor"} />
                            </div>
                        </div>
                        <br/>
                        <span style={{color:"#3C3A3A"}}><b>Checkout With PayPal</b></span>
                    </div>

                    <div style={{ width:'42%', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <div className={this.state.cihClass+" card  hoverable-card"} style={{ width:'100%'}} onClick={() => {}/*this.setSelectedPaymentMethod(1)*/}>
                            <div>
                                <img src={require("assets/img/cih_2.jpg")} className={" card-img-top pointer-cursor"} />
                            </div>
                        </div>
                        <br/>
                        <span style={{color:"#3C3A3A"}}><b>Checkout With CIH*</b></span>
                    </div>
                </div>

                <br/>
                <hr/>
                <div style={{fontSize:10}}>* CIH Payments may take up to 24H to be validated</div>
                <div style={{fontSize:10}}>** The prices may change according to your local currency</div>

                <div className={"text-center"}>
                    <button type="button" className="btn btn-primary btn-lg" disabled={this.state.payPalClass === "" && this.state.cihClass === "" }
                            onClick={() => {
                                if(this.state.payPalClass === "")
                                    this.setState({orderProgress:2});
                                else
                                    this.startPayPalPayment()
                            }}>Pay Now</button>
                </div>
            </div>
        );
    }

    getAvailableCards() {
        HttpConfig().get('cards/available')
            .then(res => {
                if (res.data.status) {
                    this.setState({availableCards: res.data.available_cards, availableCardsFetching:false},() => {
                        if(this.state.quantity <= this.state.availableCards)
                            this.setState({orderProgress:1});
                        else{
                            this.setState({availableCardsError:true});
                        }
                    });
                }
                else {
                    //error
                }
            }).catch(err => {
            //err
        })
    }
}
