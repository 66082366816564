import React from 'react';
import {checkToken, resetPassword, setNewPassword} from './JwtToken';
import {Link} from "react-router-dom";
import AuthHeader from "../../../components/Headers/AuthHeader";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import classnames from "classnames";
import axios from 'axios'

const queryString = require('query-string');


export default class ChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            setNewPassword: '',
            ConfirmPassword: '',
            error: {has_error: false, message: ''},
            success: {has_success: false, message: ''},
            loading: true,
            first: true,
            token:""
        };

    }

    componentDidMount() {
        let token = queryString.parse(this.props.location.search)['token'] || ""
        if(token == "") {
            this.props.history.push('/auth/login');
            return false;
        }
        this.checkToken(token);
    }

    async checkToken(token) {
        axios.get(`${window.api_url}api/find/${token}`)
        .then(async res => {
            if (res.data.status) {
                this.setState({token:token, loading:false, first:false})
            } else {
                this.props.history.push('/auth/login', {token: true});
            return false;
            }
        })
        .catch(err => {this.setState({isVerified:false, loading:false})});
    }

    formValidation() {
        return (
            this.state.setNewPassword.length >= 6 &&
            this.state.ConfirmPassword.length >= 6 &&
            this.state.setNewPassword === this.state.ConfirmPassword
        );
    }

    setLoading(loading) {
        this.setState({loading: loading})
    }

    validationMessage() {
        if (this.state.error.has_error) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-danger">
                        {this.state.error.message}
                    </div>
                </div>
            )
        } else if (this.state.success.has_success) {
            return (
                <div className='row'>
                    <div className="col-md-12 text-success">
                        {this.state.success.message}
                    </div>
                </div>
            )
        }
        return '';
    }

    handleSubmit() {
        if(!this.formValidation()){
            let error = {message: "Please insert some valide matching passwords", has_error: true};
            this.setState({error});
            return
        }
        this.setState({loading: true});
        setNewPassword({
            password: this.state.setNewPassword,
            password_confirmation: this.state.ConfirmPassword,
            token: this.state.token
        }, this.formValidation.bind(this), this.setLoading.bind(this));
    }


    render() {
        if (!this.state.first)
        return (
            <>
                <AuthHeader
                    title="Welcome!"
                    lead="Change your Mobioptions password"
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-center text-muted mb-4">
                                        <small>Change Your Password</small>
                                        {this.validationMessage()}
                                    </div>
                                    <FormGroup
                                        className={classnames({
                                            setNewPassword: this.state.setNewPassword
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Enter New Password"
                                                type="Enter New Password"
                                                onChange={(e) => this.setState({setNewPassword: e.target.value})}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup
                                        className={classnames({
                                            ConfirmPassword: this.state.ConfirmPassword
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open"/>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Confirm Password"
                                                type="Confirm Password"
                                                onChange={(e) => this.setState({ConfirmPassword: e.target.value})}
                                            />
                                        </InputGroup>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="button" disabled={this.state.setNewPassword.length < 6 || this.state.ConfirmPassword.length < 6 || this.state.setNewPassword !== this.state.ConfirmPassword || this.state.loading} onClick={(e) => {e.preventDefault(); this.handleSubmit()}}>
                                                Validate
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
    }
