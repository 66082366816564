/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { useLocation } from "react-router";
import ReactHtmlParser from "react-html-parser";

export default notify => {
  const queryString = require('query-string');
  const location = useLocation();
  const [content, setContent] = useState();

  useEffect(() => {
    let token = queryString.parse(location.search)['token']
    if(token == null) {
      window.location.href = window.main_domain
      return false;
    }
    Axios.get(`${window.api_url}api/preview-privacy-policy?token=${token}`)
      .then(res => {
        if (res.data.status) {
          setContent(res.data.content);
          document.getElementsByTagName("title")[0].text = "Privacy Policy";
        } else {
          window.location.href = window.main_domain
          return false;
        }
      })
      .catch(err => {});
  }, []);

  return (
    <div className="container-fluid privacy-container bg-gradient-white pt-2">
      {ReactHtmlParser(content)}
    </div>
  );
};
