export default [
  {
    question: "Console creating date",
    type: "date",
    answer: null
  },
  {
    question: "Console creation method",
    answer: null,
    options: [
      {
        label: "Virtual Machine",
        v: "Virtual Machine",
        answer: null,
        subQuestions: [
          {
            question: "Which type?",
            answer: null,
            options: [
              {
                label: "VBox",
                v: "VBox",
                answer: null,
                subQuestions: [
                  {
                    question: "Which type of internet connection?",
                    answer: null,
                    options: [
                      {
                        label: "4G",
                        v: "4G",
                        answer: null
                      },
                      {
                        label: "VPN",
                        v: "VPN",
                        answer: null,
                        subQuestions: [
                          {
                            question: "Which type?",
                            answer: null,
                            options: [
                              {
                                label: "911",
                                v: "911"
                              },
                              {
                                label: "913",
                                v: "913"
                              },
                              {
                                label: "914",
                                v: "914"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        label: "Modem",
                        v: "Modem",
                        answer: null
                      }
                    ]
                  }
                ]
              },
              {
                label: "VM Ware",
                v: "VM Ware",
                answer: null,
                subQuestions: [
                  {
                    question: "Which type of internet connection?",
                    answer: null,
                    options: [
                      {
                        label: "4G",
                        v: "4G",
                        answer: null
                      },
                      {
                        label: "VPN",
                        v: "VPN",
                        answer: null,
                        subQuestions: [
                          {
                            question: "Which type?",
                            answer: null,
                            options: [
                              {
                                label: "911",
                                v: "911"
                              },
                              {
                                label: "913",
                                v: "913"
                              },
                              {
                                label: "914",
                                v: "914"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        label: "Modem",
                        v: "Modem",
                        answer: null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: "RDP",
        v: "RDP",
        answer: null,
        subQuestions: [
          {
            label: "Which host?",
            v: "Which host?",
            answer: null,
            options: [
              {
                label: "Amazon AWS",
                v: "Amazon AWS",
                answer: null
              },
              {
                label: "Azur",
                v: "Azur",
                answer: null
              },
              {
                label: "Voltran",
                v: "Volran",
                answer: null
              }
            ]
          }
        ]
      },
      {
        label: "PC + Mode",
        v: "PC + Model",
        answer: null
      },
      {
        label: "PC + 4G",
        v: "PC + 4G",
        answer: null
      }
    ]
  },
  {
    question: "Console payments method",
    answer: null,
    options: [
      {
        label: "Wize",
        v: "Wize",
        answer: null
      },
      {
        label: "Revolit",
        v: "Revolit",
        answer: null
      },
      {
        label: "Gift card",
        v: "Gift card",
        answer: null
      },
      {
        label: "Credit Card",
        v: "Credit Card",
        answer: null
      }
    ]
  },
  {
    question: "Console email provider",
    answer: null,
    options: [
      {
        label: "Outlook",
        v: "Outlook",
        answer: null
      },
      {
        label: "Gmail",
        v: "Gmail",
        answer: null,
        subQuestions: [
          {
            question: "Creation date",
            answer: null,
            options: [
              {
                label: "Old",
                v: "Old",
                answer: null
              },
              {
                label: "New",
                v: "New",
                answer: null
              }
            ]
          }
        ]
      },
      {
        label: "Mail",
        v: "Mail",
        answer: null
      },
      {
        label: "Pro mail",
        v: "Pro mail",
        answer: null
      }
    ]
  },
  {
    question: "Account warmed with legal apps?",
    answer: null,
    options: [
      {
        label: "No",
        v: "No",
        answer: null
      },
      {
        label: "Yes",
        v: "Yes",
        answer: null,
        subQuestions: [
          {
            question: "How many?",
            answer: null,
            options: [
              {
                label: "1",
                v: "1",
                answer: null
              },
              {
                label: "2",
                v: "2",
                answer: null
              },
              {
                label: "3",
                v: "3",
                answer: null
              },
              {
                label: "+4",
                v: "+4",
                answer: null
              }
            ]
          }
        ]
      }
    ]
  },
  {
    question: "Number of spam apps in account",
    answer: null,
    options: [
      {
        label: "1",
        v: "1",
        answer: null
      },
      {
        label: "2",
        v: "2",
        answer: null
      },
      {
        label: "3",
        v: "3",
        answer: null
      },
      {
        label: "+4",
        v: "+4",
        answer: null
      }
    ]
  },
  {
    question: "App privacy policy",
    answer: null
  },
  {
    question: "Website in app details",
    answer: null
  },
  {
    question: "Developer profile graphics",
    answer: null
  },
  {
    question: "Developer address in developer information",
    answer: null
  },
  {
    question: "App signing",
    answer: null,
    options: [
      {
        label: "In a virtual machine",
        v: "In a virtual machine",
        answer: null,
        subQuestions: [
          {
            question: "Which type?",
            answer: null,
            options: [
              {
                label: "VBox",
                v: "VBox",
                answer: null,
                subQuestions: [
                  {
                    question: "Which type of internet connection?",
                    answer: null,
                    options: [
                      {
                        label: "4G",
                        v: "4G",
                        answer: null
                      },
                      {
                        label: "VPN",
                        v: "VPN",
                        answer: null,
                        subQuestions: [
                          {
                            question: "Which type?",
                            answer: null,
                            options: [
                              {
                                label: "911",
                                v: "911"
                              },
                              {
                                label: "913",
                                v: "913"
                              },
                              {
                                label: "914",
                                v: "914"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        label: "Modem",
                        v: "Modem",
                        answer: null
                      }
                    ]
                  }
                ]
              },
              {
                label: "VM Ware",
                v: "VM Ware",
                answer: null,
                subQuestions: [
                  {
                    question: "Which type of internet connection?",
                    answer: null,
                    options: [
                      {
                        label: "4G",
                        v: "4G",
                        answer: null
                      },
                      {
                        label: "VPN",
                        v: "VPN",
                        answer: null,
                        subQuestions: [
                          {
                            question: "Which type?",
                            answer: null,
                            options: [
                              {
                                label: "911",
                                v: "911"
                              },
                              {
                                label: "913",
                                v: "913"
                              },
                              {
                                label: "914",
                                v: "914"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        label: "Modem",
                        v: "Modem",
                        answer: null
                      }
                    ]
                  }
                ]
              },
              {
                label: "RDP",
                v: "RDP",
                answer: null,
                subQuestions: [
                  {
                    label: "Which host?",
                    v: "Which host?",
                    answer: null,
                    options: [
                      {
                        label: "Amazon AWS",
                        v: "Amazon AWS",
                        answer: null
                      },
                      {
                        label: "Azur",
                        v: "Azur",
                        answer: null
                      },
                      {
                        label: "Voltran",
                        v: "Volran",
                        answer: null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: "In main pc",
        v: "In main pc",
        answer: null,
        subQuestions: [
          {
            question: "Which tool?",
            answer: null,
            options: [
              {
                label: "Android studio",
                v: "Android studio",
                answer: null
              },
              {
                label: "MobiOptions Signer",
                v: "MobiOptions Signer",
                answer: null
              },
              {
                label: "Other tools",
                v: "Other tools",
                answer: null
              }
            ]
          }
        ]
      }
    ]
  },
  {
    question: "App created with",
    answer: null,
    options: [
      {
        label: "Android Studio",
        v: "Android Studio",
        answer: null
      },
      {
        label: "Unity 3D",
        v: "Unity 3D",
        answer: null
      },
      {
        label: "Build Box",
        v: "Build Box",
        answer: null
      },
      {
        label: "Other tools",
        v: "Other tools",
        answer: null
      }
    ]
  },
  {
    question: "Ad network",
    answer: null,
    subQuestions: [
      {
        label: "Admob",
        v: "Admob",
        answer: null
      },
      {
        label: "Fan + Iron source",
        v: "Fan + Iron source",
        answer: null
      },
      {
        label: "Unity",
        v: "Unity",
        answer: null
      },
      {
        label: "Mediatio",
        v: "Mediatio",
        answer: null
      },
      {
        label: "Start.io",
        v: "Start.io",
        answer: null
      },
      {
        label: "Topdoq",
        v: "Topdoq",
        answer: null
      },
      {
        label: "Others..",
        v: "Others..",
        answer: null
      }
    ]
  },
  {
    question: "Code source",
    answer: null,
    options: [
      {
        label: "New",
        v: "New",
        answer: null
      },
      {
        label: "Reskined many times",
        v: "Reskined many times",
        answer: null
      }
    ]
  },
  {
    question: "Title Includes (guide, tips, trick..)",
    answer: null
  },
  {
    question: "Description disclaimer note",
    answer: null
  },
  {
    question: "Black spam",
    answer: null,
    options: [
      {
        label: "Downloader",
        v: "Downloader",
        answer: null
      },
      {
        label: "TV",
        v: "TV",
        answer: null
      },
      {
        label: "Prank",
        v: "Prank",
        answer: null
      },
      {
        label: "Only Fans",
        v: "Only Fans",
        answer: null
      }
    ]
  },
  {
    question: "If you have 1 app, N° of downloads",
    answer: null,
    options: [
      {
        label: "-500",
        v: "-500",
        answer: null
      },
      {
        label: "+1000",
        v: "+1000",
        answer: null
      },
      {
        label: "5k-10k",
        v: "5k-10k",
        answer: null
      },
      {
        label: "+50k",
        v: "+50k",
        answer: null
      }
    ]
  },
  {
    question: "If you have 1 app, what is the category",
    answer: null,
    options: [
      {
        label: "Games",
        v: "Games",
        answer: null
      },
      {
        label: "Books and references",
        v: "Books and references",
        answer: null
      },
      {
        label: "App",
        v: "App",
        answer: null
      }
    ]
  }
];
