import React from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Input, InputGroup } from "reactstrap";
import classnames from "classnames";
import { variables as vs } from "./data";

const Form = ({ setVariables, variables }) => (
  <Col xl={6} className={"flex"}>
    <h2>General Information</h2>
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        Application Name
      </label>
      <InputGroup className={classnames("input-group-merge", {})}>
        <Input
          placeholder="App Name"
          type="text"
          onChange={e => {
            setVariables({
              ...variables,
              APP_NAME: e.target.value
            });
          }}
          value={variables[vs.APP_NAME]}
        />
      </InputGroup>
    </FormGroup>
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        Developer Name
      </label>
      <InputGroup className={classnames("input-group-merge", {})}>
        <Input
          placeholder="Developer Name"
          type="text"
          onChange={e => {
            setVariables({
              ...variables,
              DEVELOPER: e.target.value
            });
          }}
          value={variables[vs.DEVELOPER]}
        />
      </InputGroup>
    </FormGroup>
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        Personal information that you collect
      </label>
      <InputGroup className={classnames("input-group-merge", {})}>
        <Input
          placeholder="Personal information that you collect"
          type="text"
          onChange={e => {
            setVariables({
              ...variables,
              PERSONAL: e.target.value
            });
          }}
          value={variables[vs.PERSONAL]}
        />
      </InputGroup>
    </FormGroup>
    <FormGroup>
      <label className="form-control-label" htmlFor="exampleDatepicker">
        Email
      </label>
      <InputGroup className={classnames("input-group-merge", {})}>
        <Input
          placeholder="Email"
          type="text"
          onChange={e => {
            setVariables({
              ...variables,
              EMAIL: e.target.value
            });
          }}
          value={variables[vs.EMAIL]}
        />
      </InputGroup>
    </FormGroup>
  </Col>
);
export default Form;
