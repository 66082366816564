/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";
import Cookies from "js-cookie";
import { HttpConfig } from "../../tools/Http";
import { Link } from "react-router-dom";
import moment from "moment";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchApps: [],
      unseenNots: 0,
      notifications: [],
      userName: Cookies.get("full_name"),
      src: Cookies.get("avatar"),
      searchQuery: "",
      notificationClass: "",
    };
  }

  componentDidMount() {
    this.getNotifications();
    setInterval(() => {
      this.getNotifications();
    }, 300000);
  }

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function() {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function() {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  handleInputChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (this.state.searchQuery.length > 0) {
        const { searchQuery } = this.state;
        const { history } = this.props;
        history.push(`/search?q=${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  { "navbar-search-light": this.props.theme === "dark" },
                  { "navbar-search-dark": this.props.theme === "light" }
                )}
              >
                {/* <Link to={"/search"} className={"m-0 p-0"}> */}
                  <FormGroup className="mb-0">
                    <InputGroup className="input-group-alternative input-group-merge">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Search" type="text" value={this.state.searchQuery} onChange={(e) => this.handleInputChange(e)} onKeyPress={(e) => this.handleKeyPress(e)}/>
                    </InputGroup>
                  </FormGroup>
                {/* </Link> */}
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={this.closeSearch}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </Form>

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link"
                    color=""
                    tag="a"
                    onClick={() => this.setState({ notificationClass: "" })}
                  >
                    <div
                      className={
                        "pointer-cursor " + this.state.notificationClass
                      }
                    >
                      <span>
                        <i className="ni ni-bell-55" />
                      </span>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                    right
                  >
                    <div className="px-3 py-3">
                      <h6 className="text-sm text-muted m-0">
                        You have{" "}
                        <strong className="text-info">
                          {this.state.unseenNots}
                        </strong>{" "}
                        notifications.
                      </h6>
                    </div>
                    <ListGroup className={"notification-list"} flush>
                      {this.state.notifications.map(el => {
                        return this.renderNotification(el);
                      })}
                    </ListGroup>
                  </DropdownMenu>
                </UncontrolledDropdown>
                {/*<UncontrolledDropdown nav>*/}
                {/*  <DropdownToggle className="nav-link" color="" tag="a">*/}
                {/*    <i className="ni ni-ungroup" />*/}
                {/*  </DropdownToggle>*/}
                {/*  <DropdownMenu*/}
                {/*    className="dropdown-menu-lg dropdown-menu-dark bg-default"*/}
                {/*    right*/}
                {/*  >*/}
                {/*    <Row className="shortcuts px-4">*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-red">*/}
                {/*          <i className="ni ni-calendar-grid-58" />*/}
                {/*        </span>*/}
                {/*        <small>Calendar</small>*/}
                {/*      </Col>*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-orange">*/}
                {/*          <i className="ni ni-email-83" />*/}
                {/*        </span>*/}
                {/*        <small>Email</small>*/}
                {/*      </Col>*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-info">*/}
                {/*          <i className="ni ni-credit-card" />*/}
                {/*        </span>*/}
                {/*        <small>Payments</small>*/}
                {/*      </Col>*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-green">*/}
                {/*          <i className="ni ni-books" />*/}
                {/*        </span>*/}
                {/*        <small>Reports</small>*/}
                {/*      </Col>*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-purple">*/}
                {/*          <i className="ni ni-pin-3" />*/}
                {/*        </span>*/}
                {/*        <small>Maps</small>*/}
                {/*      </Col>*/}
                {/*      <Col*/}
                {/*        className="shortcut-item"*/}
                {/*        href="#pablo"*/}
                {/*        onClick={e => e.preventDefault()}*/}
                {/*        xs="4"*/}
                {/*        tag="a"*/}
                {/*      >*/}
                {/*        <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">*/}
                {/*          <i className="ni ni-basket" />*/}
                {/*        </span>*/}
                {/*        <small>Shop</small>*/}
                {/*      </Col>*/}
                {/*    </Row>*/}
                {/*  </DropdownMenu>*/}
                {/*</UncontrolledDropdown>*/}
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav style={{cursor: 'pointer'}}>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        {this.state.src && this.state.src !== "null" ? (
                          <img
                            alt={this.state.userName}
                            src={this.state.src}
                          />
                        ) : (
                          <img
                            alt="mobioptions"
                            src={require("assets/img/theme/team-1.jpg")}
                          />
                        )}
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.state.userName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>
                      <Link to={"profile"} className={"text-dark"}>
                        <DropdownItem>
                          <i className="ni ni-single-02 text-info" />
                          <span>My profile</span>
                        </DropdownItem>
                      </Link>
                    {/* <Link to={"settings"} className={"text-dark"}>
                      <DropdownItem> 
                        <i className="ni ni-settings-gear-65 text-muted" />
                        <span>Settings</span>
                      </DropdownItem>
                    </Link> */}
                    {/* <Link to={"contact-us"} className={"text-dark"}>
                      <DropdownItem>
                        <i className="ni ni-support-16 text-orange" />
                        <span>Support</span>
                      </DropdownItem>
                    </Link> */}
                    <DropdownItem divider />
                    <DropdownItem
                      href="#pablo"
                      onClick={e => {
                        Cookies.set("access_token", "undefined", { expires: 365 });
                        window.location.href = "/";
                      }}
                    >
                      <i className="ni ni-user-run text-success" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }

  renderNotification(not) {
    return (
      <ListGroupItem
        className={
          "list-group-item-action cursor-pointer " +
          (not.seen ? "bg-gradient-lighter" : "")
        }
        onClick={e => {
          this.seenNotifications(not.id, not.path);
          not.seen = true;
        }}
        tag="a">
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              alt="mobioptions"
              className="avatar rounded-circle"
              src={not.icon}
            />
          </Col>
          <div className="col ml--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="mb-0 text-sm">{not.title}</h4>
              </div>
              <div className="text-right text-muted">
                <small>{moment(not.created_at).fromNow()}</small>
              </div>
            </div>
            <p className="text-sm mb-0">{not.msg}</p>
          </div>
        </Row>
      </ListGroupItem>
    );
  }

  getNotifications() {
    HttpConfig()
      .get("notifications")
      .then(res => {
        if (res.data.status) {
          let unseenNots = 0;
          res.data.notifications.map(el => {
            if (!el.seen) unseenNots++;
          });
          this.setState({
            notifications: res.data.notifications,
            unseenNots,
            notificationClass: unseenNots > 0 ? "icon-parent" : ""
          });
        }
      }).catch(err => {console.log(err);});
  }

  seenNotifications(id, path) {
    HttpConfig().post("notif-seen-just-now/" + id)
      .then(res => {
        if (res.data.status) {
          this.setState({
            unseenNots: this.state.unseenNots > 0 ? this.state.unseenNots - 1 : 0
          });
        }
        window.location.href = `/${path}`;
        /* this.props.history.push({
          pathname: '/' + path
        }); */
      })
      .catch(err => {});
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
