import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col, Modal, InputGroup, InputGroupAddon, InputGroupText
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import {HttpConfig} from "../../tools/Http";
import Cookies from "js-cookie";
import Avatar from "react-avatar-edit";
import classnames from "classnames";
import {Link} from "react-router-dom";
import moment from "moment";
import CryptoJSAesJson from "../../tools/cryptojs-aes-format";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
var CryptoJS = require("crypto-js");

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name:"",
            last_name:"",
            username:"",
            phone:"",
            password:"",
            firstFocus: false,
            lastFocus: false,
            phoneFocus: false,
            usernameFocus: false,
            emailFocus: false,
            passwordFocus: false,
            me: {
                name:' ',
                email:'',
                phone:'',
                password:'..........',
                sub_expires_in:null
            },
            user: [],
            published:{
                published:'-',
                pending:'-',
                removed:'-',
            },
            adsTotal:0,
            loading:true,
            porjectsTotal:0,
            associates: [],
            editables: [],
            preview:null,
            defaultModal:null,
            username:"",
            imgSrc: Cookies.get('avatar')
        }

        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    getData(){
        HttpConfig(this.props.dispatch).get("get-user")
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        user: res.data.user,
                        first_name: res.data.user.first_name,
                        last_name: res.data.user.last_name,
                        username: res.data.user.username,
                        phone: res.data.user.phone
                    });
            //associates:res.data.associated
                    // let t = res.data.plan.accounts;
                    // if(t === 1)
                    //     t = 0;
                    // let arr = [];
                    // let editables = [];
                    // if(t)
                    //     for (let i = 0; i < t; i++) {
                    //         if(i < res.data.associated.length) {
                    //             arr.push(res.data.associated[i]);
                    //             editables.push(true);
                    //         }
                    //         else{
                    //             arr.push({});
                    //             editables.push(false);
                    //         }
                    //     }
                    // res.data.user.password = ".....";
                    // this.setState({me:res.data.user,associates:arr,editables});
                    this.setState({loading:false})

                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
                this.setState({loading:false})
        })
    //    this.getAssociated();
    //    this.getPublished();
    //    this.getAds();

    }

    render() {
        return (
            <>
            {this.state.loading ?
                <div
                style={{
                    position:'fixed',
                    display:'flex',
                    zIndex:1,
                    height:'100%',
                    width:'100%',
                    top:0,
                    alignItems:'center',
                    justifyContent:'center',
                    backgroundColor:'rgba(0,0,0,.2)',
                }}
                >
                <ClipLoader
                    color={"white"}
                    loading={true}
                    size={50}
                />
                </div>
                : null  
                }
                <ProfileHeader name={this.state.user.first_name}/>
                <Container className="mt--6" fluid>
                    <Row>
                        <Col className="order-xl-2" xl="4">
                            <Card className="card-profile">
                                <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/img-1-1000x600.jpg")}
                                    top
                                />
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                            <a href="#pablo" onClick={e => {
                                                e.preventDefault();
                                                this.toggleModal("defaultModal")
                                            }}>
                                                {this.state.imgSrc && this.state.imgSrc !== 'null' ? <img
                                                        alt="..."
                                                        className={"rounded-circle"}
                                                        onClick={() => this.toggleModal("defaultModal")}
                                                        src={this.state.imgSrc}
                                                    /> :
                                                    (
                                                        <img
                                                          alt="..."
                                                          src={require("assets/img/theme/team-1.jpg")}
                                                        />
                                                    )
                                                }
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    {/* <div className="d-flex justify-content-between"> */}
                                        {/* <Link
                                            className="btn btn-sm btn-info mr-4"
                                            color="info"
                                            to={'/list'}
                                        >
                                            My Apps
                                        </Link> */}
                                        {/* <Link
                                            className="btn btn-sm btn-dark mr-4"
                                            color="info"
                                            to={'/ads'}
                                        >
                                            My Projects
                                        </Link> */}
                                    {/* </div> */}
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row>
                                        <div className="col">
                                            <div className="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span className="heading">{this.state.user.pub}</span>
                                                    <span className="description">Published Apps</span>
                                                </div>
                                                <div>
                                                    <span className="heading">{this.state.user.pen}</span>
                                                    <span className="description">Pending Apps</span>
                                                </div>
                                                <div>
                                                    <span className="heading">{this.state.user.sus}</span>
                                                    <span className="description">Suspended Apps</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className="justify-content-center mb-4">
                                        <Link
                                            className="btn btn-sm btn-info"
                                            color="info"
                                            to={'/list'}
                                        >
                                            My Apps
                                        </Link>
                                    </Row>
                                    <div className="text-center">
                                        <h5 className="h3">
                                            {this.state.user.first_name + " " + this.state.user.last_name}
                                        </h5>
                                        <div>
                                            <i className="ni education_hat mr-2" />
                                            User since {this.state.user.since}
                                        </div>
                                        <div style={{height:10}}/>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col className="order-xl-1" xl="8">
                            <Row>
                                {/* <Col lg="6">
                                    <Card className="bg-gradient-success border-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        className="text-uppercase text-muted mb-0 text-white"
                                                        tag="h5"
                                                    >
                                                        Total Ads Impressions This Month
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">
                                                        {this.state.adsTotal}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                        <i className="ni ni-active-40" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">

                                                    <span className="text-nowrap text-light">
                                              Displayed across  <span className="text-white mr-2">
                                                {this.state.projectsTotal}
                                            </span> projects
                                            </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col> */}
                                <Col lg="12">
                                {moment(this.state.user.expires).isSameOrAfter(moment(new Date())) ?
                                    <Card className={"bg-gradient-" + (this.state.user.left >= 20 ? "success" : (this.state.user.left < 20 && this.state.user.left >= 7 ? "warning" : "danger")) + " border-0"} tag="h5">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                                        Plan Expires at
                                                    </CardTitle>
                                                    <span className="h2 font-weight-bold mb-0 text-white">
                                                        {this.state.user.expires ? moment(this.state.user.expires).format("dd, MMMM Do YYYY") : ""}
                                                    </span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                        <i className="ni ni-spaceship" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <p className="mt-3 mb-0 text-sm">
                                                <span className="text-white mr-2">
                                                    {this.state.user.left}
                                                </span>
                                                <span className="text-nowrap text-light">
                                                     day{this.state.user.left === 1 ? "" : "s"} left
                                                </span>
                                            </p>
                                        </CardBody>
                                    </Card>
                                    :
                                        <Card className={"bg-gradient-danger border-0"} tag="h5">
                                            <CardBody>
                                                <Row>
                                                    <div className="col">
                                                        <CardTitle className="text-uppercase text-muted mb-0 text-white">
                                                            Free Plan
                                                        </CardTitle>
                                                        <span className="h2 font-weight-bold mb-0 text-white">
                                                            Expired at {this.state.user.expires ? moment(this.state.user.expires).format("dd, MMMM Do YYYY") : ""}
                                                        </span>
                                                    </div>
                                                    <Col className="col-auto">
                                                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                                                            <i className="ni ni-spaceship" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <p className="mt-3 mb-0 text-sm">
                                                    <span className="text-nowrap text-light">
                                                        Upgrade Your Plan To Get Premium Features
                                                    </span>
                                                </p>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                            <Card>
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="6">
                                            <h3 className="mb-0">Edit Profile</h3>
                                        </Col>
                                        <Col className="text-right" xs="6">
                                            <Button
                                                color="primary"
                                                onClick={e => this.updateUser()}
                                                size="sm"
                                                disabled={
                                                    this.state.first_name === this.state.user.first_name
                                                    && this.state.last_name === this.state.user.last_name
                                                    && this.state.username === this.state.user.username
                                                    && this.state.phone === this.state.user.phone
                                                    && this.state.password === ""
                                                }
                                            >
                                                Update Profile
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            My Information
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            First Name
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: this.state.firstFocus
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-user" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="First Name"
                                                                type="text"
                                                                onFocus={e => this.setState({ firstFocus: true })}
                                                                onBlur={e => this.setState({ firstFocus: false })}
                                                                onChange={e => {this.setState({first_name: e.target.value})}}
                                                                value={this.state.first_name || ''}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-username"
                                                        >
                                                            Last Name
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: this.state.lastFocus
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-user" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Last Name"
                                                                type="text"
                                                                onFocus={e => this.setState({ lastFocus: true })}
                                                                onBlur={e => this.setState({ lastFocus: false })}
                                                                onChange={e => {this.setState({last_name: e.target.value})}}
                                                                value={this.state.last_name || ''}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Username
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: this.state.usernameFocus
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-user" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Username"
                                                                type="text"
                                                                onFocus={e => this.setState({ usernameFocus: true })}
                                                                onBlur={e => this.setState({ usernameFocus: false })}
                                                                value={this.state.username || ''}
                                                                onChange={e => {this.setState({username: e.target.value})}}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col> <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-email"
                                                        >
                                                            Phone Number
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: this.state.phoneFocus
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-phone" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Phone Number"
                                                                type="text"
                                                                onFocus={e => this.setState({ phoneFocus: true })}
                                                                onBlur={e => this.setState({ phoneFocus: false })}
                                                                value={this.state.phone || ''}
                                                                onChange={e => {this.setState({phone: e.target.value})}}
                                                            />
                                                            
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-first-name"
                                                        >
                                                            Email Address
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {})}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-envelope" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Email address"
                                                                type="email"
                                                                value={this.state.user.email || ''}
                                                                disabled={true}
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-password"
                                                        >
                                                            Password
                                                        </label>
                                                        <InputGroup
                                                            className={classnames("input-group-merge", {
                                                                focused: this.state.passwordFocus
                                                            })}
                                                        >
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <i className="fas fa-lock" />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input
                                                                placeholder="Leave it empty to keep the old one"
                                                                type="password"
                                                                value={this.state.password || ''}
                                                                onFocus={e => this.setState({ passwordFocus: true })}
                                                                onBlur={e => this.setState({ passwordFocus: false })}
                                                                onChange={e => this.setState({password: e.target.value})}/>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* <Row>
                        {this.renderAssosicated()}
                    </Row> */}
                    {this.renderUploadImageModals()}

                </Container>
            </>
        );
    }
    renderAssosicated(){
        return(
            <Col lg={12} className={""}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <Col lg="6" xs="12">
                                <h3 className="mb-0">Associated Accounts</h3>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Form>
                            {this.state.associates.map((el,i) => {
                                return(
                                    [<Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleDatepicker"
                                                >
                                                    Email of Account {i+1}
                                                </label>
                                                <Input
                                                    placeholder="Email address"
                                                    type="email"
                                                    disabled={this.state.editables[i]}
                                                    onChange={e => {
                                                        let arr = this.state.associates;
                                                        arr[i].email = e.target.value;
                                                        this.setState({associates:arr})
                                                    }}
                                                    value={el.email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="exampleDatepicker"
                                                >
                                                    Password of Account {i+1}
                                                </label>
                                                <Input
                                                    type="password"
                                                    disabled={this.state.editables[i]}
                                                    onChange={e => {
                                                        let arr = this.state.associates;
                                                        arr[i].password = e.target.value;
                                                        this.setState({associates:arr})
                                                    }}
                                                    value={el.password}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>,
                                        <div>
                                            {i === this.state.associates.length -1 &&
                                            <Row>
                                                <Col md="3">
                                                    <FormGroup>
                                                        <Button  className="btn-default" onClick={e => this.setAssociates()}>Update</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            }
                                        </div>
                                    ]
                                )
                            })
                            }
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        )
    }

    renderUploadImageModals(){
        return(
            <Modal
                className="modal-dialog-centered"
                isOpen={this.state.defaultModal}
                toggle={() => this.toggleModal("defaultModal")}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Upload An Avatar
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("defaultModal")}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body" style={{display:'flex', alignItems:'center', justifyContent:'center', overflow:'hidden'}}>
                    <Avatar
                        width={390}
                        height={295}
                        onCrop={this.onCrop}
                        onClose={this.onClose}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        src={this.state.src}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="primary" type="button"
                            onClick={() => {
                                this.setAvatar();
                                this.toggleModal("defaultModal");
                            }}>
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => {
                            this.toggleModal("defaultModal");
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
        );
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    onClose() {
        // this.setState({preview: null})
    }

    onCrop(preview) {
        this.setState({preview})
    }

    onBeforeFileLoad(elem) {
        if(elem.target.files[0].size > 1048576){
            alert("File is too big!");
            elem.target.value = "";
        };
    }

    setAvatar(){
        HttpConfig(this.props.dispatch).post('update-avatar',{avatar: this.state.preview})
            .then(res => {
                if(res.data.status){
                    this.props.notify("success", "Success","Your avatar is updated");
                    Cookies.set('avatar', window.api_url + res.data.link,{ expires: 365 });
                    setTimeout(() => window.location.reload());
                    // this.setState({category:res.data.category, apps:[]},() => this.getData());
                }
                else{
                    //error
                    this.props.notify("error", "there was an error while adding your avatar");
                }
            }).catch(err => {
            this.props.notify("error", "there was an error while adding your avatar");
        })
    }

    validatseEmail() {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(this.state.me.email==='' || !re.test(String(this.state.me.email).toLowerCase())){
            this.props.notify("error", "Please insert a valid email address");
            return false;
        }
        return true;
    }

    validateePassword() {
        if(this.state.password <= 5){
            this.props.notify("error", "Please insert a strong password containing at least 6 characters");
            return false;
        }
        return true;
    }

    updateUser(){
        if(!this.state.first_name || this.state.first_name.length < 3){
            // toast.error("Please insert a valid name");
            this.props.notify("error", "Invalid Input","Please insert a valid first name");
            return;
        }
        if(!this.state.last_name || this.state.last_name.length < 3){
            // toast.error("Please insert a valid name");
            this.props.notify("error", "Invalid Input","Please insert a valid last name");
            return;
        }
        if(!this.state.phone || this.state.phone.length < 10){
            // toast.error("Please insert a valid phone number");
            this.props.notify("error", "Invalid Input","Please insert a valid phone number");
            return;
        }

        if(this.state.password && this.state.password !== ""){
            if(!this.validateePassword())
                return;
        }
        
        // toast('Please wait while we update your account!');
        HttpConfig(this.props.dispatch).post('update-user',{
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            username: this.state.username,
            phone: this.state.phone,
            password: this.state.password
        }).then(res => {
                if(res.data.status){
                    // toast.success('Your profile is successfully updated!');
                    this.props.notify("success", "Updated","Your profile is successfully updated!");
                    Cookies.set('full_name', this.state.first_name + " " + this.state.last_name,{ expires: 365 });
                    setTimeout(() => window.location.reload());
                    // this.setState({category:res.data.category, apps:[]},() => this.getData());
                }
                else{
                    //error
                    // toast.error('there was an error while updating your account');
                    this.props.notify("error", "Error","there was an error while updating your account");
                }
            }).catch(err => {
            this.props.notify("error", "Error","there was an error while updating your account");
            // toast.error('there was an error while updating your account');
        })
    }

    validateEmail(em) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(String(em).toLowerCase())){
            return false;
        }
        return true;
    }

    validatePassword(pass) {
        if(pass.length <= 5){
            return false;
        }
        return true;
    }

    getAssociated() {
        HttpConfig(this.props.dispatch).get("associated")
            .then(res => {
                if (res.data.status) {
                    // this.setState({me:res.data.user});
                    //associates:res.data.associated
                    let t = res.data.plan.accounts;
                    if(t === 1)
                        t = 0;
                    let arr = [];
                    let editables = [];
                    if(t)
                        for (let i = 0; i < t; i++) {
                            if(i < res.data.associated.length) {
                                arr.push(res.data.associated[i]);
                                editables.push(true);
                            }
                            else{
                                arr.push({});
                                editables.push(false);
                            }
                        }
                    res.data.user.password = ".....";
                    this.setState({me:res.data.user, username:res.data.user.name,associates:arr,editables});

                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }

    getPublished() {
        HttpConfig(this.props.dispatch).get("profile/published")
            .then(res => {
                if (res.data.status) {
                    this.setState({published:res.data});
                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }
     getAds() {
         HttpConfig(window.ads_url,{
             challenge:CryptoJS.AES.encrypt(JSON.stringify(Cookies.get('id')), "sup4rsd3cr3tP4asFu", {format: CryptoJSAesJson}).toString()
         }).get("profile/adsProjects")
            .then(res => {
                if (res.data.status) {
                    this.setState({adsTotal:res.data.ads, projectsTotal:res.data.projects});
                }
                else {

                }
            }).catch(err => {
            console.log(err);
        })
    }

}


export default connect()(Profile);
