/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";
import {HttpConfig} from "../../tools/Http";

class CardsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revenue:0,
      active:0,
      users:0,
      apps:0,
    }
  }
  componentDidMount() {
    this.getDash();
  }


  getDash(){
    this.setState({jobs:[],loading:true});
    HttpConfig().get('dashboard/cards')
        .then(res => {
          if (res.data.status) {
            this.setState({
              revenue:res.data.revenue,
              active:res.data.active,
              users:res.data.users,
              apps:res.data.apps,
            });
          }
          else {
            this.setState({loading:false})
          }
        }).catch(err => {
      //err
      this.setState({loading:false})
    })
  }

  render() {
    return (
        <>
          <div className="header bg-info pb-6">
            <Container fluid>
              <div className="header-body">
                <Row className="align-items-center py-4">
                  <Col lg="6" xs="7">
                    <h6 className="h2 text-white d-inline-block mb-0">
                      {"Home"}
                    </h6>{" "}
                    <Breadcrumb
                        className="d-none d-md-inline-block ml-md-4"
                        listClassName="breadcrumb-links breadcrumb-dark"
                    >
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fas fa-home" />
                        </a>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {this.props.parentName}
                        </a>
                      </BreadcrumbItem>
                      {/*<BreadcrumbItem aria-current="page" className="active">*/}
                      {/*  {this.props.name}*/}
                      {/*</BreadcrumbItem>*/}
                    </Breadcrumb>
                  </Col>
                </Row>

                <Row>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                              Monthly Revenue
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                            {this.state.revenue}
                          </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-nowrap">Last Update: Now</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                              Active Today
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                            {this.state.active}
                          </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="ni ni-chart-pie-35" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-nowrap">Last Update: Now</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                              Total Users
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{this.state.users}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-active-40" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-nowrap">Last Update: Now</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="3">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                              Total Apps
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                            {this.state.apps}
                          </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                              <i className="ni ni-chart-bar-32" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                          <span className="text-nowrap">Last Update: Now</span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </>
    );
  }
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default CardsHeader;
