import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

const Component = ({ captcha, setCaptcha }) => {
  return (
    <Col xl={6} className={"flex2"}>
      <ReCAPTCHA
        sitekey="6LdShiMTAAAAAL0-Rydt-gOyHCcFEqUAA5VPSWVC"
        onChange={v => setCaptcha(v)}
      />
    </Col>
  );
};

export default Component;
