/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/
import Login from "./views/pages/admin/Login";
import ResetPasseword from "./views/pages/admin/ResetPassword";
import Register from "./views/pages/Register";
import Dashboard from "./views/pages/admin/Dashboard";
import Cards from "./views/pages/Cards";
import PublishedApps from "./views/pages/PublishedApps";
import DescriptionGenerator from "./views/pages/DescriptionGenerator";
import KeywordsAnalyzer from "./views/pages/KeywordsAnalyzer";
import AdsTutorial from "./views/pages/AdsTutorial";
import AdsStats from "./views/pages/AdsStats";
import FavouriteApps from "./views/pages/FavouriteApps";
import Dash from "./views/pages/Dash";
import TopCharts from "./views/pages/TopCharts";
import GraphicsCreator from "./views/pages/GraphicsCreator";
import Details from "./views/pages/Details";
import Profile from "./views/pages/Profile";
import Search from "./views/pages/Search";
import Settings from "./views/pages/Settings";
import Profile2 from "./views/pages/Profile2";
import MobioptionsAdmobLimit from "./views/pages/MobioptionsAdmobLimit";
import LimitLibraryTutorial from "./views/pages/LimitLibraryTutorial";
import TopChartsIOS from "./views/pages/TopChartsIOS";
import DetailsIOS from "./views/pages/DetailsIOS";
import ResetPassword from "./views/pages/admin/ResetPassword";
import VerifyEmail from "./views/pages/admin/VerifyEmail";
import Form from "./views/pages/admin/Form/index";
import PrivacyPolicyGenerator from "./views/pages/admin/PrivacyPolicy";
import PrivacyPolicyPage from "./views/pages/admin/PrivacyPolicy/Page";
import IOSDevApps from "./views/pages/IOSDevApps";
import AndroidDevApps from "./views/pages/AndroidDevApps";
import ChangePricingPlan from "./views/pages/ChangePricingPlan";
import Pricing from "./views/pages/Pricing";
import ChangePassword from "./views/pages/admin/ChangePasseword";
import AdsProjects2 from "./views/pages/AdsProjects2";
import PostPricing from "./views/pages/PostPricing";
import PhoneVerification from "./views/pages/PhoneVerification";
import Niches from "./views/pages/Niches";
import Screenshots from "./views/pages/Screenshots";
import Keywords from "./views/pages/Keywords";
import AdsTutorial2 from "./views/pages/AdsTutorial2";
import Error404 from "./views/pages/Error404";
import KeywordsSuggestion from "views/pages/KeywordsSuggestion";
import AABSigner from "views/pages/AABSigner";
import DevLanding from "views/pages/DevLanding";

const routes = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    miniName: "R",
    component: ResetPassword,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/VerifyEmail",
    name: "VerifyEmail",
    miniName: "V",
    component: VerifyEmail,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/form",
    name: "Form",
    miniName: "F",
    component: Form,
    layout: "/public",
    redirect: true,
  },
  {
    path: "/privacy-policy-generator",
    name: "PPG",
    miniName: "P",
    component: PrivacyPolicyGenerator,
    layout: "/public",
    redirect: true,
  },
  {
    path: "/preview-privacy-policy",
    name: "PPP",
    miniName: "PP",
    component: PrivacyPolicyPage,
    layout: "/public",
    redirect: true,
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    miniName: "C",
    component: ChangePassword,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/details-ios",
    name: "Details",
    miniName: "Di",
    component: DetailsIOS,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/ios-dev",
    name: "IOS Developer",
    miniName: "id",
    component: IOSDevApps,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/android-dev",
    name: "Android Developer",
    miniName: "ad",
    component: AndroidDevApps,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/details",
    name: "Details",
    miniName: "Da",
    component: Details,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/pricing",
    name: "Pricing",
    miniName: "pr",
    component: ChangePricingPlan,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/sign-up",
    name: "Register",
    miniName: "R",
    component: Register,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/details",
    name: "Details",
    miniName: "D",
    component: Details,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/dash",
    name: "Dashboard",
    icon: "fas fa-th-large text-muted",
    component: Dash,
    layout: "/admin",
  },

  {
    path: "/search",
    name: "Search",
    icon: "fas fa-search text-orange",
    component: Search,
    layout: "/admin",
  },
  {
    path: "/niches",
    name: "Niches",
    icon: "fas fa-fire text-blue",
    miniName: "G",
    component: Niches,
    layout: "/admin",
  },
  // {
  //   collapse: true,
  //   name: "Top Charts",
  //   icon: "fas fa-trophy text-yellow",
  //   state: "topchartsCollapse",
  //   views: [
  //     {
  //       path: "/top-charts",
  //       name: "Play Store Top Charts",
  //       component: TopCharts,
  //       miniName: "A",
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/top-charts-ios",
  //       name: "App Store Top Charts",
  //       component: TopChartsIOS,
  //       miniName: "I",
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    path: "/top-charts",
    name: "Top Charts",
    icon: "fas fa-trophy text-yellow",
    layout: "/admin",
    state: "topchartsCollapse",
    component: TopCharts,
  },
  {
    collapse: true,
    name: "My Apps",
    icon: "fas fa-rocket text-success",
    state: "componentsCollapse",
    views: [
      {
        path: "/list",
        name: "My Published Apps",
        component: PublishedApps,
        miniName: "P",
        layout: "/admin",
      },
      {
        path: "/favourite",
        name: "My Favourite Apps",
        miniName: "F",
        component: FavouriteApps,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Tools",
    icon: "fas fa-wrench ",
    state: "toolsCollapse",
    views: [
      {
        path: "/privacy-policy-generator",
        name: "Privacy Policy Generator",
        miniName: "P",
        component: PrivacyPolicyGenerator,
        layout: "/admin",
        fromAdmin: true,
      },
      {
        path: "/description",
        name: "Description Generator",
        miniName: "D",
        component: DescriptionGenerator,
        layout: "/admin",
      },
      {
        path: "/graphics",
        name: "Graphics Creator",
        miniName: "G",
        component: GraphicsCreator,
        layout: "/admin",
      },
      {
        path: "/screenshots",
        name: "Screenshots Editor",
        miniName: "SE",
        component: Screenshots,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Keywords",
    icon: "fas fa-drafting-compass text-success",
    state: "analyticsCollapse",
    views: [
      {
        path: "/keyword-analyzer",
        name: "Keywords Analyzer",
        component: KeywordsAnalyzer,
        miniName: "KA",
        layout: "/admin",
      },
      {
        path: "/keyword-suggestion",
        name: "Keywords Suggestion",
        component: KeywordsSuggestion,
        miniName: "KS",
        layout: "/admin",
      }
    ],
  },
  {
    path: "/ads-stats",
    name: "Stats",
    icon: "fas fa-code text-primary",
    component: AdsStats,
    layout: "/admin",
    redirect: true,
  },
  // , {
  //     collapse: true,
  //     // disabled:true,
  //     name: "MobiOptions Libraries",
  //     icon: "fa fa-code text-orange",
  //     state: "libraryCollapse",
  //     views: [
  //         {
  //             collapse: true,
  //             // disabled: true,
  //             name: "Limit Library",
  //             miniName: "ALL",
  //             state: "limitCollapse",
  //             views: [
  //                 {
  //                     path: "/limit",
  //                     name: "Project Manager",
  //                     component: MobioptionsAdmobLimit,
  //                     // component: Error404,
  //                     layout: "/admin",
  //                     // disabled: true,
  //                 },
  //                 {
  //                     path: "/limit-tutorials",
  //                     name: "Tutorial",
  //                     component: LimitLibraryTutorial,
  //                     // component: Error404,
  //                     layout: "/admin",
  //                     // disabled: true,
  //                 }]
  //         },
  //         {
  //             collapse: true,
  //             // disabled: true,
  //             name: "Ads Library",
  //             miniName: "AAL",
  //             state: "adsCollapse",
  //             views: [
  //                 {
  //                     path: "/ads",
  //                     name: "Project Manager",
  //                     component: AdsProjects2,
  //                     // component: Error404,
  //                     layout: "/admin",
  //                     // disabled: true,
  //                 },
  //                 {
  //                     path: "/ads-tutorials",
  //                     name: "Tutorial",
  //                     component: AdsTutorial,
  //                     // component: Error404,
  //                     layout: "/admin",
  //                     // disabled: true,
  //                 }]
  //         },
  //     ]
  // },
  // {
  //   path: "/keywords",
  //   name: "Keywords",
  //   icon: "fas fa-drafting-compass text-success",
  //   component: Keywords,
  //   layout: "/admin",
  // },
  // {
  //     path: "/cards",
  //     disabled: true,
  //     name: "Credit Cards",
  //     icon: "fa fa-credit-card text-purple",
  //     // component: Cards,
  //     component: Error404,
  //     layout: "/admin"
  // },
  {
    path: "/aab-signer",
    name: "AAB Signer",
    component: AABSigner,
    icon: "fas fa-file-signature text-blue fa-lg",
    layout: "/admin"
  },
  {
    path: "/dev-landing-page",
    name: "Developer Landing Page",
    component: DevLanding,
    icon: "fas fa-laptop-code fa-lg",
    layout: "/admin"
  },
  {
    path: "your-orders",
    name: "Your Orders",
    icon: "fas fa-cart-plus text-orange fa-lg",
    layout: "/admin"
  },
  {
    path: "/ads-intellegencedisabled",
    disabled: true,
    name: "Ads Intelligence",
    icon: "fas fa-clock text-yellow fa-lg",
    layout: "/admin"
  },
  {
    path: "/app-builderdisabled",
    disabled: true,
    name: "App Builder",
    icon: "fas fa-clock text-yellow fa-lg",
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "fa fa-user text-pink",
    component: Profile2,
    layout: "/admin",
  },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "fas fa-cog text-dark",
  //   component: Settings,
  //   layout: "/admin",
  // },
  {
    path: "/",
    name: "Dashboard",
    icon: "fas fa-th-large text-muted",
    component: Dash,
    layout: "/admin",
    redirect: true,
  },
 /*  {
    path: "/pricing",
    name: "Pricing",
    miniName: "p",
    component: Pricing,
    layout: "/auth",
    redirect: true,
  }, 
  {
    path: "/post-pricing",
    name: "Post Pricing",
    miniName: "p",
    component: PostPricing,
    layout: "/auth",
    redirect: true,
  },*/

  // {
  //   path: "/recover",
  //   name: "ResetPassword",
  //   miniName: "R",
  //   component: ResetPassword,
  //   layout: "/auth",
  //   redirect: true,
  // },
  {
    path: "/phone",
    name: "PhoneInput",
    miniName: "P",
    component: PhoneVerification,
    layout: "/auth",
    redirect: true,
  },
  {
    path: "/reset",
    name: "ChangePassword",
    miniName: "C",
    component: ChangePassword,
    layout: "/auth",
    redirect: true,
  },
];

export default routes;
