/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import Chart from "chart.js";
import {
    Badge,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Container, DropdownItem, DropdownMenu, DropdownToggle, Form, Input,
    ListGroup,
    ListGroupItem, Media,
    Nav,
    NavItem,
    NavLink, Pagination, PaginationItem, PaginationLink, Progress,
    Row, Table, UncontrolledDropdown, UncontrolledTooltip
} from "reactstrap";
import classnames from "classnames";
import {Bar, Line} from "react-chartjs-2";

import {
    chartExample1,
    chartExample2,
    parseOptions,
    chartOptions,
} from "variables/charts.js";
import CardsHeader from "../../../components/Headers/CardsHeader";
import {VectorMap} from "react-jvectormap";
import {HttpConfig} from "../../../tools/Http";
import moment from "moment";
// import Loader from "react-loader-spinner";

let mapData = {
    AU: 760,
    BR: 550,
    CA: 120,
    DE: 1300,
    FR: 540,
    GB: 690,
    GE: 200,
    IN: 200,
    RO: 600,
    RU: 300,
    US: 2920
};
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNav: 1,
            chartExample1Data: "data1",
            jobs: [],
            onlineUsers: [],
            paymentsGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowth : {labels: [], datasets: [{data: []}]},

        };
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
    }

    componentDidMount() {
        this.getData(moment().subtract(30, "days").format("YYYY-MM-DD"))
    }

    getData(startDate){
        this.setState({
            paymentsGrowth : {labels: [], datasets: [{data: []}]},
            usersGrowth : {labels: [], datasets: [{data: []}]}
        });
        this.getPaymentsGrowth(startDate);
        this.getUsersGrowth(startDate);
        this.getJobs();
        this.getOnlineUsers();
    }

    getOnlineUsers(){
        HttpConfig().get('users/realtime')
            .then(res => {
                if (res.data.status) {
                    this.setState({onlineUsers:res.data.users});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    getJobs(){
        HttpConfig().get('jobs/failed')
            .then(res => {
                if (res.data.status) {
                    this.setState({
                        jobs:res.data.jobs.data ? res.data.jobs.data : [],
                    });
                }
                else {
                    this.setState({loading:false})
                }
            }).catch(err => {
            //err
            this.setState({loading:false})
        })
    }

    getUsersGrowth(startDate){
        HttpConfig().get('users/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let usersGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({usersGrowth});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }
    getPaymentsGrowth(startDate){
        HttpConfig().get('payments/growth/monthly/'+startDate)
            .then(res => {
                if (res.data.status) {
                    let labels = [];
                    let datasets = [];
                    res.data.entries.forEach((el) => {
                        labels.push(el.end);
                        datasets.push(el.count);
                    });
                    let paymentsGrowth = {
                        labels:labels,
                        datasets: [{data: datasets}]
                    };
                    this.setState({paymentsGrowth});
                }
                else {

                }
            }).catch(err => {
            //err
        })
    }

    render() {
        return (
            <>
                <CardsHeader name="Default" parentName="Dashboards" />
                <Container className="mt--6" fluid>
                    <Row>
                        <Col xl="8">
                            <Card className="bg-default">
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-light text-uppercase ls-1 mb-1">
                                                Overview
                                            </h6>
                                            <h5 className="h3 text-white mb-0">Monthly Revenue</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Line
                                            data={this.state.paymentsGrowth}
                                            options={chartExample1.options}
                                            id="chart-sales-dark"
                                            className="chart-canvas"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">
                            <Card>
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                                                Users
                                            </h6>
                                            <h5 className="h3 mb-0">Monthly New Arriving Users</h5>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="chart">
                                        <Bar
                                            data={this.state.usersGrowth}
                                            options={chartExample2.options}
                                            className="chart-canvas"
                                            id="chart-bars"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="4">
                            <Card className="widget-calendar">
                                <CardHeader>
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h5 className="h3 mb-0">Users By Country</h5>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <VectorMap
                                        containerClassName="vector-map vector-map-sm"
                                        containerStyle={{
                                            width: "100%",
                                            height: "280px"
                                        }}
                                        map={"world_mill"}
                                        zoomOnScroll={false}
                                        scaleColors={["#f00", "#0071A4"]}
                                        normalizeFunction="polynomial"
                                        hoverOpacity={0.7}
                                        hoverColor={false}
                                        backgroundColor="transparent"
                                        regionStyle={{
                                            initial: {
                                                fill: "#e9ecef",
                                                "fill-opacity": 0.8,
                                                stroke: "none",
                                                "stroke-width": 0,
                                                "stroke-opacity": 1
                                            },
                                            hover: {
                                                fill: "#dee2e6",
                                                "fill-opacity": 0.8,
                                                cursor: "pointer"
                                            },
                                            selected: {
                                                fill: "yellow"
                                            },
                                            selectedHover: {}
                                        }}
                                        markerStyle={{
                                            initial: {
                                                fill: "#fb6340",
                                                "stroke-width": 0
                                            },
                                            hover: {
                                                fill: "#2946fc",
                                                "stroke-width": 0
                                            }
                                        }}
                                        markers={[
                                            {
                                                latLng: [41.9, 12.45],
                                                name: "Vatican City"
                                            },
                                            {
                                                latLng: [43.73, 7.41],
                                                name: "Monaco"
                                            },
                                            {
                                                latLng: [35.88, 14.5],
                                                name: "Malta"
                                            },
                                            {
                                                latLng: [1.3, 103.8],
                                                name: "Singapore"
                                            },
                                            {
                                                latLng: [1.46, 173.03],
                                                name: "Kiribati"
                                            },
                                            {
                                                latLng: [-21.13, -175.2],
                                                name: "Tonga"
                                            },
                                            {
                                                latLng: [15.3, -61.38],
                                                name: "Dominica"
                                            },
                                            {
                                                latLng: [-20.2, 57.5],
                                                name: "Mauritius"
                                            },
                                            {
                                                latLng: [26.02, 50.55],
                                                name: "Bahrain"
                                            }
                                        ]}
                                        series={{
                                            regions: [
                                                {
                                                    values: mapData,
                                                    scale: ["#ced4da", "#adb5bd"],
                                                    normalizeFunction: "polynomial"
                                                }
                                            ]
                                        }}
                                    />
                                    <ListGroup className="list my--3" flush>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/icons/flags/US.png")}
                                                    />
                                                </Col>
                                                <div className="col">
                                                    <small>Country:</small>
                                                    <h5 className="mb-0">United States</h5>
                                                </div>
                                                <div className="col">
                                                    <small>Visits:</small>
                                                    <h5 className="mb-0">2500</h5>
                                                </div>
                                                <div className="col">
                                                    <small>Bounce:</small>
                                                    <h5 className="mb-0">30%</h5>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem className="px-0">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/icons/flags/DE.png")}
                                                    />
                                                </Col>
                                                <div className="col">
                                                    <small>Country:</small>
                                                    <h5 className="mb-0">Germany</h5>
                                                </div>
                                                <div className="col">
                                                    <small>Visits:</small>
                                                    <h5 className="mb-0">2500</h5>
                                                </div>
                                                <div className="col">
                                                    <small>Bounce:</small>
                                                    <h5 className="mb-0">30%</h5>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8">
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col">
                                            <h3 className="mb-0">Failed Jobs</h3>
                                        </div>
                                        <div className="col text-right">
                                            <Button
                                                color="primary"
                                                href="#pablo"
                                                onClick={e => window.location.href='/admin/jobs'}
                                                size="sm"
                                            >
                                                See all
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                {this.getJobsTable()}
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.getRealTimeTable()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    getJobsTable(){
        return(
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Date</th>
                        {/*<th>Command</th>*/}
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {this.state.jobs.map ? this.state.jobs.map(el => {
                        return (
                            this.getUserRow(el)
                        )
                    }) : Object.keys(this.state.jobs).map(el => {
                        return (
                            this.getUserRow(this.state.jobs[el])
                        )
                    }) }

                    </tbody>
                </Table>
        );
    }
    getUserRow(el){
        return (
            <tr>
                <td>
                    <span className="text-muted">
                            {el.id}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.name}
                    </span>
                </td>
                <td>
                    <span className="text-muted">
                            {el.created_at ? moment(el.created_at).format('MMM DD YYYY hh:mm') : '---'}
                    </span>
                </td>
                {/*<td>*/}
                {/*    <span className="text-muted">*/}
                {/*            {el.command}*/}
                {/*    </span>*/}
                {/*</td>*/}
                <td>
                    <Badge color={""} className="badge-dot mr-4">
                        <i className={"bg-danger"} />
                        <span className="status">Failed</span>
                    </Badge>
                </td>
            </tr>
        );
    }

    getRealTimeTable(){
        return (
            <Card>
                <CardHeader className="border-0">
                    <Row>
                        <Col xs="6">
                            <h3 className="mb-0 text-success">Users Online Now</h3>
                        </Col>
                    </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                        <th>User</th>
                        <th>Created At</th>
                        <th>Status</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Last Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.onlineUsers.map(el => {
                        return (
                            <tr>
                                <td className="table-user">
                                    <img
                                        alt="..."
                                        className="avatar rounded-circle mr-3"
                                        src={require("assets/img/theme/team-1.jpg")}
                                    />
                                    <b>{el.name}</b>
                                </td>
                                <td>
                                    <span className="text-muted">{moment(el.created_at).format('MMM DD YYYY')}</span>
                                </td>
                                <td>
                                    <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        <span className="status">Online</span>
                                    </Badge>
                                </td>
                                <td>
                                    <span className="text-muted">{el.email}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{el.phone}</span>
                                </td>
                                <td className="table-actions">
                                    <span className="text-muted">{moment(el.last_action).fromNow()}</span>
                                </td>
                            </tr>
                        )
                    })}

                    </tbody>
                </Table>
            </Card>
        )
    }

}

export default Dashboard;
