/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,

} from "reactstrap";
// import Loader from "react-loader-spinner";
import {Link} from "react-router-dom";
import Select2 from "react-select2-wrapper";
import EpicTable from "../../components/Mobi/EpicTable";
import classnames from "classnames";
import SelectRow from "../../components/Mobi/SelectRow";
import {HttpConfig} from "../../tools/Http";
import moment from "moment";
import SelectRowIOS from "../../components/Mobi/SelectRowIOS";
import EpicTableIOS from "../../components/Mobi/EpicTableIOS";
import Utils from "../../Utils";

export default class TopChartsIOS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apps:[],
            userCars:[],
            collection:"",
            country:"",
            category:"",
            lastUrl:"",
            publishingDate:"",
            downloads:0,
            tags:[],
            arabicApps:false,
            removedApps:false,
            url:null,
            emptyTable:false,
            ranking:null,
            loading:true,
            activePage:1,
        };
    }

    componentDidMount() {
        this.getData();
        // this.getCategories();
    }

    getData(url){
        // setTimeout(() => {this.setState({apps:[]})},750);
        if(/*this.state.userCars.length > 0 &&*/ url !== undefined && this.lastUrl !== url){
            this.setState({emptyTable:false,ranking:null, loading:true})
            this.lastUrl = url;
            HttpConfig().get(url)
                .then(res => {
                    if(res.data.status){
                        this.setState({apps:res.data.apps, emptyTable:res.data.length === 0,
                            ranking:res.data.ranking,loading:false
                        });
                    }
                    else{
                        this.setState({emptyTable:true,loading:false})
                    }
                }).catch(err => {

                this.setState({emptyTable:true,loading:false})

            })
            // this.setState({apps:[],loading:false})
        }
    }
    setLoading(loading){
        this.setState({loading})
    }

    getApps(){
        return this.state.apps.slice((this.state.activePage-1)*50, (this.state.activePage-1)*50+50);
    }

    render() {
        return (
            <>
                {this.getHeader()}
                <Container className="mt--6" fluid>
                    <Row className="justify-content-center">
                        <Col className="card-wrapper" lg="12">
                            <SelectRowIOS
                                getData={this.getData.bind(this)}
                                url={this.state.url}/>
                        </Col>
                        <Col className="card-wrapper" lg="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <h3 className="mb-0">{this.getTableTitle()}</h3>
                                        </Col>
                                        {/* <Col className="mt-3 mt-md-0 text-md-right" xl="1" >
                                            {this.state.loading && <Loader
                                                type="Puff"
                                                color="#5E72E4"
                                                height={30}
                                                width={30}
                                                className={"mt-2"}
                                            />}
                                        </Col> */}
                                    </Row>
                                </CardHeader>
                                <EpicTableIOS apps={this.getApps()}
                                           setLoading={this.setLoading.bind(this)}
                                           notify={this.props.notify}
                                           hideDelete={true}
                                              setSortFilter={this.setSortFilter.bind(this)}

                                />
                                <CardFooter className="py-4">
                                    {this.state.apps.length > 0 &&
                                        <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            <PaginationItem >
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.setState({activePage:1})
                                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
                                                    }}
                                                    tabIndex="-1"
                                                >
                                                    <i className="fas fa-angle-left" />
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            {this.state.activePage-1 >= 1 &&
                                                <PaginationItem>
                                                    <PaginationLink
                                                        href="#pablo"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            this.setState({activePage:this.state.activePage-1})
                                                            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });

                                                        }}
                                                    >
                                                        {this.state.activePage-1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            }
                                            <PaginationItem
                                                className="active"
                                            >
                                                <PaginationLink
                                                    onClick={e => e.preventDefault()}

                                                >
                                                    {this.state.activePage}
                                                    <span className="sr-only">(current)</span>
                                                </PaginationLink>
                                            </PaginationItem>

                                            {this.state.activePage + 1 <= this.state.apps.length/50 &&
                                                <PaginationItem>
                                                    <PaginationLink
                                                        href="#pablo"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
                                                            this.setState({activePage:this.state.activePage+1})
                                                        }}
                                                    >
                                                        {this.state.activePage+1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            }

                                            <PaginationItem>
                                                <PaginationLink
                                                    href="#pablo"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
                                                        this.setState({activePage:parseInt((this.state.apps.length/50)+"")})
                                                    }}
                                                >
                                                    <i className="fas fa-angle-right" />
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>

                                        </Pagination>
                                    </nav>
                                    }
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    getHeader(){
        return(
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">
                                    Applications
                                </h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            <i className="fas fa-rocket" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={e => e.preventDefault()}>
                                            App Store Top Charts
                                        </a>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }



    getTableTitle(){
        if(this.state.ranking){
            let str = moment(this.state.ranking.date).format('MMMM Do')+', '+
                (this.state.ranking.collection ? this.state.ranking.collection.replace(/_/g,' ').toUpperCase() : "")+' '+
                (this.state.ranking.category_name ? this.state.ranking.category_name.replace(/_/g,' ').toUpperCase() : "")+' '+
                ' in '+this.state.ranking.country.toUpperCase()+"  ";
            return str;
        }
        return null;
    }

    //
    // getCategories(){
    //     HttpConfig().get('categories')
    //         .then(res => {
    //             if(res.data.status){
    //                 this.setState({userCars:res.data.categories});
    //             }
    //             else{
    //                 //error
    //             }
    //         }).catch(err => {
    //
    //     })
    //
    // }


    setSortFilter(filter, bool, desc){
        let apps = this.state.apps;
        Utils.sortByAttr(apps,filter, bool,desc)
        this.setState({apps});
    }
}
