/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React from 'react';
import {Badge, Card, CardBody, Col, Row, Tooltip} from "reactstrap";
import {Link} from "react-router-dom";

export default class VanillaCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen:false
        }
    }

    render() {
        let card = this.props.card;
        if(!card || card.blocked)
            return this.renderEmpty();
        return(
            <Col key={this.props.i} md="6" lg="4">
                <Card className="bg-gradient-light">
                    <CardBody>
                        <Row className="justify-content-between align-items-center">
                            <div className="col">
                                <img
                                    alt="..."
                                    src={require("assets/img/icons/cards/visa.png")}
                                />
                            </div>
                            <Col className="col-auto">
                                {this.props.switchCardState &&
                                    <Badge className="btn btn-sm" color={card.used ? "warning" : "success"} onClick={() => this.props.switchCardState(card)}>
                                        {card.used ? "Used" : "Not Used"}
                                    </Badge>
                                }
                            </Col>
                        </Row>
                        <div className="my-4">
                            <span className="h6 surtitle text-light">Card number</span>
                            <div className="card-serial-number h1 text-white">
                                <div>{card? card.serial.substring(0,4) : "----"}</div>
                                <div>{card? card.serial.substring(4,8) : "----"}</div>
                                <div>{card? card.serial.substring(8,12) : "----"}</div>
                                <div>{card? card.serial.substring(12,16) : "----"}</div>
                            </div>
                        </div>
                        <Row>
                            <div className="col">
                                <span className="h6 surtitle text-light">Name</span>
                                <span className="d-block h3 text-white">Vanilla Credit Card</span>
                            </div>
                            <div className="col">
                                      <span className="h6 surtitle text-light">
                                        Expiry date
                                      </span>
                                <span className="d-block h3 text-white">{card? card.expires : "--/--"}</span>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }

    renderEmpty(){
        return(
            <Col key={this.props.i} md="6" lg="4">
                <Card className="bg-gradient-light">
                    <CardBody style={{opacity:1}}>
                        <Row className="justify-content-between align-items-center">
                            <div className="col">
                                <img

                                    alt="..."
                                    src={require("assets/img/icons/cards/visa.png")}
                                />
                            </div>
                        </Row>
                        <div className="my-4">
                            <span className="h6 surtitle text-light">Card number</span>
                            <div className="card-serial-number h1 text-white">
                                <div>0000</div>
                                <div>0000</div>
                                <div>0000</div>
                                <div>0000</div>
                            </div>
                        </div>
                        <Row>
                            <div className="col">
                                <span className="h6 surtitle text-light">Name</span>
                                <span className="d-block h3 text-white">Vanilla Credit Card</span>
                            </div>
                            <div className="col">
                                      <span className="h6 surtitle text-light">
                                        Expiry date
                                      </span>
                                <span className="d-block h3 text-white">00/00</span>
                            </div>
                        </Row>
                    </CardBody>
                    <Link
                        to={"/cards"}
                        className={'add-card'}
                         style={{
                             border: 'dashed 1px #fff',
                             borderRadius: 20,
                             width: 40,
                             height: 40,
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center',
                             position: 'absolute',
                             top:0,
                             right: 0,
                             marginRight: 8,
                             marginTop: 8,
                         }}>
                        <i className={"fa fa-plus"}  id="TooltipExample" style={{ alignSelf: 'center', fontSize:15, color:"#fff"}}/>
                        <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="TooltipExample" toggle={() => this.setState({tooltipOpen:!this.state.tooltipOpen})}>
                            Purchase a new card
                        </Tooltip>
                    </Link>
                </Card>
            </Col>
        )
    }
}
