/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  FormText,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles.scss";
import { HttpConfig } from "tools/Http";
import EpicTable from "components/Mobi/EpicTable";
import Utils from "../../Utils";
import { useDispatch } from "react-redux";


export default ({notify}) => {
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [devID, setDevID] = useState('');
  const [UUID, setUUID] = useState('');
  const [name, setName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [primaryColor, setPrimaryColor] = useState('');
  const [backgroundBanner, setBackgroundBanner] = useState('');
  const [darkMode, setDarkMode] = useState();
  const [isPublished, setIsPublished] = useState();
  const [IDGoogleAnalytics, setIDGoogleAnalytics] = useState('');
  const [SEOTitle, setSEOTitle] = useState('');
  const [SEODescription, setSEODescription] = useState('');
  const [SEOKeywords, setSEOKeywords] = useState('');
  const [statusPP, setStatusPP] = useState('');
  const [contentPP, setContentPP] = useState('');
  const [icon, setIcon] = useState('');
  const [favicon, setFavicon] = useState('');
  const [selectedTab, setSelectedTab] = useState('tab1');
  const [includePrivacy, setIncludePrivacy] = useState(false);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState('');
  const dispatch = useDispatch()

  useEffect(() => {
    getPages();
  }, [0]);

  const getPages = () => {
    setLoading(true)
    HttpConfig(dispatch).get('lists-pages-developer')
    .then(response => {
        if (response.data.status) {
          setPages(response.data.settings_page)
          setLoading(false)
        } else {
          setLoading(false)
        }
    })
    .catch(err => {
        console.log(err)
        setLoading(false)
    });
  }

  const addLanding = () => {
    setModal(false)
    setLoading(true)
    console.log(includePrivacy)
    HttpConfig().post('create-new-page', {
      "name": devID,
      "include_privacy": includePrivacy,
    }).then(response => {
      setLoading(false)
      if(response.data.status) {
        getPages()
        notify("success", "Created", response.data.message)
      }
      else {
        notify("danger", "Failed", response.data.message)
        setModal(true)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const deleteLanding = (selectedUUID) => {
    setDeleteModal(false)
    setLoading(true)
    HttpConfig().delete('delete-page-developer/' + selectedUUID)
    .then(response => {
      setLoading(false)
      if(response.data.status) {
        getPages()
        notify("success", "Deleted", response.data.message)
      }
      else {
        notify("danger", "Failed", response.data.message)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const updatePage = (e) => {
    e.preventDefault()
    setLoading(true)
    setSettingsModal(false)
    HttpConfig().post('update-settings-page/' + UUID, {
      "primary_color": primaryColor,
      "background_banner": backgroundBanner,
      "favicon": favicon,
      "icon": icon,
      "primary_color": primaryColor,
      "dark_mode": darkMode,
      "analytics_id": IDGoogleAnalytics,
      "seo_title": SEOTitle,
      "seo_description": SEODescription,
      "seo_keywords": SEOKeywords,
      "status_pp": statusPP,
      "content_pp": contentPP,
      "is_publish": isPublished
    }).then(response => {
      setLoading(false)
      if(response.data.status) {
        notify("success", "Saved", response.data.message)
      }
      else {
        setSettingsModal(true)
        notify("danger", "Failed", response.data.message)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  const handleFileRead = async (event, type) => {
    var reader = new FileReader();
    var file = event.target.files[0];

    reader.onload = function(upload) {
      if (type == "icon")
        setIcon(upload.target.result)
      if (type == "favicon")
        setFavicon(upload.target.result)
      if (type == "banner")
        setBackgroundBanner(upload.target.result)
    };
    reader.readAsDataURL(file);
    console.log(icon)
  }

  const configurePage = (pageID) => {
    setUUID(pageID)
    setConfigureLoading(true)
    console.log(includePrivacy)
    HttpConfig().get('settings-page/' + pageID)
    .then(response => {
      if(response.data.status) {
        setIDGoogleAnalytics(response.data.settings_page.analytics_id)
        setBackgroundBanner(response.data.settings_page.background_banner)
        setContentPP(response.data.settings_page.content_pp)
        setDarkMode(response.data.settings_page.dark_mode)
        setIcon(response.data.settings_page.icon)
        setFavicon(response.data.settings_page.favicon)
        setIsPublished(response.data.settings_page.is_publish)
        setName(response.data.settings_page.name)
        setPrimaryColor(response.data.settings_page.primary_color)
        setSEODescription(response.data.settings_page.seo_description)
        setSEOTitle(response.data.settings_page.seo_title)
        setSEOKeywords(response.data.settings_page.seo_keywords)
        setStatusPP(response.data.settings_page.status_pp)
        setSubdomain(response.data.settings_page.sub_domain)
        setSettingsModal(true)
        setConfigureLoading(false)
      }
    }).catch(err => {
      setConfigureLoading(false)
      console.log(err)
    })
  }

  return (
    <>
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={modal}
      toggle={() => setModal(!modal)}
    >
      <div className="modal-header">
        <Col>
          <h6 className="modal-title" id="modal-title-notification">
            Add Landing Page
          </h6>
          <FormGroup className={"pt-4"}>
            <label className="form-control-label">
              Developer ID or Name
            </label>
            <Input
              placeholder="Developer ID or Name"
              type="text"
              value={devID}
              onChange={e => setDevID(e.target.value)}
            />
            <div className="mt-3 custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="includepp"
                type="checkbox"
                checked={includePrivacy}
                onChange={e => setIncludePrivacy(e.target.checked)}
              />
              <label
                  className="custom-control-label"
                  htmlFor="includepp"
              >
                  <span className="text-muted">Include Privacy Policy</span>
              </label>
            </div>
          </FormGroup>
          <div style={{ display: "flex", alignItems: "space-between" }}>
            <Button
              color="success"
              size="sm"
              type="button"
              onClick={addLanding}
            >
              Add Landing Page
            </Button>
            <Button
              className="ml-auto"
              color="link"
              size="sm"
              data-dismiss="modal"
              type="button"
              onClick={() => setModal(!modal)}
            >
              Close
            </Button>
          </div>
        </Col>
      </div>
    </Modal>
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={deleteModal}
      toggle={() => setDeleteModal(!deleteModal)}
    >
      <div className="modal-header pb-0">
        <Col>
          <h6 className="modal-title text-center" id="modal-title-notification">
            Are you sure?
          </h6>
        </Col>
      </div>
      <div className="modal-footer">
        <Button
          color="primary"
          type="button"
          style={{width:'47%'}}
          onClick={e => deleteLanding(selectedDelete)}
          disabled={loading}
        >
          <i className="fas fa-check-circle fa-lg"></i>&nbsp;&nbsp;Save
        </Button>
        <Button
          className="ml-auto"
          color="danger"
          data-dismiss="modal"
          type="button"
          style={{width:'47%'}}
          onClick={() => {setSelectedDelete(''); setSettingsModal(!settingsModal)}}
        >
          <i className="fas fa-times-circle fa-lg"></i>&nbsp;&nbsp;Cancel
        </Button>
      </div>
    </Modal>
    <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={settingsModal}
        toggle={() => setSettingsModal(!settingsModal)}
      >
        <div className="modal-header p-0 bg-success" style={{display: 'flex', borderRadius: "7px", justifyContent: 'center'}}>
          <Row style={{width: '100%'}}>
            <Col className={selectedTab == "tab1" ? "bg-gradient-success" : "bg-gradient-info"} style={{display: "flex", alignItems: "center", height: 40, cursor: 'pointer', justifyContent: 'center', color:'#FFF', borderRadius: "7px 7px 0px 0px"}} lg={4} onClick={() => setSelectedTab('tab1')}>
              <div>Page Settings</div>
            </Col>
            <Col className={selectedTab == "tab2" ? "bg-gradient-success" : "bg-gradient-info"} style={{display: "flex", alignItems: "center", height: 40, cursor: 'pointer', justifyContent: 'center', color:'#FFF', borderRadius: "7px 7px 0px 0px"}} lg={4} onClick={() => setSelectedTab('tab2')}>
              SEO
            </Col>
            <Col className={selectedTab == "tab3" ? "bg-gradient-success" : "bg-gradient-info"} style={{display: "flex", alignItems: "center", height: 40, cursor: 'pointer', justifyContent: 'center', color:'#FFF', borderRadius: "7px 7px 0px 0px"}} lg={4} onClick={() => setSelectedTab('tab3')}>
              Privacy Policy
            </Col>
          </Row>
        </div>
        <div className="modal-body pb-0">
          <Row hidden={selectedTab !== 'tab1'}>
            <Col xs={12}>
              <Row style={{marginBottom: 8}}>
                <Col xs={6}>
                  <div className="custom-control text-center custom-control-alternative custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="darkmode"
                        type="checkbox"
                        checked={darkMode}
                        onChange={e => setDarkMode(e.target.checked)}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="darkmode"
                    >
                        <span className="text-muted">Dark Mode</span>
                    </label>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="custom-control text-center custom-control-alternative custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="published"
                        type="checkbox"
                        checked={isPublished}
                        onChange={e => setIsPublished(e.target.checked)}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="published"
                    >
                        <span className="text-muted">Published</span>
                    </label>
                  </div>
                </Col>
              </Row>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  Developer ID or Name
                </label>
                <Input
                  placeholder="Developer ID or Name"
                  type="text"
                  value={name}
                  readOnly
                />
              </FormGroup>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  Subdomain
                </label>
                <Input
                  placeholder="Subdomain"
                  type="text"
                  value={subdomain}
                  readOnly
                />
              </FormGroup>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  Primary Color
                </label>
                <Input
                  type="color"
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                />
              </FormGroup>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  Google Analytics ID
                </label>
                <Input
                  placeholder="Google Analytics ID"
                  type="text"
                  value={IDGoogleAnalytics}
                  onChange={(e) => setIDGoogleAnalytics(e.target.value)}
                />
              </FormGroup>
              <Row style={{marginBottom: 8}}>
                <Col xs={8}>
                  <FormGroup className={'m-0'}>
                    <label className="form-control-label m-0">
                      Background Banner
                    </label>
                    <Input
                      placeholder="Background Banner"
                      type="file"
                      // value={backgroundBanner}
                      accept="image/*"
                      onChange={e => handleFileRead(e, 'banner')}
                    />
                    <FormText>recommended size 1920x1080</FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                  <img src={backgroundBanner} height={50} />
                </Col>
              </Row>
              <Row style={{marginBottom: 8}}>
                <Col xs={8}>
                  <FormGroup className={'m-0'}>
                    <label className="form-control-label m-0">
                      Icon
                    </label>
                    <Input
                      placeholder="Icon"
                      type="file"
                      // value={icon}
                      accept="image/*"
                      onChange={e => handleFileRead(e, 'icon')}
                    />
                  <FormText>recommended size 500x500</FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                  <img src={icon} height={50} />
                </Col>
              </Row>
              <Row style={{marginBottom: 8}}>
                <Col xs={8}>
                  <FormGroup className={'m-0'}>
                    <label className="form-control-label m-0">
                      Favicon
                    </label>
                    <Input
                      placeholder="Favicon"
                      type="file"
                      // value={favicon}
                      accept="image/*"
                      onChange={e => handleFileRead(e, 'favicon')}
                    />
                  <FormText>recommended size 64x64</FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
                  <img src={favicon} height={50} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row hidden={selectedTab !== 'tab2'}>
            <Col xs={12}>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  SEO Title
                </label>
                <Input
                  placeholder="SEO Title"
                  type="text"
                  value={SEOTitle}
                  onChange={e => handleFileRead(e, 'icon')}
                />
              </FormGroup>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  SEO Description
                </label>
                <textarea
                  placeholder="SEO Description"
                  type="text"
                  className="form-control"
                  rows={15}
                  value={SEODescription}
                  onChange={(e) => setSEODescription(e.target.value)}
                />
              </FormGroup>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  SEO Keywords
                </label>
                <Input
                  placeholder="keyword1, keyword2, ..."
                  type="text"
                  value={SEOKeywords}
                  onChange={(e) => setSEOKeywords(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row hidden={selectedTab !== 'tab3'}>
            <Col xs={12}>
              <div className="custom-control text-center custom-control-alternative custom-checkbox">
                <input
                    className="custom-control-input"
                    id="statuspp"
                    type="checkbox"
                    checked={statusPP}
                    onChange={e => setStatusPP(e.target.checked)}
                />
                <label
                    className="custom-control-label"
                    htmlFor="statuspp"
                >
                    <span className="text-muted">Display Privacy Policy</span>
                </label>
              </div>
              <FormGroup style={{marginBottom: 8}}>
                <label className="form-control-label m-0">
                  Privacy Policy Content
                </label>
                <textarea
                  placeholder="Privacy Policy Content"
                  className="form-control"
                  type="text"
                  rows={20}
                  value={contentPP}
                  onChange={(e) => setContentPP(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            type="button"
            style={{width:'47%'}}
            onClick={updatePage}
            disabled={loading}
          >
            <i className="fas fa-check-circle fa-lg"></i>&nbsp;&nbsp;Save
          </Button>
          <Button
            className="ml-auto"
            color="danger"
            data-dismiss="modal"
            type="button"
            style={{width:'47%'}}
            onClick={() => setSettingsModal(!settingsModal)}
          >
            <i className="fas fa-times-circle fa-lg"></i>&nbsp;&nbsp;Cancel
          </Button>
        </div>
      </Modal>
      {loading ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            zIndex: 1,
            height: "100%",
            width: "100%",
            top: 0,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,.2)",
          }}
        >
          <ClipLoader color={"white"} loading={true} size={50} />
        </div>
      ) : null}
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Developer Landing Page
                </h6>
                <Breadcrumb
                  className="d-none d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <i className="fas fa-laptop-code" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      Developer Landing page
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                <Button
                  className="btn-neutral"
                  color="default"
                  title="Add New"
                  size="sm"
                  onClick={() => setModal(true)}
                >
                  <i className="fas fa-plus fa-lg"></i>&nbsp;&nbsp; New Landing Page
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Card style={{height: "80vh", display: "flex", overflowY: 'auto'}}>
          <CardHeader>
            <Row>
              <Col lg="6" xs="7">
                <h3 className="mb-0">Landing Pages</h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className={"p-0"}>
            {pages.length > 0 && !loading ?
              <div className="table-responsive">
                <Table className="align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Developer Name</th>
                      <th scope="col">Subdomain</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pages.map((el, index) => {
                      return(
                        <tr key={index}>
                          <td><h5>{el.name}</h5></td>
                          <td><h5>{el.subdomain}</h5></td>
                          <td><h5>{el.created_at}</h5></td>
                          <td>
                            <div>
                              <Button className={'btn btn-sm btn-outline-primary'} title={'Preview Page'} onClick={e => {window.open("https://" + el.subdomain, "_blank");}}><i className="fas fa-external-link-alt fa-lg"></i></Button>
                              <Button className={'btn btn-sm btn-outline-success'} title={'Copy Page Link'} onClick={e => {navigator.clipboard.writeText("https://" + el.subdomain);notify("success", "Success", "Link Copied Successfully");}}><i className="far fa-clipboard fa-lg"></i></Button>
                              <Button className={'btn btn-sm btn-outline-info'} title={'Configure Page'} disabled={configureLoading} onClick={() => configurePage(el.uuid)}>{configureLoading && UUID == el.uuid ? <ClipLoader color={"blue"} loading={true} size={10} /> : <i className="fas fa-cog fa-lg"></i>}</Button>
                              <Button className={'btn btn-sm btn-outline-danger'} title={'Delete Page'} onClick={e => {setSelectedDelete(el.uuid); setDeleteModal(true)}}><i className="fas fa-trash-alt fa-lg"></i></Button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
              :
                pages.length == 0 && !loading ?
                  <p className='text-muet text-center mt-3'>No landing page yet. You can create new one!</p>
                :
                  null
              }
          </CardBody>
        </Card>
      </Container>
    </>
  );

  // .catch(err => {
  //   this.setState({loading: false})
  //   this.props.notify(
  //     "danger",
  //     "Error",
  //     "There was an error deleting selected app"
  //   );
  //   //err
  // });
}